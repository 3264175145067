import React, { useContext } from "react";
import { RootStoreContext } from "ticker-core";

// import PropTypes from "prop-types";

const withRootStore = ComponentToWrap => {
  return function RootStoreContextComponent(props) {
    let rootStore = useContext(RootStoreContext);
    return <ComponentToWrap {...props} rootStore={rootStore} />;
  };
};

export default withRootStore;
