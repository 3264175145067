import { computed } from "mobx";
import { gradeTypes } from "../../variables/constants";

let byOrderPosition = (a, b) => {
  return a.item.orderPosition - b.item.orderPosition;
};

let getEmptyFilterAnalysis = () => {
  return {
    wrappers: [],
    unfilteredCount: 0,
    filteredCount: 0
  };
};

export default class FilterAnalysisStore {
  constructor(climbSelectionStore, computedPersonalCacheStore, isCacheLoaded) {
    this.sortedFilterAnalysises = computed(() => {
      let retVal = this.filterAnalysises.get();
      retVal.climbingRouteAssemblies.sort(climbSelectionStore.sortBy);
      return retVal;
    });

    this.filterAnalysises = computed(() => {
      /*
       * The unfiltered counts are the number of that item that are not filtered.
       * e.g. if you select a wall and grade that means that there are only 2 colors
       * available, then the unfilteredCount = 2
       *
       * For wall, and section, the unfilteredCount is the same as the length
       * of the wrappers array.
       * But for the grades and the Colors, the length of the wrappers array is wrong because the arrays are used more
       * like a map. This is because the ids of an item (e.g. color) are used to reference the wrapper
       * e.g. color.wrappers[colorId]... where colorId = 1528 for example. Therefore the length of the array might be 1528.
       */
      let retVal = {
        section: getEmptyFilterAnalysis(),
        wall: getEmptyFilterAnalysis(),
        color: getEmptyFilterAnalysis(),
        onHoldValue: getEmptyFilterAnalysis(),
        grades: [],
        climbingRouteAssemblies: []
      };

      retVal.onHoldValue.wrappers[1] = {
        item: { onHold: true, label: "On Hold", id: 1},
        visibleClimbsCount: 0
      };
      retVal.onHoldValue.wrappers[0] = {
        item: { onHold: false, label: "Not On Hold", id: 0},
        visibleClimbsCount: 0
      };
      
      console.log("THE WRAPPER from FA Store=" + JSON.stringify(retVal.onHoldValue.wrappers));

      let filteredColors = [];
      let filteredGradeses = [];

      gradeTypes.ALL.forEach(gradeType => {
        retVal.grades[gradeType] = getEmptyFilterAnalysis();
        filteredGradeses[gradeType] = [];
      });

      if (!isCacheLoaded()) {
        return retVal;
      }

      let computedCache = computedPersonalCacheStore.computedPersonalCache.get();

      for (let sectionAssembly of computedCache.sectionAssemblies) {
        // If a different section is selected
        // or if it is archived
        // then don't include this one

        if (sectionAssembly.climbingSection.isArchived === true) {
          continue;
        }

        let newSectionWrapper = {
          item: sectionAssembly.climbingSection,
          visibleClimbsCount: 0
        };

        let sectionIsFiltered = false;

        if (
          climbSelectionStore.selectedSectionWrapper !== null &&
          climbSelectionStore.selectedSectionWrapper.item.id !==
            sectionAssembly.climbingSection.id
        ) {
          sectionIsFiltered = true;
        }

        let atLeastOneClimbOnThisSectionIsNotFilteredByColorGradeOrWall = false;
        for (let wallAssembly of sectionAssembly.climbingWallAssemblies) {
          // if this wall is archived
          // or a different wall has been selected
          // then skip it
          if (wallAssembly.climbingWall.isArchived) {
            continue;
          }

          let atLeastOneClimbOnThisWallIsNotFilteredByColorGradeOrSection = false;
          let newWallWrapper = {
            item: wallAssembly.climbingWall,
            visibleClimbsCount: 0
          };

          let wallIsFiltered = false;
          if (
            // A different wall has been selected
            (climbSelectionStore.selectedWallWrapper !== null &&
              climbSelectionStore.selectedWallWrapper.item.id !==
                wallAssembly.climbingWall.id) ||
            // A non compatible grade has been selected
            (climbSelectionStore.selectedGradeWrapper &&
              climbSelectionStore.selectedGradeWrapper.item.gradeType !==
                wallAssembly.climbingWall.gradeType)
          ) {
            wallIsFiltered = true;
          }

          for (let climbingRouteAssembly of wallAssembly.climbingRouteAssemblies) {
            // If the route is archived then skip it
            if (climbingRouteAssembly.isArchived) {
              continue;
            }

            let colorIsFiltered = false;
            // if this climb is not the right color
            // then skip it
            if (
              climbSelectionStore.selectedColorWrapper !== null &&
              climbingRouteAssembly.markerColor.id !==
                climbSelectionStore.selectedColorWrapper.item.id
            ) {
              colorIsFiltered = true;
            }

            let gradeIsFiltered = false;

            if (
              climbSelectionStore.selectedGradeWrapper !== null &&
              climbingRouteAssembly.climbingGrade.id !==
                climbSelectionStore.selectedGradeWrapper.item.id
            ) {
              gradeIsFiltered = true;
            }

            let onHoldIsFiltered = false;
            if (climbSelectionStore.selectedOnHoldWrapper !== null &&
              climbingRouteAssembly.climbingRoute.isOnHold !== climbSelectionStore.selectedOnHoldWrapper.item.onHold){
                onHoldIsFiltered = true;
            }

            let climbHasBeenFiltered = false;

            if (
              gradeIsFiltered ||
              wallIsFiltered ||
              colorIsFiltered ||
              sectionIsFiltered ||
              onHoldIsFiltered
            ) {
              climbHasBeenFiltered = true;
            }

            if (!sectionIsFiltered && !colorIsFiltered && !gradeIsFiltered && !onHoldIsFiltered) {
              atLeastOneClimbOnThisWallIsNotFilteredByColorGradeOrSection = true;
            }

            if (!wallIsFiltered && !colorIsFiltered && !gradeIsFiltered && !onHoldIsFiltered) {
              atLeastOneClimbOnThisSectionIsNotFilteredByColorGradeOrWall = true;
            }

            if (!climbHasBeenFiltered) {
              /*
               * This route has not been filtered so add it to the list
               */
              retVal.climbingRouteAssemblies.push(climbingRouteAssembly);

              // addRegrades(
              //   climbingRouteAssembly,

              //   gradeStores
              // );

              // addLogEntryCounts(climbingRouteAssembly, cachedLogCountStore);
            }

            /*
             * This current climb has not been filtered so we need to increment
             * all the counts for each button
             */

            if (!climbHasBeenFiltered) {
              newWallWrapper.visibleClimbsCount++;
              newSectionWrapper.visibleClimbsCount++;
            }

            /*
             * Increment the count for the grade
             */
            if (!sectionIsFiltered && !wallIsFiltered && !colorIsFiltered && !onHoldIsFiltered) {
              //Get the grade wrapper for this gradeType and ID
              let gradeWrapper =
                retVal.grades[climbingRouteAssembly.climbingGrade.gradeType]
                  .wrappers[climbingRouteAssembly.climbingGrade.id];

              // If no gradeWrapper has been created yet, then create one
              if (!gradeWrapper) {
                gradeWrapper = {
                  item: climbingRouteAssembly.climbingGrade,
                  visibleClimbsCount: 0
                };
                retVal.grades[
                  climbingRouteAssembly.climbingGrade.gradeType
                ].wrappers[
                  climbingRouteAssembly.climbingGrade.id
                ] = gradeWrapper;
              }

              if (!climbHasBeenFiltered) {
                //Add one to this grade wrapper's visibleClimbCount
                gradeWrapper.visibleClimbsCount++;
              }
            }
            // else we need to keep a track of which grades HAVE been filtered
            // so that we can increment the counter
            else {
              // Make sure we haven't noted that this grade has been filtered already.
              if (
                !filteredGradeses[
                  climbingRouteAssembly.climbingGrade.gradeType
                ].includes(climbingRouteAssembly.markerColor.id)
              ) {
                filteredGradeses[
                  climbingRouteAssembly.climbingGrade.gradeType
                ].push(climbingRouteAssembly.markerColor.id);
              }
            }

            /*
             * Increment the count for the color
             */
            if (!gradeIsFiltered && !sectionIsFiltered && !wallIsFiltered && !onHoldIsFiltered) {
              let colorWrapper =
                retVal.color.wrappers[climbingRouteAssembly.markerColor.id];

              if (!colorWrapper) {
                colorWrapper = {
                  item: climbingRouteAssembly.markerColor,
                  visibleClimbsCount: 0
                };
                retVal.color.wrappers[
                  climbingRouteAssembly.markerColor.id
                ] = colorWrapper;
              }

              if (!climbHasBeenFiltered) {
                colorWrapper.visibleClimbsCount++;
              }
            }
            // else we need to keep a track of which colors HAVE been filtered
            // so that we can increment the counter
            else {
              // Make sure we haven't noted that this color has been filtered already.
              if (
                !filteredColors.includes(climbingRouteAssembly.markerColor.id)
              ) {
                filteredColors.push(climbingRouteAssembly.markerColor.id);
              }
            }

            /*
             * Increment the count for the onHolds
             */
            if (!gradeIsFiltered && !sectionIsFiltered && !wallIsFiltered && !colorIsFiltered) {
              if (!climbHasBeenFiltered){
                let onHoldWrapper = retVal.onHoldValue.wrappers[climbingRouteAssembly.climbingRoute.isOnHold ? 1 : 0]
                onHoldWrapper.visibleClimbsCount++;
              }
            }
          }
          if (
            newWallWrapper.visibleClimbsCount > 0 ||
            atLeastOneClimbOnThisWallIsNotFilteredByColorGradeOrSection
          ) {
            retVal.wall.wrappers.push(newWallWrapper);
            if (!wallIsFiltered) {
              retVal.wall.unfilteredCount++;
            }
          } else {
            retVal.wall.filteredCount++;
          }
        }
        if (
          newSectionWrapper.visibleClimbsCount > 0 ||
          atLeastOneClimbOnThisSectionIsNotFilteredByColorGradeOrWall
        ) {
          retVal.section.wrappers.push(newSectionWrapper);

          if (!sectionIsFiltered) {
            retVal.section.unfilteredCount++;
          }
        } else {
          retVal.section.filteredCount++;
        }
      }

      retVal.color.wrappers.sort(byOrderPosition);

      retVal.color.filteredCount = filteredColors.length;
      retVal.color.unfilteredCount = 0;
      retVal.color.wrappers.forEach(wrapper => {
        if (wrapper) {
          retVal.color.unfilteredCount++;
        }
      });

      retVal.grades.forEach((filterAnalysis, gradeIndex) => {
        filterAnalysis.filteredCount = filteredGradeses[gradeIndex].length;
        filterAnalysis.wrappers.sort(byOrderPosition);

        filterAnalysis.wrappers.forEach(wrapper => {
          if (wrapper) {
            filterAnalysis.unfilteredCount++;
          }
        });
      });

      retVal.onHoldValue.unfilteredCount = 2;
      retVal.onHoldValue.filteredCount = 0;
      return retVal;
    });
  }
}
