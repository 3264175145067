import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import styles from "../../assets/jss/material-dashboard-pro-react/components/mainNavbarStyle.js";
import { withCachedRootStore } from "ticker-core";
import { useObserver } from "mobx-react";
import MainNavBarDropDown from "./MainNavBarDropDown.js";
import ElevationScroll from "./ElevationScroll.js";

const useStyles = makeStyles(styles);
function MainNavbar(props) {
  // verifies if routeName is the one active (in browser input)
  const isActiveRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  const classes = useStyles();
  const { color, routes, layout, rootStore } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });

  const {
    regStores,
    personalRootStore: {
      personalLogEntryStores: { logEntryStore }
    }
  } = rootStore;

  const { regStore } = regStores;

  return useObserver(() => {
    const registrationIsLoggedIn = regStores.isRegistered();

    const showLogoutAlert =
      registrationIsLoggedIn &&
      regStore.noActivityCountDown < 6 &&
      regStore.noActivityCountDown > 0;
    // console.log(
    //   "The details from mainnavbar registrationIsLoggedIn=" +
    //     registrationIsLoggedIn +
    //     "...regStore.noActivityCountDown=" +
    //     regStore.noActivityCountDown +
    //     ".. logout=" +
    //     showLogoutAlert
    // );

    // let activeRouteTitle;
    var list = (
      <List className={classes.list}>
        {routes.map(route => {
          if (layout !== route.layout) return null;
          if (route.hideInNavBar) return null;
          if (route.showToLoggedInRegistrationsOnly && !registrationIsLoggedIn)
            return null;
          if (route.showToLoggedOutRegistrationsOnly && registrationIsLoggedIn)
            return null;

          // Default to a
          let rename = route.name;
          let smallName = route.smallName ? route.smallName : route.name;

          if (route.useUserName) {
            rename = regStore.userName;
            smallName = regStore.userName;
          } else if (route.useDateOption) {
            // If there is a label that matches this selection
            // then use it
            logEntryStore.dateOptions.forEach(dateOption => {
              if (logEntryStore.dateOption === dateOption) {
                rename = dateOption.label;
                smallName = dateOption.label;
              }
            });
            // If no label was found then it must be a date
            // so use the date as the label
            if (rename === route.name) {
              rename = logEntryStore.dateStr;
              smallName = rename;
            }
          }

          let completeRoute = route.layout + route.path;

          // if (isActiveRoute(completeRoute)) {
          //   if (route.useDateOption) {
          //     activeRouteTitle = "Date climbed is set to " + rename;
          //   } else {
          //     activeRouteTitle = rename;
          //   }
          // }
          if (route.collapse) {
            return (
              <ListItem
                className={classes.listItem}
                key={completeRoute + route.name}
              >
                <MainNavBarDropDown route={route} rootStore={rootStore} />
              </ListItem>
            );
          }
          return (
            <ListItem
              className={classes.listItem}
              key={completeRoute + route.name}
            >
              <NavLink
                to={completeRoute}
                className={cx(classes.navLink, {
                  [classes.navLinkActive]: isActiveRoute(completeRoute)
                })}
                onClick={regStore.resetNoActivityTimeout}
              >
                <Hidden smDown>
                  {route.icon && (
                    <route.icon className={classes.listItemIcon} />
                  )}
                  {rename ? (
                    <ListItemText
                      primary={
                        rename +
                        (route.showCountDown && registrationIsLoggedIn
                          ? " " + regStore.noActivityCountDown
                          : "")
                      }
                      disableTypography={true}
                      className={classes.listItemText}
                    />
                  ) : null}
                </Hidden>
                <Hidden mdUp>
                  {route.icon && (
                    <route.icon
                      className={
                        classes.listItemIcon + " " + classes.centerIcon
                      }
                    />
                  )}
                  <span className={classes.smallName}>
                    {smallName +
                      (route.showCountDown && registrationIsLoggedIn
                        ? " " + regStore.noActivityCountDown
                        : "")}
                  </span>
                </Hidden>
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );

    return (
      <>
        {showLogoutAlert && (
          <SweetAlert
            // style={{ display: "block", marginTop: "-100px", position: "fixed" }}
            title={"You will be logged out in a few seconds"}
            onConfirm={() => regStore.resetNoActivityTimeout()}
            onCancel={() => regStore.logoutRegistration()}
            confirmBtnText="Stay logged in"
            cancelBtnText="Logout"
            confirmBtnCssClass={
              classes.alertButton + " " + classes.alertSuccess
            }
            cancelBtnCssClass={classes.alertButton + " " + classes.alertDanger}
            showCancel
          />
        )}
        <ElevationScroll>
          <AppBar position="fixed" className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container} disableGutters>
              <div className={classes.listy}>{list}</div>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </>
    );
  });
}

MainNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string,
      layout: PropTypes.string.isRequired,
      icon: PropTypes.elementType
    })
  ),
  layout: PropTypes.string,
  rootStore: PropTypes.object.isRequired
};

export default withCachedRootStore(MainNavbar);
