export const SPREAD = 10;

export function getAbsoluteCombinedModifier(orderPosition, modifier) {
  return orderPosition * SPREAD + modifier;
}

export function getRelativeCombinedModifier(
  avgCombinedModifier,
  orderPosition
) {
  if (isNaN(avgCombinedModifier) || isNaN(orderPosition)) {
    debugger;
  }
  return avgCombinedModifier - SPREAD * orderPosition;
}

export default {
  getAbsoluteCombinedModifier,
  getRelativeCombinedModifier,
  SPREAD
};
