import { useObserver } from "mobx-react";
import React, { useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { CachedRootStoreContext } from "./CachedRootStoreProvider";
import { HANGDOG_ID } from "../variables/constants";

function withCachedRootStore(ComponentToWrap) {
  return function CachedRootStoreContextComponent(props) {
    let rootStore = useContext(CachedRootStoreContext);
    return useObserver(() => {
      let targetGym =
        rootStore.userStores.profileStore.gym_id !== null
          ? rootStore.userStores.profileStore.gym_id
          : rootStore.userStores.profileStore.selected_gym_id;

      if (!targetGym) {
        targetGym = HANGDOG_ID;
      }

      if (!rootStore.isCacheLoaded() && targetGym !== null) {
        rootStore.loadCache({
          gym_id: targetGym
        });
      } else {
        // console.log(
        //   "Not loading cache in CDM: gym_id=" +
        //     rootStore.userStores.profileStore.gym_id +
        //     "... selectedId" +
        //     rootStore.userStores.profileStore.selected_gym_id
        // );
      }

      //console.log("Rendering the storeWrapper");
      if (rootStore.isCacheLoaded()) {
        return <ComponentToWrap {...props} rootStore={rootStore} />;
      } else {
        return (
          <div
            style={{ height: "100em", textAlign: "center", paddingTop: "5em" }}
          >
            <div style={{ paddingBottom: "1em" }}>Loading gym details</div>{" "}
            <CircularProgress />
          </div>
        );
      }
    });
  };
}

export default withCachedRootStore;
