import { action } from "mobx";

import getCachable from "../../utils/getCachable";

let areEqual = (a, b) => a.id === b.id;

export default class /* PersonalRegradeStores */ {
  constructor(
    context,
    asyncOpCreate,
    regStore,
    gradeStores,
    overwriteIdentificationFieldsFn,
    httpCodeHandlerFactory
  ) {
    let errorHandler = httpCodeHandlerFactory.getDefaultCopy();
    errorHandler.handleStatusFns[409] = () => {
      //do nothing because the user isn't registered
    };

    this.cachedOrganisations = getCachable(
      asyncOpCreate,
      context.rest,
      areEqual,
      "organisations",
      null,
      () => overwriteIdentificationFieldsFn({}),
      errorHandler
    );

    this.organisationStore = getOrganisationStore(
      asyncOpCreate,
      context.rest,
      this.cachedOrganisations,
      overwriteIdentificationFieldsFn,
      httpCodeHandlerFactory
    );

    this.memberStore = getMemberStore(
      asyncOpCreate,
      context.rest,
      overwriteIdentificationFieldsFn
    );
  }
}

function getMemberStore(asyncOpCreate, rest, overwriteIdentificationFieldsFn) {
  const this__ = {};
  this__.opGetMembers = asyncOpCreate(
    payload => rest.get("members", payload),
    "opGetMembers"
  );

  this__.getMembers = action(async function(payload) {
    this__.opGetMembers.execute(overwriteIdentificationFieldsFn(payload));
  });
  return this__;
}

function getOrganisationStore(
  asyncOpCreate,
  rest,
  cachedOrganisations,
  overwriteIdentificationFieldsFn,
  httpCodeHandlerFactory
) {
  const this__ = {};

  this__.opUpdateOrganisation = asyncOpCreate(
    payload => rest.put("organisations", payload),
    "opupdateOrganisation"
  );

  this__.updateOrganisation = action(async function(payload) {
    // typeof climbingAssembly.userRegrade !== "undefined"
    //   ? climbingAssembly.userRegrade.regrade
    //   : undefined;

    //We'll assume that the network transmission works
    //and update the UI store now
    cachedOrganisations.mergeOrInsert(payload);

    let callBack = response => {
      // Update the cachedRatings with the new ID and other details that may have been missed in the merge above
      cachedOrganisations.mergeOrInsert(response);
    };
    await this__.opUpdateOrganisation.execute(
      overwriteIdentificationFieldsFn(payload),
      callBack
    );
  });

  this__.opDeleteOrganisation = asyncOpCreate(
    payload => rest.delete("organisations/" + payload.id, payload),
    "opDeleteOrganisation"
  );

  this__.deleteOrganisation = action(async function(payload, callBack) {
    let localCallBack = response => {
      cachedOrganisations.removeItemById(payload.id);
      if (callBack) {
        callBack(response);
      }
    };
    await this__.opDeleteOrganisation.execute(
      overwriteIdentificationFieldsFn(payload),
      localCallBack
    );
  });

  this__.opCreateOrganisation = asyncOpCreate(
    payload => rest.post("organisations", payload),
    "opCreateOrganisation"
  );

  this__.createOrganisation = action(async function(payload, callBack) {
    let localCallBack = response => {
      // Update the cachedRatings with the new ID and other details that may have been missed in the merge above
      cachedOrganisations.mergeOrInsert(response);
      if (callBack) {
        callBack(response);
      }
    };

    await this__.opCreateOrganisation.execute(
      overwriteIdentificationFieldsFn(payload),
      localCallBack
    );
  });

  this__.opJoinOrganisation = asyncOpCreate(
    payload => rest.post("members", payload),
    "opJoinOrganisation"
  );

  this__.joinOrganisation = action(async function(
    payload,
    callBack200,
    callBack404
  ) {
    let localCallBack = response => {
      // Update the cachedRatings with the new ID and other details that may have been missed in the merge above
      cachedOrganisations.mergeOrInsert(response);
      if (callBack200) {
        callBack200(response);
      }
    };
    let statusCodeHandler = httpCodeHandlerFactory.getDefaultCopy();
    statusCodeHandler.handleStatusFns[404] = callBack404;

    await this__.opJoinOrganisation.execute(
      overwriteIdentificationFieldsFn(payload),
      localCallBack,
      statusCodeHandler
    );
  });

  this__.opLeaveOrganisation = asyncOpCreate(
    payload => rest.delete("members/" + payload.id, payload),
    "opLeaveOrganisation"
  );

  this__.leaveOrganisation = action(async function(payload, callBack) {
    cachedOrganisations.removeItemById(payload.id);

    await this__.opLeaveOrganisation.execute(
      overwriteIdentificationFieldsFn(payload),
      callBack
    );
  });

  this__.getStoreName = () => {
    return "organisationStore";
  };

  return this__;
}
