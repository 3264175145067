import React from "react";
import { useObserver } from "mobx-react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import {
  GridItem,
  Card,
  CardBody,
  MoreListButton,
  ClimbSelectors,
  ClimbCard
} from "ticker-components";

import "./react-infinite-scroll-component-CSS-overide.css";

// sections for this page

import { withCachedRootStore, coreStyle } from "ticker-core";

const { container, highTop } = coreStyle;
let routePageStyle = makeStyles({ container, highTop });

const ClimbsPage = function({ rootStore }) {
  let {
    personalRootStore,
    regStores: { regStore }
  } = rootStore;

  let { filterAnalysisStore } = personalRootStore;

  // console.log("rendering the route page");

  return useObserver(() => {
    let cvc = filterAnalysisStore.filterAnalysises.get();

    const classes = routePageStyle();

    /*
     * If the primary selection method is section
     */

    let renderAssembly = assembly => {
      return (
        <GridItem xs={12} sm={6} md={6} lg={4} key={assembly.climbingRoute.id}>
          <ClimbCard assembly={assembly} showTitle={true} />
        </GridItem>
      );
    };

    return (
      <>
        <div className={classes.container}>
          <Card narrowWhite className={classes.highTop}>
            <CardBody narrowWhite>
              <ClimbSelectors />
            </CardBody>
          </Card>
          <MoreListButton
            fullArray={cvc.climbingRouteAssemblies}
            childrenFn={renderAssembly}
            singular="climb"
            plural="climbs"
            missingMessage="No climbs match these filters."
            resetNoActivityTimeout={regStore.resetNoActivityTimeout}
          />
        </div>
      </>
    );
  });
};

ClimbsPage.propTypes = {};

export default withCachedRootStore(ClimbsPage);
