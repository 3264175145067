import { planTypes } from "../variables/constants";
import ArchiveClimbUtility from "../stores/ArchiveClimbUtility";

export default {
  pretendToArchive: function pretendToArchive(
    settingPlanExecuting,
    climbingRouteCacheStore
  ) {
    if (settingPlanExecuting.planType === planTypes.REPLACE) {
      let stripClimb = climbingRouteCacheStore.getItemById(
        settingPlanExecuting.route_to_strip_id
      );
      if (stripClimb) {
        // Remove the route from the cache so that it disappears from the map
        stripClimb.isArchived = true;
      }
    }
  },

  undoPretendArchive: function undoPretendArchive(
    settingPlanExecuting,
    climbingRouteCacheStore
  ) {
    if (settingPlanExecuting.planType === planTypes.REPLACE) {
      let stripClimb = climbingRouteCacheStore.getItemById(
        settingPlanExecuting.route_to_strip_id
      );
      if (stripClimb) {
        stripClimb.isArchived = false;
      }
    }
  },

  archiveStrippedRoute: function archiveStrippedRoute(
    settingPlanExecuting,
    rootStore
  ) {
    let { climbingRouteCacheStore } = rootStore.climbingRouteStores;

    if (settingPlanExecuting.planType === planTypes.REPLACE) {
      let stripClimb = climbingRouteCacheStore.getItemById(
        settingPlanExecuting.route_to_strip_id
      );
      if (stripClimb) {
        ArchiveClimbUtility.archiveClimb(stripClimb.id, rootStore, 0);
      }
    }
  },

  getSettingPlanFromLocation: function getSettingPlanFromLocation(
    settingPlanCacheStore,
    location
  ) {
    if (location.state && typeof location.state.settingPlanId !== "undefined") {
      return {
        settingPlan: settingPlanCacheStore.getItemById(
          location.state.settingPlanId
        ),
        shouldArchiveOnSave: location.state.shouldArchiveOnSave
      };
    }
    return {};
  },

  setSettingPlanInLocation: function setSettingPlanInLocation(
    newLocation,
    settingPlan,
    shouldArchiveOnSave
  ) {
    return {
      pathname: newLocation,
      state: {
        settingPlanId: settingPlan.id,
        shouldArchiveOnSave: shouldArchiveOnSave
      }
    };
  },

  clearSettingPlanFromLocation: function clearSettingPlanFromLocation(
    location
  ) {
    location.state = {};
  }
};
