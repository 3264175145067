import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useObserver } from "mobx-react";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withRootStore } from "ticker-core";

import { Table, Button } from "ticker-components";

import EditLogEntryPopUp from "./EditLogEntryPopUp";
import styles from "../../assets/jss/material-dashboard-pro-react/components/tickListStyle.js";

const useListStyles = makeStyles(styles);

let dateFormat = "YYYY-MM-DD";

let timeFormat = "k:mm";

function TickPopup({ rootStore, assembly, closeFn, onDeleteTick }) {
  const classes = useListStyles();

  let [deletingIndex, setDeletingIndex] = useState(-1);

  let [entriesAreLoaded, setEntriesAreLoaded] = useState(false);

  let [showEditDialog, setShowEditDialog] = useState(false);

  let [editEntry, setEditEntry] = useState();

  let {
    personalRootStore: { personalLogEntryStores }
  } = rootStore;

  let { logEntryStore } = personalLogEntryStores;

  let entriesCallBack = () => {
    setEntriesAreLoaded(true);
  };

  useEffect(() => {
    personalLogEntryStores.logEntriesOfRoute.loadLogEntriesOfRoute(
      assembly.climbingRoute.id,
      entriesCallBack
    );
  }, []);

  return useObserver(() => {
    let entries = personalLogEntryStores.logEntriesOfRoute.logEntries;

    let tableData = entries.map((entry, i) => {
      let showEditFn = () => {
        setShowEditDialog(true);
        setEditEntry(entry);
      };

      let dateStr = (
        <span onClick={showEditFn}>
          {moment(entry.dateClimbedUTC).format(dateFormat)}
        </span>
      );
      let timeStr = (
        <span onClick={showEditFn}>
          {moment(entry.dateClimbedUTC).format(timeFormat)}
        </span>
      );
      let created = (
        <span onClick={showEditFn}>
          {moment(entry.created_at).format("YYYY-MM-DD HH:mm:ss")}
        </span>
      );

      let rowData = [
        dateStr,
        timeStr,
        created,
        // eslint-disable-next-line react/jsx-key
        <Button
          //color={i !== deletingIndex ? "danger" : "gray"}
          color="danger"
          className={classes.actionButton}
          onClick={showEditFn}
        >
          <Edit className={classes.icon} />
        </Button>,
        <Button
          key={entry.id + 10000}
          //color={i !== deletingIndex ? "danger" : "gray"}
          color="danger"
          disabled={i === deletingIndex}
          className={classes.actionButton}
          onClick={() => {
            if (onDeleteTick) {
              onDeleteTick();
            }
            let logDeleted = false;
            let timesUp = false;
            let callBack = () => {
              logDeleted = true;
              if (timesUp) {
                personalLogEntryStores.logEntriesOfRoute.loadLogEntriesOfRoute(
                  assembly.climbingRoute.id
                );
              }
            };
            logEntryStore.deleteLogEntry(entry, entries, callBack);
            setDeletingIndex(i);
            setTimeout(() => {
              setDeletingIndex(-1);
              if (logDeleted) {
                personalLogEntryStores.logEntriesOfRoute.loadLogEntriesOfRoute(
                  assembly.climbingRoute.id
                );
              }
            }, 500);
          }}
        >
          <Delete className={classes.icon} />
        </Button>
      ];
      if (i === deletingIndex) {
        return {
          color: "gray",
          data: rowData
        };
      }
      return rowData;
    });

    let MAX_LIMIT = personalLogEntryStores.logEntriesOfRoute.MAX_LIMIT;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
      <>
        <Dialog
          onClose={closeFn}
          aria-labelledby="simple-dialog-title"
          open={true}
          fullScreen={fullScreen && entriesAreLoaded}
        >
          {!entriesAreLoaded && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "8em",
                  minWidth: "10em"
                }}
              >
                <CircularProgress />
              </div>
              <div style={{ textAlign: "center", width: "100%" }}>
                <Button simple color="info" onClick={closeFn}>
                  cancel
                </Button>
              </div>
            </div>
          )}
          {entriesAreLoaded && (
            <>
              <DialogTitle>
                {entries.length !== MAX_LIMIT
                  ? "You've ticked this climb " +
                    entries.length +
                    " time" +
                    (entries.length === 1 ? "" : "s")
                  : "Showing your last " + MAX_LIMIT + " ticks"}
              </DialogTitle>
              {entries.length !== 0 && (
                <DialogContent dividers={true}>
                  <Table
                    tableHead={[
                      "Date Climbed",
                      "Time Climbed",
                      "Date Ticked",
                      ""
                    ]}
                    tableData={tableData}
                  />
                </DialogContent>
              )}
              <DialogActions>
                <Button color="success" onClick={closeFn}>
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>

        {editEntry && (
          <EditLogEntryPopUp
            isOpen={showEditDialog}
            entry={editEntry}
            closeFn={() => {
              setShowEditDialog(false);
            }}
          />
        )}
      </>
    );
  });
}

TickPopup.propTypes = {
  rootStore: PropTypes.object.isRequired,
  assembly: PropTypes.object.isRequired,
  closeFn: PropTypes.func.isRequired
};

export default withRootStore(TickPopup);
