import createDraggableItemsStore from "./DragggableStore.js";

export default class RouteStatusStores {
  constructor(context, asyncOpCreate, getCurrentGymId) {
    this.routeStatusStore = createDraggableItemsStore(
      context,
      "routeStatuses",
      "Status",
      null, // No item filter
      asyncOpCreate,
      getCurrentGymId
    );
  }
}
