let singleton;

export function setupCoordsSingleton(gymStore, sectionStore, wallStore) {
  if (!singleton) {
    singleton = {};
    singleton.sectionCountMap = new Map();
    singleton.wallCountMap = new Map();

    singleton.reset = () => {
      singleton.unpositionedSectionCount = 0;
      singleton.sectionCountMap.clear();
      singleton.wallCountMap.clear();
    };

    /*
     * Section Coords
     */
    singleton.getSectionX = section => {
      if (typeof section.labelX !== undefined && section.labelX !== null) {
        return section.labelX;
      }
      return gymStore.imageWidth / 2;
    };
    singleton.getSectionY = section => {
      if (typeof section.labelY !== undefined && section.labelY !== null) {
        return section.labelY;
      }
      singleton.unpositionedSectionCount++;
      return gymStore.imageHeight / 2 + singleton.unpositionedSectionCount * 10;
    };

    /*
     * Wall Coords
     */

    singleton.getWallX = (wallObject, section) => {
      // Use the walls labelX
      console.log("COORDS: Searching for WallX");
      if (
        typeof wallObject.labelX !== undefined &&
        wallObject.labelX !== null
      ) {
        console.log(
          "COORDS: Founct labelX in WallX for " +
            wallObject.name +
            ": " +
            wallObject.labelX
        );
        return wallObject.labelX;
      }
      if (!section) {
        section = sectionStore.getItemById(wallObject.climbing_section_id);
      }

      console.log(
        "COORDS: Didn't find labelX in WallX for " +
          JSON.stringify(wallObject, null, 3)
      );

      // Use the Sections labelX
      if (typeof section.labelX !== undefined && section.labelX !== null) {
        return section.labelX;
      }

      console.log("The wall and section have no label?");
      debugger;
      return gymStore.imageWidth / 2;
    };

    singleton.getWallY = (wallObject, section) => {
      // Use the walls labelX
      if (
        typeof wallObject.labelY !== undefined &&
        wallObject.labelY !== null
      ) {
        return wallObject.labelY;
      }
      if (!section) {
        section = sectionStore.getItemById(wallObject.climbing_section_id);
      }

      // Use the Sections labelY
      if (typeof section.labelY !== undefined && section.labelY !== null) {
        let unpositionedCount = singleton.sectionCountMap.get(
          wallObject.climbing_section_id
        );
        if (!unpositionedCount) {
          unpositionedCount = 0;
        }
        unpositionedCount++;
        singleton.sectionCountMap.set(
          wallObject.climbing_section_id,
          unpositionedCount
        );
        return section.labelY + unpositionedCount * 20;
      }

      console.log("The wall and section have no label?");
      debugger;
      singleton.unpositionedSectionCount++;
      return gymStore.imageHeight / 2 + singleton.unpositionedSectionCount * 10;
    };

    /*
     * Climb Coords
     */

    singleton.getClimbY = (climbObject, wall) => {
      // Use the climb's labelY
      if (
        typeof climbObject.labelY !== undefined &&
        climbObject.labelY !== null
      ) {
        return climbObject.labelY;
      }
      if (!wall) {
        wall = wallStore.getItemById(climbObject.climbing_wall_id);
      }

      // Use the Sections labelY
      if (typeof wall.labelY !== undefined && wall.labelY !== null) {
        let unpositionedCount = singleton.wallCountMap.get(
          climbObject.climbing_wall_id
        );
        if (!unpositionedCount) {
          unpositionedCount = 0;
        }
        unpositionedCount++;
        singleton.wallCountMap.set(
          climbObject.climbing_wall_id,
          unpositionedCount
        );
        return wall.labelY + unpositionedCount * 20;
      }

      console.log("The climb and wall have no label?");
      debugger;
      singleton.unpositionedSectionCount++;
      return gymStore.imageHeight / 2 + singleton.unpositionedSectionCount * 10;
    };

    singleton.getClimbX = (climbObject, wall) => {
      // Use the climb's labelX
      if (
        typeof climbObject.labelX !== undefined &&
        climbObject.labelX !== null
      ) {
        return climbObject.labelX;
      }
      if (!wall) {
        wall = wallStore.getItemById(climbObject.climbing_wall_id);
      }

      //
      // Use the Sections labelX
      if (typeof wall.labelX !== undefined && wall.labelX !== null) {
        let unpositionedCount = singleton.wallCountMap.get(
          climbObject.climbing_wall_id
        );
        if (!unpositionedCount) {
          unpositionedCount = 0;
        }
        unpositionedCount++;
        singleton.wallCountMap.set(
          climbObject.climbing_wall_id,
          unpositionedCount
        );
        return wall.labelX + unpositionedCount * 20;
      }
      console.log("The climb and wall have no label?");
      debugger;
      singleton.unpositionedSectionCount++;
      return gymStore.imageHeight / 2 + singleton.unpositionedSectionCount * 10;
    };

    singleton.reset();
  }
}

let wallSingleton;
export function getWallCoords(gymStore, sectionStore, wallStore) {
  if (!wallSingleton) {
    setupCoordsSingleton(gymStore, sectionStore, wallStore);
    wallSingleton = {};

    wallSingleton.getX = (object, section) =>
      singleton.getWallX(object, section);

    wallSingleton.getY = (object, section) =>
      singleton.getWallY(object, section);

    wallSingleton.reset = singleton.reset;
  }
  singleton.reset();
  return wallSingleton;
}

let sectionSingleton;
export function getSectionCoords(gymStore, sectionStore, wallStore) {
  if (!sectionSingleton) {
    setupCoordsSingleton(gymStore, sectionStore, wallStore);
    sectionSingleton = {};

    sectionSingleton.getX = object => singleton.getSectionX(object);

    sectionSingleton.getY = object => singleton.getSectionY(object);

    sectionSingleton.reset = singleton.reset;
  }
  singleton.reset();
  return sectionSingleton;
}

let climbSingleton;
export function getClimbCoords(gymStore, sectionStore, wallStore) {
  if (!climbSingleton) {
    setupCoordsSingleton(gymStore, sectionStore, wallStore);
    climbSingleton = {};

    climbSingleton.getX = object => {
      let result = singleton.getClimbX(object);
      // console.log("Getting Climb X coord:" + result);
      return result;
    };

    climbSingleton.getY = object => {
      let result = singleton.getClimbY(object);
      // console.log("Getting Climb Y coord:" + result);
      return result;
    };

    climbSingleton.reset = singleton.reset;
  }
  singleton.reset();
  return climbSingleton;
}
