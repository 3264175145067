import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import moment from "moment";

import Button from "../CustomButtons/Button.js";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import EditClimbButton from "../ClimbingRoute/EditClimbButton";
import CustomInput from "../CustomInput/CustomInput";
import RatingDetailsDialog from "../../components/ClimbCore/RatingDetailsDialog";
import RegradeDetailsDialog from "../../components/ClimbCore/RegradeDetailsDialog";

import Archive from "@material-ui/icons/Archive";
import Unarchive from "@material-ui/icons/Unarchive";
import Block from "@material-ui/icons/Block";
import Star from "@material-ui/icons/Star";
import Person from "@material-ui/icons/Person";
import ReportProblem from "@material-ui/icons/ReportProblem";

import Popover from "@material-ui/core/Popover";

import withStyles from "@material-ui/core/styles/withStyles";

import { withRootStore, ArchiveClimbUtility, coreStyle } from "ticker-core";

import WallAndSectionName from "../Walls/WallAndSectionName.js";
import ModifierIcon from "../ModifierIcon/ModifierIcon";

import IssuesDialog from "./IssuesDialog";

const { secondRow } = coreStyle;

let style = {
  nameTop: {
    paddingLeft: "2em"
  },
  longGridItem: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  marginRight: {
    marginRight: "5em"
  },
  emphasized: {
    fontWeight: "bold",
    marginRight: "1em"
  },
  centered: {
    textAlign: "center"
  },
  noMargin: {
    margin: 0
  },
  smaller: {
    fontSize: "1.2em",
    margin: 0
  },
  padded: {
    margin: "1em"
  },
  secondRow,
  modifierIcon: {
    marginLeft: "0.2em",
    verticalAlign: "middle",
    fontSize: "2em"
  }
};

class ClimbingRouteTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChangeStatusMenuVisible: false,
      anchorEl: null,
      showIssues: false
    };
    this.routeStatusHack = this.props.climbingRouteAssembly.routeStatus;

    this.openStatusMenu = event => {
      console.log("opening status menu");
      this.setState({
        isChangeStatusMenuVisible: true,
        anchorEl: event.target
      });
    };
    this.closeStatusMenu = () => {
      console.log("Closing Status Menu");
      this.setState({
        isChangeStatusMenuVisible: false,
        anchorEl: null
      });
    };

    this.changeStatus = async routeStatus => {
      this.routeStatusHack = routeStatus;
      const { climbingRoute } = this.props.climbingRouteAssembly;
      const {
        climbingRouteStore,
        climbingRouteCacheStore
      } = this.props.rootStore.climbingRouteStores;
      climbingRoute.route_status_id = routeStatus.id;
      climbingRouteStore.merge(climbingRoute, climbingRouteStore);
      await climbingRouteStore.updateClimbingRoute();
      //await climbingRouteCacheStore.cacheItems({ forceReload: true });
      this.closeChangeStatusDialog();
    };

    this.closeChangeStatusDialog = () => {
      this.setState({ isChangeStatusMenuVisible: false });
    };

    this.getStatusButton = (routeStatus, isDisabled) => {
      const { classes } = this.props;
      return (
        <Button
          size="xsm"
          disabled={isDisabled}
          color={routeStatus.themeColorName}
          className={classes.marginRight}
          onClick={event => {
            this.openStatusMenu(event);
          }}
        >
          {routeStatus.name}
        </Button>
      );
    };

    this.getStatusButtonList = (/*currentRouteStatus*/) => {
      const { rootStore } = this.props;
      const { routeStatusStore } = rootStore.routeStatusStores;

      return (
        <React.Fragment>
          {routeStatusStore.getAllItemsEvenArchived().map(routeStatus => {
            if (
              routeStatus.isArchived
              // ||
              // routeStatus.id === currentRouteStatus.id
            ) {
              return null;
            }
            return (
              <Button
                key={routeStatus.id}
                size="xsm"
                color={
                  routeStatus.themeColorName === "NONE"
                    ? undefined
                    : routeStatus.themeColorName
                }
                onClick={() => {
                  this.changeStatus(routeStatus);
                }}
              >
                {routeStatus.name}
              </Button>
            );
          })}
        </React.Fragment>
      );
    };

    this.toggleHold = async () => {
      let climbingRouteAssembly = this.props.climbingRouteAssembly;
      const {
        climbingRouteStore,
        climbingRouteCacheStore
      } = this.props.rootStore.climbingRouteStores;

      const { notificationStore } = this.props.rootStore;
      if (climbingRouteAssembly.climbingRoute.isOnHold) {
        notificationStore.enqueueNotification("Taking off hold", {
          variant: "success"
        });
      } else {
        notificationStore.enqueueNotification("Putting on hold", {
          variant: "success"
        });
      }
      climbingRouteAssembly.climbingRoute.isOnHold = !climbingRouteAssembly
        .climbingRoute.isOnHold;
      climbingRouteStore.merge(
        climbingRouteAssembly.climbingRoute,
        climbingRouteStore
      );
      await climbingRouteStore.updateClimbingRoute();
    };
  }
  render() {
    const { classes, climbingRouteAssembly, rootStore, index } = this.props;

    let ChangeArchive = Archive;
    let onChangeArchive = () => {
      ArchiveClimbUtility.archiveClimb(
        climbingRouteAssembly.climbingRoute.id,
        rootStore,
        200
      );
    };

    if (climbingRouteAssembly.climbingRoute.isArchived) {
      ChangeArchive = Unarchive;
      onChangeArchive = () => {
        ArchiveClimbUtility.unArchiveClimb(
          climbingRouteAssembly.climbingRoute.id,
          rootStore
        );
      };
    }

    let statusButton = this.getStatusButton(
      this.routeStatusHack,
      climbingRouteAssembly.climbingRoute.isArchived
    );

    let secondRowClass;
    if (index % 2 === 0) {
      secondRowClass = classes.secondRow;
    }

    let showName =
      climbingRouteAssembly.climbingRoute.name !== "" &&
      climbingRouteAssembly.climbingRoute.name;

    let modifierBtnColor = "info";
    let btnIsSimple = true;
    if (climbingRouteAssembly.climbingRoute.combinedModifierCount > 1) {
      if (
        climbingRouteAssembly.relativeCombinedModifier > 10 ||
        climbingRouteAssembly.relativeCombinedModifier < -10
      ) {
        modifierBtnColor = "danger";
        btnIsSimple = false;
      } else if (
        climbingRouteAssembly.relativeCombinedModifier > 5 ||
        climbingRouteAssembly.relativeCombinedModifier < -5
      ) {
        modifierBtnColor = "warning";
        btnIsSimple = false;
      }
    }

    let isOnHoldColor = !climbingRouteAssembly.climbingRoute.isOnHold
      ? "disabled"
      : "secondary";

    return (
      <React.Fragment>
        {this.state.showRatingDetailsDialog && (
          <RatingDetailsDialog
            onClose={() => this.setState({ showRatingDetailsDialog: false })}
            open={true}
            assembly={climbingRouteAssembly}
          />
        )}
        {this.state.showRegradeDetailsDialog && (
          <RegradeDetailsDialog
            onClose={() => this.setState({ showRegradeDetailsDialog: false })}
            open={true}
            assembly={climbingRouteAssembly}
            rootStore={rootStore}
          />
        )}

        {this.state.showIssues && (
          <IssuesDialog
            assembly={climbingRouteAssembly}
            open={this.state.showIssues}
            onClose={() => this.setState({ showIssues: false })}
            updateIssuesStore={rootStore.reportedIssueStores.updateIssuesStore}
            postArchiveOnClick={issue => {
              rootStore.reportedIssueStores.cachedIssuesStore.removeItemById(
                issue.id
              );
            }}
          />
        )}

        <GridContainer
          className={classes.nameTop + " " + secondRowClass}
          alignItems="center"
        >
          <GridItem style={{ flexGrow: 1 }}>
            {this.props.showWallAndSection && (
              <WallAndSectionName
                climbingWall={
                  climbingRouteAssembly.climbingWall
                    ? climbingRouteAssembly.climbingWall
                    : climbingRouteAssembly.wallAssembly.climbingWall
                }
                climbingSection={
                  climbingRouteAssembly.climbingSection
                    ? climbingRouteAssembly.climbingSection
                    : climbingRouteAssembly.sectionAssembly.climbingSection
                }
              />
            )}
            <div>
              <h4>
                <span style={{ fontWeight: "400", paddingRight: "2em" }}>
                  {climbingRouteAssembly.markerColor.name} Holds
                </span>
                Grade {climbingRouteAssembly.climbingGrade.primaryLabel}
                <Block
                  color={isOnHoldColor}
                  style={{
                    marginLeft: "0.2em",
                    verticalAlign: "middle",
                    cursor: "pointer"
                  }}
                  onClick={this.toggleHold}
                />
                {typeof climbingRouteAssembly.climbingRoute.avgRating !==
                  "undefined" &&
                  climbingRouteAssembly.climbingRoute.avgRating !== null && (
                    <Button
                      simple
                      color="info"
                      onClick={() => {
                        this.setState({ showRatingDetailsDialog: true });
                      }}
                    >
                      <Star
                        style={{
                          marginLeft: "0.2em",
                          verticalAlign: "middle",
                          color: "#fdbc4b"
                        }}
                      />
                      {climbingRouteAssembly.climbingRoute.avgRating.toFixed(1)}
                      <Person
                        style={{
                          marginLeft: "0.2em",
                          verticalAlign: "middle",
                          color: "gray"
                        }}
                      />

                      {climbingRouteAssembly.climbingRoute.ratingCount}
                    </Button>
                  )}
                {typeof climbingRouteAssembly.relativeCombinedModifier !==
                  "undefined" &&
                  climbingRouteAssembly.relativeCombinedModifier !== null && (
                    <Button
                      simple={btnIsSimple}
                      color={modifierBtnColor}
                      onClick={() => {
                        this.setState({ showRegradeDetailsDialog: true });
                      }}
                    >
                      <ModifierIcon
                        className={classes.modifierIcon}
                        modifier={
                          climbingRouteAssembly.relativeCombinedModifier
                        }
                      />
                      {(
                        climbingRouteAssembly.relativeCombinedModifier / 10
                      ).toFixed(1)}
                      <Person
                        style={{
                          marginLeft: "0.2em",
                          verticalAlign: "middle",
                          color: "gray"
                        }}
                      />
                      {
                        climbingRouteAssembly.climbingRoute
                          .combinedModifierCount
                      }
                    </Button>
                  )}
                {climbingRouteAssembly.unarchivedIssues &&
                climbingRouteAssembly.unarchivedIssues.length > 0 ? (
                  <span
                    style={{ paddingLeft: "1em" }}
                    onClick={() => this.setState({ showIssues: true })}
                  >
                    <ReportProblem
                      style={{
                        marginLeft: "0.2em",
                        verticalAlign: "middle",
                        color: "orange"
                      }}
                    />
                    {climbingRouteAssembly.unarchivedIssues.length}
                  </span>
                ) : (
                  undefined
                )}
              </h4>
              {showName && (
                <div>{climbingRouteAssembly.climbingRoute.name}</div>
              )}
            </div>

            <GridContainer>
              <GridItem>
                <div>{climbingRouteAssembly.setterAlias.alias}</div>
                <div>
                  {climbingRouteAssembly.parsedSettingDateTime.format(
                    "YYYY-MM-DD hh:mm:SSS"
                  )}
                </div>
              </GridItem>
              <GridItem>
                <div>
                  <span className={classes.emphasized}>Notes </span>
                  {climbingRouteAssembly.climbingRoute.setterNotes}
                </div>
                <div>
                  <span className={classes.emphasized}>Description </span>
                  {climbingRouteAssembly.climbingRoute.description}
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem>
            <EditClimbButton
              climbingRoute={climbingRouteAssembly.climbingRoute}
            />
            {statusButton}
            <Popover
              id="simple-popper"
              open={this.state.isChangeStatusMenuVisible}
              onClose={this.closeStatusMenu}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <CustomInput
                labelText="Setter Notes"
                id="setterNotes"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event =>
                    (climbingRouteAssembly.climbingRoute.setterNotes =
                      event.target.value),
                  type: "text",
                  value: climbingRouteAssembly.climbingRoute.setterNotes,
                  multiline: true,
                  inputProps: {
                    rows: 4
                  }
                }}
              />{" "}
              {this.getStatusButtonList(climbingRouteAssembly.routeStatus)}
            </Popover>

            {climbingRouteAssembly.climbingRoute.isArchived && (
              <div style={{ float: "right" }}>
                <span>Archived on </span>
                {moment(climbingRouteAssembly.climbingRoute.archiveDate).format(
                  "YYYY-MM-DD"
                )}
              </div>
            )}
            <Button
              justIcon
              round
              size="sm"
              color="info"
              onClick={onChangeArchive}
            >
              <ChangeArchive className={classes.icons} />
            </Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

ClimbingRouteTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
  climbingRouteAssembly: PropTypes.object.isRequired,
  showWallAndSection: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired
};

export default withRootStore(
  withStyles(style)(observer(ClimbingRouteTableRow))
);
