import executeRouteAi from "../../utils/RouteAi/executeRouteAi";
import RouteAiHelper from "../../utils/RouteAi/RouteAiHelper";

import { planTypes } from "../../variables/constants";
import {
  calculateSettingUrgency,
  getMostRecentRouteSettingDateTimesByGrade,
  basedOnLongevityRank
} from "../../utils/ReportStoreUtility";

import GeneratePlan from "../../utils/GeneratePlan";

import {
  addLongevity,
  addLongevityForSettingPlan,
  FIXED_ADJUSTMENT,
  INFLUENCE_MIDWAY_POINT_3,
  getLongevityObject,
  addToLongevityObjectBasedOnCurrentConditions,
  getOldestAdjustedDate,
  getOldestDateInDays,
  getAcceptedWallsWithPlanAssembliesAdded
} from "../../utils/longevityFns";

const getSlotStr = function getSlotStr(gradeStore, gradeIdList) {
  let retVal = "";
  for (let i = 0; i < gradeIdList.length; i++) {
    let gradeId = gradeIdList[i];
    if (i !== 0) {
      retVal += ", ";
    }
    if (gradeIdList.length > 1 && gradeIdList.length - 1 === i) {
      retVal += "or ";
    }
    retVal += gradeStore.getItemById(gradeId).primaryLabel;
  }
  return retVal;
};

export default function getOrderedLongevityAssemblies(
  rootStore,
  now,
  planAssemblies,
  gradeType,
  allowDuplicates,
  planningQuantityMap,
  areWorkingOutParallelLongevities
) {
  const { computedCacheStore, gymStores } = rootStore;

  const computedCache = computedCacheStore.computedCache.get();

  const { gymStore } = gymStores;

  const { gradeStores } = rootStore.gradeStores;

  let longevityClimbAssemblies_retVal = [];

  // This value is set inside createAcceptedWallAssembly()
  let mostRecentRoutesettingDateTimesByGrade = getMostRecentRouteSettingDateTimesByGrade(
    computedCache.allClimbingAssemblies,
    now,
    planAssemblies
  );

  let acceptedWallAssemblies = getAcceptedWallsWithPlanAssembliesAdded(
    computedCache,
    gradeType,
    now,
    planAssemblies,
    gradeStores,
    false // includeOnHoldRoutes
  );

  let oldestAdjustedDateOfClimbInAllWalls = getOldestAdjustedDate(
    computedCache,
    gradeType,
    now
  );

  let oldestRouteAgeInDays = getOldestDateInDays(
    now,
    oldestAdjustedDateOfClimbInAllWalls
  );

  let wallMostRecentsettingDateTimeRange =
    now - oldestAdjustedDateOfClimbInAllWalls;

  /*
   * I THINK this loop:
   * Creates a new LongevityObject and adds it to the wall's list of longevity object.
   * It only adds an object for existing route (as opposed to settingPlans)
   * That's because the longevity is to determine which route to strip or replace
   * We shouldn't be scheduling the strip of a settingPlan route.
   */
  for (let i = 0; i < acceptedWallAssemblies.length; i++) {
    let acceptedWallAssembly = acceptedWallAssemblies[i];

    let wallAssembly = acceptedWallAssembly.wallAssembly;

    RouteAiHelper.fillDefaultRoutesAndSlots(wallAssembly);

    acceptedWallAssembly.longevityClimbAssemblies = [];

    for (let climbAssembly of acceptedWallAssembly.acceptedClimbAssemblies) {
      // If this is a setting plan then we should skip it
      if (climbAssembly.isSettingPlan !== true) {
        let longevityBreakdown = getLongevityObject(
          climbAssembly,
          gymStore,
          oldestRouteAgeInDays
        );

        addToLongevityObjectBasedOnCurrentConditions(
          climbAssembly,
          acceptedWallAssembly.mostRecentOfWall,
          wallMostRecentsettingDateTimeRange,
          oldestAdjustedDateOfClimbInAllWalls,
          gymStore,
          allowDuplicates,
          now,
          acceptedWallAssembly.acceptedClimbAssemblies,
          mostRecentRoutesettingDateTimesByGrade,
          planningQuantityMap,
          oldestRouteAgeInDays,
          longevityBreakdown
        );

        let newLongevityAssembly = {
          climbAssembly,
          longevityBreakdown: longevityBreakdown,
          acceptedWallAssembly
        };
        acceptedWallAssembly.longevityClimbAssemblies.push(
          newLongevityAssembly
        );
      }
    } //End of the acceptedClimbAssemblies Loop

    //NOTE: The skipDuplicateCheck here needs to be true instead of allowDuplicates
    // because we don't want the RouteAI to not return things because it is a duplicate
    let routeAi = new executeRouteAi(
      acceptedWallAssembly.longevityClimbAssemblies,
      acceptedWallAssembly.wallAssembly.slotConfigsCopy,
      {
        allowFailures: true,
        skipDuplicateCheck: true,
        checkEligibleSlots: false,
        debugMode: false
      }
    );

    //wall.mappingList is used in the Longevity method below
    acceptedWallAssembly.mappingList = routeAi.getMapping();

    //This next loop needs to be done on the sorted list so that any empty slot get assigned to climb
    // with the highest rank

    /*
     * This longevity figure shows the value of stripping this route with regards to setting another desirable route
     * E.g. if this 18 is in an (18,19) slot, are either 18 or 19 in deman? If not then there is less point in stripping
     * this route becuase there is nothing desirable to replace it with
     */

    let numberOfClimbs =
      acceptedWallAssembly.wallAssembly.climbingRouteAssemblies.length;

    /*
     * I THINK this loop:
     * Works out how many climbs will be added because of the plans
     */

    let futurePlansForThisWallCount = 0;
    for (let i = 0; i < planAssemblies.length; i++) {
      let planAssembly = planAssemblies[i];

      if (planAssembly.wallAssembly === wallAssembly) {
        // if (
        //   planAssemblies.length > 6 &&
        //   planAssembly.wallAssembly.climbingWall.id === 12
        // ) {
        //   debugger;
        // }
        if (planAssembly.settingPlan.planType === planTypes.STRIP) {
          futurePlansForThisWallCount--;
        } else if (planAssembly.settingPlan.planType === planTypes.SET) {
          futurePlansForThisWallCount++;
        } else {
          //Do nothing for REPLACE because the count will be the same
        }
      }
    }

    acceptedWallAssembly.longevityClimbAssemblies.forEach(
      longevityClimbAssembly =>
        (longevityClimbAssembly.numberOfClimbsIncludingPlans =
          numberOfClimbs + futurePlansForThisWallCount)
    );

    let wallWillBeExactlyFull =
      acceptedWallAssembly.numberOfClimbsIncludingPlans ===
      wallAssembly.climbingWall.slotConfigsJSON.length;

    let wallWillBeTooFull =
      acceptedWallAssembly.numberOfClimbsIncludingPlans >
      wallAssembly.climbingWall.slotConfigsJSON.length;

    /*
     * For each climbAssembly work out the longevity item for the setting space that will become available
     */
    for (
      let i = 0;
      i < acceptedWallAssembly.longevityClimbAssemblies.length;
      i++
    ) {
      let longevityClimbAssembly =
        acceptedWallAssembly.longevityClimbAssemblies[i];
      let climbAssembly = longevityClimbAssembly.climbAssembly;

      if (climbAssembly.isSettingPlan !== true) {
        let slotOfThisRoute = null;
        //                  let mappingOfThisRoute = null;
        let bestEmptySlot = null;
        let emptySlotsBestGrade = null;
        let emptySlot = null;
        let highestSettingUrgencyOfAnyGradeInASlotInThisWall = null;
        //calculateStrippingUrgency(surplusCountMap[climb.climbingGradeId].desired, surplusCountMap[climb.climbingGradeId].actual);
        let foundBetterSlot = false;
        let foundEmptySlot = false;
        let finalDuplicatesFoundInThisSlot = [];

        let slotStr = "";

        // For each mapping:
        // Find the slotOfThisRoute if it exists
        // and find the mappingOfThisRoute if it exists
        //

        for (let j = 0; j < acceptedWallAssembly.mappingList.length; j++) {
          let mapping = acceptedWallAssembly.mappingList[j];
          let longevityClimb =
            acceptedWallAssembly.longevityClimbAssemblies[mapping.climbIndex];
          let duplicatesFoundInThisSlot = [];

          // if this is a slot mapping
          // of it is a climb mapping that is not a setting plan
          if (
            !longevityClimb ||
            longevityClimb.climbAssembly.isSettingPlan !== true
          ) {
            let mappingBelongsToCurrentRoute =
              mapping.isMapped &&
              longevityClimb.climbAssembly.climbingRoute.id ===
                climbAssembly.climbingRoute.id;

            if (mappingBelongsToCurrentRoute) {
              slotOfThisRoute =
                wallAssembly.climbingWall.slotConfigsJSON[mapping.slotIndex];
            }

            // If the current mapping has an empty slot (!isMapped && mapping.slotIndex != empty)
            // or this mapping belongs to this climbAssembly
            // then we need to record this slot as the best empty slot if
            // the slot is 'more desirable' i.e. has a higher settingUrgency
            // than any previously found empty slot
            if (
              (!mapping.isMapped &&
                mapping.slotIndex !== null &&
                typeof mapping.slotIndex !== "undefined") ||
              mappingBelongsToCurrentRoute
            ) {
              //For each grade in the slot:
              // calaculate the stripping Urgency of each grade in this slot
              // and record the slot as the prefered slot if the stripping urgency is higher
              let settingUrgencies = [];
              for (
                let k = 0;
                k <
                wallAssembly.climbingWall.slotConfigsJSON[mapping.slotIndex]
                  .length;
                k++
              ) {
                let gradeObj =
                  wallAssembly.climbingWall.slotConfigsJSON[mapping.slotIndex][
                    k
                  ];
                if (typeof planningQuantityMap[gradeObj] === "undefined") {
                  return;
                }

                // Search for the string sgsgsssaasde to see what I mean.
                let settingUrgency = calculateSettingUrgency(
                  planningQuantityMap[gradeObj].targetQuantity,
                  planningQuantityMap[gradeObj].actualQuantity
                );

                settingUrgencies[k] = {
                  strippingUrgency: settingUrgency,
                  gradeObj
                };

                let isHigherSettingUrgency =
                  (highestSettingUrgencyOfAnyGradeInASlotInThisWall === null ||
                    highestSettingUrgencyOfAnyGradeInASlotInThisWall <
                      settingUrgency) &&
                  gradeObj !== climbAssembly.climbingGrade.id;

                /* If this mapping belongs to this route and it has a higher setting urgency
                 * then we need to remember that this slot is the best so far and we will regain it once stripped
                 *
                 * OR
                 * if the wallWillBeExactlyFull and this empty* slot has a higher setting urgency.
                 * then when we strip this route we will be able to use this empty slot instead
                 * and since it has a higher setting urgency it is more desirable.
                 * Note: This current slot is unmapped and the wall is full so there should be
                 * another route on this wall that is not mapped to a slot. It could have a higher stripping urgency
                 * because it doesn't fit on the wall at the moment.
                 *
                 * *This slot is either empty or the mappingBelongsToCurrentRoute because of if statement above.
                 */

                if (
                  isHigherSettingUrgency &&
                  (mappingBelongsToCurrentRoute || wallWillBeExactlyFull)
                ) {
                  let passedDuplicateCheck = true;
                  if (!allowDuplicates) {
                    finalDuplicatesFoundInThisSlot = duplicatesFoundInThisSlot;

                    for (
                      let l = 0;
                      l < acceptedWallAssembly.acceptedClimbAssemblies.length;
                      l++
                    ) {
                      let possibleDuplicate =
                        acceptedWallAssembly.acceptedClimbAssemblies[l];
                      if (
                        possibleDuplicate !== climbAssembly &&
                        possibleDuplicate.climbingGrade.id === gradeObj
                      ) {
                        passedDuplicateCheck = false;
                        break;
                      }
                    }
                  }
                  if (passedDuplicateCheck) {
                    acceptedWallAssembly.strippingUrgencies = settingUrgencies;

                    highestSettingUrgencyOfAnyGradeInASlotInThisWall = settingUrgency;
                    bestEmptySlot =
                      wallAssembly.climbingWall.slotConfigsJSON[
                        mapping.slotIndex
                      ];
                    foundEmptySlot = true;
                    emptySlotsBestGrade = gradeObj;
                    emptySlot =
                      wallAssembly.climbingWall.slotConfigsJSON[
                        mapping.slotIndex
                      ];

                    if (!mappingBelongsToCurrentRoute) {
                      foundBetterSlot = true;
                    }
                  } else if (!allowDuplicates) {
                    duplicatesFoundInThisSlot.push(gradeObj);
                  }
                }
              }
            }
          }
        }

        if (
          wallWillBeExactlyFull &&
          emptySlotsBestGrade === null &&
          acceptedWallAssembly.mappingList.length > 0
        ) {
          console.log(
            "I think this is an error. The isHigherSettingUrgency should have allowed the mappingBelongsToCurrentRoute into the if statement above to set the emptySlotsBestGrade"
          );
          debugger;
          /*emptySlotsBestGrade = gradeObj;
              emptySlot =
                wallAssembly.climbingWall.slotConfigsJSON[
                  mapping.slotIndex
                ];*/
        }

        longevityClimbAssembly.proposedGradeReplacement = null;
        // longevityClimbAssembly.wallIsTooFull = wallWillBeTooFull;
        longevityClimbAssembly.finalDuplicatesFoundInThisSlot = finalDuplicatesFoundInThisSlot;

        addLongevity(longevityClimbAssembly.longevityBreakdown, {
          desc:
            "FYI: WallWillBeExactlyFull: " +
            wallWillBeExactlyFull +
            "   WallWillBeTooFull: " +
            wallWillBeTooFull,
          amount: 0,
          days: 0
        });

        const gradeStore = gradeStores[wallAssembly.climbingWall.gradeType];

        let noPointInStrippingAdjustment =
          -(
            2 /
            planningQuantityMap[climbAssembly.climbingGrade.id].targetQuantity
          ) * FIXED_ADJUSTMENT;

        //If the wall is too full now (i.e. at least 1 extra route),
        //then even after stripping there will be no spare space
        if (wallWillBeTooFull) {
          addLongevity(longevityClimbAssembly.longevityBreakdown, {
            desc:
              "This wall has too many routes so a route needs to be stripped.",
            amount: 0,
            days: 0
          });
        } else {
          let settingPlan;

          if (areWorkingOutParallelLongevities) {
            settingPlan = GeneratePlan.generateSettingPlans(
              rootStore,
              1, //numberToSet
              [longevityClimbAssembly.climbAssembly],
              gradeType,
              false
            )[0];

            longevityClimbAssembly.settingPlan = settingPlan;

            if (
              settingPlan &&
              settingPlan.route_to_strip_id !==
                longevityClimbAssembly.climbAssembly.climbingRoute.id
            ) {
              console.log(
                "ERROR: Somehow the system has recommended that we strip a different route instead of the one we are working out the longevity of???"
              );
              debugger;
            }

            addLongevityForSettingPlan(
              longevityClimbAssembly.longevityBreakdown,
              settingPlan,
              gradeStore,
              planningQuantityMap,
              oldestRouteAgeInDays
            );

            if (slotOfThisRoute !== null || bestEmptySlot !== null) {
              let slotSettingUrgencyAdjustment;

              if (foundBetterSlot || foundEmptySlot) {
                slotSettingUrgencyAdjustment =
                  highestSettingUrgencyOfAnyGradeInASlotInThisWall *
                  FIXED_ADJUSTMENT;
              }
              //This is just used for debugging at the moment
              longevityClimbAssembly.emptySlot = emptySlot;
              longevityClimbAssembly.slotOfThisRouteDebugging = slotOfThisRoute;

              let desc;

              if (climbAssembly.climbingRoute.id === 79) {
                //debugger;
              }
              // if another empty slot existed which had a grade with a better urgency
              if (foundBetterSlot) {
                //This handle is used to keep the alreadyClaimed value
                climbAssembly.bestEmptySlot = bestEmptySlot;
                let bestEmptySlotStr = getSlotStr(gradeStore, bestEmptySlot);

                desc =
                  "By clearing this route we will gain this empty slot " +
                  JSON.stringify(bestEmptySlotStr) +
                  " which has a max setting value of '" +
                  slotSettingUrgencyAdjustment.toFixed(2) +
                  "' for the grade '" +
                  gradeStore.getItemById(emptySlotsBestGrade).primaryLabel;
              } else {
                longevityClimbAssembly.bestEmptySlot = slotOfThisRoute;
                let slotOfThisRouteStr = getSlotStr(
                  gradeStore,
                  slotOfThisRoute
                );
                if (!foundEmptySlot) {
                  // If this slot has too many duplicates, i.e. there is no space where we can set
                  if (
                    allowDuplicates ||
                    finalDuplicatesFoundInThisSlot.length === 0
                  ) {
                    desc =
                      "By clearing this route we only get back the same slot (" +
                      slotOfThisRouteStr +
                      ")";
                    //If there was no other grade to set then we need to set this grade again
                    if (
                      highestSettingUrgencyOfAnyGradeInASlotInThisWall === null
                    ) {
                      slotSettingUrgencyAdjustment = noPointInStrippingAdjustment;
                    } else {
                      //I don't think this code will ever be reached
                      debugger;
                      slotSettingUrgencyAdjustment = highestSettingUrgencyOfAnyGradeInASlotInThisWall;
                    }
                  } else {
                    //if the wall is completely filled with duplicates then we can't set anything
                    if (
                      finalDuplicatesFoundInThisSlot.length ===
                      slotOfThisRoute.length
                    ) {
                      desc =
                        "By clearing this route we only get back the same slot (" +
                        slotOfThisRouteStr +
                        "), but the wall is filled with duplicates which prevent the slot from being used. " +
                        getSlotStr(gradeStore, finalDuplicatesFoundInThisSlot) +
                        ".";

                      // Then there is no point in stripping this route because we can't set.
                      slotSettingUrgencyAdjustment = noPointInStrippingAdjustment;
                    } else {
                      /* else no better slot was found (foundBetterSlot)
                       * and not even the current slot was empty (foundEmptySlot)
                       * and we don't allow duplicates and there are some duplicates
                       * and the wall is not completely full of duplicates
                       *
                       * Then ???
                       */
                      desc =
                        "By clearing this route we only get back the same slot (" +
                        slotOfThisRouteStr +
                        "). There are duplicates that limit options.";

                      //If the suggested grade is the one we are looking at stripping
                      //then we need to use the opposite of the stripping urgency as
                      // the setting urgency.
                      if (
                        emptySlotsBestGrade === climbAssembly.climbingGrade.id
                      ) {
                        let fractionalInfluence =
                          gymStore.shortageLongevityInfluence /
                          INFLUENCE_MIDWAY_POINT_3;

                        slotSettingUrgencyAdjustment = calculateSettingUrgency(
                          planningQuantityMap[emptySlotsBestGrade]
                            .targetQuantity,
                          planningQuantityMap[emptySlotsBestGrade]
                            .actualQuantity,
                          fractionalInfluence
                        );
                      } else {
                        slotSettingUrgencyAdjustment =
                          highestSettingUrgencyOfAnyGradeInASlotInThisWall *
                          FIXED_ADJUSTMENT;
                      }
                    }
                  }
                } else {
                  desc =
                    "By clearing this route we will regain this slot (" +
                    slotOfThisRouteStr +
                    ") which has a max setting value of '" +
                    slotSettingUrgencyAdjustment.toFixed(2) +
                    "' for the grade '" +
                    gradeStore.getItemById(emptySlotsBestGrade).primaryLabel +
                    "'.";
                }
              }

              longevityClimbAssembly.proposedGradeReplacement = emptySlotsBestGrade
                ? gradeStore.getItemById(emptySlotsBestGrade)
                : climbAssembly.climbingGrade;

              const strippedSlotDays = Math.floor(
                oldestRouteAgeInDays * slotSettingUrgencyAdjustment
              );

              addLongevity(longevityClimbAssembly.longevityBreakdown, {
                desc: desc + "... days=" + strippedSlotDays,
                amount: Math.floor(slotSettingUrgencyAdjustment * 100),
                days: 0,
                slotStr: slotStr
              });

              // Now we need to pretend that this route is gone from the slot so on the next iteration of this loop
              // We don't get the same empty slot suggested.
              //
              // NOTE: I'm not sure if pretendStripped is used properly anymore??? Brook - 17/02
              //if (mappingOfThisRoute !== null) {
              //mappingOfThisRoute.pretendStripped = true;
              //mappingOfThisRoute.isMapped = false;
              //}
            } else {
              const strippedSlotDays = Math.floor(
                oldestRouteAgeInDays * noPointInStrippingAdjustment
              );
              addLongevity(longevityClimbAssembly.longevityBreakdown, {
                desc:
                  "There will be no spare slots gained by stripping this route." +
                  strippedSlotDays,
                amount: Math.floor(noPointInStrippingAdjustment * 100),
                days: 0
              });
            }
          }
        }
        addLongevity(longevityClimbAssembly.longevityBreakdown, {
          desc: "FYI: The max age of all climbs is: " + oldestRouteAgeInDays,
          amount: 0,
          days: 0
        });
      }
    }
    // This sort seems unneccessary
    // acceptedWallAssembly.longevityClimbAssemblies.sort(basedOnLongevityRank);

    // Push all the assemblies from the wallAssembly into the final retVal
    // but only if they aren't settingPlans (because we can't strip a setting plan)
    for (let longevityAssembly of acceptedWallAssembly.longevityClimbAssemblies) {
      if (!longevityAssembly.climbAssembly.isSettingPlan) {
        longevityClimbAssemblies_retVal.push(longevityAssembly);
      }
    }
  }

  longevityClimbAssemblies_retVal.sort(basedOnLongevityRank);

  longevityClimbAssemblies_retVal.oldestRouteAgeInDays = oldestRouteAgeInDays;
  return longevityClimbAssemblies_retVal;
}
