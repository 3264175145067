import { coreStyle } from "ticker-core";

import { buttonStyle } from "ticker-components";
const { container, dangerColor } = coreStyle;

const dateSelectionPageStyle = () => ({
  container,
  //hoverCardStyle,
  button: buttonStyle.button,
  success: buttonStyle.success,
  danger: buttonStyle.danger,
  warning: {
    color: dangerColor[0],
    minHeight: "2.5em",
    paddingTop: ".5em",
    paddingBottom: ".5em"
  }
});

export default dateSelectionPageStyle;
