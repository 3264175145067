import createDraggableItemsStore from "./DragggableStore.js";
import { gradeTypes } from "../variables/constants";

export default class GradeStores {
  constructor(context, asyncOpCreate, getCurrentGymId) {
    this.gradeStores = [];

    this.gradeStores[gradeTypes.ROUTE] = createDraggableItemsStore(
      context,
      "climbingGrades",
      "RouteGrade",
      item => item.gradeType === gradeTypes.ROUTE,
      asyncOpCreate,
      getCurrentGymId
    );

    this.gradeStores[gradeTypes.BOULDER] = createDraggableItemsStore(
      context,
      "climbingGrades",
      "BoulderGrade",
      item => item.gradeType === gradeTypes.BOULDER,
      asyncOpCreate,
      getCurrentGymId
    );

    this.getItemById = id => {
      let result = this.gradeStores[gradeTypes.ROUTE].getItemById(id);

      if (result) {
        return result;
      }
      return this.gradeStores[gradeTypes.BOULDER].getItemById(id);
    };
  }
}
