import executeRouteAi from "./executeRouteAi";

export default function SuggestionAI(
  unsetRouteAssemblies,
  unusedSlotList,
  options
) {
  let suggestionKey = 0;
  /*
   * This Key is used in the react 'key' property
   */
  let getNextSuggestionKey = function getNextSuggestionKey() {
    //Cast to a string because the draggableId should be a string
    return suggestionKey++ + "";
  };

  let routeAi = new executeRouteAi(
    unsetRouteAssemblies,
    unusedSlotList,
    options
  );

  let suggestionListsGroupedBySection = [];
  let suggestions = [];
  let leftOverGradesMappedToCounts = [];
  let sectionIdToSectionGrouping = [];
  let wallIdToWallGrouping = [];

  routeAi.getMapping().forEach(map => {
    //If there is no usedslot for this slotMap (i.e. a climb without a slot)
    // then we don't want to include it in the suggestionList
    if (typeof map.slotIndex === "undefined") {
      let gradeId = unsetRouteAssemblies[map.climbIndex].climbingGrade.id;
      if (!leftOverGradesMappedToCounts[gradeId]) {
        leftOverGradesMappedToCounts[gradeId] = {
          maps: [],
          count: 0,
          climbingGrade: unsetRouteAssemblies[map.climbIndex].climbingGrade
        };
      }
      leftOverGradesMappedToCounts[gradeId].maps.push(map);
      leftOverGradesMappedToCounts[gradeId].count++;
      return;
    }

    //If there is a slot without a climb
    //if (typeof map.slotIndex !== "undefined" && !map.isMapped) {
    //  debugger;
    //}

    // pop off the first acceptableAreas . We don't care which one because it could
    // be any of them. We need to remove it via pop so that
    // The next climb to use this equivalentSlot doesn't get the same wall
    // (unless the wall has two slots for this equivalentSlot, in which case the wall will appear twice in the wall array)
    let currentWallAssembly = unusedSlotList[map.slotIndex].wallAssembly;
    let wallId = currentWallAssembly.climbingWall.id;
    let sectionId = currentWallAssembly.sectionAssembly.climbingSection.id;

    let slotStr;
    if (typeof unusedSlotList[map.slotIndex].slotStr === "undefined") {
      slotStr = unusedSlotList[map.slotIndex].representativeSlot.slotStr;
    } else {
      slotStr = unusedSlotList[map.slotIndex].slotStr;
    }

    let slotGradeList;
    if (typeof unusedSlotList[map.slotIndex].slotGradeList === "undefined") {
      slotGradeList =
        unusedSlotList[map.slotIndex].representativeSlot.slotGradeList;
    } else {
      slotGradeList = unusedSlotList[map.slotIndex].slotGradeList;
    }

    // This slot has a climbGrade so make it a suggestion.
    if (map.isMapped) {
      let suggestionListsGroupedByWall;
      if (typeof sectionIdToSectionGrouping[sectionId] === "undefined") {
        sectionIdToSectionGrouping[sectionId] =
          suggestionListsGroupedBySection.length;
        suggestionListsGroupedByWall = [];
        suggestionListsGroupedBySection.push({
          sectionAssembly: currentWallAssembly.sectionAssembly,
          suggestionListsGroupedByWall: suggestionListsGroupedByWall
          //sectionHasAllSlotsFilled: true,
          //missingSlotCount: 0
        });
      } else {
        suggestionListsGroupedByWall =
          suggestionListsGroupedBySection[sectionIdToSectionGrouping[sectionId]]
            .suggestionListsGroupedByWall;
      }

      if (typeof wallIdToWallGrouping[wallId] === "undefined") {
        wallIdToWallGrouping[wallId] = suggestionListsGroupedByWall.length;
        suggestionListsGroupedByWall.push({
          wallAssembly: currentWallAssembly,
          suggestionList: [],
          wallHasAllSlotsFilled: true,
          missingSlotCount: 0
        });
      }

      let suggestion = {
        key: getNextSuggestionKey(),
        isComplete: true,
        slotStr: slotStr,
        slotGradeList: slotGradeList,
        climbingGrade: unsetRouteAssemblies[map.climbIndex].climbingGrade,

        // Note: You can't attach the wallAssembly here because when the
        // Suggestion is added to the observable pretendAssemblies
        // there is an error to do with "Maximum call stack size exceeded"
        // So I just reference the Wall id instead.
        wallAssembly: {
          climbingWall: currentWallAssembly.climbingWall,
          climbingSection: currentWallAssembly.sectionAssembly.climbingSection
          /*            stuffFromWallAssembly: currentWallAssembly.stuffFromWallAssembly*/
        }
      };

      suggestion.map = map;
      suggestionListsGroupedByWall[
        wallIdToWallGrouping[wallId]
      ].suggestionList.push(suggestion);

      suggestions.push(suggestion);
      //console.log(JSON.stringify(suggestion));
    }
    //else if (typeof map.slotIndex !== "undefined"){
    //	suggestion.isComplete = false;
    //	suggestion.slotStr = slotStr;
    //	suggestionListsGroupedByArea[suggestionIndexMap[wallId]].suggestionList.push(suggestion);
    //	suggestionListsGroupedByArea[suggestionIndexMap[wallId]].wallHasAllSlotsFilled = false;
    //	suggestionListsGroupedByArea[suggestionIndexMap[wallId]].missingSlotCount++;

    //}
  });

  for (let ssl = 0; ssl < suggestionListsGroupedBySection.length; ssl++) {
    let suggestionListsGroupedByWall =
      suggestionListsGroupedBySection[ssl].suggestionListsGroupedByWall;
    for (let sl = 0; sl < suggestionListsGroupedByWall.length; sl++) {
      if (
        suggestionListsGroupedByWall[sl].suggestionList.length !==
        suggestionListsGroupedByWall[sl].wallAssembly.slotConfigsCopy.length
      ) {
        suggestionListsGroupedByWall[sl].wallHasAllSlotsFilled = false;
      }
    }
    suggestionListsGroupedByWall.ranOutOfTime = routeAi.outOfTime;
  }

  let byCountNumber = (a, b) => {
    return b.count - a.count;
  };
  let stickingPoints = routeAi.deepestStickingPointsList.sort(byCountNumber);

  return {
    suggestionsGroupedBySection: suggestionListsGroupedBySection,
    suggestions,
    leftOverGradesMappedToCounts,
    stickingPoints: stickingPoints,
    routeAi
  };
}
