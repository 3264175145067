import React, { memo } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  wallName: { fontSize: "small", display: "inline", fontWeight: "bold" }
};

class WallAndSectionName extends React.Component {
  render() {
    const { classes, climbingWall, climbingSection } = this.props;

    return (
      <React.Fragment>
        <h4 className={classes.sectionName}>
          {climbingSection.name}{" "}
          <span className={classes.wallName}>{climbingWall.name}</span>
        </h4>
      </React.Fragment>
    );
  }
}

WallAndSectionName.propTypes = {
  climbingWall: PropTypes.object.isRequired,
  climbingSection: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};
export default memo(withStyles(style)(WallAndSectionName));
