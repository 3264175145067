import { observable, action } from "mobx";

import { groups } from "../variables/constants.js";

export default class GroupStores {
  constructor(context, asyncOpCreate) {
    const rest = context.rest;

    let userBelongsToGroup = {};
    Object.values(groups).forEach(
      groupName => (userBelongsToGroup[groupName] = false)
    );

    //The observable it not put directly into 'this' because it is referenced below
    // in the cacheItems callback

    userBelongsToGroup = observable(userBelongsToGroup);

    this.userBelongsToGroup = userBelongsToGroup;

    this.groupStore = {
      items: observable([]),
      opCacheItems: asyncOpCreate(() => rest.get("groups"), "cacheItem-Group"),

      /*
       * Why isn't this create using getCachable???
       * 18/08/20
       */
      cacheItems: action(async function({ forceReload }) {
        try {
          if (!this.itemsCached || forceReload) {
            this.itemsCached = false;
            console.log("Loading the items: Groups");
            this.items = await this.opCacheItems.execute({}, newItems => {
              this.pushIntoObservableArrays(newItems);
              this.itemsCached = true;
              newItems.forEach(
                groupName => (userBelongsToGroup[groupName] = true)
              );
            });
          } else {
            console.log("The items 'groups' are already cached, or forced.");
          }
        } catch (error) {
          console.log(error);
          console.log("errormehere");
          console.trace("errormehere");
          throw error;
        }
      }),

      pushIntoObservableArrays: action(function(newItems) {
        //We must clear the observable arrays first
        this.items.clear();

        newItems.forEach(item => {
          this.items.push(item);
        });
      }),
      getStoreName: () => {
        return "groupStore";
      }
    };
  }
}
