import React from "react";
import PropTypes from "prop-types";
import { useObserver } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Email from "@material-ui/icons/Email";

// core components
import {
  GridContainer,
  GridItem,
  Card,
  Button,
  CustomInput,
  CardBody,
  SnackbarContent
} from "ticker-components";

import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import AddAlert from "@material-ui/icons/AddAlert";

import { withRootStore } from "ticker-core";

import registerPageStyle from "../assets/jss/material-dashboard-pro-react/views/registerPageStyle";

function RegisterPage({ classes, rootStore }) {
  const registerStore = rootStore.authStores.getStores().register;
  const loginStore = rootStore.authStores.getStores().login;

  return useObserver(() => {
    let passwordErrMsg, emailErrMsg;

    const { errors, serverMessage } = registerStore;
    if (errors.password && errors.password[0]) {
      passwordErrMsg = errors.password[0];
    }

    if (errors.email && errors.email[0]) {
      emailErrMsg = errors.email[0];
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem>
            <Card style={{ maxWidth: "500px" }} className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Sign up!</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem>
                    <form className={classes.form}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        error={errors.email}
                        helperText={emailErrMsg}
                        inputProps={{
                          onChange: e => {
                            registerStore.setEmail(e.target.value);
                          },
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: "Email...",
                          value: registerStore.email
                        }}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        helperText={passwordErrMsg}
                        error={errors.password}
                        inputProps={{
                          onChange: e => {
                            registerStore.setPassword(e.target.value);
                          },
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          placeholder: "Password...",
                          value: registerStore.password,
                          type: "password"
                        }}
                      />
                      <div className={classes.center}>
                        <Button
                          round
                          color="primary"
                          onClick={() => registerStore.register()}
                        >
                          Get started
                        </Button>
                      </div>
                      <div></div>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <div style={{ textAlign: "center", width: "100%" }}>
              Don't want to sign up yet?{" "}
              <Button
                onClick={() => {
                  rootStore.history.push("");
                }}
                color="primary"
                round
              >
                test drive now
              </Button>
            </div>
            {serverMessage.message && (
              <SnackbarContent
                message={serverMessage.message}
                close
                icon={AddAlert}
                color="warning"
                style={{ maxWidth: "500px" }}
                actions={
                  serverMessage.showBtn
                    ? [
                        <Button
                          color="primary"
                          key="useless"
                          onClick={() => {
                            registerStore.serverMessage = {};
                            loginStore.setEmail(registerStore.email);
                            loginStore.setPassword(registerStore.password);
                            rootStore.history.push(
                              rootStore.context.config.userLoginPath
                            );
                          }}
                        >
                          Login Instead
                        </Button>
                      ]
                    : undefined
                }
              />
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  });
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default withRootStore(withStyles(registerPageStyle)(RegisterPage));
