import ClimbSelectorPage from "./src/views/climbsPage/ClimbsSelectorPage";
import DateSelectionPage from "./src/views/registration/DateSelectionPage";
import HeightPage from "./src/views/HeightPage";
import InspirationPage from "./src/views/inspiration/InspirationPage";
import LeaderboardPage from "./src/views/leaderboard/LeaderboardPage";
import MapPage from "./src/views/mapPage/MapPage.js";
import OrganisationPage from "./src/views/OrganisationPage";
import PasswordLogoutPage from "./src/views/PasswordLogoutPage";
import CompletionPage from "./src/views/myStats/completion/CompletionPage";
import RegistrationUpdatePage from "./src/views/registration/login/RegistrationUpdatePage";
import RegistrationLoginPage from "./src/views/registration/login/RegistrationLoginPage";
import RegistrationLogoutPage from "./src/views/registration/login/RegistrationLogoutPage";
import RegistrationRegisterPage from "./src/views/registration/login/RegistrationRegisterPage";
import TicksGraphPage from "./src/views/myStats/ticks/TicksGraphPage";
import TicksPage from "./src/views/myStats/ticks/TicksPage";
import TourPage from "./src/views/TourPage";

import RegistrationLoginPageStyle from "./src/assets/jss/material-dashboard-pro-react/views/registration/registrationLoginPageStyle";

export {
  ClimbSelectorPage,
  DateSelectionPage,
  HeightPage,
  InspirationPage,
  LeaderboardPage,
  MapPage,
  PasswordLogoutPage,
  CompletionPage,
  OrganisationPage,
  RegistrationLoginPage,
  RegistrationLoginPageStyle,
  RegistrationLogoutPage,
  RegistrationRegisterPage,
  RegistrationUpdatePage,
  TicksGraphPage,
  TicksPage,
  TourPage
};
