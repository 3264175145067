import executeRouteAi from "../../utils/RouteAi/executeRouteAi";
import RouteAiHelper from "../../utils/RouteAi/RouteAiHelper";

import {
  calculateStrippingUrgency,
  createAcceptedWallAssembly,
  addSettingPlanToAcceptedWallAssembly
} from "../../utils/ReportStoreUtility";

function getUnusedClimbSlotList(
  computedCache,
  gradeType,
  now,
  planAssemblies,
  options,
  gradeStores,
  planningGradeCountMap,
  climbingGrades
) {
  let { ignoreStrippingPlans, includeOnHoldRoutes, allowDuplicates } = options;

  let unusedClimbSlotList = [];
  computedCache.sectionAssemblies.forEach(sectionAssembly => {
    if (!sectionAssembly.climbingSection.isArchived) {
      sectionAssembly.climbingWallAssemblies.forEach(function(wallAssembly) {
        if (
          wallAssembly.climbingWall.isArchived ||
          wallAssembly.climbingWall.gradeType !== gradeType
        ) {
          return;
        }

        /*
         * unslottedClimbCount and eligibleSlots are used in executeRouteAi to help
         * work out if an wall has space for another slot.
         */
        wallAssembly.unslottedClimbCount = 0;
        wallAssembly.fullSlotsCount = 0;

        RouteAiHelper.fillDefaultRoutesAndSlots(wallAssembly);

        let acceptedWallAssembly = createAcceptedWallAssembly(
          wallAssembly,
          now,
          planAssemblies,
          {
            ignoreStrippingPlans,
            includeOnHoldRoutes
          }
        );

        let gradeStore = gradeStores.gradeStores[gradeType];

        addSettingPlanToAcceptedWallAssembly(
          planAssemblies,
          acceptedWallAssembly,
          gradeStore
        );

        let options = {
          allowFailures: true,
          skipDuplicateCheck: true,
          checkEligibleSlots: false
          // debugMode: true
        };

        let routeAi = new executeRouteAi(
          acceptedWallAssembly.acceptedClimbAssemblies,
          wallAssembly.slotConfigsCopy,
          options
        );

        //wall.mappingList is used in the Longevity method below
        wallAssembly.mappingList = routeAi.getMapping();

        wallAssembly.eligibleSlots = [];

        if (wallAssembly.climbingWall.name === "Little Northern") {
          //debugger;
        }
        for (let k = 0; k < wallAssembly.mappingList.length; k++) {
          let mapping = wallAssembly.mappingList[k];
          if (mapping.isMapped) {
            wallAssembly.fullSlotsCount++;
          }

          // Else this is a climb that has no slot
          else if (typeof mapping.climbIndex !== "undefined") {
            wallAssembly.unslottedClimbCount++;
          }
        }

        /*
         * Cycle through the routeAi mappings and for each unfilled slot:
         *
         */
        for (let k = 0; k < wallAssembly.mappingList.length; k++) {
          let mapping = wallAssembly.mappingList[k];
          if (typeof mapping.slotIndex !== "undefined") {
            let originalSlot = wallAssembly.slotConfigsCopy[mapping.slotIndex];

            let filteredSlot = getFilteredSlot(
              originalSlot,
              planningGradeCountMap,
              climbingGrades,
              allowDuplicates,
              acceptedWallAssembly
            );

            //This originalSlot.strippingUrgency is used in the longevity calculations
            originalSlot.strippingUrgency = filteredSlot.strippingUrgency;
            originalSlot.slotStr = filteredSlot.slotStr;
            originalSlot.slotGradeList = filteredSlot.slotGradeList;

            /*
             * if the slot has no grades then it is open so we need to accept this slot.
             * or if after filtering the duplicates and unneeded grades there is still at least one
             * grade in the slot.
             * Then add it to the eligible list.
             */
            //We can only add it to the slot list if there aren't too many routes in this wall already
            let wallIsFull =
              wallAssembly.unslottedClimbCount +
                wallAssembly.fullSlotsCount +
                wallAssembly.eligibleSlots.length >=
              wallAssembly.slotConfigsCopy.length;
            if (
              !mapping.isMapped &&
              !wallIsFull &&
              (originalSlot.length === 0 || filteredSlot.length > 0)
            ) {
              wallAssembly.eligibleSlots.push(filteredSlot);
            } else {
              /*
              console.log("\n\n\nSlot rejected:" + originalSlot.slotStr);
              console.log(
                "   wallName = " + wallAssembly.climbingWall.name
              );
              console.log("   isMapped = " + mapping.isMapped);
              console.log("   wallIsFull = " + wallIsFull);
              console.log(
                "   originalSlot.length = " + originalSlot.length
              );
              console.log(
                "   filteredSlot.length = " + filteredSlot.length
              );*/
            }
          }
        } // end the for mappingList

        /*
         * At this stage we should have a list of eligible slots
         * BUT we have to remove a number slots that matches the number
         * of unslotted routes.
         *
         * BUT the only way to do this perfectly is to number crunch the whole
         * gym.
         * At this point I have decided not to code all that so I am taking a shortcut
         * where I removed the least likely to be found
         */
        unusedClimbSlotList.sort(basedOnStrippingUrgency);
        wallAssembly.eligibleSlots.forEach(function(slot) {
          //This reference to wall is used in the executeRouteAi
          //slot.wallAssembly = wallAssembly;

          unusedClimbSlotList.push(slot);
        });
      }); // end the wall.forEach
    }
  });
  return unusedClimbSlotList;
}

function getFilteredSlot(
  originalSlot,
  planningGradeCountMap,
  climbingGrades,
  allowDuplicates,
  acceptedWallAssembly
) {
  let filteredSlot = [];
  filteredSlot.originalSlot = originalSlot;
  let slotStr = "(";
  filteredSlot.slotGradeList = [];

  filteredSlot.strippingUrgency = 0;

  /*
   * forEach possible grade within a slot
   */

  for (let l = 0; l < originalSlot.length; l++) {
    let gradeObj = originalSlot[l];
    /*
     * First we get the primaryLabel for debugging and displaying on screen
     */
    let primaryLabel = "";
    let gradeCount = planningGradeCountMap[gradeObj];

    for (let m = 0; m < climbingGrades.length; m++) {
      let climbingGrade = climbingGrades[m];
      if (gradeObj === climbingGrade.id) {
        primaryLabel = climbingGrade.primaryLabel;
        filteredSlot.slotGradeList.push(climbingGrade);
        break;
      }
    }
    /*
     * Check for duplicate climb grades on the same wall.
     * At the moment we only check for duplicates for ROPED climbs
     */
    let isAlreadyPresentOnWall = false;

    if (!allowDuplicates) {
      for (let climbingRouteAssembly of acceptedWallAssembly.acceptedClimbAssemblies) {
        if (climbingRouteAssembly.climbingGrade.id === gradeObj) {
          isAlreadyPresentOnWall = true;
          break;
        }
      }
    }

    if (typeof gradeCount === "undefined") {
      //This grade is probably archived
      slotStr += "Archived" + primaryLabel + "Archived or ";
    } else if (!allowDuplicates && isAlreadyPresentOnWall) {
      //do nothing because we don't want to include the decrement count of
      // this grade because this slot will never be used for this grade.
      // because of the no duplicates rules.

      // But we do want to annotate the slotStr for debugging
      slotStr += "*" + primaryLabel + "* or ";
    } else {
      // If this grade is needed the include the grade
      if (gradeCount.targetQuantity - gradeCount.actualQuantity > 0) {
        filteredSlot.push(gradeObj);
        slotStr += primaryLabel + " or ";
      } else if (gradeCount.targetQuantity === gradeCount.actualQuantity) {
        slotStr += "@" + primaryLabel + "@ or ";
      }
      // else this grade is not needed so we just annotate the slotStr
      else {
        slotStr += "#" + primaryLabel + "# or ";
      }

      filteredSlot.strippingUrgency =
        filteredSlot.strippingUrgency +
        calculateStrippingUrgency(
          gradeCount.targetQuantity,
          gradeCount.actualQuantity
        );
    }
  } // End originalSlot forEach

  // take off the last " or "
  if (slotStr.length > 5) slotStr = slotStr.slice(0, -4);

  filteredSlot.slotStr = slotStr + ")";

  filteredSlot.wallAssembly = originalSlot.wallAssembly;
  return filteredSlot;
}

function basedOnStrippingUrgency(slotA, slotB) {
  return slotB.strippingUrgency - slotA.strippingUrgency;
}

let byPlanningDate = (a, b) => {
  if (a.planningDate < b.planningDate) {
    return -1;
  }
  if (b.planningDate === a.planningDate) {
    return 0;
  }
  return 1;
};

export { byPlanningDate, basedOnStrippingUrgency, getUnusedClimbSlotList };
