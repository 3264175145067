import { coreStyle } from "ticker-core";

import { customCheckboxRadioSwitchStyle } from "ticker-core";

const { highTop, getSlenderContainer } = coreStyle;

const inspirationPageStyle = theme => ({
  ...customCheckboxRadioSwitchStyle,
  listItemIcon: coreStyle.listItemIconStyle,
  highTop,
  gridRow: {
    [theme.breakpoints.down("xs")]: {
      borderBottom: "solid 2px grey"
    }
  },
  title: {
    textAlign: "center"
  },
  slenderContainer: getSlenderContainer(theme),
  padded: {
    paddingLeft: "1em",
    paddingRight: "1em"
  },
  wellPadded: {
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: "0.5em",
    paddingBottom: "0.5em"
  },
  sliderContainer: {
    textAlign: "center",
    maxWidth: "50em",
    margin: "auto",
    paddingTop: "3em",
    paddingLeft: "2em",
    paddingRight: "2em"
  },
  text: {
    fontSize: "0.9em"
  }
});

export default inspirationPageStyle;
