import React from "react";
import PropTypes from "prop-types";
import Badge from "../Badge/Badge.js";
import Button from "../CustomButtons/Button.js";
import Clear from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";

let styles = {
  badge: {
    marginLeft: "1em"
  },
  crossContainer: {
    minWidth: "1.2em"
  }
};

let useStyles = makeStyles(styles);

function OptionButton(props) {
  let { isSelected, onClick, label, badgeText, showCross } = props;

  let classes = useStyles();

  let style;
  let color = "gray";
  let isSimple = true;
  if (isSelected) {
    color = "info";
    // isSimple = false;
  }

  return (
    <Button
      color={color}
      round
      size="sm"
      onClick={onClick}
      style={style}
      simple={isSimple}
    >
      {showCross && (
        <span className={classes.crossContainer}>
          {isSelected && showCross && (
            <Clear
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            />
          )}
        </span>
      )}

      {label}
      {badgeText && (
        <Badge color={color} otherClassName={classes.badge}>
          {badgeText}
        </Badge>
      )}
    </Button>
  );
}

OptionButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  badgeText: PropTypes.node,
  showCross: PropTypes.bool
};

export default OptionButton;
