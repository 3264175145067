import React, { useState } from "react";
import { useObserver } from "mobx-react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import {
  Accordion,
  GridContainer,
  GridItem,
  Button,
  Badge,
  SortButton
} from "ticker-components";

import Clear from "@material-ui/icons/Clear";

// sections for this page

import Selectors from "./Selectors.js";

import { gradeTypes } from "ticker-core";

import styles from "../../assets/jss/material-dashboard-pro-react/components/climbSelectorsStyle.js";

import { withCachedRootStore, getClimbSelectionSortOptions } from "ticker-core";

let routePageStyle = makeStyles(styles);

let badgeStyle = makeStyles({
  forBadge: {
    minWidth: "1.5em",
    textAlign: "right",
    marginRight: "0.5em",
    fontSize: "large"
  },
  floatLeft: { float: "left" }
});

const storeNamesWithLabels = [
  {
    wrapperName: "selectedSectionWrapper",
    fieldName: "name",
    labelPreText: null,
    getAccordionPanel: () => 0
  },
  {
    wrapperName: "selectedColorWrapper",
    fieldName: "name",
    labelPreText: "Color",
    getAccordionPanel: () => 1
  },
  {
    wrapperName: "selectedGradeWrapper",
    fieldName: "primaryLabel",
    labelPreText: "Grade",
    //snwlIndex is the storeNamesWithLables Index
    getAccordionPanel: wrapper => {
      if (wrapper.item.gradeType === gradeTypes.ROUTE) {
        return 3; // 3 is the index of the route panel in the accordion
      }
      return 2; // 2 is for the boulder panel
    }
  },
  {
    wrapperName: "selectedWallWrapper",
    fieldName: "name",
    labelPreText: null,
    getAccordionPanel: () => 5
  },
  {
    wrapperName: "selectedOnHoldWrapper",
    fieldName: "label",
    labelPreText: null,
    getAccordionPanel: () => 5
  }
];

function AccordionTitle({ title, count }) {
  let classes = badgeStyle();

  return (
    <>
      {/* <Badge color="warning" otherClassName={classes.forBadge}>
        {count}
      </Badge> */}
      <span className={classes.forBadge + " " + classes.floatLeft}>
        {count}
      </span>
      <span className={classes.floatLeft}>{title}</span>
    </>
  );
}
AccordionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
};
const ClimbsPage = function({ rootStore, onSelectCallback }) {
  const [accordionPanel, setAccordionPanel] = useState(0);
  const [hasSelectedOtherPanel, setHasSelectedOtherPanel] = useState(false);

  let {
    climbSelectionStore,
    personalRootStore,
    regStores: { regStore }
  } = rootStore;

  let { filterAnalysisStore } = personalRootStore;

  // console.log("rendering the route page");

  return useObserver(() => {
    let cvc = filterAnalysisStore.filterAnalysises.get();

    //
    // This is for the infinite scrolling
    //
    // let LIMIT = 5;
    // const [offset, setOffset] = useState(5);
    // const fetchMoreData = () => {
    //   setOffset(offset + LIMIT);
    //   console.log("offset = " + offset);
    // };
    // let someVisibleRoutes = cvc.climbingRouteAssemblies.slice(0, offset + 1);

    const onChange = panel => (event, expanded) => {
      console.log(
        "onChange in routespage. The expanded=" +
          expanded +
          ". The panel =" +
          panel
      );
      setAccordionPanel(expanded ? panel : -1);
      setHasSelectedOtherPanel(true);
    };

    const classes = routePageStyle();

    /*
     * If the primary selection method is section
     */

    let goToNextPanel = () => {
      if (!hasSelectedOtherPanel) {
        let newAccordionValue = accordionPanel + 1;
        while (
          newAccordionValue < accordionCollapseData.length &&
          accordionCollapseData[newAccordionValue].filterAnalysis
            .unfilteredCount === 0
        ) {
          newAccordionValue++;
        }
        if (newAccordionValue < accordionCollapseData.length) {
          setAccordionPanel(newAccordionValue);
        }
      }
    };

    let accordionCollapseData = [
      {
        filterAnalysis: cvc.section,
        title: "Sections",
        fieldName: "name",
        selectorStoreVariableName: "selectedSectionWrapper"
      },
      {
        filterAnalysis: cvc.color,
        title: "Hold Colors",
        fieldName: "name",
        selectorStoreVariableName: "selectedColorWrapper"
      },
      {
        filterAnalysis: cvc.grades[gradeTypes.BOULDER],
        title: "Boulder Grades",
        fieldName: "primaryLabel",
        selectorStoreVariableName: "selectedGradeWrapper"
      },
      {
        filterAnalysis: cvc.grades[gradeTypes.ROUTE],
        title: "Route Grades",
        fieldName: "primaryLabel",
        selectorStoreVariableName: "selectedGradeWrapper"
      },
      {
        filterAnalysis: cvc.wall,
        title: "Walls",
        fieldName: "name",
        selectorStoreVariableName: "selectedWallWrapper"
      },
      {
        filterAnalysis: cvc.onHoldValue,
        title: "On Hold Options",
        fieldName: "label",
        selectorStoreVariableName: "selectedOnHoldWrapper"
      }
    ];

    let collapses = accordionCollapseData.map(data => {
      return {
        title: (
          <AccordionTitle
            title={data.title}
            count={data.filterAnalysis.unfilteredCount}
          />
        ),
        content: (
          <Selectors
            filterAnalysis={data.filterAnalysis}
            fieldName={data.fieldName}
            selectorStore={climbSelectionStore}
            selectorStoreVariableName={data.selectorStoreVariableName}
            displayName={data.title}
            onSelection={() => {
              if (onSelectCallback) {
                onSelectCallback();
              }
              goToNextPanel();
            }}
            onClear={() => {
              if (onSelectCallback) {
                onSelectCallback();
              }
            }}
          />
        )
      };
    });

    collapses.push({
      title: "Sort Order",
      content: (
        <div style={{ textAlign: "center", width: "100%" }}>
          {getClimbSelectionSortOptions(climbSelectionStore).map(option => (
            <SortButton
              key={option.label}
              onClick={() => {
                regStore.resetNoActivityTimeout();
                option.onClick();
                if (onSelectCallback) {
                  onSelectCallback();
                }
              }}
              label={option.label}
              isSelected={option.isSelected}
              ascending={option.ascending}
            />
          ))}
        </div>
      )
    });
    let hasSelected = false;
    for (let nwl of storeNamesWithLabels) {
      if (!climbSelectionStore[nwl.wrapperName]) continue;

      hasSelected = true;
    }

    return (
      <>
        <GridContainer justify="center" className={classes.filterContainer}>
          {!hasSelected && <h4>Please select a filter</h4>}
          {hasSelected && (
            <>
              {storeNamesWithLabels.map((dataPoint, index) => {
                let wrapper = climbSelectionStore[dataPoint.wrapperName];
                if (!wrapper) return null;

                let label = dataPoint.labelPreText
                  ? dataPoint.labelPreText +
                    ": " +
                    wrapper.item[dataPoint.fieldName]
                  : wrapper.item[dataPoint.fieldName];

                return (
                  <GridItem key={index}>
                    <Button
                      color="info"
                      round
                      simple
                      size="sm"
                      onClick={e => {
                        e.stopPropagation();
                        setAccordionPanel(dataPoint.getAccordionPanel(wrapper));
                        climbSelectionStore.setWrapper(
                          dataPoint.wrapperName,
                          null
                        );
                        if (onSelectCallback) {
                          onSelectCallback();
                        }
                      }}
                    >
                      <Clear />
                      {label}
                      <Badge color="info" otherClassName={classes.badge}>
                        {wrapper.visibleClimbsCount}
                      </Badge>
                    </Button>
                  </GridItem>
                );
              })}
              <GridItem>
                <Button
                  color="info"
                  round
                  size="xsm"
                  onClick={e => {
                    e.stopPropagation();
                    for (let nwl of storeNamesWithLabels) {
                      climbSelectionStore.setWrapper(nwl.wrapperName, null);
                    }
                    setHasSelectedOtherPanel(false);
                    setAccordionPanel(0);
                    if (onSelectCallback) {
                      onSelectCallback();
                    }
                  }}
                >
                  <Clear />
                  Clear Filters
                </Button>
              </GridItem>
            </>
          )}
        </GridContainer>
        <Accordion
          overrideActive={accordionPanel}
          onChange={onChange}
          collapses={collapses}
        />
        <h4 style={{ textAlign: "center" }}>
          {cvc.climbingRouteAssemblies.length + " "} Unfiltered Route
          {cvc.climbingRouteAssemblies.length !== 1 ? "s " : " "}
          {hasSelected && (
            <Button
              color="info"
              round
              size="xsm"
              onClick={e => {
                e.stopPropagation();
                for (let nwl of storeNamesWithLabels) {
                  climbSelectionStore.setWrapper(nwl.wrapperName, null);
                }
                setHasSelectedOtherPanel(false);
                setAccordionPanel(0);
                if (onSelectCallback) {
                  onSelectCallback();
                }
              }}
            >
              <Clear />
              Clear Filters
            </Button>
          )}
        </h4>
      </>
    );
  });
};

ClimbsPage.propTypes = {};

export default withCachedRootStore(ClimbsPage);
