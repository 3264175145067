import { action } from "mobx";

import getCachable from "../../utils/getCachable";

export default class /* PersonalRatingStores */ {
  constructor(
    context,
    asyncOpCreate,
    regStore,
    getCurrentGymId,
    getCurrentUserId
  ) {
    let areEqual = (a, b) => a.climbing_route_id === b.climbing_route_id;

    this.cachedRatings = getCachable(
      asyncOpCreate,
      context.rest,
      areEqual,
      "userRatings",
      null,
      () => ({
        user_id: getCurrentUserId(),
        passcode: regStore.passcode,
        gym_id: getCurrentGymId()
      })
    );

    this.ratingStore = getRatingStore(
      asyncOpCreate,
      context.rest,
      this.cachedRatings,
      regStore,
      getCurrentGymId,
      getCurrentUserId
    );
  }
}

function getRatingStore(
  asyncOpCreate,
  rest,
  cachedRatings,
  regStore,
  getCurrentGymId,
  getCurrentUserId
) {
  const this__ = {};

  this__.opUpdateUserRating = asyncOpCreate(
    payload => rest.put("userRatings", payload),
    "opupdateRegistrationRating"
  );

  this__.updateRegistrationRating = action(async function(
    payload,
    climbingAssembly
  ) {
    payload.climbing_route_id = climbingAssembly.climbingRoute.id;

    payload.passcode = regStore.passcode;

    updateAvgRating(climbingAssembly, payload);

    //We'll assume that the network transmission works
    //and update the UI store now
    cachedRatings.mergeOrInsert(payload);

    let callBack = response => {
      // Update the cachedRatings with the new ID and other details that may have been missed in the merge above
      cachedRatings.mergeOrInsert(response);
    };
    await this__.opUpdateUserRating.execute(payload, callBack);
  });

  this__.opDeleteUserRating = asyncOpCreate(
    payload => rest.delete("userRatings/" + payload.id, payload),
    "opDeleteUserRating"
  );
  this__.deleteUserRating = action(function(climbingAssembly) {
    let payload = {
      gym_id: getCurrentGymId(),
      user_id: getCurrentUserId(),
      passcode: regStore.passcode,
      id: climbingAssembly.userRating.id
    };

    removeFromAvgRating(climbingAssembly);

    cachedRatings.removeItemById(climbingAssembly.userRating.id);
    climbingAssembly.userRating = undefined;

    this__.opDeleteUserRating.execute(payload);
  });

  this__.getStoreName = () => {
    return "ratingStore";
  };

  return this__;
}

function updateAvgRating(climbingAssembly, payload) {
  let originalPublicRating = parseFloat(
    climbingAssembly.climbingRoute.avgRating
  );

  if (isNaN(originalPublicRating)) {
    originalPublicRating = 0;
  }
  let originalPrivateRating =
    typeof climbingAssembly.userRating !== "undefined" &&
    climbingAssembly.userRating.rating !== null
      ? climbingAssembly.userRating.rating
      : undefined;

  let originalCount = climbingAssembly.climbingRoute.ratingCount;

  if (
    // payload.rating can be undefined when sending the regrade or modifier
    typeof payload.rating !== "undefined" &&
    payload.rating !== originalPrivateRating
  ) {
    let originalTotal = originalPublicRating * originalCount;
    if (typeof originalPrivateRating !== "undefined") {
      climbingAssembly.climbingRoute.avgRating =
        (originalTotal + payload.rating - originalPrivateRating) /
        originalCount;
    } else {
      // Increase the count to include this new rating
      climbingAssembly.climbingRoute.ratingCount++;

      climbingAssembly.climbingRoute.avgRating =
        (originalTotal + payload.rating) / (originalCount + 1);
    }
  }
}
function removeFromAvgRating(climbingAssembly) {
  // We need to make sure that the regrade_id and modifier aren't
  // accidentally cleared.
  // the "delete" keyword removes the key from the object entirely
  // which means that mergeOrInsert wont clobber the value

  let originalPublicRating = parseFloat(
    climbingAssembly.climbingRoute.avgRating
  );

  if (isNaN(originalPublicRating)) {
    originalPublicRating = 0;
  }
  let originalPrivateRating =
    typeof climbingAssembly.userRating !== "undefined" &&
    climbingAssembly.userRating.rating !== null
      ? climbingAssembly.userRating.rating
      : undefined;

  let originalTotal =
    originalPublicRating * climbingAssembly.climbingRoute.ratingCount;

  climbingAssembly.climbingRoute.ratingCount--;

  if (
    originalTotal - originalPrivateRating === 0 ||
    climbingAssembly.climbingRoute.ratingCount === 0
  ) {
    climbingAssembly.climbingRoute.avgRating = undefined;
  } else {
    climbingAssembly.climbingRoute.avgRating =
      (originalTotal - originalPrivateRating) /
      climbingAssembly.climbingRoute.ratingCount;
  }
}
