import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import AlertInput from "../AlertInput/AlertInput.js";

import Button from "../CustomButtons/Button.js";

class ClimbingRouteTableRow extends React.Component {
  getStatusButton(routeStatus) {
    const { classes } = this.props;
    return (
      <Button
        size="sm"
        color={routeStatus.themeColorName}
        className={classes.marginRight}
      >
        {routeStatus.name}
      </Button>
    );
  }

  render() {
    let testVar = (
      <div>
        <Button>sdgsdg</Button>
      </div>
    );
    return (
      <AlertInput
        alertTitle="What is the status of this route"
        cancelCallBack={this.closeAlert}
        confirmCallBack={this.state.confirmCallBack}
        content={testVar}
      />
    );
  }
}

ClimbingRouteTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  climbingRoute: PropTypes.object.isRequired
};

export default withRouter(observer(ClimbingRouteTableRow));
