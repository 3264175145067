import React, { useRef } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

import Keyboard from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";
import "../../assets/css_see_packageJSON/keyboard.css";

// core components

function PasscodeKeyboard({
  onChange,
  onClear,
  clearNow,
  regStore,
  codeLength,
  buttonTheme
}) {
  const keyboard = useRef();

  const onKeyPress = button => {
    regStore.resetNoActivityTimeout();
    if (button === "Clear") {
      keyboard.current.setInput("");
      onClear();
    }
  };

  if (clearNow) {
    keyboard.current.setInput("");
  }

  const internalOnChange = newCode => {
    if (newCode === "Clear") {
      keyboard.current.setInput("");
      return;
    }
    if (newCode.length < codeLength + 1) {
      keyboard.current.setInput(newCode);

      onChange(newCode);
      return;
    }

    // This is to make sure that the internal input of the keyboard
    // stays inline with the external input
    keyboard.current.setInput(newCode.substring(0, codeLength));
  };

  return (
    <Keyboard
      keyboardRef={r => (keyboard.current = r)}
      onChange={internalOnChange}
      onKeyPress={onKeyPress}
      disableButtonHold={true}
      layout={{
        default: ["1 2 3", "4 5 6", "7 8 9", "Clear 0 {bksp}"]
      }}
      buttonTheme={buttonTheme}
      theme="hg-theme-default myTheme numberKeyboard"
    />
  );
}

PasscodeKeyboard.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  clearNow: PropTypes.bool,
  regStore: PropTypes.object.isRequired,
  codeLength: PropTypes.number.isRequired,
  buttonTheme: PropTypes.array
};

export default PasscodeKeyboard;
