import "moment-timezone";

import { observable } from "mobx";

let LOGOUT_SECONDS = 60;

export default class RegStores {
  constructor(
    context,
    hist,
    asyncOpCreate,
    /*loggedInURL,*/ clearPersonalDataFn,
    initUserFn,
    getCurrentGymId,
    profileStore,
    rootStore
  ) {
    // if (!loggedInURL) {
    //   console.log(
    //     "The URL is missing from the RegStore. It must be passed in to the rootStore."
    //   );
    //   debugger;
    //   throw new Error(
    //     "The URL is missing from the RegStore. It must be passed in to the rootStore."
    //   );
    // }

    this.regStore = getRegStore(
      context,
      hist,
      asyncOpCreate,
      // loggedInURL,
      clearPersonalDataFn,
      initUserFn,
      getCurrentGymId,
      this,
      profileStore,
      rootStore
    );

    this.isRegistered = () => {
      return (
        this.regStore.isLoggedIn ||
        (rootStore.isClimberSite &&
          typeof this.regStore.userName !== "undefined")
      );
    };

    this.initClimberSite = partRegistration => {
      if (!partRegistration) {
        console.log("The registration is missing???");
        return;
      }
      this.regStore.userName = partRegistration.userName;
      this.regStore.id = partRegistration.reg_id;
    };
  }
}

function getRegStore(
  context,
  hist,
  asyncOpCreate,
  // loggedInURL,
  clearPersonalDataFn,
  initUserFn,
  getCurrentGymId,
  regStores,
  profileStore,
  rootStore
) {
  const rest = context.rest;

  const this__ = observable({
    id: undefined,
    user_id: undefined,
    gym_id: undefined,
    userName: undefined,
    passcode: undefined,
    noActivityCountDown: undefined,
    timeout: undefined,
    isLoggedIn: undefined,
    selectedRegistration: undefined
  });

  this__.opGetRegistrations = asyncOpCreate(
    payload => rest.get("registrations", payload),
    "opGetRegistrations"
  );

  this__.getRegistrations = function() {
    let payload = { gym_id: getCurrentGymId() };
    this__.opGetRegistrations.execute(payload);
  };

  this__.opRegisterRegistration = asyncOpCreate(
    payload => rest.post("registrations", payload),
    "opRegisterRegistration"
  );

  this__.registerRegistration = async (
    userName,
    passcodeStr,
    callBackParam
  ) => {
    let passcode;
    if (passcodeStr) {
      passcode = parseInt(passcodeStr);

      if (isNaN(passcode)) {
        console.log("Why is passcode NaN?" + passcodeStr);
        return;
      }
    }

    let callBack = response => {
      this__.finalizeLogin({
        ...response,
        passcode
      });
      if (callBackParam) {
        callBackParam(response);
      }
    };
    let user_id;
    if (rootStore.isClimberSite) {
      user_id = profileStore.user_id;
    }
    let payload = { userName, passcode, gym_id: getCurrentGymId(), user_id };
    await this__.opRegisterRegistration.execute(payload, callBack);
  };

  this__.opUpdateRegistration = asyncOpCreate(
    payload => rest.put("registrations/" + payload.id, payload),
    "opUpdateRegistration"
  );

  this__.updateRegistration = async (updatedFields, callBackParam) => {
    let callBack = () => {
      if (updatedFields.userName) {
        this__.userName = updatedFields.userName;
      }
      if (updatedFields.passcode) {
        this__.passcode = parseInt(updatedFields.passcode, 10);
      }

      // this__.finalizeLogin({
      //   ...response,
      //   passcode
      // });
      if (callBack) {
        callBackParam();
      }
    };
    await this__.opUpdateRegistration.execute(updatedFields, callBack);
  };

  // this__.opResetRegistrationPasscode = asyncOpCreate(
  //   payload => rest.put("registrations/resetPasscode", payload),
  //   "opResetRegistration"
  // );

  // this__.resetRegistrationPasscode = newPasscode => {
  //   let callBack = () => {
  //     notificationStore.enqueueNotification("Passcode reset", {
  //       variant: "info"
  //     });
  //     hist.push("/main/registration/view");
  //   };

  //   let payload = {
  //     passcode: newPasscode,
  //     id: this__.selectedRegistration.id
  //   };
  //   this__.opResetRegistrationPasscode.execute(payload, callBack);
  // };

  this__.opLoginRegistration = asyncOpCreate(
    payload => rest.get("loginRegistration", payload),
    "opLoginRegistration"
  );

  this__.loginRegistration = (
    selectedRegistration,
    passcodeStr,
    loginFailedCallBack
  ) => {
    let passcode;
    if (passcodeStr) {
      passcode = parseInt(passcodeStr);

      if (isNaN(passcode)) {
        console.log("Why is passcode NaN?" + passcodeStr);
        return;
      }
    }

    let callBack = response => {
      if (response.isLoggedIn) {
        this__.finalizeLogin({
          ...selectedRegistration,
          passcode
        });
      } else {
        loginFailedCallBack();
      }
    };
    this__.opLoginRegistration.execute(
      { registrationId: selectedRegistration.id, passcode },
      callBack
    );
  };

  this__.finalizeLogin = user => {
    initUserFn(user);
  };

  this__.initUser = user => {
    let { user_id, gym_id, userName, passcode, id } = user;

    console.log("Finalizing the login:" + JSON.stringify(user));
    this__.id = id;
    this__.user_id = user_id;
    this__.gym_id = gym_id;
    this__.userName = userName;
    this__.passcode = passcode;
    this__.isLoggedIn = true;
    this__.resetNoActivityTimeout();
  };

  this__.resetNoActivityTimeout = () => {
    this__.noActivityCountDown = LOGOUT_SECONDS;
    this__.decrementTimeout();
  };

  /*
   * NOTE: This recursive timeout could be a memory leak, but I don't think it is
   * The memory leak happens because the callstack of the timeout gets bigger and bigger
   * but I dont' think it is a problem because it gets cleared frequently and stops
   * after 1 min.
   */
  this__.decrementTimeout = () => {
    // If we are on the climber site then we don't want to log out
    if (rootStore.isClimberSite) return;

    // console.log("Counting down:" + this__.noActivityCountDown);
    this__.clearLoginTimeout();
    this__.noActivityCountDown -= 1;
    if (this__.noActivityCountDown < 1) {
      if (this__.isLoggedIn) {
        this__.logoutRegistration();
      }
      hist.push(context.config.welcomePath);
    } else {
      this__.timeout = setTimeout(this__.decrementTimeout, 1000);
    }
  };

  this__.clearLoginTimeout = () => {
    if (this__.timeout) {
      clearTimeout(this__.timeout);
    }
  };

  this__.logoutRegistration = () => {
    this__.loadCacheFn({ forceReload: true });
    clearPersonalDataFn();
  };

  this__.clearPersonalData = () => {
    this__.gym_id = undefined;
    this__.user_id = undefined;
    this__.userName = undefined;
    this__.passcode = undefined;
    this__.noActivityCountDown = undefined;
    this__.isLoggedIn = false;
    this__.clearLoginTimeout();
    return true;
  };

  this__.setLoadCacheFn = loadCacheFn => {
    this__.loadCacheFn = loadCacheFn;
  };

  return this__;
}
