let recycledMappings = [];

export default class Recycler {
  /*
   * Saved Mapping is a way of reusing objects for efficiency.
   * The two associated methods do nothing but stash the objects in the list.
   */

  recycleMappingObject(mapping) {
    recycledMappings.push(mapping);
  }

  getRecycledMappingObject() {
    if (recycledMappings.length === 0) {
      return {};
    } else {
      let mapping = recycledMappings.pop();
      return mapping;
    }
  }
}
