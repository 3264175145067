export default class PersonalProfileStores {
  constructor(context, asyncOpCreate, overwriteIdentificationFieldsFn) {
    const rest = context.rest;

    this.updateProfileStore = {
      height: null,

      opUpdate: asyncOpCreate(
        payload => rest.patch("guest/me", payload),
        "updateGuestMe"
      ),
      update: () => {
        let payload = {
          height: this.updateProfileStore.height
        };

        overwriteIdentificationFieldsFn(payload);

        return this.updateProfileStore.opUpdate.execute(payload);
      }
    };
  }
}
