import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Chartist from "chartist";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";

import ChartistGraph from "react-chartist";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import TitledDialog from "../TitledDialog/TitledDialog";
import { coreStyle } from "ticker-core";

import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";

import RegradeControl from "./RegradeControl";
import ModifierIcon from "../ModifierIcon/ModifierIcon";

import Box from "@material-ui/core/Box";

let style = theme => ({
  padded: {
    [theme.breakpoints.up("sm")]: {
      paddingBottom: ".5em",
      paddingLeft: ".5em",
      paddingRight: ".5em"
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "2em",
      paddingLeft: "2em",
      paddingRight: "2em"
    }
  },
  left: { float: "left" },
  right: { float: "right" },

  icon: { color: "#fdbc4b" },
  listItemIcon: coreStyle.listItemIconStyle
});

let useStyles = makeStyles(style);

function RegradeDetailsDialog({ onClose, open, assembly, rootStore }) {
  let { regradeReportStore, gradeStores, regStores } = rootStore;

  let classes = useStyles();

  let [awaitingSave, setAwaitingSave] = useState(false);

  useEffect(() => {
    if (!awaitingSave) {
      regradeReportStore.getClimbReport(assembly.climbingRoute.id);
      regradeReportStore.getUsersReport(assembly.climbingRoute.id);
      setAwaitingSave(true);
    }
  }, [
    assembly.userRegrade,
    assembly.userRegrade && assembly.userRegrade.modifier,
    assembly.userRegrade && assembly.userRegrade.regrade_id,
    awaitingSave
  ]);

  let gradeStore = gradeStores.gradeStores[assembly.climbingGrade.gradeType];

  var delays = 80,
    durations = 500;

  let graph = {
    data: {
      labels: ["1", "2", "3", "4", "5", "6", "7"],
      series: [[0, 0, 0, 0, 0, 0, 0]]
    },
    options: {
      high: 5,
      //   low: 0,
      width: "300px",
      height: "250px",
      axisY: {
        onlyInteger: true,
        showGrid: false
      },
      axisX: {
        showGrid: false
      },
      showPoint: false
    },
    animation: {
      draw: function(data) {
        if (data.type === "line" || data.type === "area") {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path
                .clone()
                .scale(1, 0)
                .translate(0, data.chartRect.height())
                .stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint
            }
          });
        } else if (data.type === "point") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: "ease"
            }
          });
        }
      }
    }
  };

  if (
    regradeReportStore.opGetClimbReport.executed &&
    regradeReportStore.opGetClimbReport.data
  ) {
    let grades = gradeStore.items;

    let response = regradeReportStore.opGetClimbReport.data;
    let labels = [];
    let series = [];
    let matchedResponses = [];

    let highestCount = 0;

    let firstVisibleGradeIndex = 0;
    let lastVisibleGradeIndex = 0;
    let currentGradeIndex = 0;

    let hack = grades.length;
    for (let index = 0; index < hack; index++) {
      let grade = grades[index];
      if (grade.id === assembly.climbingGrade.id) {
        currentGradeIndex = index;
      }

      if (grade.isArchived) {
        continue;
      }

      for (let countResponse of response) {
        if (
          grade.id === countResponse["regrade.id"] &&
          countResponse.gradingCounts > 0
        ) {
          if (!firstVisibleGradeIndex) {
            firstVisibleGradeIndex = index;
          }
          lastVisibleGradeIndex = index;
        }
      }
    }

    // If all the grade suggestions are higher than
    // the current grade index,
    // Then we should display from the current grade index
    if (currentGradeIndex < firstVisibleGradeIndex) {
      firstVisibleGradeIndex = currentGradeIndex;
    }

    if (currentGradeIndex > lastVisibleGradeIndex) {
      lastVisibleGradeIndex = currentGradeIndex;
    }

    grades.forEach((grade, index) => {
      if (
        index < firstVisibleGradeIndex - 1 ||
        index > lastVisibleGradeIndex + 1 ||
        grade.isArchived
      ) {
        return;
      }

      console.log("processins grade:" + grade.primaryLabel);

      matchedResponses.length = 0;

      for (let countResponse of response) {
        if (
          grade.id === countResponse["regrade.id"] &&
          countResponse.gradingCounts > 0
        ) {
          matchedResponses.push(countResponse);
        }
      }

      // Put in the labels for this grade
      labels.push(""); // -4
      // Put the label slightly left so that it looks more centered.
      // Should figure out how to use the CSS for this. ct-label
      labels.push(grade.primaryLabel.length > 2 ? grade.primaryLabel : ""); // -2
      labels.push(grade.primaryLabel.length < 3 ? grade.primaryLabel : ""); // 0
      labels.push(""); // 2
      labels.push(""); // 4

      let startIndex = series.length;

      // Set the series to zero for this grade in case there are no counts for a particular seriesIndex
      series[startIndex] = 0;
      series[startIndex + 1] = 0;
      series[startIndex + 2] = 0;
      series[startIndex + 3] = 0;
      series[startIndex + 4] = 0;

      // If there were some responses that matched this grade
      // Then use those values
      if (matchedResponses.length > 0) {
        // Overwrite the 0 defaults with any values from the responses.
        matchedResponses.forEach(matchedResponse => {
          series[startIndex + matchedResponse.seriesIndex] =
            matchedResponse.gradingCounts;
          if (highestCount > matchedResponse.gradingCounts) {
            highestCount = matchedResponse.gradingCounts;
          }
        });
      }
    });

    graph.data.series = [series];
    graph.data.labels = labels;
    if (highestCount + 5 > graph.options.high) {
      graph.options.high = highestCount + 5;
    }
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(400));

  let hasAtLeastOne =
    typeof assembly.climbingRoute.avgCombinedModifier !== "undefined" &&
    assembly.climbingRoute.avgCombinedModifier !== null;

  let usersData = regradeReportStore.opGetUsersReport.data;

  // This is used to avoid having to search for the grade all the time.
  let previousGrade;

  return (
    <>
      <TitledDialog
        onClose={onClose}
        open={open}
        fullScreen={fullScreen}
        title={
          hasAtLeastOne ? (
            <>
              <ModifierIcon
                className={classes.modifierIcon}
                modifier={assembly.relativeCombinedModifier}
              />
              Grade suggestions by climbers
            </>
          ) : (
            "This climb hasn't been graded yet."
          )
        }
        dialogId="regradesDialog"
      >
        <div
          className={classes.padded}
          style={{ textAlign: "center", color: "#999" }}
        >
          {hasAtLeastOne && (
            <>
              <ChartistGraph
                className="ct-chart"
                data={graph.data}
                type="Line"
                options={graph.options}
                responsiveOptions={graph.responsiveOptions}
                listener={graph.animation}
              />
            </>
          )}

          {!regStores.isGymSite && (
            <>
              <hr />
              {/* <div>How hard is this climb for you?</div> */}

              <RegradeControl
                assembly={assembly}
                callBack={() => {
                  setAwaitingSave(false);
                }}
              />
            </>
          )}
          {usersData && (
            <>
              <hr />
              <Box pl="1em" pr="1em" pb="2em">
                {usersData.map((regrade, i) => {
                  // if (
                  //   regrade.user_id === personalRootStore.getCurrentUserId()
                  // ) {
                  //   return null;
                  // }

                  if (
                    !previousGrade ||
                    previousGrade.id !== regrade.regrade_id
                  ) {
                    previousGrade = gradeStores.getItemById(regrade.regrade_id);
                  }

                  if (!previousGrade) {
                    console.error("There is no grade");
                    debugger;
                    return;
                  }
                  console.log("rendering the box");
                  return (
                    <GridContainer
                      style={{ paddingTop: i === 0 ? 0 : "1.5em" }}
                      key={regrade.id}
                    >
                      <GridItem>
                        {regrade["user.Registrations.userName"]}
                      </GridItem>
                      <GridItem
                        style={{
                          flexGrow: 1,
                          textAlign: "right"
                        }}
                      >
                        Grade {previousGrade.primaryLabel}
                      </GridItem>
                      <GridItem xs={2} style={{ textAlign: "left" }}>
                        <ModifierIcon
                          className={classes.listItemIcon}
                          modifier={regrade.modifier}
                        />
                      </GridItem>
                    </GridContainer>
                  );
                })}
              </Box>
            </>
          )}
        </div>
      </TitledDialog>
    </>
  );
}

RegradeDetailsDialog.propTypes = {
  onClose: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  assembly: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default observer(RegradeDetailsDialog);
