import { coreLayoutStyle } from "ticker-core";

const appStyle = theme => ({
  ...coreLayoutStyle,
  content: {
    ...coreLayoutStyle.content,
    marginTop: "70px",
    padding: "30px 15px"
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  toolbarHeight: {
    ...theme.mixins.toolbar,
    // This margin just pushes the main content a litte bit
    // further down to make sure it doesn't get overlaped
    marginBottom: "10px"
  }
});

export default appStyle;
