import React from "react";
import PropTypes from "prop-types";
class LoginPage extends React.Component {
  render() {
    // Cause an error in this render by attempting to invoke a method that doesn't exist.
    this.notRealVar();
    return null;
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default LoginPage;
