import { gradeTypes, leaderboardDateSelections } from "../variables/constants";
import { observable } from "mobx";
import moment from "moment";

const getLeaderboardStore = (asyncOpCreate, rest, getCurrentGymId) => {
  let lbs = observable({
    gradeType: gradeTypes.ROUTE,
    dateSelection: leaderboardDateSelections[0],
    leaderboardStyle: "QUANTITY",
    viewTotals: false,
    organisation_id: undefined
  });

  lbs.opGetLeaderboard = asyncOpCreate(
    payload => rest.get("leaderboard", payload),
    "getLeaderBoard"
  );

  lbs.getLeaderboard = function() {
    let payload = {
      gradeType: lbs.gradeType,
      startDateTimeUTC: moment()
        .startOf("day")
        .subtract(lbs.dateSelection.days, "d")
        .utc()
        .toISOString(),
      endDateTimeUTC: moment()
        .endOf("day")
        .utc()
        .toISOString(),
      leaderboardStyle: lbs.leaderboardStyle,
      gym_id: getCurrentGymId(),
      organisation_id: lbs.organisation_id
    };

    lbs.opGetLeaderboard.execute(payload);
  };

  lbs.sortByCount = function() {
    lbs.opGetLeaderboard.data.sort((a, b) => {
      if (lbs.viewTotals) {
        return;
      }
    });
  };
  return lbs;
};

export default getLeaderboardStore;
