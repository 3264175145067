import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ProfileAutoLoader from "./utils/profileAutoLoader";
import {
  Notifiers,
  NetworkError,
  MainErrorBoundary,
  LoginDialog
} from "ticker-components";

import "mobx-react-lite/batchingForReactDom";

import {
  rootStore as rootStoreClass,
  CachedRootStoreProvider,
  RootStoreProvider,
  createConfig,
  logger
} from "ticker-core";

import "assets/scss/material-dashboard-pro-react.scss";

import AuthLayout from "layouts/Auth.js";
import MainLayout from "layouts/Main.js";
import BlankLayout from "layouts/Blank.js";

import { SnackbarProvider } from "notistack";
import config from "./config";

var hist = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

logger.setup(
  "14494110-2e74-4be7-9a33-6f9d84e6c957",
  window.location.href.indexOf("localhost") !== -1
);

const MyContextConfig = createConfig({}, config);

const rootStore = new rootStoreClass(MyContextConfig, hist, "climber");

hist.oldPush = hist.push;
hist.push = (a, b, c, d) => {
  // Note: This hack wont take into account <Redirect/> tags
  //console.log("pushing in the history hack-- ", a, b, c, d);
  hist.hack_prevPushPath = hist.location.pathname;
  hist.oldPush(a, b, c, d);
};
ReactDOM.render(
  <MainErrorBoundary>
    <SnackbarProvider maxSnack={7}>
      <Notifiers rootStore={rootStore} />
      <RootStoreProvider rootStore={rootStore}>
        <CachedRootStoreProvider rootStore={rootStore}>
          <Router history={hist} basename={config.nginxURL}>
            <React.Fragment>
              <LoginDialog />
              <ProfileAutoLoader
                history={hist}
                registrationPagePath="/auth/registration-page"
                stores={rootStore.authStores.stores}
                profileStore={rootStore.userStores.profileStore}
                regStore={rootStore.regStores.regStore}
              />
              <NetworkError />
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <Route path="/main" component={MainLayout} />
                <Route path="/blank" component={BlankLayout} />
                <Redirect from="/" to={config.welcomePath} />
              </Switch>
            </React.Fragment>
          </Router>
        </CachedRootStoreProvider>
      </RootStoreProvider>
    </SnackbarProvider>
  </MainErrorBoundary>,
  document.getElementById("root")
);
