import React, { useState } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";

import ReportProblem from "@material-ui/icons/ReportProblem";
import Mood from "@material-ui/icons/Mood";

import {
  Button,
  CustomInput,
  Accordion,
  TitledDialog
} from "ticker-components";

import DialogContentText from "@material-ui/core/DialogContentText";

let style = {
  padded: {
    paddingBottom: "2em",
    paddingLeft: "2em",
    paddingRight: "2em"
  },
  left: { float: "left" },
  right: { float: "right" }
};

let useStyles = makeStyles(style);

let collapses = [
  {
    title: "Dangers",
    labels: [
      {
        label: "A hold is spinning freely.",
        isDangerous: true
      },
      {
        label: "A hold has spun."
      },
      {
        label: "The fall is dangerous."
      }
    ]
  },
  {
    title: "Negative Feedback",
    labels: [
      {
        label: "The climb is much harder for short climbers."
      },
      {
        label: "The climb is much harder for tall climbers."
      },
      {
        label: "The nearby colors clash with this color."
      },
      {
        label: "Very awkward body positions"
      },
      {
        label: "Unreachable dyno"
      },
      {
        label: "Repetitive moves"
      }
    ]
  },
  {
    title: "Positive Feedback",
    labels: [
      {
        label: "Love the movements"
      },
      {
        label: "Great holds"
      },
      {
        label: "So technical"
      },
      {
        label: "Perfect Dyno"
      }
    ]
  }
];

export default function ReportDialog({
  onClose,
  open,
  climbing_route_id,
  createIssueStore,
  regStore,
  notificationStore,
  activeIndex
}) {
  let classes = useStyles();
  let sendReport = () => {
    console.log("Clicking the report button");
    createIssueStore.description = description;
    createIssueStore.climbing_route_id = climbing_route_id;
    createIssueStore.createIssue();
    regStore.resetNoActivityTimeout();
    notificationStore.enqueueNotification(
      <>
        Thanks <Mood />{" "}
      </>,
      {
        variant: "success"
      }
    );
    onClose();
  };

  let [description, setDescription] = useState();

  collapses.forEach(collapse => {
    collapse.content = collapse.labels.map(label => {
      return (
        <div key={label.label}>
          <DialogContentText
            onClick={() => {
              regStore.resetNoActivityTimeout();
              setDescription(label.label);
            }}
          >
            {label.label}
          </DialogContentText>
        </div>
      );
    });
  });

  return (
    <>
      <TitledDialog
        onClose={onClose}
        open={open}
        title={
          <>
            <ReportProblem /> Send a message about this climb
          </>
        }
        dialogId="reportDialog"
      >
        <div className={classes.padded}>
          <div>
            <CustomInput
              labelText="You can edit your message"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "email",
                multiline: true,
                value: description,
                onChange: evt => {
                  setDescription(evt.target.value);
                },
                inputProps: {
                  rows: 4
                }
              }}
              labelProps={{
                shrink: !!description
              }}
            />
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                disabled={!description || description.length < 5}
                color="success"
                onClick={sendReport}
              >
                Send
              </Button>
              <hr />
            </div>
          </div>
          <Accordion collapses={collapses} active={activeIndex} />
        </div>
      </TitledDialog>
    </>
  );
}

ReportDialog.propTypes = {
  onClose: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  climbing_route_id: PropTypes.number.isRequired,
  createIssueStore: PropTypes.object.isRequired,
  regStore: PropTypes.object.isRequired,
  activeIndex: PropTypes.number.isRequired,
  notificationStore: PropTypes.object.isRequired
};
