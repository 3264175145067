import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { coreLayoutStyle } from "ticker-core";

// import PropTypes from "prop-types";

let styles = theme => ({
  container: {
    ...coreLayoutStyle.container,
    ...coreLayoutStyle.content,
    [theme.breakpoints.down("sm")]: {
      padding: "0px"
    }
  }
});

let useStyles = makeStyles(styles);

const withContainer = ComponentToWrap => {
  return function RootStoreContextComponent(props) {
    let classes = useStyles();
    return (
      <div className={classes.container}>
        <ComponentToWrap {...props} />
      </div>
    );
  };
};

export default withContainer;
