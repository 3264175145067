import React from "react";
import { observer, useObserver } from "mobx-react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import {
  Card,
  CardBody,
  TitledDialog,
  ClimbCore,
  ClimbCoreTitle
} from "ticker-components";

function ClimbDialog(props) {
  let { isOpen, climbAssembly, handleClose } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(400));

  return useObserver(() => (
    <>
      <TitledDialog
        onClose={handleClose}
        open={isOpen}
        fullScreen={fullScreen}
        title={<ClimbCoreTitle assembly={climbAssembly} />}
        dialogId="climbDialog"
        centeredTitle
      >
        {climbAssembly && (
          <Card route popUp data-tour="wholeCard">
            <CardBody route>
              <ClimbCore
                assembly={climbAssembly}
                onDone={handleClose}
                showTitle={false}
              />
            </CardBody>
          </Card>
        )}
      </TitledDialog>
    </>
  ));
}
// ClimbDialog.propTypes = {};
export default observer(ClimbDialog);
