import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { MainNavbar } from "ticker-components";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/mainStyle.js";

import { withRootStore } from "ticker-core";

const useStyles = makeStyles(styles);

export default withRootStore(function Pages(props) {
  const { rootStore, ...rest } = props;
  // ref for the wrapper div
  const mainPanel = React.createRef();
  // styles
  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [rootStore.history.location.pathname]);

  const getRoutes = routes => {
    return routes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.views);
      }
      if (route.layout === "/main") {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  // const getBgImage = () => {
  //   if (window.location.pathname.indexOf("/main/ticks") !== -1) {
  //     return register;
  //   } else if (window.location.pathname.indexOf("/main/routes") !== -1) {
  //     return login;
  //   }
  // };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i];
        }
      }
    }
    return activeRoute;
  };

  let activeRoute = getActiveRoute(routes);

  // Could use react router's match object instead of windo.location.href
  let myReg = /.*main\/(.*)/g;
  let match = myReg.exec(window.location.href);

  console.log("XXXXXXXXXXXXXXXXXXXXXXXXX The route is..." + match[1]);
  console.log(
    "XXXXXXXXXXXXXXXXXXXXXXXXX The route is..." + JSON.stringify(match)
  );

  if (window.location.href && match[1]) {
    for (let route of routes) {
      if (route.path === "/registration") {
        debugger;
        for (let registrationRoute of route.views) {
          if (
            !rootStore.regStores.isRegistered() &&
            registrationRoute.path === match[1] &&
            registrationRoute.showToLoggedInRegistrationsOnly
          ) {
            return <Redirect to="/main/registration/registrationLogin" />;
          }
        }
        break;
      }
    }
  }

  return (
    <div className={classes.wrapper}>
      <MainNavbar
        brandText={activeRoute.name}
        {...rest}
        routes={routes}
        layout="/main"
      />
      <div className={classes.toolbarHeight} />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/main" to="/main/map" />
        </Switch>
        {/* <Footer /> */}
      </div>
    </div>
  );
});
