import { LogglyTracker } from "loggly-jslogger";

const loggly = new LogglyTracker();

const logger = {
  log: (logObject, logObject2, logObject3) => {
    if (!logger.isSetup) {
      console.error("Unable to log. Setup() needs to be executed");
      return;
    }
    if (logger.isDevelopment) {
      console.log(logObject, logObject2, logObject3);
      return;
    }

    loggly.push({
      logObject,
      logObject2,
      logObject3,
      location: ":" + window.location.href
    });

    console.log("logging: ", logObject, logObject2, logObject3);
  },
  setup: (customerToken, isDevelopment) => {
    logger.isDevelopment = isDevelopment;
    loggly.push({ logglyKey: customerToken });
    logger.isSetup = true;
  }
};

export default logger;
