import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";

// import LockOutline from "@material-ui/icons/LockOutline";

import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import {
  GridContainer,
  GridItem,
  CustomInput,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  SnackbarContent
} from "ticker-components";

import { withRootStore } from "ticker-core";

import loginPageStyle from "../assets/jss/material-dashboard-pro-react/views/loginPageStyle";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };

    this.alreadyLoggingInAutomatically = false;
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  loginAutomatically() {
    return false;
    /*    if (this.alreadyLoggingInAutomatically !== true) {
          this.alreadyLoggingInAutomatically = true;
          const { login } = this.props.rootStore.authStores.getStores();
          login.password = "staffPassword123";
          login.username = "admin";
          login.login();
        }*/
  }
  render() {
    const { classes, rootStore } = this.props;

    const loginStore = rootStore.authStores.getStores().login;
    const registerStore = rootStore.authStores.getStores().register;

    let { errors, serverMessage } = loginStore;

    let passwordErrMsg, emailErrMsg;

    if (errors.password && errors.password[0]) {
      passwordErrMsg = errors.password[0];
    }

    if (errors.email && errors.email[0]) {
      emailErrMsg = errors.email[0];
    }

    setTimeout(() => {
      this.loginAutomatically();
    }, 3000);

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem>
            {loginStore.op.loading ? (
              <div>
                {" "}
                <CircularProgress size={100} />{" "}
              </div>
            ) : (
              <form>
                <Card
                  style={{ maxWidth: "500px" }}
                  login
                  className={classes[this.state.cardAnimaton]}
                >
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="rose"
                  >
                    <h4 className={classes.cardTitle}>Log in</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText={"Email"}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      onChange={e => {
                        loginStore.setEmail(e.target.value);
                      }}
                      helperText={emailErrMsg}
                      error={errors.email}
                      inputProps={{
                        onChange: e => {
                          loginStore.setEmail(e.target.value);
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        value: loginStore.email
                      }}
                    />
                    {/*
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helpText={errors.password && errors.password[0]}
                      inputProps={{
                        onChange: e => {
                          loginStore.setEmail(e.target.value);
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        )
                      }}
                    />
                    */}
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={passwordErrMsg}
                      error={errors.password}
                      inputProps={{
                        onChange: e => {
                          loginStore.setPassword(e.target.value);
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        value: loginStore.password,
                        type: "password"
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      color="primary"
                      round
                      onClick={() => loginStore.login()}
                    >
                      Log in
                    </Button>
                  </CardFooter>
                </Card>
                <div style={{ textAlign: "center", width: "100%" }}>
                  Don't want to sign up yet?{" "}
                  <Button
                    onClick={() => {
                      rootStore.history.push("");
                    }}
                    color="primary"
                    round
                  >
                    test drive now
                  </Button>
                </div>
              </form>
            )}
            {serverMessage.message && (
              <SnackbarContent
                message={serverMessage.message}
                close
                icon={AddAlert}
                color="warning"
                style={{ maxWidth: "500px" }}
                actions={
                  serverMessage.showLoginBtn
                    ? [
                        <Button
                          color="primary"
                          key="useless"
                          onClick={() => {
                            loginStore.serverMessage = {};
                            registerStore.setEmail(loginStore.email);
                            registerStore.setPassword(loginStore.password);
                            rootStore.history.push(
                              rootStore.context.config.userRegisterPath
                            );
                          }}
                        >
                          Sign Up Instead
                        </Button>
                      ]
                    : undefined
                }
              />
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default withRootStore(withStyles(loginPageStyle)(observer(LoginPage)));
