import { observable, action } from "mobx";

export default function createSettingPlanUIStore() {
  let hack = {};
  hack = observable({
    selectionPills: 0,
    availableTab: 0,
    stripTab: 0,
    filterTab: 0
  });

  hack.handleSelectionPillsChange = action(
    function(event, newValue) {
      this.selectionPills = newValue;
    }.bind(hack)
  );

  hack.handleAvailableTabChange = function(event, newValue) {
    this.availableTab = newValue;
  }.bind(hack);

  hack.handleStripTabChange = function(event, newValue) {
    this.stripTab = newValue;
  }.bind(hack);

  hack.handleFilterTabChange = function(event, newValue) {
    this.filterTab = newValue;
  }.bind(hack);

  return hack;
}
