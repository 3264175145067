import React from "react";

import registrationStyles from "../../assets/jss/material-dashboard-pro-react/components/registrationStyle.js";

import withStyles from "@material-ui/core/styles/withStyles";

// nodejs library to set properties for components
import PropTypes from "prop-types";
function RegistrationPanel({
  title,
  subTitle,
  keyboard,
  input,
  leftButton,
  rightButton,
  registrationMessage,
  note,
  classes
}) {
  let messageClass = classes.warning;
  if (registrationMessage && registrationMessage.type === "good") {
    messageClass = classes.good;
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h3 className={classes.title}>{title}</h3>
        {subTitle && <h5 className={classes.title}>{subTitle}</h5>}
        {note && <h6 className={classes.note}>{note}</h6>}
      </div>
      {keyboard}
      <h5 className={messageClass + " " + classes.message}>
        {registrationMessage && registrationMessage.msg}
      </h5>
      {input}
      <div className={classes.footer}>
        <div className={classes.left}>{leftButton}</div>
        <div className={classes.right}>{rightButton}</div>
      </div>
    </div>
  );
}

export default withStyles(registrationStyles)(RegistrationPanel);

RegistrationPanel.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  keyboard: PropTypes.element,
  input: PropTypes.element,
  leftButton: PropTypes.element,
  rightButton: PropTypes.element,
  registrationMessage: PropTypes.object,
  note: PropTypes.string
};
