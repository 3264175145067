import { ZoomLevels, constants } from "ticker-core";

import { action } from "mobx";

/*
 * This is used as an onPointerUp handler mostly
 */

let onUpJustOccured;

let doubleTapStarted = false;
let doubleTapTimeout;

function getMapHandlers(
  regStore,
  SVGStore,
  selectionStore,
  allowObjectCreation,
  onCreate,
  onUpFinished,
  allowShallowClicks
) {
  let detectDoublePointer = event => {
    // console.log("Detecting the double pointer in the map");
    /*
     * ARRGG: For some reason the handler onPointerUpOnMap is called twice from the tablet
     * Both for touchend events but one event is from the rect and the other is from the svg.
     * I can't figure out why it is being called on the rect.
     * So this hack prevents the onPointerUpOnMap being called twice within 20ms
     */
    if (onUpJustOccured) {
      // console.log(
      //   "Skipping a call to detectDoublePointer because it happened to soon to the previous call"
      // );
      return;
    }
    onUpJustOccured = true;
    setTimeout(() => {
      // console.log("clearing justOccured");
      onUpJustOccured = false;
    }, 20);

    clearTimeout(doubleTapTimeout);

    if (event.originalEvent.touches && event.originalEvent.touches.length > 0) {
      // console.log("doubleTapStarted = false because event.touches.length > 0");
      doubleTapStarted = false;
      // return nextValue;
    } else if (doubleTapStarted) {
      // We are double tapping
      // console.log(
      //   "doubleTapStarted was true but now is false because this is the double tap"
      // );
      doubleTapStarted = false;
      // clearTimeout(doubleTapTimeout);

      // console.log("Calling onDoubleClick() inside SVGRouteMapLevel2. ");
      regStore.resetNoActivityTimeout();
      // selectionStore.creatingNewObject = false;
      selectionStore.clearDoubleClickTimeout();

      // debugger;
      //onDoubleClick();
    } else {
      // We might be double tapping

      // This allows us to use the event in the setTimeout()
      // see https://reactjs.org/docs/events.html#event-pooling
      if (event.originalEvent && event.originalEvent.persist) {
        event.originalEvent.persist();
      }

      // If we have dragged a route then we need to stop dragging immediately
      if (selectionStore.hasObjectMoved) {
        // console.log("Has dragged therefore not delaying the onSinglePointerUp");
        onSinglePointerUp(event);
        doubleTapStarted = false;
      } else {
        // console.log(
        //   "doubleTapStarted = true because it wasn't true already",
        //   event
        // );
        doubleTapStarted = true;
        doubleTapTimeout = setTimeout(() => {
          console.log(
            "The doubleTap timer has expired so executing the onSinglePointerUp"
          );
          onSinglePointerUp(event);
          // Any second tap was too slow so assume it's not a double tap
          doubleTapStarted = false;
        }, constants.DOUBLE_TAP_DELAY);
      }
    }
  };

  let onSinglePointerUp = action(evt => {
    console.log("The single pointer up is firing on the map");
    if (
      !allowShallowClicks &&
      ZoomLevels.getDetailLevel(SVGStore.value.a) < 1
    ) {
      console.log("The single pointer up is bailing because it is too shallow");
      onUpFinished(evt);
      return;
    }

    regStore.resetNoActivityTimeout();

    if (allowObjectCreation) {
      let hasDraggedOrZoomed =
        evt.value.startX !== evt.value.endX &&
        evt.value.startY !== evt.value.endY;

      // if (selectionStore.newObjectPositioned) {
      // console.log(
      //   "Do nothing because the object has been positioned. They can cancel if they need to."
      // );
      // } else
      if (selectionStore.objectWasClicked) {
        // console.log("789 Object was clicked. Not alerting");
      } else if (selectionStore.hasObjectMoved) {
        // console.log("789 Object was moved. Not alerting");
      } else if (hasDraggedOrZoomed) {
        // console.log(
        //   "wwww -Can this peice of code be reached??? Delete hasDraggedOrZoomed"
        // );
      } else {
        // console.log("789 Object Not Clicked, therefore creating new object");
        let coords = evt.points ? evt.points[0] : evt.point;
        if (!coords) {
          coords = evt.changedPoints[0];
        }

        selectionStore.createNewObjectInStore(coords, coords, onCreate);
      }
    }
    // if (!longPressIsDisabled){
    let currentDragObject = selectionStore.getCurrentDragObject();

    let wasPositioned = !!(
      selectionStore.creatingNewObject &&
      currentDragObject &&
      selectionStore.hasObjectMoved
    );

    //At this point, the dragging should have finished (except for multiple events from the same action, see hack below)
    // so we need to clear all the drag related values

    // console.log(
    //   "Setting the wasPositioned to:" +
    //     wasPositioned +
    //     ".. The event is " +
    //     evt.originalEvent.type
    // );
    // if (currentDragObject) {
    //   selectionStore.newObjectPositioned = wasPositioned;
    // }

    onUpFinished(evt);

    selectionStore.objectWasClicked = false;
    selectionStore.hasObjectMoved = false;

    selectionStore.currentDragObject = null;
    // }
  });

  return {
    detectDoublePointer,
    onSinglePointerUp
  };
}

let MINIMUM_MOVEMENT = 1;
function getOnMoveObject(
  regStore,
  SVGStore,
  selectionStore,
  gymStore,
  longPressIsDisabled
) {
  return action(evt => {
    regStore.resetNoActivityTimeout();
    if (longPressIsDisabled) {
      return;
    }

    let currentDragObject = selectionStore.getCurrentDragObject();

    // If the pointer hasn't already moved
    // And we aren't dragging something
    // then make sure the move is big enough to count
    if (
      !selectionStore.pointerMoved &&
      !currentDragObject &&
      typeof evt.originalEvent.movementX !== "undefined" &&
      evt.originalEvent.movementX < MINIMUM_MOVEMENT &&
      typeof evt.originalEvent.movementY !== "undefined" &&
      evt.originalEvent.movementY < MINIMUM_MOVEMENT
    ) {
      // console.log(
      //   "getOnMoveObject fired but Not moved enough",
      //   evt.originalEvent.movementX,
      //   evt.originalEvent.movementY
      // );
      return;
    }
    // console.log(
    //   "getOnMoveObject fired and moved",
    //   evt.originalEvent.movementX,
    //   evt.originalEvent.movementY
    // );

    selectionStore.pointerMoved = true;
    if (currentDragObject) {
      // console.log(
      //   "EVENT: SVGRouteMap.onMoveObject with:",
      //   currentDragObject,
      //   selectionStore.isMovingLineEnd
      // );
      selectionStore.hasObjectMoved = true;
      // The touch interface has points plural
      let coords = evt.points ? evt.points[0] : evt.point;
      if (!coords) {
        coords = evt.changedPoints[0];
      }
      // console.log("Moving the pointer:" + selectionStore.isMovingLineEnd);
      setCoords(
        currentDragObject,
        selectionStore.isMovingLineEnd,
        coords,
        gymStore.imageWidth,
        gymStore.imageHeight
      );
    } else {
      // console.log(
      //   "EVENT: SVGRouteMap.onMoveObject ------------xxxxxx No route to move"
      // );
    }
  });
}

function setCoords(
  targetObject,
  isMovingLineEnd,
  coords,
  imageWidth,
  imageHeight
) {
  if (isInBounds(coords, imageWidth, imageHeight)) {
    if (isMovingLineEnd) {
      targetObject.lineEndX = roundTo5(coords.x);
      // console.log(
      //   "Changing the circles coords in setCoords in the climbingRoute object"
      // );
      targetObject.lineEndY = roundTo5(coords.y);
    } else {
      targetObject.labelX = roundTo5(coords.x);
      targetObject.labelY = roundTo5(coords.y);
    }
    // This array copy will trigger a render.
    //setAssemblies(assemblies.slice());
  } else {
    console.log("Out of bounds");
  }
}
function isInBounds({ x, y }, width, height) {
  // console.log(`x=${x} y=${y} width=${width} height=${height}`);
  return !(x < 0 || x >= width || y < 0 || y >= height);
}
function roundTo5(target) {
  return Math.round(target / 5) * 5;
}
export { getOnMoveObject, getMapHandlers };
