import React, { useState } from "react";

import { withCachedRootStore } from "ticker-core";

import {
  Button,
  Card,
  CardBody,
  GetSelect,
  LoginRequest
} from "ticker-components";

import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

import { customCheckboxRadioSwitchStyle } from "ticker-core";
import routeFilterStyle from "./routeFilterStyle";
import Collapse from "@material-ui/core/Collapse";
import Grow from "@material-ui/core/Grow";

let styles = {
  ...customCheckboxRadioSwitchStyle,
  ...routeFilterStyle,
  breaker: {
    borderBottom: "solid 1px #ddd",
    paddingBottom: "1em",
    paddingTop: "1em"
  },

  smallGap: {
    paddingLeft: "0.3em",
    margin: "auto !important",
    maxWidth: "20em"
  },
  centeredStyle: {
    position: "absolute",
    top: "50%",
    marginTop: "-2em",
    zIndex: 1,
    width: "100%",
    textAlign: "center"
  },
  typography: {
    padding: "1em"
  },
  padded: {
    paddingLeft: "1em",
    paddingRight: "1em"
  },
  blocking: {
    display: "block",
    textAlign: "center"
  },
  paper: {
    background: "white",
    width: "100%"
  },
  list: {
    margin: "0px",
    listStyleType: "none"
  },
  sliderContainer: {
    textAlign: "center",
    maxWidth: "50em",
    margin: "auto",
    paddingTop: "3em",
    paddingLeft: "2em",
    paddingRight: "2em"
  }
};

let useStyles = makeStyles(styles);

export default withCachedRootStore(
  observer(function MissingPopper(props) {
    let { rootStore, isOpen, onClose, showNextStep } = props;

    let [colorId, setColorId] = useState();
    let classes = useStyles();
    let {
      selectionStore,
      personalRootStore: {
        personalMissingStores: { createMissingStore }
      },
      notificationStore,
      regStores
    } = rootStore;

    const colorSelect = GetSelect({
      label: "Color",
      instruction: "Choose Color",
      onChange: event => {
        setColorId(event.target.value);
      },
      value: colorId,
      inputName: "MarkerColor",
      inputId: "MarkerColor",
      items: rootStore.markerColorStores.markerColorStore.getAllItemsEvenArchived(),
      labelGetterFn: item => item.name,
      classes: classes
    });

    let fullOnClose = () => {
      setColorId(undefined);
      onClose();
    };
    return (
      <Collapse in={isOpen}>
        <Card popUp raised>
          <CardBody>
            {!regStores.isRegistered() && (
              <LoginRequest instruction=" to report a missing climb" />
            )}

            {regStores.isRegistered() && (
              <>
                <div className={classes.typography}>
                  <h5>Report a missing climb!</h5>
                </div>
                <Collapse in={!showNextStep} id="menu-list">
                  <div
                    className={classes.typography}
                    style={{ maxWidth: "300px" }}
                  >
                    <p>
                      Click on the map to place a marker where the missing climb
                      starts.
                    </p>
                  </div>
                </Collapse>
                <Collapse in={showNextStep} id="menu-list">
                  <div>
                    <div style={{ textAlign: "center" }}>
                      What is the color of the hold?
                    </div>
                    <div>{colorSelect}</div>
                  </div>
                </Collapse>
                <div style={{ textAlign: "center" }}>
                  <Button color="danger" onClick={fullOnClose} simple>
                    Cancel
                  </Button>
                  <Grow in={!!(showNextStep && colorId)}>
                    <Button
                      color="success"
                      onClick={async () => {
                        createMissingStore.marker_color_id = colorId;
                        createMissingStore.labelX =
                          selectionStore.newClimbingObject.labelX;
                        createMissingStore.labelY =
                          selectionStore.newClimbingObject.labelY;
                        notificationStore.enqueueNotification("Thanks", {
                          variant: "success"
                        });

                        onClose();
                        createMissingStore.createMissing();
                      }}
                      disabled={!selectionStore.newClimbingObject}
                    >
                      Send Report
                    </Button>
                  </Grow>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Collapse>
    );
  })
);
