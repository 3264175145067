import { gradeTypes } from "../variables/constants";

export default function getOppositeGradeType(gradeType) {
  if (typeof gradeType === "undefined") {
    console.log("Possible error. No grade provided to getOpposite.");
    debugger;
  }
  if (gradeType === gradeTypes.ROUTE) {
    return gradeTypes.BOULDER;
  } else return gradeTypes.ROUTE;
}
