import { coreStyle } from "ticker-core";
const {
  container,
  defaultFont,
  cardTitle,
  roseColor,
  whiteColor,
  grayColor,
  hexToRgb,
  listItemIconStyle
} = coreStyle;

const climbCardStyle = theme => ({
  listItemIcon: listItemIconStyle,
  iconList: { margin: "auto", fontSize: "small" },
  listItem: {
    float: "left",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0 1em !important",
    borderBottom: "none !important"
    //,
    // [theme.breakpoints.down("sm")]: {
    //   zIndex: "999",
    //   width: "100%",
    //   paddingRight: "15px"
    // }
  },
  downOne: {
    position: "absolute",
    paddingTop: "5px"
  },
  navLink: {
    color: grayColor[2],
    // fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus": {
      background: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
      cursor: "pointer"
    }
  },
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  title: {
    ...defaultFont,
    color: whiteColor,
    marginTop: "5vh",
    marginBottom: "30px",
    textAlign: "center"
  },
  description: {
    fontSize: "18px",
    color: whiteColor,
    textAlign: "center"
  },
  label: {
    fontWeight: "400"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + " !important"
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px"
  },
  cardCategoryWhite: {
    color: whiteColor,
    marginTop: "10px"
  },
  icon: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid " + grayColor[11],
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconWhite: {
    color: whiteColor
  },
  iconRose: {
    color: roseColor[0]
  },
  marginTop30: {
    marginTop: "30px"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  login: {
    paddingBottom: "1em"
  }
});

export default climbCardStyle;
