import React, { useRef, useState } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

import Keyboard from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";
import "../../assets/css_see_packageJSON/keyboard.css";

// core components

function UserNameKeyboard({ onChange, showShift, regStore }) {
  const keyboard = useRef();
  const [layoutName, setLayoutName] = useState("default");

  const onKeyPress = button => {
    regStore.resetNoActivityTimeout();

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}") handleShift();
  };

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  // This wrapper function is unnecessary. I've left it here to help me debug a weird case in registrationRegisterPage
  // where the userName state is not correct (it is the default "") in the onChange function
  // This little wrapper can be removed safely with out consequence.
  // Brook 17/11/19
  const internalOnChange = newCode => {
    onChange(newCode);
  };

  let shiftKey = "";
  if (showShift) {
    shiftKey = "{shift} ";
  }
  return (
    <Keyboard
      keyboardRef={r => (keyboard.current = r)}
      onChange={internalOnChange}
      onKeyPress={onKeyPress}
      layoutName={layoutName}
      layout={{
        default: [
          "0 1 2 3 4 5 6 7 8 9",
          "q w e r t y u i o p",
          "a s d f g h j k l",
          "z x c v b n m _ -",
          shiftKey + "{space} {bksp}"
        ],
        shift: [
          "0 1 2 3 4 5 6 7 8 9",
          "Q W E R T Y U I O P",
          "A S D F G H J K L",
          "Z X C V B N M _ -",
          shiftKey + "{space} {bksp}"
        ]
      }}
      theme="hg-theme-default myTheme"
    />
  );
}

UserNameKeyboard.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  showShift: PropTypes.bool.isRequired,
  regStore: PropTypes.object.isRequired
};

export default UserNameKeyboard;
