import { observable, action } from "mobx";
import createAssembly from "./AssemblyHelper.js";
import moment from "moment";
import getCachable from "../utils/getCachable.js";

export default class ClimbStores {
  constructor(context, rootStore, asyncOpCreate, getCurrentGymId) {
    let rest = context.rest;

    let removeItemById = (list, id, isSameIdFn) => {
      if (typeof id === "string") {
        console.log("The ID is a string");
        throw Error("The ID is a string");
      }
      for (let i = 0; i < list.length; i++) {
        if (isSameIdFn(id, list[i])) {
          list.splice(i, 1);
          return true;
        }
      }
      return false;
    };

    let getItemById = (list, id, isSameIdFn) => {
      if (typeof id === "string") {
        console.log("The ID is a string");
        throw Error("The ID is a string");
      }
      for (let i = 0; i < list.length; i++) {
        if (isSameIdFn(id, list[i])) {
          return list[i];
        }
      }
      return null;
    };

    this.climbingRouteCacheStore = getCachable(
      asyncOpCreate,
      rest,
      (a, b) => a.id === b.id,
      "climbingRoutes",
      null,
      () => ({ gym_id: getCurrentGymId() })
    );

    this.archivedStore = observable({
      archivedClimbingRouteAssemblies: [],
      opGetNextArchivedClimbingRoutes: asyncOpCreate(
        payload => rest.get("climbingRoutes", payload),
        "climbingRoutes"
      ),
      getNextArchivedClimbingRouteAssemblies: action(async function(
        start,
        limit
      ) {
        let payload = {
          offset: start,
          limit: limit,
          orderField: "archiveDate",
          order: "DESC",
          isArchived: true,
          gym_id: getCurrentGymId()
        };
        let archivedClimbingRoutes = await this.opGetNextArchivedClimbingRoutes.execute(
          payload
        );

        archivedClimbingRoutes.forEach(climbingRoute => {
          this.archivedClimbingRouteAssemblies.push(
            this.createNewAssembly(climbingRoute)
          );
        });
      }),

      prependClimbingRoute: function(climb) {
        this.archivedClimbingRouteAssemblies.unshift(
          this.createNewAssembly(climb)
        );
      },

      createNewAssembly: function(climbingRoute) {
        let newAssembly = createAssembly(rootStore, climbingRoute);
        newAssembly.routeStatus = rootStore.routeStatusStores.routeStatusStore.getItemById(
          climbingRoute.route_status_id
        );
        newAssembly.climbingWall = rootStore.wallStores.wallStore.getItemById(
          climbingRoute.climbing_wall_id
        );
        newAssembly.climbingSection = rootStore.sectionStores.sectionStore.getItemById(
          newAssembly.climbingWall.climbing_section_id
        );
        return newAssembly;
      },

      getItemById(id) {
        return getItemById(
          this.archivedClimbingRouteAssemblies,
          id,
          (id, assembly) => id === assembly.climbingRoute.id
        );
      },

      removeItemById(id) {
        return removeItemById(
          this.archivedClimbingRouteAssemblies,
          id,
          (id, assembly) => id === assembly.climbingRoute.id
        );
      }
    });

    this.climbingRouteStore = observable({
      description: "",
      setterNotes: "",
      route_status_id: "",
      setter_alias_id: "",
      marker_color_id: "",
      climbing_grade_id: "",
      climbing_wall_id: "",
      isOnHold: false,
      isArchived: false,
      isAlteration: false,
      isExcludedFromRatings: false,
      id: null,
      gym_id: null,
      hasLoaded: false,
      settingDateTime: null,
      settingDateOnly: null,
      archiveDate: null,
      labelX: null,
      labelY: null,
      lineEndX: null,
      lineEndY: null,

      setToDefaults: function() {
        // console.log("Set to defaults");
        this.clearMostDetails();
        this.climbing_wall_id = "";
        this.id = null;
        this.gym_id = null;
        this.hasLoaded = false;
        this.archiveDate = null;
      },
      clearBasicDetails: function() {
        // console.log("Clearing basic details");
        this.description = "";
        this.setterNotes = "";
        this.marker_color_id = "";
        this.climbing_grade_id = "";
        this.isArchived = false;
        this.isOnHold = false;
        this.isExcludedFromRatings = false;
      },
      clearDateDetails: function() {
        let now = moment();
        this.settingDateTime = now.utc().toISOString();
        this.settingDateOnly = now.format("YYYY-MM-DD");
      },

      clearMostDetails: function() {
        // console.log("Clearing Most details");
        this.clearBasicDetails();
        this.clearDateDetails();
        this.route_status_id = rootStore.gymStores.gymStore
          .default_route_status_id
          ? rootStore.gymStores.gymStore.default_route_status_id
          : "";
        this.setter_alias_id = "";
        this.isAlteration = false;
      },

      getStoreName: () => "climbingRouteStore",
      opcreateClimbingRoute: asyncOpCreate(
        payload => rest.post("climbingRoutes", payload),
        "opcreateClimbingRoute"
      ),
      createClimbingRoute: action(async function() {
        console.log(
          "uuuxx ClimbingRouteControls.saveRoute() inside createClimbingRoute: id=" +
            this.id
        );

        let that = this;
        const payload = this.getPayload();
        let callBack = newRoutePayload => {
          console.log(
            "uuuxx ClimbingRouteControls.saveRoute() inside the callback of createClimbingRoute: id=" +
              that.id
          );
          rootStore.climbingRouteStores.climbingRouteCacheStore.items.push(
            newRoutePayload
          );
          console.log("Created the route and in callback:", newRoutePayload);
        };
        let newRoute = await this.opcreateClimbingRoute.execute(
          payload,
          callBack
        );
        return newRoute;
      }),
      opUpdateClimbingRoute: asyncOpCreate(
        ({ payload, id }) => rest.put("climbingRoutes/" + id, payload),
        "opUpdateClimbingRoute"
      ),
      updateClimbingRoute: action(async function() {
        if (this.id === null || typeof this.id === "undefined") {
          console.log("The id is null or empty can't update: " + this.id);
          debugger;
          throw new Error("The id is null or empty. Can't update");
        }
        console.log("uuu ClimbingRouteStore.updateClimbingRoute()");

        try {
          const payload = this.getPayload();
          //We need to copy the values into the local cache
          // This could be done after the server update
          // But that would delay the user interface
          // So we are doing it immediately
          let existingRoute = rootStore.climbingRouteStores.climbingRouteCacheStore.getItemById(
            this.id
          );

          if (existingRoute) {
            this.merge(payload, existingRoute);
          } else {
            console.log("Not merging the route because it isn't in the cache");
          }
          return await this.opUpdateClimbingRoute.execute({
            payload,
            id: this.id
          });
        } catch (error) {
          if (error === null || typeof error === "undefined") {
            console.log(
              "The call to 'updateClimb' has failed inside the ClimbStore. The error is undefined."
            );
          } else {
            if (error.response) {
              const {
                response: { status }
              } = error;
              if (401 === status) {
                console.log(
                  "The call to 'updateClimb' has returned with 401. The user is probably not logged in"
                );
              } else {
                console.log("The call to 'updateClimb' has failed: ", error);
              }
            } else {
              console.log(
                "The error doesn't have a response. Here is the error:",
                error
              );
            }
          }
          throw error;
        } finally {
          this.hasLoaded = true;
        }
      }),
      getPayload: function getPayloadFn() {
        const payload = {
          description: this.description,
          setterNotes: this.setterNotes,
          route_status_id: this.route_status_id,
          setter_alias_id: this.setter_alias_id,
          marker_color_id: this.marker_color_id,
          climbing_grade_id: this.climbing_grade_id,
          climbing_wall_id: this.climbing_wall_id,
          gym_id: this.gym_id,
          isOnHold: this.isOnHold,
          isArchived: this.isArchived,
          isAlteration: this.isAlteration,
          isExcludedFromRatings: this.isExcludedFromRatings,
          settingDateTime: this.settingDateTime,
          settingDateOnly: this.settingDateOnly,
          archiveDate: this.archiveDate,
          lineEndX: this.lineEndX,
          lineEndY: this.lineEndY,
          labelX: this.labelX,
          labelY: this.labelY
        };
        if (this.id !== null && typeof this.id !== "undefined") {
          payload.id = this.id;
        }

        if (
          payload.description !== null &&
          typeof payload.description !== "undefined" &&
          payload.description.trim() === ""
        ) {
          payload.description = null;
        }

        if (
          payload.setterNotes !== null &&
          typeof payload.setterNotes !== "undefined" &&
          payload.setterNotes.trim() === ""
        ) {
          payload.setterNotes = null;
        }

        return payload;
      },
      merge: function mergeFn(from, to, copyBlanks) {
        if (from === null || typeof from === "undefined") {
          console.log("ERROR: from is null or undefined:" + from);
          debugger;
        }
        if (to === null || typeof to === "undefined") {
          console.log("ERROR: to is null or undefined:" + to);

          debugger;
        }
        let fieldNames = [
          "id",
          "description",
          "setterNotes",
          "route_status_id",
          "setter_alias_id",
          "marker_color_id",
          "climbing_grade_id",
          "climbing_wall_id",
          "gym_id",
          "isOnHold",
          "isArchived",
          "isAlteration",
          "isExcludedFromRatings",
          "settingDateTime",
          "settingDateOnly",
          "archiveDate",
          "lineEndX",
          "lineEndY",
          "labelX",
          "labelY"
        ];

        // console.log("Merging the climbingRoute");
        // console.trace();
        // console.log("rrr Beginning the merge.");
        fieldNames.forEach(fieldName => {
          if (
            copyBlanks ||
            from[fieldName] === false ||
            from[fieldName] === 0 ||
            from[fieldName] ||
            from[fieldName] === null
          ) {
            // console.log(
            //   "rrr Merging field:" + fieldName + " Value=" + from[fieldName]
            // );
            to[fieldName] = from[fieldName];
          }
        });
        return to;
      }
    });

    //   let prev = {};
    //   autorun(() => {

    //     console.log("---------- xxSomething changed in the ClimbingRouteStore.");
    //     console.log(
    //       "---------------xxSomething The value of this.climbingRouteStore.id was =" +
    //         prev.id +
    //         " and is now " +
    //         this.climbingRouteStore.id
    //     );
    //     if (prev.id !== this.climbingRouteStore.id) {
    //       debugger;
    //     }
    //     prev.id = this.climbingRouteStore.id;
    //   }, [this.climbingRouteStore.id]);
  }
}
