import { coreStyle } from "ticker-core";
const {
  container,
  defaultFont,
  cardTitle,
  roseColor,
  whiteColor,
  grayColor,
  highTop
} = coreStyle;

const climbPageStyle = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  highTop,
  title: {
    ...defaultFont,
    marginTop: "5vh",
    marginBottom: "30px",
    textAlign: "center"
  },
  description: {
    fontSize: "18px",
    color: whiteColor,
    textAlign: "center"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + " !important"
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px"
  },
  cardCategoryWhite: {
    color: whiteColor,
    marginTop: "10px"
  },
  icon: {
    color: "inherit",
    width: "30px",
    height: "24px",
    float: "left",
    position: "inherit",
    top: "3px",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    opacity: "0.8"
  },
  iconWhite: {
    color: whiteColor
  },
  iconRose: {
    color: roseColor[0]
  },
  marginTop30: {
    marginTop: "30px"
  },
  primarySelectors: {
    backgroundColor: grayColor[1]
  },
  filterContainer: {
    minHeight: "3em",
    maxWidth: "970px",
    margin: "auto"
  },
  gridRow: {
    [theme.breakpoints.down("xs")]: {
      borderBottom: "solid 2px grey"
    }
  },
  badge: {
    marginLeft: "0.5em"
  }
});

export default climbPageStyle;
