import createDraggableItemsStore from "./DragggableStore.js";

export default class WallStores {
  constructor(context, asyncOpCreate, getCurrentGymId) {
    this.wallStore = createDraggableItemsStore(
      context,
      "climbingWalls",
      "Wall",
      null, // No item filter
      asyncOpCreate,
      getCurrentGymId
    );
  }
}
