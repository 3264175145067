import { observable, action } from "mobx";

export default function getCachable(
  asyncOpCreate,
  rest,
  areEqual,
  url,
  callBack,
  defaultPayloadFn,
  errorHandler
) {
  let this__ = observable({
    items: observable([]),
    itemsCached: false
  });

  this__.getStoreName = () => {
    return url;
  };

  this__.opCacheItems = asyncOpCreate(
    payload => rest.get(url, payload),
    "cacheItem " + url,
    errorHandler
  );
  this__.cacheItems = action(async function(props) {
    try {
      console.log("CachingItems in getCachable: " + url);
      let { forceReload, payload } = props;
      if (!payload) {
        payload = defaultPayloadFn();
      }
      console.log("#2 CachingItems in getCachable: " + url);

      if (!this__.itemsCached || forceReload) {
        this__.itemsCached = false;
        console.log("Executing cacheItems in getCachable: " + url);
        return this__.opCacheItems.execute(payload, newItems => {
          this__.pushIntoObservableArrays(newItems);
          this__.itemsCached = true;
          if (callBack) {
            callBack(newItems);
          }
        });
      } else {
        console.log(
          "The items at URL '" + url + "' are already cached, and not forced."
        );
      }
    } catch (error) {
      debugger;
      console.log(error);
      throw error;
    }
  });

  this__.clearCache = action(async function() {
    this__.items.clear();
    this__.itemsCached = false;
  });

  this__.pushIntoObservableArrays = action(function(newItems) {
    //We must clear the observable arrays first
    this__.items.clear();

    newItems.forEach(item => {
      this__.items.push(item);
    });
  });

  this__.removeItem = action(function(itemIndex) {
    this__.items.splice(itemIndex, 1);
  });

  this__.getItemById = id => {
    if (typeof id === "string") {
      console.log("The ID is a string");
      throw Error("The ID is a string");
    }
    for (let i = 0; i < this__.items.length; i++) {
      if (id === this__.items[i].id) {
        return this__.items[i];
      }
    }
    return null;
  };

  this__.removeItemById = id => {
    if (typeof id !== "number") {
      console.log("The ID is a string");
      throw Error("The ID is a string");
    }
    for (let i = 0; i < this__.items.length; i++) {
      if (id === this__.items[i].id) {
        this__.items.splice(i, 1);
        return true;
      }
    }
    return false;
  };

  this__.mergeOrInsert = newItem => {
    for (let item of this__.items) {
      if (areEqual(newItem, item)) {
        Object.assign(item, newItem);
        return;
      }
    }
    this__.items.push(newItem);
  };

  this__.clearCache = () => {
    this__.itemsCached = false;
    this__.items.clear();
    this__.opCacheItems.executed = false;
  };
  return this__;
}
