import createDraggableItemsStore from "./DragggableStore.js";

export default class SetterAliasStores {
  constructor(context, asyncOpCreate, getCurrentGymId) {
    this.setterAliasStore = createDraggableItemsStore(
      context,
      "setterAliases",
      "Setter",
      null, // No item filter
      asyncOpCreate,
      getCurrentGymId
    );
  }
}
