import React, { useCallback } from "react";

import PropTypes from "prop-types";

import { withCachedRootStore, ZoomLevels, coords } from "ticker-core";
import { Button } from "ticker-components";

import CenterFocusStrong from "@material-ui/icons/CenterFocusStrong";
import { MODE_ZOOMING } from "./react-svg-pan-zoom/constants";
import LabelView from "./SVGObjects/LabelView";
import { getSectionFontSize, getLabelFontSize } from "./fontSize";

import {
  UncontrolledReactSVGPanZoom,
  TOOL_AUTO,
  TOOL_NONE
} from "./react-svg-pan-zoom";
import { useObserver } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

import { getMapHandlers, getOnMoveObject } from "./handlers/SVGMapHandlers";
import LabelObjects from "./LabelObjects";

import svgMapStyles from "../../assets/jss/material-dashboard-pro-react/components/svgMap";

let styles = {
  centeredStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-2em",
    textAlign: "center"
  },
  typography: {
    padding: "1em"
  },
  filterButton: {},
  ...svgMapStyles
};
let useStyles = makeStyles(styles);

function SVGLabelsMap(props) {
  let {
    rootStore,
    longPressIsDisabled,
    onPointerUpFinishFn,
    isSections,
    labelObjects,
    getLabelFn,
    newLabelText,
    coordsFactory
  } = props;

  let classes = useStyles();
  let {
    regStores: { regStore },
    gymStores: { gymStore }
  } = rootStore;

  const {
    SVGStore,
    selectionStore,
    sectionStores: { sectionStore },
    wallStores: { wallStore }
  } = props.rootStore;

  let setViewer = viewer => {
    SVGStore.viewer = viewer;
  };

  let callableOnTouchMoveCB = useCallback(
    evt => {
      SVGStore.viewer.callableOnTouchMove(evt);
    },
    [SVGStore.viewer]
  );

  let callableOnTouchEndCB = useCallback(
    evt => {
      SVGStore.viewer.callableOnTouchEnd(evt);
    },
    [SVGStore.viewer]
  );

  return useObserver(() => {
    let climbingSectionsX = sectionStore.getUnarchivedItems();

    let onChangeValue = newValue => {
      SVGStore.setValue({ ...newValue });
    };

    let onMoveObject = getOnMoveObject(
      regStore,
      SVGStore,
      selectionStore,
      gymStore,
      longPressIsDisabled
    );

    let mapHandlers = getMapHandlers(
      regStore,
      SVGStore,
      selectionStore,
      longPressIsDisabled,
      null,
      onPointerUpFinishFn, // onFinish
      false // allowShallowClicks
      // () => {
      //   sectionStore.putItems();
      // }
    );

    let value = SVGStore.value;
    let zoomRatio =
      typeof value.a === "undefined" || value.a === null ? 1 : value.a;

    let sectionFontSize = getSectionFontSize(zoomRatio);
    let labelFontSize = getLabelFontSize(zoomRatio);

    if (isSections) {
      labelFontSize = sectionFontSize;
    }

    let sectionCoords = coords.getSectionCoords(
      gymStore,
      sectionStore,
      wallStore
    );

    let { minScaleFactor, maxScaleFactor } = ZoomLevels.getScaleFactorLimits(
      rootStore.SVGStore.value
    );

    return (
      <div className={classes.svgContainer}>
        <div className={classes.filterPanel}>
          <Button
            color="info"
            onClick={() => SVGStore.recenter()}
            size="sm"
            className={classes.filterButton}
            simple
          >
            <CenterFocusStrong /> Recenter
          </Button>
        </div>
        <UncontrolledReactSVGPanZoom
          {...SVGStore.dimensions}
          // onClick={onClick}
          toolbarProps={{ position: "none" }}
          tool={SVGStore.disableMapTool ? TOOL_NONE : TOOL_AUTO}
          miniatureProps={{ position: "none" }}
          detectAutoPan={false}
          onChangeValue={onChangeValue}
          // onDoubleClick={onDoubleClick}
          // viewerDomCallBack={logSetViewerDOM}
          // Move an object if it is selected
          onMouseMove={onMoveObject}
          onTouchMove={onMoveObject}
          // Events that will clear the stored object to move
          onMouseUp={mapHandlers.detectDoublePointer}
          onTouchEnd={mapHandlers.detectDoublePointer}
          background="none"
          targetZoomDepth={
            ZoomLevels.getDoubleClickBoundary(SVGStore.dimensions) + 0.2
          }
          value={value}
          setViewer={setViewer}
          scaleFactorMax={maxScaleFactor}
          scaleFactorMin={minScaleFactor}
        >
          <svg width={gymStore.imageWidth} height={gymStore.imageHeight}>
            <g>
              <image x={50} y={50} href="/FloorPlan.png" />
              <LabelObjects
                labelObjects={labelObjects}
                longPressIsDisabled={longPressIsDisabled}
                selectionStore={selectionStore}
                gymStore={gymStore}
                isClickable
                useDefaultHandlers={true}
                fontSize={labelFontSize}
                callableOnTouchEnd={callableOnTouchEndCB}
                callableOnTouchMove={callableOnTouchMoveCB}
                getLabelFn={getLabelFn}
                coordsFactory={coordsFactory}
              />

              {selectionStore.creatingNewObject &&
              value.mode !== MODE_ZOOMING ? (
                <LabelView
                  labelText={newLabelText}
                  x={selectionStore.newClimbingObject.labelX}
                  y={selectionStore.newClimbingObject.labelY}
                  fontSize={labelFontSize}
                  labelObject={selectionStore.newClimbingObject}
                  isSelected={true}
                  longPressIsDisabled={false}
                  callableOnTouchEnd={callableOnTouchEndCB}
                  callableOnTouchMove={callableOnTouchMoveCB}
                  isClickable={true}
                  useDefaultHandlers={true}
                  wasLongPressed={
                    !!selectionStore.wasLongPressed.get(
                      selectionStore.newClimbingObject.id
                    )
                  }
                  getLabelFn={getLabelFn}
                  coordsFactory={coordsFactory}
                />
              ) : (
                undefined
              )}
              {!isSections && (
                <LabelObjects
                  labelObjects={climbingSectionsX}
                  longPressIsDisabled={longPressIsDisabled}
                  selectionStore={selectionStore}
                  gymStore={gymStore}
                  isClickable={false}
                  useDefaultHandlers={false}
                  fontSize={sectionFontSize}
                  callableOnTouchEnd={callableOnTouchEndCB}
                  callableOnTouchMove={callableOnTouchMoveCB}
                  getLabelFn={getLabelFn}
                  coordsFactory={sectionCoords}
                  // No onClick because there are no routes to use to zoom properly - onClick={}
                />
              )}
            </g>
          </svg>
        </UncontrolledReactSVGPanZoom>
      </div>
    );
  });
}

// function getLabelFn(section) {
//   return section.name;
// }
SVGLabelsMap.propTypes = {
  longPressIsDisabled: PropTypes.bool,
  onPointerUpFinishFn: PropTypes.func.isRequired,
  isSections: PropTypes.bool.isRequired,
  labelObjects: PropTypes.array.isRequired,
  getLabelFn: PropTypes.func.isRequired,
  newLabelText: PropTypes.string.isRequired
};

export default withCachedRootStore(SVGLabelsMap);
