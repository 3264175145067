import React from "react";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";

import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";

class AlertWrapper extends React.Component {
  render() {
    //By having the classes and alertTitle props extrcacted Headers,
    // We can use ...other to pass to other props down to the wrapped component
    //
    // eslint-disable-next-line no-unused-vars
    const {
      confirmCallBack,
      cancelCallBack,
      inputValue,
      // classes,
      alertTitle,
      classes
    } = this.props;
    return (
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px", position: "fixed" }}
        title={alertTitle}
        onConfirm={e => {
          confirmCallBack(e);
        }}
        onCancel={() => {
          cancelCallBack();
        }}
        confirmBtnCssClass={classes.button + " " + classes.info}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        defaultValue={inputValue}
        customClass="smallerh2"
      />
    );
  }
}

AlertWrapper.propTypes = {
  alertTitle: PropTypes.string.isRequired,
  confirmCallBack: PropTypes.func.isRequired,
  cancelCallBack: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(buttonStyle)(AlertWrapper);
