import createDraggableItemsStore from "./DragggableStore.js";

export default class SectionStores {
  constructor(context, asyncOpCreate, getCurrentGymId) {
    this.sectionStore = createDraggableItemsStore(
      context,
      "climbingSections",
      "Section",
      null, // No item filter
      asyncOpCreate,
      getCurrentGymId
    );
  }
}
