import ZoomLevels from "../utils/ZoomLevels";

function zoomPanToClosestSection(coords, rootStore, viewer) {
  let sectionAssembly = getClosestSection(rootStore, coords);

  zoomPanToSection(sectionAssembly, rootStore, viewer);
}

function zoomPanToSection(section, rootStore, viewer) {
  let sectionAssembly = rootStore.computedCacheStore.computedCache
    .get()
    .sectionAssemblies.find(
      sectionAssembly => sectionAssembly.climbingSection.id === section.id
    );

  if (sectionAssembly) {
    let minX = sectionAssembly.climbingSection.labelX;
    let maxX = minX;
    let minY = sectionAssembly.climbingSection.labelY;
    let maxY = minY;

    sectionAssembly.climbingWallAssemblies.forEach(wallAssembly => {
      if (wallAssembly.isArchived) return;

      wallAssembly.climbingRouteAssemblies.forEach(climbAssembly => {
        if (climbAssembly.climbingRoute.labelX < minX) {
          minX = climbAssembly.climbingRoute.labelX;
        }
        if (climbAssembly.climbingRoute.lineEndX < minX) {
          minX = climbAssembly.climbingRoute.lineEndX;
        }
        if (climbAssembly.climbingRoute.labelX > maxX) {
          maxX = climbAssembly.climbingRoute.labelX;
        }
        if (climbAssembly.climbingRoute.lineEndX > maxX) {
          maxX = climbAssembly.climbingRoute.lineEndX;
        }
        if (climbAssembly.climbingRoute.labelY < minY) {
          minY = climbAssembly.climbingRoute.labelY;
        }
        if (climbAssembly.climbingRoute.lineEndY < minY) {
          minY = climbAssembly.climbingRoute.lineEndY;
        }
        if (climbAssembly.climbingRoute.labelY > maxY) {
          maxY = climbAssembly.climbingRoute.labelY;
        }
        if (climbAssembly.climbingRoute.lineEndY > maxY) {
          maxY = climbAssembly.climbingRoute.lineEndY;
        }
      });
    });
    zoomPanToArea({ maxX, minX, maxY, minY }, rootStore.SVGStore.value, viewer);
  } else {
    console.error("Couldn't match the section to an assembly???");
    debugger;
  }
}

function getEnclosingCoords(climbList) {
  let minX = 100000;
  let minY = 1000000;
  let maxX = 0;
  let maxY = 0;

  climbList.forEach(climbAssembly => {
    if (climbAssembly.climbingRoute.labelX < minX) {
      minX = climbAssembly.climbingRoute.labelX;
    }
    if (climbAssembly.climbingRoute.lineEndX < minX) {
      minX = climbAssembly.climbingRoute.lineEndX;
    }
    if (climbAssembly.climbingRoute.labelX > maxX) {
      maxX = climbAssembly.climbingRoute.labelX;
    }
    if (climbAssembly.climbingRoute.lineEndX > maxX) {
      maxX = climbAssembly.climbingRoute.lineEndX;
    }
    if (climbAssembly.climbingRoute.labelY < minY) {
      minY = climbAssembly.climbingRoute.labelY;
    }
    if (climbAssembly.climbingRoute.lineEndY < minY) {
      minY = climbAssembly.climbingRoute.lineEndY;
    }
    if (climbAssembly.climbingRoute.labelY > maxY) {
      maxY = climbAssembly.climbingRoute.labelY;
    }
    if (climbAssembly.climbingRoute.lineEndY > maxY) {
      maxY = climbAssembly.climbingRoute.lineEndY;
    }
  });
  return { maxX, minX, maxY, minY };
}

function zoomPanToArea({ maxX, minX, maxY, minY }, origValue, viewer) {
  let targetWidth = maxX - minX;
  let targetHeight = maxY - minY;

  console.log("Inside zoomPanToArea");

  let { SVGWidth, SVGHeight, viewerWidth, viewerHeight } = origValue;

  let PADDING = 0.07;
  if (SVGWidth * 0.05 > targetWidth || SVGHeight * 0.05 > targetHeight) {
    PADDING = 0.6;
  } else if (SVGWidth * 0.1 > targetWidth || SVGHeight * 0.1 > targetHeight) {
    PADDING = 0.5;
  } else if (SVGWidth * 0.2 > targetWidth || SVGHeight * 0.2 > targetHeight) {
    PADDING = 0.4;
  } else if (SVGWidth * 0.3 > targetWidth || SVGHeight * 0.3 > targetHeight) {
    PADDING = 0.3;
  } else if (SVGWidth * 0.4 > targetWidth || SVGHeight * 0.4 > targetHeight) {
    PADDING = 0.09;
  }

  // This test for a targetWidth or targetHeight that is too large should be
  // after the assignment of the minX below
  // At the moment the test doesn't include the padding which isn't ideal.
  if (targetWidth > SVGWidth) {
    targetWidth = SVGWidth;
  }
  if (targetHeight > SVGHeight) {
    targetHeight = SVGHeight;
  }

  minX = minX - (targetWidth * PADDING) / 2;
  minY = minY - (targetHeight * PADDING) / 2;

  targetWidth = targetWidth * (1 + PADDING);
  targetHeight = targetHeight * (1 + PADDING);

  let { minScaleFactor } = ZoomLevels.getScaleFactorLimits(origValue);

  let minTargetWidth = viewerWidth * minScaleFactor;
  if (minTargetWidth > targetWidth) {
    // reposition the top left corner
    minX = minX - (minTargetWidth - targetWidth) / 2;

    // Make the view space larger
    targetWidth = minTargetWidth;
  }

  let minTargetHeight = viewerHeight * minScaleFactor;
  if (minTargetHeight > targetHeight) {
    minY = minY - (minTargetHeight - targetHeight) / 2;
    targetHeight = minTargetHeight;
  }

  let scaleX = viewerWidth / targetWidth;
  let scaleY = viewerHeight / targetHeight;

  let isMoreNarrowThanScreen =
    viewerWidth / viewerHeight > targetWidth / targetHeight;

  let scaleLevel = Math.min(scaleX, scaleY);
  debugger;

  let x = minX;
  let y = minY;

  // debugger;

  if (isMoreNarrowThanScreen) {
    let adjustment = (viewerWidth / scaleLevel - targetWidth) / 2;
    x = minX - adjustment;
  } else {
    let yAdjustment = (viewerHeight / scaleLevel - targetHeight) / 2;
    y = minY - yAdjustment;
  }
  viewer.fitSelection(x, y, targetWidth, targetHeight);
}

function getDiff(a, b) {
  if (a > b) {
    return a - b;
  }
  return b - a;
}

function getDistance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(getDiff(x1, x2), 2) + Math.pow(getDiff(y1, y2), 2));
}

function getClosestSection(rootStore, coords) {
  let { computedCacheStore } = rootStore;
  let cache = computedCacheStore.computedCache.get();
  let distances = [];
  cache.sectionAssemblies.forEach(sectionAssembly => {
    if (sectionAssembly.isArchived) return;
    sectionAssembly.climbingWallAssemblies.forEach(wallAssembly => {
      if (wallAssembly.isArchived) return;

      wallAssembly.climbingRouteAssemblies.forEach(climbAssembly => {
        let distance = getDistance(
          coords.x,
          coords.y,
          climbAssembly.climbingRoute.labelX,
          climbAssembly.climbingRoute.labelY
        );
        distances.push({
          sectionAssembly,
          isAssembly: false,
          distance
        });
      });
    });
    let distance = getDistance(
      coords.x,
      coords.y,
      sectionAssembly.climbingSection.labelX,
      sectionAssembly.climbingSection.labelY
    );
    distances.push({
      sectionAssembly,
      distance,
      isAssembly: true
    });
  });

  distances.sort(byDistance);

  let NUM_CLOSEST = 5;
  if (distances.length < NUM_CLOSEST) {
    return distances[0].sectionAssembly;
  }

  // If the closest thing is a section then zoom to it
  if (distances[0].isAssembly) {
    return distances[0].sectionAssembly;
  }

  let sectionToCountMap = new Map();

  for (let i = 0; i < NUM_CLOSEST; i++) {
    if (!sectionToCountMap[distances[i]]) {
      sectionToCountMap.set(distances[i], 0);
    }
    sectionToCountMap.set(
      distances[i],
      sectionToCountMap.get(distances[i]) + 1
    );
  }

  let bestSection;
  for (let [key, value] of sectionToCountMap) {
    if (!bestSection || value > sectionToCountMap.get(bestSection)) {
      bestSection = key;
    }
  }
  return bestSection.sectionAssembly;
}

function byDistance(a, b) {
  return a.distance - b.distance;
}

export default {
  zoomPanToClosestSection,
  zoomPanToSection,
  getEnclosingCoords,
  zoomPanToArea
};
