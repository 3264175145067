import { observable } from "mobx";

let ErrorStore = observable({
  hasErrorOccurred: false,
  isNetworkError: false,
  retryAsyncOpMap: observable(new Map())
});

ErrorStore.isRetrying = () => {
  for (let value of ErrorStore.retryAsyncOpMap.values()) {
    if (value.retrying) return true;
  }
  return false;
};

ErrorStore.retryLastAction = () => {
  console.log("Retrying:" + ErrorStore.retryAsyncOpMap.size);
  ErrorStore.hasErrorOccurred = false;
  ErrorStore.isNetworkError = false;
  if (ErrorStore.retryAsyncOpMap.size > 0) {
    ErrorStore.retryAsyncOpMap.forEach(value => {
      value.retrying = true;
    });
    setTimeout(() => {
      ErrorStore.retryAsyncOpMap.forEach((value, retryAsyncOpStore) => {
        console.log("Starting retry");
        retryAsyncOpStore.execute(value.payload, value.callback);

        let interval = setInterval(() => {
          console.log("Checking retry");
          if (retryAsyncOpStore.executed) {
            console.log("The retry is over.....");

            ErrorStore.retryAsyncOpMap.delete(retryAsyncOpStore);

            clearInterval(interval);
          }
        }, 300);
      });
    }, 800);
  }
};

export default ErrorStore;
