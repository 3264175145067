export default function getRefCallBack(
  SVGStore,
  textDimensions,
  setTextDimensions,
  textRef
) {
  if (!textRef) {
    console.log("WARN: Text Ref is missing");
    debugger;
  }
  return element => {
    if (element) {
      textRef.current = element;

      let rectWidth =
        textRef.current.getBoundingClientRect().width /
        (typeof SVGStore.value.a !== "undefined" ? SVGStore.value.a : 1);
      let rectHeight =
        textRef.current.getBoundingClientRect().height /
        (typeof SVGStore.value.a !== "undefined" ? SVGStore.value.a : 1);

      let hDelta = Math.abs(textDimensions.h - rectHeight);
      let wDelta = Math.abs(textDimensions.w - rectWidth);

      // Hack.
      // Something keeps twitching when the dimensions are set.
      // It causes an infinite loop. This 'if' causes the dimensions to be set only if
      // the change is big enough.
      if (hDelta > 2 || wDelta > 2) {
        setTextDimensions({ w: rectWidth, h: rectHeight });

        // if (assembly.climbingRoute.id == 345) {
        //   console.log(
        //     "12345 - In the refCallBack of the SVGClimbingRoute with zoomRatio" +
        //       zoomRatio +
        //       "...Width=" +
        //       rectWidth +
        //       "...height=" +
        //       rectHeight
        //   );
        // }
      } else {
        // console.log(
        //   "12345 - The dimensions for 345 are too small to change:" +
        //     rectHeight +
        //     "..." +
        //     dimensions.h +
        //     "..." +
        //     hDelta
        // );
      }
    }
  };
}
