import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import { observer } from "mobx-react";

// core components
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import OptionButton from "../OptionButton/OptionButton";

function Selectors(props) {
  const {
    selectorStore,
    selectorStoreVariableName,
    filterAnalysis,
    fieldName,
    displayName,
    onSelection,
    onClear
  } = props;

  let clear = () => {
    console.log("clearing the selection");
    selectorStore[selectorStoreVariableName] = null;
    if (onClear) {
      console.log("clearing the selection and calling the onClear fn");
      onClear();
    }
  };

  let selectedId = selectorStore[selectorStoreVariableName]
    ? selectorStore[selectorStoreVariableName].item.id
    : null;

  let retVal;
  if (filterAnalysis.unfilteredCount === 0) {
    retVal = "All the " + displayName + " have been filtered";
  } else {
    console.log("NAME=" + displayName + " WRAPPERS ARRAY=" + JSON.stringify(filterAnalysis.wrappers));
    retVal = (
      <>
        {filterAnalysis.wrappers.map(wrapper => {
          let onClick = () => {
            console.log("clicking the selection");
            selectorStore.setWrapper(selectorStoreVariableName, wrapper);
            if (onSelection) {
              onSelection();
            }
          };

          if (wrapper.item.id === selectedId) {
            onClick = clear;
          }

          let badgeText = wrapper.visibleClimbsCount + "";
          if (wrapper.visibleClimbsCount === 0) {
            badgeText = undefined;
          }

          return (
            <OptionButton
              key={wrapper.item.id}
              onClick={onClick}
              label={wrapper.item[fieldName]}
              badgeText={badgeText}
              isSelected={wrapper.item.id === selectedId}
              showCross={true}
            />
          );
        })}
        {filterAnalysis.filteredCount !== 0 && (
          <div style={{ color: "grey" }}>
            {filterAnalysis.filteredCount + " " + displayName} are filtered.
          </div>
        )}
      </>
    );
  }
  return (
    <GridContainer
      justify="center"
      alignItems="center"
      style={{ textAlign: "center" }}
    >
      <GridItem>{retVal}</GridItem>
    </GridContainer>
  );
}

Selectors.propTypes = {
  selectorStore: PropTypes.object.isRequired,
  selectorStoreVariableName: PropTypes.string.isRequired,
  filterAnalysis: PropTypes.object.isRequired,
  color: PropTypes.string,
  fieldName: PropTypes.string,
  displayName: PropTypes.string,
  onSelection: PropTypes.func,
  onClear: PropTypes.func
};

export default observer(Selectors);
