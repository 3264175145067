//import isString from "lodash/isString";
import { observable, action } from "mobx";

//import alert from "app/components/alert";

/*function createHttpError(payload = {}) {
  const { response = {} } = payload;
  function name() {
    if (isString(response)) {
      return response;
    }
    return response.statusText;
  }
  function message() {
    const { data } = response;
    if (isString(data)) {
      return data;
    } else if (data && isString(data.message)) {
      return data.message;
    } else if (payload.message) {
      return payload.message;
    }
  }
  const errorOut = {
    name: name(),
    code: response.status,
    message: message()
  };
  return errorOut;
}*/

export default function asyncOp(httpCodeHandlerFactory) {
  return function create(apiFn, fnName, errorHandler) {
    if (!fnName) {
      debugger;
    }

    if (!errorHandler) {
      errorHandler = httpCodeHandlerFactory.getDefault();
    }

    const store = observable({
      executing: false,
      executed: false,
      data: null,
      error: null,
      promise: null,
      status: null,
      payload: null,

      getFnName: function() {
        return fnName;
      },
      clearData: action(function() {
        store.data = null;
      }),
      execute: action(async function(payload, callback, executeErrorHandler) {
        try {
          store.executed = false;
          store.executing = true;
          store.error = null;
          store.status = null;

          console.log(
            "The asyncOp payload is..." +
              JSON.stringify(payload) +
              " for " +
              fnName
          );
          // console.log(
          //   "The asyncOp callback is..." +
          //     JSON.stringify(callback) +
          //     " for " +
          //     fnName
          // );
          store.promise = apiFn.apply(null, [payload]);
          store.data = await store.promise;
          // console.log("Finished awaiting the async Op for " + fnName);
          if (callback) {
            // console.log("About to call the callback for:" + fnName);
            try {
              callback(store.data);
            } catch (error) {
              console.error(
                "Failed to complete the callback in the async op for function:" +
                  fnName
              );
              console.error(error);
              throw error;
            }
          }
          store.executed = true;
          store.executing = false;

          return store.data;
        } catch (error) {
          console.log(
            "The async op has failed. This is inside the try-catch block"
          );
          if (error.response && error.response.status) {
            store.status = error.response.status;
          }
          if (executeErrorHandler) {
            executeErrorHandler.handle(error, fnName, store, payload, callback);
          } else {
            errorHandler.handle(error, fnName, store, payload, callback);
          }
        } finally {
          store.executing = false;
        }
      })
    });
    return store;
  };
}
