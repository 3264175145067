import { observable, action } from "mobx";
import { gradeTypes } from "../variables/constants";

export default class GymWizardStores {
  constructor(context, hist, gymStores, gradeStores) {
    this.hist = hist;

    this.gradeSystems = {
      NO_BOULDERS: {
        name: "No Boulders",
        label: "We do not have any boulders"
      },
      NO_ROUTES: {
        name: "No Routes",
        label: "We do not have any routes"
      },
      EWBANK: {
        name: "Ewbank",
        label: "The Ewbank system e.g. 17 and 22",
        gradeLabels: [
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30"
        ]
      },
      V: {
        name: "V",
        label: "The V system e.g. V1 and V2",
        gradeLabels: [
          "VB",
          "V0",
          "V1",
          "V2",
          "V3",
          "V4",
          "V5",
          "V6",
          "V7",
          "V8",
          "V9",
          "V10",
          "V11",
          "V12",
          "V13",
          "V14",
          "V15"
        ]
      },
      FONT: {
        name: "Font",
        label: "The Font system e.g. 6A+ or 8A",
        gradeLabels: [
          "1",
          "2",
          "3",
          "4",
          "4+",
          "5",
          "5+",
          "6A",
          "6A+",
          "6B",
          "6B+",
          "6C",
          "6C+",
          "7A",
          "7A+",
          "7B",
          "7B+",
          "7C",
          "7C+",
          "8A",
          "8A+",
          "8B",
          "8B+",
          "8C",
          "8C+"
        ]
      },

      CUSTOM: {
        name: "Custom",
        label: "Custom System e.g. a 'Yellow' circuit",
        gradeLabels: [
          "Edit these to make your own labels",
          "Add your grades here"
        ]
      }
    };

    this.getSystemWithGrades = function(system, gradeType) {
      let newSystem = Object.assign({}, system);

      if (system.gradeLabels) {
        newSystem.grades = system.gradeLabels.map(label => {
          return {
            primaryLabel: label,
            isArchived: false,
            gradeType: gradeType
          };
        });
      }
      return newSystem;
    };

    this.setGradeSystem = action((store, system, grades) => {
      store.gradeSystem = system;
      if (system === null) {
        store.hasClimbs = false;
      } else {
        grades.replace(system.grades ? system.grades.slice() : []);
        store.hasClimbs = true;
      }
    });

    this.nameStore = observable({
      name: "unset"
    });
    this.boulderWizardStore = observable({
      gradeSystem: null,
      hasClimbs: true,
      markerType: null
    });
    this.routeWizardStore = observable({
      gradeSystem: null,
      hasClimbs: true,
      markerType: null
    });

    this.finishStore = observable({
      /*        opSave: asyncOpCreate(() => {
          return rest.post("gyms", payload);
        }), */
      saveAll: action(async () => {
        try {
          this.finishStore.savingIndicator = true;
          await gymStores.gymStore.createGym(this.nameStore.name);

          gradeStores.gradeStores[gradeTypes.ROUTE]
            .getAllItemsEvenArchived()
            .forEach((element, index) => {
              element.orderPosition = index;
            });

          gradeStores.gradeStores[gradeTypes.BOULDER]
            .getAllItemsEvenArchived()
            .forEach((element, index) => {
              element.orderPosition = index;
            });

          let promises = [];
          promises.push(gradeStores.gradeStores[gradeTypes.ROUTE].putItems());
          promises.push(gradeStores.gradeStores[gradeTypes.BOULDER].putItems());
          await Promise.all(promises);

          hist.push("/admin/settings/climbingWalls");
        } catch (theError) {
          console.log("error saving all the Gym Data:" + theError);
          console.log(
            "An error happened one time and the error was empty. I found this by deleting the try/catch. There error turned out to be no such function."
          );
          debugger;
          throw theError;
        } finally {
          this.finishStore.savingIndicator = false;
        }
      }),
      savingIndicator: false
    });
  }
}
