import { coreStyle } from "ticker-core";

import { buttonStyle } from "ticker-components";
const { getSlenderContainer, highTop } = coreStyle;

const leaderboardPageStyle = theme => ({
  ...buttonStyle,
  highTop,
  slenderContainer: getSlenderContainer(theme),
  right: {
    textAlign: "right"
  },
  rightHeader: {
    textAlign: "right",
    padding: "30px !important"
  },
  userName: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "7em"
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "5em"
    }
  },
  rank: {
    padding: "0 0 0 5px !important",
    margin: "0 !important",
    minWidth: "2em"
  },
  emptyMessage: {
    textAlign: "center",
    paddingTop: "2em"
  },
  message: {
    textAlign: "center"
  },
  scoreHeader: {
    paddingLeft: "40px",
    paddingRight: "40px"
  }
});

export default leaderboardPageStyle;
