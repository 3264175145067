// html,
// body,
// #root {
//   height: 100%;
//   margin: 0
// }

export default {
  box: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
    minHeight: "calc(100vh)"
  },

  // The 70px comes from the header minHeight of 50px plus the padding of 10px * 2
  boxWithHeader: {
    minHeight: "calc(100vh - 70px)"
  },

  boxRowHeader: {
    flex: "0 1 auto"
    /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
  },

  boxRowContent: {
    flex: "1 1 auto",
    overflow: "auto",

    // Setting the z-index to zero here stops a problem where the
    // popper menu for "settings" is displayed underneath the filter panel for the SVGMap
    zIndex: 0
  }
};
