import { observable } from "mobx";

export default class PersonalReportedIssuesStores {
  constructor(context, asyncOpCreate, overwriteIdentificationFieldsFn) {
    this.createIssueStore = observable({
      description: null
    });

    this.createIssueStore.climbing_route_id = null;

    this.createIssueStore.opCreateIssue = asyncOpCreate(
      payload => context.rest.post("reportedIssues", payload),
      "createReportedIssue"
    );

    this.createIssueStore.createIssue = async function() {
      if (!this.description || !this.description.length > 5) {
        console.error("The description isn't present or isn't long enough");
        return;
      }
      let payload = {
        description: this.description,
        climbing_route_id: this.climbing_route_id
      };

      overwriteIdentificationFieldsFn(payload, { excludeGym: true });
      await this.opCreateIssue.execute(payload);
    }.bind(this.createIssueStore);
  }
}
