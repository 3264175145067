import React from "react";
import { withRootStore } from "ticker-core";
import SweetAlert from "react-bootstrap-sweetalert";

import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";

import { makeStyles } from "@material-ui/core/styles";

import { useObserver } from "mobx-react";
//let { buttonStyle } = tickerComponents;

let useStyles = makeStyles(buttonStyle);

export default withRootStore(function NetworkError({ rootStore }) {
  let classes = useStyles();

  return useObserver(() => {
    let { errorStore } = rootStore;
    let isRetrying = errorStore.isRetrying();

    if (!errorStore.hasErrorOccurred && !isRetrying) {
      return null;
    }
    return (
      <>
        <SweetAlert
          // style={{ display: "block", marginTop: "-100px", position: "fixed" }}
          title="Are you connected to the internet?"
          onConfirm={() => errorStore.retryLastAction()}
          onCancel={() => window.location.reload()}
          confirmBtnText="Retry"
          cancelBtnText="Reload"
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          showCancel
          openAnim={
            isRetrying ? false : { name: "showSweetAlert", duration: 300 }
          }
        >
          <div style={{ minHeight: "4em" }}>
            {errorStore.hasErrorOccurred
              ? "Please check your connection and try again, or reload the page."
              : "Retrying"}
          </div>
        </SweetAlert>
      </>
    );
  });
});
