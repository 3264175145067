import React, { memo } from "react";

import { PropTypes } from "prop-types";

let arrowScale = 0.6;

// M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z
let singleUpArrowPath = [
  "M",
  12,
  8,
  "l",
  -6 * arrowScale,
  6 * arrowScale,
  "l",
  1.41 * arrowScale,
  1.41 * arrowScale,
  "l",
  4.59 * arrowScale,
  -4.58 * arrowScale,
  "l",
  4.59 * arrowScale,
  4.58 * arrowScale,
  "l",
  1.41 * arrowScale,
  -1.41 * arrowScale,
  "z"
];

console.log("arrowpath=" + singleUpArrowPath.join(" "));
let doubleArrowUpPath1of2 = singleUpArrowPath.slice();
let doubleArrowUpPath2of2 = singleUpArrowPath.slice();

let singleDownArrowPath = [
  "M",
  12,
  8,
  "l",
  -6 * arrowScale,
  -6 * arrowScale,
  "l",
  1.41 * arrowScale,
  -1.41 * arrowScale,
  "l",
  4.59 * arrowScale,
  4.58 * arrowScale,
  "l",
  4.59 * arrowScale,
  -4.58 * arrowScale,
  "l",
  1.41 * arrowScale,
  1.41 * arrowScale,
  "z"
];

let doubleArrowDownPath1of2 = singleDownArrowPath.slice();
let doubleArrowDownPath2of2 = singleDownArrowPath.slice();
console.log("slicing the paths");

function Arrows(props) {
  let {
    labelCornerX,
    labelCornerY,
    paddedWidth,
    paddedHeight,
    detailLevel,
    relativeAvgCombinedModifier
  } = props;

  let roundedCorners = 3;

  let circleXCenter = labelCornerX + paddedWidth - roundedCorners / 2 + 1.3;

  singleDownArrowPath[1] = circleXCenter;
  doubleArrowDownPath1of2[1] = circleXCenter;
  doubleArrowDownPath2of2[1] = circleXCenter;

  singleDownArrowPath[2] = labelCornerY + paddedHeight + 1;
  doubleArrowDownPath1of2[2] = labelCornerY + paddedHeight + 3;
  doubleArrowDownPath2of2[2] = labelCornerY + paddedHeight + 0.3;

  singleUpArrowPath[1] = circleXCenter;
  doubleArrowUpPath1of2[1] = circleXCenter;
  doubleArrowUpPath2of2[1] = circleXCenter;

  singleUpArrowPath[2] = labelCornerY + paddedHeight - roundedCorners - 0.1;
  doubleArrowUpPath1of2[2] = labelCornerY + paddedHeight - roundedCorners - 2.2;
  doubleArrowUpPath2of2[2] = labelCornerY + paddedHeight - roundedCorners + 0.3;

  if (detailLevel >= 3) {
    let gradeModifierComponent;
    if (relativeAvgCombinedModifier) {
      if (relativeAvgCombinedModifier > 2) {
        if (relativeAvgCombinedModifier > 5) {
          gradeModifierComponent = (
            <>
              <path d={doubleArrowUpPath1of2.join(" ")} fill="white" />
              <path d={doubleArrowUpPath2of2.join(" ")} fill="white" />
            </>
          );
        } else {
          gradeModifierComponent = (
            <path d={singleUpArrowPath.join(" ")} fill="white" />
          );
        }
      } else if (relativeAvgCombinedModifier < -2) {
        if (relativeAvgCombinedModifier < -5) {
          gradeModifierComponent = (
            <>
              <path d={doubleArrowDownPath1of2.join(" ")} fill="white" />
              <path d={doubleArrowDownPath2of2.join(" ")} fill="white" />
            </>
          );
        } else {
          gradeModifierComponent = (
            <path d={singleDownArrowPath.join(" ")} fill="white" />
          );
        }
      }
    }
    if (gradeModifierComponent) {
      return (
        <>
          <circle
            cx={circleXCenter}
            cy={labelCornerY + paddedHeight - roundedCorners / 2 + 0.35}
            r="5"
            stroke="white"
            strokeWidth="1.5"
            fill="green"
            vectorEffect="non-scaling-stroke"
          />
          {gradeModifierComponent}
        </>
      );
    } else {
      return null;
    }
  }
  return null;
}
Arrows.propTypes = {
  detailLevel: PropTypes.number,
  labelCornerX: PropTypes.number.isRequired,
  labelCornerY: PropTypes.number.isRequired,
  paddedWidth: PropTypes.number.isRequired,
  paddedHeight: PropTypes.number.isRequired,
  relativeAvgCombinedModifier: PropTypes.number.isRequired
};

export default memo(Arrows);
