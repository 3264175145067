import getCachable from "../utils/getCachable";

export default class ReportedIssuesStores {
  constructor(context, asyncOpCreate, getCurrentGymId) {
    const rest = context.rest;

    this.fetchIssuesStore = {
      climbing_route_id: null,
      opGetIssues: asyncOpCreate(
        payload => rest.get("reportedIssues", payload),
        "getReportedIssues"
      ),

      getIssues: async function() {
        let payload = { gym_id: getCurrentGymId() };
        if (this.climbing_route_id) {
          // if (!this.climbing_route_id) {
          //   console.error("The climbing_route_id is:" + this.climbing_route_id);
          //   return;
          // }

          payload.climbing_route_id = this.climbing_route_id;
        }
        await this.opGetIssues.execute(payload);
      }
    };

    this.cachedIssuesStore = getCachable(
      asyncOpCreate,
      context.rest,
      (a, b) => a.id === b.id,
      "reportedIssues",
      null,
      () => ({
        isArchived: false,
        gym_id: getCurrentGymId()
      }),
      null
    );

    this.updateIssuesStore = {
      issueId: null,
      isArchived: null,
      opUpdateIssue: asyncOpCreate(payload => {
        let paramId = payload.issueId;
        payload.issueId = undefined;
        return rest.put("reportedIssues/" + paramId, payload);
      }, "updateReportedIssues")
    };

    this.updateIssuesStore.updateIssue = async function() {
      if (!this.issueId || typeof this.isArchived !== "boolean") {
        console.error(
          "Failed to updateIssue. The issueid is:" +
            this.issueId +
            " and the isArchived is:" +
            this.isArchived
        );
        return;
      }
      let payload = {
        issueId: this.issueId,
        isArchived: this.isArchived
      };
      this.opUpdateIssue.execute(payload);
    }.bind(this.updateIssuesStore);
  }
}
