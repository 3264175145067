import React, { useState, useRef } from "react";

import PropTypes from "prop-types";

import Popper from "@material-ui/core/Popper";

import styles from "../../assets/jss/material-dashboard-pro-react/components/mainNavbarStyle.js";
import { makeStyles } from "@material-ui/core/styles";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import MenuList from "@material-ui/core/MenuList";

import { NavLink } from "react-router-dom";

let useStyles = makeStyles(styles);

function MainNavBarDropDown({ route, rootStore }) {
  let classes = useStyles();

  let { regStores, history } = rootStore;

  const { regStore } = regStores;
  let [isDroppedDown, setIsDroppedDown] = useState(false);

  let buttonRef = useRef();
  let handleClose = () => {
    setIsDroppedDown(!isDroppedDown);
    return false;
  };

  let rename = route.name;
  let smallName = route.smallName ? route.smallName : route.name;

  if (route.useUserName) {
    rename = regStore.userName;
    smallName = regStore.userName;
  }

  return (
    <>
      <NavLink
        to="#"
        onClick={e => {
          handleClose();
          e.preventDefault();
        }}
        className={classes.navLink}
        ref={buttonRef}
      >
        <Hidden smDown>
          {route.icon && <route.icon className={classes.listItemIcon} />}
          <div className={classes.listItemText}>{rename}</div>
        </Hidden>
        <Hidden mdUp>
          {route.icon && (
            <route.icon
              className={classes.listItemIcon + " " + classes.centerIcon}
            />
          )}
          <span className={classes.smallName}>{smallName}</span>
        </Hidden>
      </NavLink>
      <Popper
        open={isDroppedDown}
        anchorEl={buttonRef.current}
        transition
        placement="bottom"
        disablePortal
        // className={classNames({
        //   [classes.popperClose]: !openProfile,
        //   [classes.popperResponsive]: true,
        //   [classes.popperNav]: true
        // })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="profile-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList role="menu">
                  {route.views.map(subRoute => {
                    console.log(
                      "Creating NavLink with key:" +
                        subRoute.layout +
                        subRoute.path
                    );
                    if (subRoute.hideInNavBar) return null;
                    if (
                      subRoute.showToLoggedInRegistrationsOnly &&
                      !regStores.isRegistered()
                    )
                      return null;
                    if (
                      subRoute.showToLoggedOutRegistrationsOnly &&
                      regStores.isRegistered()
                    )
                      return null;

                    return (
                      <MenuItem
                        key={subRoute.layout + subRoute.path}
                        onClick={() => {
                          handleClose();
                          history.push(subRoute.layout + subRoute.path);
                        }}
                        className={classes.navLink}
                      >
                        {subRoute.icon && (
                          <subRoute.icon className={classes.listItemIcon} />
                        )}
                        {subRoute.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
MainNavBarDropDown.propTypes = {
  route: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};
export default MainNavBarDropDown;
