import { coreStyle } from "ticker-core";

const { hexToRgb, whiteColor } = coreStyle;

const cardBodyStyle = theme => ({
  cardBody: {
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardBodyBackground: {
    position: "relative",
    zIndex: "2",
    minHeight: "280px",
    paddingTop: "40px",
    paddingBottom: "40px",
    maxWidth: "440px",
    margin: "0 auto"
  },
  cardBodyPlain: {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  cardBodyFormHorizontal: {
    paddingLeft: "15px",
    paddingRight: "15px",
    "& form": {
      margin: "0"
    }
  },
  cardPricing: {
    padding: "15px!important",
    margin: "0px!important"
  },
  cardRoute: {
    padding: "15px 15px 0 15px !important",
    margin: "0px!important",
    minWidth: "267px" // This number 267 is just "about right" and is just to make it look nicer. Brook 04/02/20
  },
  cardSignup: {
    padding: "0px 30px 0px 30px"
  },
  cardBodyColor: {
    borderRadius: "6px",
    "& h1,& h2,& h3": {
      "& small": {
        color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)"
      }
    }
  },
  cardBodyProfile: {
    marginTop: "15px"
  },
  cardBodyCalendar: {
    padding: "0px !important"
  },
  cardBodyNarrowWhite: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px !important",
      paddingRight: "0px !important"
    }
  },
  cardBodyNarrowTransparent: {
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
      boxShadow: "none",
      paddingLeft: "0px",
      paddingRight: "0px"
    }
  }
});

export default cardBodyStyle;
