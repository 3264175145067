import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components

import { withRootStore } from "ticker-core";
import { RegistrationUserNamePasscode } from "ticker-components";

function RegistrationRegisterPage({
  rootStore: {
    regStores: { regStore }
  }
}) {
  return (
    <RegistrationUserNamePasscode
      onComplete={regStore.registerRegistration}
      message="Registering"
      redirectPage="/main/height"
    />
  );
}

RegistrationRegisterPage.propTypes = {
  rootStore: PropTypes.object
};

export default withRootStore(RegistrationRegisterPage);
