import React, { useEffect } from "react";
import { PropTypes, useObserver } from "mobx-react";
import Datetime from "react-datetime";
import moment from "moment";

import { withCachedRootStore, constants } from "ticker-core";

import {
  Table,
  Button,
  Card,
  CardBody,
  GridContainer,
  GridItem,
  OptionButton,
  tickListStyle
} from "ticker-components";

import Hidden from "@material-ui/core/Hidden";
import Close from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

let dateFormat = "YYYY-MM-DD";

let { gradeTypes } = constants;

let timeFormat = "k:mm";

const useListStyles = makeStyles(tickListStyle);

function ListTable({ rootStore }) {
  const classes = useListStyles();

  const {
    personalRootStore: {
      personalLogEntryStores: { logEntriesList, logEntryStore }
    }
  } = rootStore;

  useEffect(() => {
    //logEntriesList.parameters.endDateStr = "2020-01-01";
    //logEntriesList.propTypes.gradeType = gradeTypes.BOULDER;
    logEntriesList.getLogEntriesList(0, 100);
  }, [
    logEntriesList,
    logEntriesList.parameters.endDateStr,
    logEntriesList.parameters.startDateStr,
    logEntriesList.parameters.sortColumn,
    logEntriesList.parameters.gradeType
  ]);

  return useObserver(() => {
    const tableData = logEntriesList.reportData.logEntries.map(entry => {
      let cr = entry.ClimbingRoute;
      let dateStr = moment(entry.dateClimbedUTC).format(dateFormat);
      let timeStr = moment(entry.dateClimbedUTC).format(timeFormat);
      return [
        <>
          {dateStr}
          <br />
          {timeStr}
        </>,
        cr.ClimbingGrade.primaryLabel,
        cr.ClimbingWall.ClimbingSection.name,
        cr.ClimbingWall.name,
        cr.MarkerColor.name,
        <>
          {moment(entry.created_at).format("YYYY-MM-DD")}
          <br />
          {moment(entry.created_at).format("HH:mm:ss")}
        </>,
        // eslint-disable-next-line react/jsx-key
        <Button
          color="danger"
          className={classes.actionButton}
          onClick={() => {
            logEntryStore.deleteLogEntry(
              entry,
              logEntriesList.reportData.logEntries
            );
          }}
        >
          <Close className={classes.icon} />
        </Button>
      ];
    });

    return (
      <div style={{ minHeight: "30em" }}>
        <GridContainer justify="center" alignItems="center">
          <GridItem>
            <OptionButton
              onClick={() => {
                if (
                  logEntriesList.parameters.gradeType === gradeTypes.BOULDER
                ) {
                  logEntriesList.parameters.gradeType = undefined;
                } else {
                  logEntriesList.parameters.gradeType = gradeTypes.BOULDER;
                }
              }}
              label={"Boulders"}
              isSelected={
                logEntriesList.parameters.gradeType === gradeTypes.BOULDER
              }
              showCross={true}
            />
          </GridItem>
          <GridItem>
            <OptionButton
              onClick={() => {
                if (logEntriesList.parameters.gradeType === gradeTypes.ROUTE) {
                  logEntriesList.parameters.gradeType = undefined;
                } else {
                  logEntriesList.parameters.gradeType = gradeTypes.ROUTE;
                }
              }}
              label={"Routes"}
              isSelected={
                logEntriesList.parameters.gradeType === gradeTypes.ROUTE
              }
              showCross={true}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" alignItems="center">
          <GridItem>
            <InputLabel className={classes.label}>Show log from </InputLabel>
          </GridItem>
          <GridItem>
            <FormControl>
              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "...Select Date", readOnly: true }}
                defaultValue={logEntriesList.parameters.startDateStr}
                closeOnSelect={true}
                dateFormat={dateFormat}
                onChange={date => {
                  if (typeof date === "string") {
                    alert("Wrong Date Type");
                    return;
                  }
                  logEntriesList.parameters.startDateStr = date.format(
                    dateFormat
                  );
                }}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" alignItems="center">
          <GridItem>
            <InputLabel className={classes.label}>Show log up to </InputLabel>
          </GridItem>
          <GridItem>
            <FormControl>
              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "...Select Date", readOnly: true }}
                defaultValue={logEntriesList.parameters.endDateStr}
                closeOnSelect={true}
                dateFormat={dateFormat}
                onChange={date => {
                  if (typeof date === "string") {
                    alert("Wrong Date Type");
                    return;
                  }
                  logEntriesList.parameters.endDateStr = date.format(
                    dateFormat
                  );
                }}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" alignItems="center">
          <GridItem>
            <InputLabel className={classes.label}>Order by </InputLabel>
          </GridItem>
          <GridItem>
            <OptionButton
              label="Date Climbed"
              showCross={false}
              isSelected={logEntriesList.parameters.sortColumn === "climbed"}
              onClick={() => {
                logEntriesList.parameters.sortColumn = "climbed";
              }}
            />
          </GridItem>
          <GridItem>
            <OptionButton
              label="Date Created"
              showCross={false}
              isSelected={logEntriesList.parameters.sortColumn === "created"}
              onClick={() => {
                logEntriesList.parameters.sortColumn = "created";
              }}
            />
          </GridItem>
        </GridContainer>
        {tableData.length === 0 ? (
          <h4 style={{ paddingTop: "2em", textAlign: "center" }}>
            {moment(logEntriesList.parameters.endDateStr).isBefore(
              logEntriesList.parameters.startDateStr
            )
              ? "The start date must be before the end date."
              : "There are no climbs completed with the current filters."}
          </h4>
        ) : (
          <>
            <div>
              You&apos;ve climbed {logEntriesList.reportData.logEntries.length}{" "}
              in this period
            </div>
            <Hidden smDown>
              <Table
                tableHead={[
                  "Date Climbed",
                  "Grade",
                  "Section",
                  "Wall",
                  "Hold Color",
                  "Date Created",
                  ""
                ]}
                tableData={tableData}
                customCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right
                ]}
                customClassesForCells={[0, 5, 6]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 5, 6]}
              />
            </Hidden>
            <Hidden mdUp>
              {logEntriesList.reportData.logEntries.map(entry => {
                let cr = entry.ClimbingRoute;

                let dateStr = moment(entry.dateClimbedUTC).format(dateFormat);
                let timeStr = moment(entry.dateClimbedUTC).format(timeFormat);
                return (
                  <Card pricing plain key={entry.id}>
                    <CardBody pricing plain>
                      <h4 className={classes.cardTitle}>
                        {dateStr + " " + timeStr}
                      </h4>
                      <h5 className={classes.cardTitle}>
                        Grade {cr.ClimbingGrade.primaryLabel}
                      </h5>
                      <ul>
                        <li>{cr.ClimbingWall.ClimbingSection.name}</li>
                        <li>{cr.ClimbingWall.name}</li>
                        <li>
                          <span className={classes.label}>Hold Color</span>{" "}
                          {cr.MarkerColor.name}
                        </li>
                        {cr.description && <li>{cr.description}</li>}
                        <li>
                          <span className={classes.label}>Created</span>{" "}
                          {moment(entry.created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </li>
                      </ul>
                      <Button
                        color="danger"
                        className={classes.actionButton}
                        onClick={async () => {
                          await logEntryStore.deleteLogEntry(
                            entry,
                            logEntriesList.reportData.logEntries
                          );
                          logEntriesList.getLogEntriesList(0, 100);
                        }}
                      >
                        <Close className={classes.icon} />
                      </Button>
                    </CardBody>
                  </Card>
                );
              })}
            </Hidden>
          </>
        )}
      </div>
    );
  });
}

ListTable.propTypes = {
  classes: PropTypes.object,
  rootStore: PropTypes.object
};

export default withCachedRootStore(ListTable);
