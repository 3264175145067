let idList = [];
let currentId = "";

class DialogController {
  addId(id) {
    idList.push(id);
    currentId = id;
  }

  isCurrent(newId) {
    return currentId === newId;
  }

  removeId() {
    idList.pop();
    currentId = idList[idList.length - 1];
  }

  getIdList() {
    return idList;
  }

  current() {
    return currentId;
  }
}

export default new DialogController();
