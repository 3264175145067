import React, { useState, useRef, useEffect } from "react";
import style from "../../assets/jss/material-dashboard-pro-react/components/SVG.js";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

let useStyles = makeStyles(style);

function MaxSizeFrame(props) {
  let classes = useStyles();
  console.log("12345 - Rendering the Max Frame Size");
  let [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  // let [refCallBackCount, setRefCallBackCount] = useState(0);

  let svgContainerRef = useRef(null);

  let handleResize = () => {
    console.log("RouteMapLevel2 Handle the resize" + svgContainerRef.current);
    if (svgContainerRef.current) {
      setDimensionsFromElement(svgContainerRef.current);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  let refCallBack = element => {
    // console.log("12345 - yyyaaxxxThe refCalledBack count=" + refCallBackCount);
    // if (refCallBackCount > 10) {
    //   console.log("The callback has been called to many times");
    //   return;
    // }
    if (element) {
      svgContainerRef.current = element;

      setDimensionsFromElement(element);
    }
  };

  let setDimensionsFromElement = element => {
    console.log("Setting dimensions from element - resiz");
    let newHeight = element.getBoundingClientRect().height;

    // HACK - There is a problem with one of the DOM nodes above this one being 10px to big
    // hopefully this hack will stop that
    newHeight = newHeight - 10;

    let newWidth = element.getBoundingClientRect().width;

    // HACK
    // The SVG div, or some panel, seems to keep growing by about 2 points
    // So this hack makes sure that the growth (window resize) is more than 3 points
    // before recording the change in dimensions
    let hDelta = Math.abs(dimensions.height - newHeight);
    let wDelta = Math.abs(dimensions.width - newWidth);

    console.log(
      "RouteMapLevel2 The change is" + hDelta + " wDelta" + wDelta + "resize"
    );
    if (hDelta > 20 || wDelta > 20) {
      // setRefCallBackCount(refCallBackCount + 1);
      let newDimensions = {
        width: newWidth,
        height: newHeight
      };
      setDimensions(newDimensions);
      if (props.onDimensionsSet) {
        props.onDimensionsSet(newDimensions);
      }
    }
  };

  let className = classes.box;
  if (props.hasHeader) {
    className = className + " " + classes.boxWithHeader;
  }

  return (
    <div className={className}>
      {/* <div className="row header">
        <p>
          <b>header</b>
          <br />
          <br />
          (sized to content)
        </p>
      </div> */}
      <div
        id="maxFrameSize"
        className={classes.boxRowContent}
        ref={refCallBack}
      >
        {!(dimensions.width && dimensions.height > 100)
          ? undefined
          : props.children}
      </div>
      {/* <div className="row footer">
        <p>
          <b>footer</b> (fixed height)
        </p>
      </div> */}
    </div>
  );
}

MaxSizeFrame.propTypes = {
  onDimensionsSet: PropTypes.func,
  hasHeader: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
export default MaxSizeFrame;
