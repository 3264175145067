import { action } from "mobx";

import { ZoomLevels } from "ticker-core";

let LONGPRESS_DURATION = 350;
export default function getObjectHandlers(
  SVGStore,
  selectionStore,
  hasPointerMoved,
  setHasPointerMoved,
  positionableObject,
  longPressIsDisabled,
  lpTimeout,
  setStateLPTimeout,
  callableOnTouchEnd,
  callableOnTouchMove,
  onSelect
) {
  let resetAll = action(function() {
    selectionStore.wasLongPressed.set(positionableObject, false);

    clearLPTimeout();

    // This double up of the hasPointerMoved variable is to get around some problems
    // where the event functions have an old value of hasPointerMoved
    // because the route hasn't re-rendered yet but a different event
    // has been fired. The second event would use the original hasPointerMoved value
    // if we didn't reset it here.
    hasPointerMoved.value = false;
    // console.log("Changing the pointerHasMoved to false in reset all");
    // And we also need to keep the state between renders
    setHasPointerMoved(hasPointerMoved);

    SVGStore.disableMapTool = false;
    // console.log("Reseting all");
  });

  let onPointerUp = action(function(/*evt*/) {
    // console.log("yyyyyyyyyyyyyyyy pointer up");
    // setIdToMove(false);

    resetAll();

    //If a double tap timer has been started then
    //We can ignore this click because it is a double tap
    if (selectionStore.creatingDoubleClickTimeout) {
      // console.log("Clearing timeout");
      selectionStore.clearDoubleClickTimeout();
      return;
    }

    if (ZoomLevels.getDetailLevel(SVGStore.value.a) === 0) return;

    // console.log(
    //   "789: SVGClimbingRoute.onPointerUp: " +
    //     evt.target +
    //     "...type=" +
    //     evt.type +
    //     "...hasPointerMoved=" +
    //     hasPointerMoved.value +
    //     " ...wasLongPressed=" +
    //     selectionStore.wasLongPressed.get(positionableObject)
    // );
    if (
      !selectionStore.wasLongPressed.get(positionableObject) &&
      !hasPointerMoved.value
    ) {
      // console.log("yyyyyyyyyyyyyyyy insdie if");
      selectionStore.setDoubleClickTimeout(() => {
        // console.log("yyyyyyyyyyyyyyyy inside double click");
        // setFadeProps({ in: false, timeout: FADE_TIMEOUT });
        // console.log(
        //   "aaaa setting the currentSelectedId in onFinish() for event" +
        //     evt.type
        // );
        if (onSelect) {
          console.log("yyyyyyyyyyyyyyyy calling onSelect");
          onSelect(positionableObject);
        } else {
          // This might be a section label
          // debugger;
        }
      });
    }
  });

  let onMouseMove = action(function(evt) {
    //Only do something for this mouse move if
    // 1) There is some movement in the mouse
    // I'm getting an issue where this onMouseMove is being called from the
    // tablet. It occurs when I hold down the route to engage the long press
    // By putting in this check. The long press isn't cancelled by the weird
    // onMouseMove fired by the long hold tap.
    if (
      typeof evt.movementX !== "undefined" &&
      evt.movementX === 0 &&
      typeof evt.movementY !== "undefined" &&
      evt.movementY === 0
    ) {
      return;
    }

    if (!positionableObject) {
      debugger;
    }
    if (selectionStore.wasLongPressed.get(positionableObject)) {
      //do nothing because we are moving the object
      return;
    }

    // console.log("Changing the pointerHasMoved to true in onMouseMove");

    selectionStore.pointerMoved = true;
    hasPointerMoved.value = true;
    setHasPointerMoved(hasPointerMoved);

    // 2) If our mouse is moving over another route, but we aren't dragging this
    // route, then we don't want to do anything.
    if (selectionStore.currentSelected !== positionableObject) {
      return;
    }

    if (longPressIsDisabled) {
      return;
    }

    clearLPTimeout();
  });

  let clearLPTimeout = () => {
    if (lpTimeout.value) {
      console.log(
        "EVENT: SVGClimbingRoute.clearLPTimeout(). Clearing Timeout for long press because this is a move"
      );
      clearTimeout(lpTimeout.value);
      setStateLPTimeout({ value: null });
      lpTimeout.value = null;
    } else {
      console.log(
        "EVENT: SVGClimbingRoute.clearLPTimeout()... no timeout to clear though"
      );
    }
  };

  let onTouchMove = evt => {
    // console.log("Changing the pointerHasMoved to true in onTouchMove");
    selectionStore.pointerMoved = true;
    hasPointerMoved.value = true;
    setHasPointerMoved(hasPointerMoved);
    if (longPressIsDisabled) {
      return;
    }
    clearLPTimeout();
    // selectionStore.currentSelectedId = undefined;
    // selectionStore.currentSelected = undefined;

    if (callableOnTouchMove) {
      callableOnTouchMove(evt);
    }
  };

  let onPointerDown = action((evt, isMovingLineEnd) => {
    // if (ZoomLevels.getDetailLevel(SVGStore.value.a) === 0) {
    //   console.log("Bailing from the onPointerDown because the zoom level is 0");
    //   return;
    // }

    // console.log("OnPointerDown in getObjectHandlers.");

    if (
      // If The form is edited
      selectionStore.isFormEdited &&
      // And the user isn't selecting/dragging the same route as the form
      selectionStore.currentSelectedId !== positionableObject.id
    ) {
      // then we need to ask for approval to discard the changes in the form
      selectionStore.onDiscardApprovedFn = action(function() {
        selectionStore.currentSelectedId = positionableObject.id;
        selectionStore.currentSelected = positionableObject;
        // selectionStore.objectWasClicked = true;
        selectionStore.showSelected = false;

        if (positionableObject === selectionStore.newClimbingObject) {
          selectionStore.setDoubleClickTimeout(() => {
            selectionStore.creatingNewObject = true;
          });
        } else {
          selectionStore.creatingNewObject = false;
          // selectionStore.newObjectPositioned = false;
        }
        selectionStore.isFormEdited = false;
        if (onSelect) {
          onSelect(positionableObject);
        }
      });
      selectionStore.changeApprovalNeeded = true;
      return;
    }

    let onTouchEndCleaned = event => {
      onPointerUp(event);
      event.target.removeEventListener("touchmove", onTouchMove);
      callableOnTouchEnd(event);

      event.target.removeEventListener("touchend", onTouchEndCleaned);
    };

    evt.target.addEventListener("touchend", onTouchEndCleaned);
    evt.target.addEventListener("touchmove", onTouchMove);

    // console.log("Changing the pointerHasMoved to false in onPointerDown");

    selectionStore.pointerMoved = false;
    hasPointerMoved.value = false;
    setHasPointerMoved(hasPointerMoved);

    // props.onClimbingRouteSelected(climbingRoute);
    // setFadeProps({ in: false, timeout: FADE_TIMEOUT });
    // console.log(
    //   "aaaa setting the currentSelectedId in startLongPressTimer() for event " +
    //     evt.type
    // );

    selectionStore.currentSelectedId = positionableObject.id;
    selectionStore.currentSelected = positionableObject;
    selectionStore.objectWasClicked = true;
    selectionStore.showSelected = false;

    // If this is not a new climb then we have selected a different existing climb
    // so we must not be creating a new climb

    if (positionableObject === selectionStore.newClimbingObject) {
      selectionStore.setDoubleClickTimeout(() => {
        selectionStore.creatingNewObject = true;
      });
    } else {
      selectionStore.creatingNewObject = false;
      // selectionStore.newObjectPositioned = false;
    }

    // if (!longPressIsDisabled) {
    if (selectionStore.wasLongPressed.get(positionableObject)) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    // }
    if (
      !longPressIsDisabled &&
      ZoomLevels.getDetailLevel(SVGStore.value.a) > 0
    ) {
      lpTimeout.value = setTimeout(
        action(() => {
          if (!hasPointerMoved.value && !selectionStore.pointerMoved) {
            console.log(
              "zzzz EVENT: the longpress timer has run out.. therefore the pointer has not moved and WAS LONG PRESSED "
            );
            selectionStore.wasLongPressed.set(positionableObject, true);
            SVGStore.disableMapTool = true;
            selectionStore.isMovingLineEnd = isMovingLineEnd;
            selectionStore.currentDragObject = positionableObject;
          }
        }),
        LONGPRESS_DURATION
      );
      setStateLPTimeout(lpTimeout);

      // console.log(
      //   "zzzz EVENT: onPointerDown The timer has been set:" +
      //     JSON.stringify(lpTimeout)
      // );
    }
  });
  //   }

  // The zoomRatio is old at the time of render?

  return isMovingLineEnd => ({
    onMouseDown: evt => onPointerDown(evt, isMovingLineEnd),
    onTouchStart: evt => onPointerDown(evt, isMovingLineEnd),
    onTouchEnd: onPointerUp,
    //onTouchMove: onTouchMove,
    onMouseMove: onMouseMove,
    onMouseUp: onPointerUp
  });
}
