import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";

import ChartistGraph from "react-chartist";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// core components
import { OptionButton } from "ticker-components";

import { gradeTypes } from "ticker-core";

import moment from "moment";

import { withRootStore } from "ticker-core";

let dateFormat = "YYYY-MM-DD";

let typeButtons = [
  {
    label: "Routes",
    gradeType: gradeTypes.ROUTE
  },
  {
    label: "Boulders",
    gradeType: gradeTypes.BOULDER
  },
  {
    label: "ALL",
    gradeType: undefined
  }
];

let presetButtons = [
  {
    label: "Week",
    startDateFn: () =>
      moment()
        .subtract(6, "days")
        .format(dateFormat)
  },
  {
    label: "Two Weeks",
    startDateFn: () =>
      moment()
        .subtract(13, "days")
        .format(dateFormat)
  },
  {
    label: "30 Days",
    startDateFn: () =>
      moment()
        .subtract(30, "days")
        .format(dateFormat)
  },
  {
    label: "60 days",
    startDateFn: () =>
      moment()
        .subtract(60, "days")
        .format(dateFormat)
  }
];

var options = {
  low: 0,
  axisX: {}
};

const ByTime = function({ rootStore }) {
  const localStore = useLocalStore(() => ({
    buttonPreset: undefined,
    setButtonPreset(newButtonPreset) {
      this.buttonPreset = newButtonPreset;
      let {
        logEntriesByTimeReport
      } = rootStore.personalRootStore.personalLogEntryStores;
      let { parameters } = logEntriesByTimeReport;

      parameters.endDateStr = moment().format(dateFormat);
      parameters.startDateStr = localStore.buttonPreset.startDateFn();
      logEntriesByTimeReport.getLogEntriesByTime();
    }
  }));

  useEffect(() => {
    localStore.setButtonPreset(presetButtons[0]);
  }, [localStore]);

  let reportRef = React.createRef();

  const {
    logEntriesByTimeReport
  } = rootStore.personalRootStore.personalLogEntryStores;

  options.high = undefined;

  options.axisX.labelInterpolationFnc = function(value, index, labels) {
    // Always show the first one
    if (index === 0) return value;

    // If there are more than 35 labels (i.e. more than a month)
    // show the label "today" on the third to last label
    // it will line up better that way
    if (labels.length > 35 && index === labels.length - 3) {
      return "Today";

      // If there are less 35 but more than 20
      // Then show the label "today" in the second to last spot
    } else if (
      labels.length >= 20 &&
      labels.length <= 35 &&
      index === labels.length - 2
    ) {
      return "Today";

      // otherwise show the label "today" on the last position
    } else if (labels.length < 20) {
      if (index === labels.length - 1) return "Today";
    }

    let screenWidth = document.body.clientWidth;

    // default to 6 extra labels
    let numLabels = 6;

    //Small Screen
    if (screenWidth < 500) {
      numLabels = 1;
    }

    let gap = Math.ceil(labels.length / numLabels);

    console.log(
      "inter: " +
        index +
        " length:" +
        labels.length +
        " gap:" +
        gap +
        " value:" +
        value
    );

    // If this label might overlap with the label at the end of the graph
    // then don't display it
    if (index + gap > labels.length) {
      return null;
    }

    // Show every second label
    return index % gap === 0 ? value : null;
  };

  let foundHigherThan12 = false;
  if (
    logEntriesByTimeReport.reportData &&
    logEntriesByTimeReport.reportData.series &&
    logEntriesByTimeReport.reportData.series.length > 0
  ) {
    for (let point of logEntriesByTimeReport.reportData.series[0]) {
      if (point > 12) {
        foundHigherThan12 = true;
      }
    }

    if (!foundHigherThan12) {
      options.high = 12;
    }
  }

  console.log(
    "The report=" + JSON.stringify(logEntriesByTimeReport.reportData)
  );
  console.log("The options:" + JSON.stringify(options, null, 3));
  return useObserver(() => {
    // I need to access the observable 'reports' object to trigger a rerender
    // So I'm recreating the 'reports' object in this variable
    let mobxChartistData = {
      labels: logEntriesByTimeReport.reportData.labels,
      series: logEntriesByTimeReport.reportData.series
    };

    console.log("The chart data = " + JSON.stringify(mobxChartistData));

    let dateRangeButtons = (
      <div justify="center" alignItems="center" style={{ textAlign: "center" }}>
        {presetButtons.map(preset => (
          <OptionButton
            onClick={() => {
              localStore.setButtonPreset(preset);
            }}
            label={preset.label}
            isSelected={
              preset && localStore.buttonPreset
                ? preset.label === localStore.buttonPreset.label
                : false
            }
            key={preset.label}
          />
        ))}
      </div>
    );

    return (
      <>
        <div
          justify="center"
          alignItems="center"
          style={{ textAlign: "center" }}
        >
          {typeButtons.map(type => (
            <OptionButton
              onClick={() => {
                logEntriesByTimeReport.parameters.gradeType = type.gradeType;
                logEntriesByTimeReport.getLogEntriesByTime();
              }}
              label={type.label}
              isSelected={
                type.gradeType === logEntriesByTimeReport.parameters.gradeType
              }
              key={type.label}
            />
          ))}
        </div>
        {dateRangeButtons}
        <div ref={reportRef}>
          <ChartistGraph data={mobxChartistData} type="Bar" options={options} />
        </div>
      </>
    );
  });
};

ByTime.propTypes = {
  classes: PropTypes.object
};

export default withRootStore(ByTime);
