import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { observer, useObserver } from "mobx-react";

import ReactResizeDetector from "react-resize-detector";
import { SVGRouteMap, ClimbDialog } from "ticker-components";

import { withCachedRootStore } from "ticker-core";

function MapPage(props) {
  let {
    rootStore: { SVGStore, personalRootStore, selectionStore }
  } = props;

  let cache = personalRootStore.getCacheWithPersonalDataIfPossible();
  let allClimbingAssemblies = cache.allClimbingAssemblies;

  let selectedAssembly;

  // console.log(
  //   "afg - rendering the mappage id=" +
  //     selectionStore.currentSelectedId +
  //     "typeof=" +
  //     typeof selectionStore.currentSelectedId +
  //     " length of allassemblies=" +
  //     allClimbingAssemblies.length +
  //     " showSelected=" +
  //     selectionStore.showSelected
  // );
  if (selectionStore.currentSelectedId && selectionStore.showSelected) {
    for (let assembly of allClimbingAssemblies) {
      if (assembly.climbingRoute.id === selectionStore.currentSelectedId) {
        selectedAssembly = assembly;
        // console.log("afg- assembly found");
        break;
      }
    }
  }

  // // I'm getting double taps on the route.
  // // The second tap is causing the dialog to close prematurely.
  // // This hack uses a boolean to diallow closing until after 50ms
  // let [allowClose, setAllowClose] = useState(true);
  // useEffect(() => {
  //   setAllowClose(false);
  //   setTimeout(() => {
  //     setAllowClose(true);
  //   }, 50);
  // }, [selectionStore.currentSelectedId, selectionStore.showSelected]);

  let handleClose = useCallback(() => {
    // Don't close unless 50ms has passed since opened (see the comment above)
    // if (allowClose) {
    selectionStore.showSelected = false;
    // }
  });

  let isOpen =
    selectedAssembly !== null && typeof selectedAssembly !== "undefined";

  let callableOnTouchMoveCB = useCallback(evt => {
    SVGStore.viewer.callableOnTouchMove(evt);
  });

  let callableOnTouchEndCB = useCallback(evt => {
    SVGStore.viewer.callableOnTouchEnd(evt);
  });

  return useObserver(() => {
    let newObject;
    if (selectionStore.newClimbingObject) {
      console.log("Rendering the thing again");
      newObject = (
        <>
          <circle
            cx={selectionStore.newClimbingObject.labelX}
            cy={selectionStore.newClimbingObject.labelY}
            r={10 / SVGStore.value.a}
            stroke="grey"
            fill="yellow"
            opacity="0.9"
            strokeWidth="1.5"
            vectorEffect="non-scaling-stroke"
            style={{ filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, 1))" }}
          />
          <circle
            cx={selectionStore.newClimbingObject.labelX}
            cy={selectionStore.newClimbingObject.labelY}
            r={10 / SVGStore.value.a}
            stroke="grey"
            fill="none"
            strokeWidth="1.5"
            vectorEffect="non-scaling-stroke"
          />
        </>
      );
    }
    return (
      <>
        <ClimbDialog
          isOpen={isOpen}
          climbAssembly={selectedAssembly}
          handleClose={handleClose}
        />
        <ReactResizeDetector onResize={SVGStore.setDimensions}>
          <div id="hereWeAre" style={{ height: "calc(100vh - 70px)" }}>
            <SVGRouteMap
              longPressIsDisabled
              hideFilters={isOpen}
              callableOnTouchEndCB={callableOnTouchEndCB}
              callableOnTouchMoveCB={callableOnTouchMoveCB}
              showReportButton
              newObject={newObject}
              showMissing={false}
            />
          </div>
        </ReactResizeDetector>
      </>
    );
  });
}
MapPage.propTypes = {
  rootStore: PropTypes.object.isRequired
};
export default withCachedRootStore(observer(MapPage));
