import getCachable from "../utils/getCachable";

export default class MissingStores {
  constructor(context, asyncOpCreate, getCurrentGymId) {
    const rest = context.rest;

    this.cachedMissingStore = getCachable(
      asyncOpCreate,
      context.rest,
      (a, b) => a.id === b.id,
      "missing",
      null,
      () => ({
        gym_id: getCurrentGymId()
      }),
      null
    );

    this.deleteMissingStore = {
      opDeleteMissing: asyncOpCreate(
        payload => rest.delete("missing/" + payload.id),
        "opDeleteMissing"
      ),
      deleteMissing: async missing => {
        // Assume success and remove from the cache
        this.cachedMissingStore.removeItemById(missing.id);

        let payload = {
          gym_id: getCurrentGymId,
          id: missing.id
        };

        await this.deleteMissingStore.opDeleteMissing.execute(payload);
      }
    };
  }
}
