import { coreStyle } from "ticker-core";

const { container } = coreStyle;

const dateSelectionPageStyle = () => ({
  container,
  icon: {
    marginLeft: "0.25em"
  },
  message: {
    marginTop: "2em"
  },
  messageBottom: {
    marginBottom: "2em"
  }
});

export default dateSelectionPageStyle;
