import React, { useState } from "react";
import { useObserver } from "mobx-react";

import { action } from "mobx";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";

import { LoginRequest, HighlightChange } from "ticker-components";

import Star from "@material-ui/icons/Star";
import Place from "@material-ui/icons/Place";
import Person from "@material-ui/icons/Person";
import ReportProblem from "@material-ui/icons/ReportProblem";

import Grid from "@material-ui/core/Grid";

import UserControls from "./UserControls";

import { withRootStore } from "ticker-core";

import climbCoreStyle from "../../assets/jss/material-dashboard-pro-react/components/climbCoreStyle.js";
import ReportDialog from "./ReportDialog";
import RatingDetailsDialog from "./RatingDetailsDialog";
import ClimbCoreTitle from "./ClimbCoreTitle";

let useCardStyles = makeStyles(climbCoreStyle);

function ClimbCore({
  rootStore,
  assembly,
  onTick,
  onDeleteTick,
  onDone,
  tourRefs,
  showTitle
}) {
  let classes = useCardStyles();

  let [showReportDialog, setShowReportDialog] = useState(false);
  let [activeIndex, setActiveIndex] = useState(0);

  let [showRatingDetailsDialog, setShowRatingDetailsDialog] = useState(false);

  return useObserver(() => {
    let {
      regStores,
      personalRootStore,
      history,
      SVGStore,
      selectionStore,
      notificationStore,
      isClimberSite,
      userStores: { profileStore },
      loginDialogStore
    } = rootStore;

    let { regStore } = regStores;

    let {
      personalReportedIssuesStores: { createIssueStore },
      inspirationStore
    } = personalRootStore;

    let hasDescription =
      assembly.climbingRoute.description &&
      assembly.climbingRoute.description.trim() !== "" &&
      assembly.climbingRoute.description.length > 2;

    let viewRoute = action(() => {
      selectionStore.currentSelectedId = assembly.climbingRoute.id;
      selectionStore.currentSelected = assembly.climbingRoute;
      SVGStore.panToCoordinates = {
        x: assembly.climbingRoute.labelX,
        y: assembly.climbingRoute.labelY
      };

      inspirationStore.clearSelection();
      history.push(rootStore.context.config.viewRoutePath);
    });

    return (
      <React.Fragment>
        {showReportDialog && (
          <ReportDialog
            onClose={() => setShowReportDialog(false)}
            open={showReportDialog}
            climbing_route_id={assembly.climbingRoute.id}
            createIssueStore={createIssueStore}
            regStore={regStore}
            activeIndex={activeIndex}
            notificationStore={notificationStore}
          />
        )}
        {showRatingDetailsDialog && (
          <RatingDetailsDialog
            onClose={() => setShowRatingDetailsDialog(false)}
            open={showRatingDetailsDialog}
            assembly={assembly}
          />
        )}

        {showTitle && <ClimbCoreTitle assembly={assembly} />}
        <Grid container className={classes.iconList} justify="space-evenly">
          <Grid
            item
            onClick={() => {
              if (
                !profileStore.hasLoaded ||
                (rootStore.isPublicSite && !regStores.isRegistered())
              ) {
                loginDialogStore.showDialog = true;
                loginDialogStore.instruction =
                  "to send feedback about a climb.";
                return;
              }
              setActiveIndex(0);
              setShowReportDialog(true);
            }}
            className={classes.navLink}
            ref={
              tourRefs
                ? newRef => {
                    tourRefs.push(newRef);
                  }
                : undefined
            }
            data-tour="reportIssue"
          >
            {(regStore.isLoggedIn || isClimberSite) && (
              <ReportProblem className={classes.listItemIcon} />
            )}
          </Grid>
          <Grid
            className={classes.navLink}
            item
            onClick={() => {
              if (onDone) {
                onDone();
              }
              viewRoute();
            }}
            data-tour="mapPlace"
          >
            <Place className={classes.listItemIcon} />
          </Grid>
          <Grid
            className={classes.navLink}
            item
            data-tour="ratingCount"
            onClick={() => {
              setShowRatingDetailsDialog(true);
            }}
          >
            <HighlightChange
              monitoredValue={assembly.climbingRoute.ratingCount}
            >
              <Person className={classes.listItemIcon} />
              {assembly.climbingRoute.ratingCount}
            </HighlightChange>
          </Grid>
          <Grid
            className={classes.navLink}
            item
            data-tour="avgRating"
            onClick={() => {
              setShowRatingDetailsDialog(true);
            }}
          >
            <HighlightChange monitoredValue={assembly.climbingRoute.avgRating}>
              <Star
                style={{ color: "#fdbc4b" }}
                className={classes.listItemIcon}
              />
              {assembly.climbingRoute.avgRating
                ? assembly.climbingRoute.avgRating.toFixed(1)
                : "?"}
            </HighlightChange>
          </Grid>
        </Grid>

        <UserControls
          assembly={assembly}
          history={history}
          onTick={() => {
            if (onTick) {
              onTick();
            }
          }}
          onDeleteTick={onDeleteTick}
          setActiveIndex={setActiveIndex}
          setShowReportDialog={setShowReportDialog}
        />
        <ul data-tour="climbDetails">
          <li>{assembly.sectionAssembly.climbingSection.name}</li>
          <li>{assembly.wallAssembly.climbingWall.name}</li>
          {hasDescription && <li>{assembly.climbingRoute.description}</li>}
          <li>
            <span className={classes.label}>Hold Color</span>{" "}
            {assembly.markerColor.name}
          </li>
        </ul>
      </React.Fragment>
    );
  });
}

ClimbCore.propTypes = {
  rootStore: PropTypes.object.isRequired,
  assembly: PropTypes.object.isRequired,
  onTick: PropTypes.func,
  onDeleteTick: PropTypes.func,
  showTitle: PropTypes.bool
};

export default withRootStore(ClimbCore);
