import { observable } from "mobx";

import sortFns from "../utils/sortFns.js";

export default function climbSelectionStore(regStore) {
  let theStore = observable({
    selectedSectionWrapper: null,
    selectedWallWrapper: null,
    selectedColorWrapper: null,
    selectedGradeWrapper: null,
    selectedOnHoldWrapper: null,
    sortBy: sortFns.sortByGradeAscending
  });

  theStore.setWrapper = (storeName, wrapper) => {
    regStore.resetNoActivityTimeout();
    if (wrapper) {
      theStore[storeName] = observable(wrapper);
    } else {
      theStore[storeName] = null;
    }
  };

  theStore.clearSelections = () => {
    theStore.selectedSectionWrapper = null;
    theStore.selectedColorWrapper = null;
    theStore.selectedGradeWrapper = null;
    theStore.selectedWallWrapper = null;
    theStore.selectedOnHoldWrapper = null;
    theStore.sortBy = sortFns.sortByGradeAscending;
  };

  return theStore;
}
