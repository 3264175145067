import { observable, action } from "mobx";

import rules from "../services/rules.js";
import validate from "validate.js";
import { parse } from "qs";

/*
 * DEBUG NOTE:
 * It seems like the actions need to be declared as regular functions.
 * If they are declared as Arrow Functions then the binding of action wont work properly.
 * Here is a comment from the Mobx docs:
 * Note: don't use action.bound with arrow functions; arrow functions are already bound and cannot be rebound.
 */

export default class AuthStores {
  constructor(
    context,
    hist,
    viewAfterLogin,
    asyncOpCreate,
    httpCodeHandlerFactory
  ) {
    if (!viewAfterLogin) {
      console.log(
        "The construction of the AuthStores is missing the viewAfterLogin parameter"
      );
      debugger;
    }

    const rest = context.rest;

    this.hist = hist;
    this.stores = {};
    //this.userStores = rootStore.userStores;

    let this__ = this;

    // This isn't used at the moment I think.
    // This is my guess at the moment: I think the cookie is set up in Axios.
    // The bearer token is created in
    let localStorageKey = "JWT" + context.config.nginxURL;

    const authStore = observable({
      isAuthenticated: false,

      token: "",
      setAuthenticated() {
        authStore.isAuthenticated = true;
      },
      setToken(token) {
        authStore.isAuthenticated = true;
        authStore.token = token;
        localStorage.setItem(localStorageKey, token);
      },
      getToken() {
        if (authStore.token) {
          return authStore.token;
        }
        // Brook added this localStorage.getItem
        return localStorage.getItem(localStorageKey);
      },
      reset() {
        console.log("Reseting authentication");
        localStorage.removeItem(localStorageKey);
        authStore.isAuthenticated = false;
        authStore.token = "";
      }
    });

    context.rest.setJwtSelector(() => authStore.getToken());

    function _redirectAfterAuthentication(path) {
      const nextPath = parse(window.location.search.slice(1)).nextPath || path; // "/admin/overview";
      hist.push(nextPath);
    }

    let loginHttpCodeHandler = httpCodeHandlerFactory.getDefaultCopy();

    this.stores = {
      auth: authStore,
      checkLoggedIn: observable({
        isChecking: false,
        hasChecked: false,
        checkNow: action(async function() {
          if (this.isChecking) {
            console.log("Still is Checking so do nothing ");
            return;
          }
          try {
            console.log("Checking for logged in");
            this.isChecking = true;
            await rest.get("checkLoggedIn");
            authStore.setAuthenticated();
            this.isChecking = false;
            console.log("Logged in.");
            const { pathname } = window.location;
            if (pathname === "/login") {
              // From social login
              _redirectAfterAuthentication(viewAfterLogin);
            }
          } catch (errors) {
            console.log("An error has been thrown: " + errors);
            authStore.reset();
            console.log("Not logged in");
          } finally {
            this.hasChecked = true;
          }
        })
      }),
      login: observable({
        email: "",
        password: "",
        errors: {},
        serverMessage: {},
        setEmail: function setEmailFn(email) {
          this.email = email.toLowerCase();
          this.clearMessages();
        },
        setPassword: function setPasswordFn(password) {
          this.password = password;
          this.clearMessages();
        },
        clearMessages: function clearMessagesFn() {
          this.errors = {};
          this.serverMessage = {};
        },
        cardAnimaton: "cardHidden",
        op: asyncOpCreate(
          payload => {
            return rest.post("auth/login", payload);
          },
          "auth/login",
          loginHttpCodeHandler
        ),
        login: action(async function(path) {
          this.errors = {};
          this.serverMessage = {};
          const payload = {
            email: this.email.trim(),
            password: this.password
          };
          const constraints = {
            email: rules.email,
            password: rules.password
          };
          const vErrors = validate(payload, constraints);
          if (vErrors) {
            this.errors = vErrors;
            return;
          }

          try {
            const response = await this.op.execute(payload);
            if (typeof response === "undefined") {
              this.serverMessage.message = "Invalid Email or Password";
            } else {
              const { token } = response;
              authStore.setToken(token);
              _redirectAfterAuthentication(path ? path : viewAfterLogin);
            }
          } catch (errors) {
            let strangeWorkAround = errors;
            if (
              strangeWorkAround.response &&
              strangeWorkAround.response.status === 401
            ) {
              this.serverMessage = {
                message: "Failed to login",
                showLoginBtn: true
              };
            } else if (
              strangeWorkAround.response &&
              strangeWorkAround.response.data.error
            ) {
              this.serverMessage.message =
                strangeWorkAround.response.data.error.message + "";
            } else {
              this.serverMessage.message = strangeWorkAround + "";
            }
            console.error(
              "login xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
              errors
            );
            localStorage.removeItem(localStorageKey);
          }
        })
      }),
      register: observable({
        email: "",
        password: "",
        errors: {},
        serverMessage: {},
        setEmail: function setEmailFn(email) {
          this.email = email.toLowerCase();
          this.clearMessages();
        },
        setPassword: function setPasswordFn(password) {
          this.password = password;
          this.clearMessages();
        },
        clearMessages: function clearMessagesFn() {
          this.errors = {};
          this.serverMessage = {};
        },
        op: asyncOpCreate(payload => {
          return rest.post("auth/register", payload);
        }, "auth/register"),
        register: action(async function() {
          this.errors = {};
          const payload = {
            email: this.email.trim(),
            password: this.password
          };
          const constraints = {
            email: rules.email,
            password: rules.password
          };
          const vErrors = validate(payload, constraints);
          if (vErrors) {
            this.errors = vErrors;
            return;
          }
          let response = await this.op.execute(payload);

          console.log(
            "The response from the register is:" + JSON.stringify(response)
          );
          if (response.success) {
            this__.stores.login.setPassword(this.password);
            this__.stores.login.setEmail(this.email);
            this__.stores.login.login(context.config.tourPath);
          } else {
            this.serverMessage = {
              message: "That email address is already taken",
              showBtn: true
            };
          }
        })
      }),
      logout: observable({
        op: asyncOpCreate(() => rest.post("auth/logout"), "auth/logout"),
        logout: action(async function() {
          await this.op.execute({});
          authStore.reset();
          hist.push(context.config.userLoginPath);

          // Just reload the page to clear out all the old data
          setTimeout(() => {
            console.log(
              "Reloading the page because the logout page fn was called"
            );
            window.location.reload();
          }, 50);
        })
      }),
      verifyEmailCode: observable({
        op: asyncOpCreate(
          payload => rest.post("auth/verify_email_code", payload),
          "verify_email_code"
        ),
        execute: action(async function(payload) {
          try {
            await this.op.execute(payload);
            context.history.push(`/auth/login`);
          } catch (errors) {
            //
          }
        })
      }),
      resetPassword: observable({
        step: "SetPassword",
        password: "",
        errors: {},
        op: asyncOpCreate(
          payload => rest.post("auth/verify_reset_password_token", payload),
          "verify_reset_password_token"
        ),
        resetPassword: action(async function(token) {
          this.errors = {};
          const payload = {
            password: this.password,
            token
          };
          const constraints = {
            password: rules.password
          };
          const vErrors = validate(payload, constraints);
          if (vErrors) {
            this.errors = vErrors;
            return;
          }
          try {
            await this.op.execute(payload);
            this.step = "SetNewPasswordDone";
          } catch (errors) {
            console.error("resetPassword ", errors);
          }
        })
      }),
      forgotPassword: observable({
        step: "SendPasswordResetEmail",
        email: "",
        errors: {},
        op: asyncOpCreate(
          payload => rest.post("auth/reset_password", payload),
          "reset_password"
        ),
        requestPasswordReset: action(async function() {
          this.errors = {};
          const payload = {
            email: this.email.trim()
          };
          const constraints = {
            email: rules.email
          };
          const vErrors = validate(payload, constraints);
          if (vErrors) {
            this.errors = vErrors;
            return;
          }
          try {
            await this.op.execute(payload);
            this.step = "CheckEmail";
          } catch (errors) {
            console.error(errors);
          }
        })
      }),
      logoutWithPassword: observable({
        password: "",
        serverMessage: undefined,
        setPassword: function setPasswordFn(password) {
          this.password = password;
          this.clearMessages();
        },
        clearMessages: function clearMessagesFn() {
          this.serverMessage = undefined;
        },
        cardAnimaton: "cardHidden",
        op: asyncOpCreate(payload => {
          return rest.post("auth/logoutWithPassword", payload);
        }, "auth/logoutWithPassword"),
        logoutWithPassword: action(async function() {
          this.serverMessage = undefined;
          const payload = {
            password: this.password
          };

          try {
            const response = await this.op.execute(payload);
            console.log("The response from logout with password is:", response);
            if (typeof response === "undefined" || response.success === false) {
              this.serverMessage = "Invalid Password";
            } else {
              authStore.reset();
              // Just reload the page to clear out all the old data
              setTimeout(() => {
                console.log(
                  "Reloading the page because the logoutWithPassword was successful."
                );
                window.location.reload();
              }, 200);
            }
          } catch (errors) {
            debugger;
            let strangeWorkAround = errors;
            if (
              strangeWorkAround.response &&
              strangeWorkAround.response.data.error
            ) {
              this.serverMessage.message =
                strangeWorkAround.response.data.error.message + "";
            } else {
              this.serverMessage.message = strangeWorkAround + "";
            }
            console.error(
              "login xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
              errors
            );
            localStorage.removeItem(localStorageKey);
          }
        })
      })
    };
    //this.stores.register.register.bind(this.stores.register.register);
    loginHttpCodeHandler.handleStatusFns[401] = () => {
      //Do nothing. This just overrides the default redirect to login for 401
    };
  }

  isAuthenticated({ pathname }) {
    console.log("isAuthenticated " + pathname);
    const { isAuthenticated } = this.stores.auth;
    if (!isAuthenticated) {
      setTimeout(() => this.hist.push(`login?nextPath=${pathname}`), 1);
      throw new Error({ name: "redirect", status: 302 });
    }
  }

  getStores() {
    return this.stores;
  }
}
