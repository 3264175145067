import { observable, computed } from "mobx";
import {
  gradeTypes,
  MIDDLE_RATING,
  MIN_RATING,
  MAX_RATING
} from "../../variables/constants";
import getOppositeGradeType from "../../utils/getOppositeGradeType";

import sortFns from "../../utils/sortFns.js";

export default class InspirationStore {
  constructor(personalRootStore, isCacheLoaded, computedCacheStore, regStore) {
    this.listPageSelection = {
      selection: getSelectionObject(false),
      UISelection: getSelectionObject(false)
    };
    this.mapPageSelection = {
      selection: getSelectionObject(true),
      UISelection: getSelectionObject(true)
    };

    this.getUISelectionGradeType = pageName => {
      return this[pageName].UISelection.showGradeTypes[gradeTypes.ROUTE]
        ? gradeTypes.ROUTE
        : gradeTypes.BOULDER;
    };

    this.transitionStore = observable({
      isFadingIn: true,
      stayVisibleMap: {},
      FADE_OUT_DURATION: 300,
      FADE_IN_DURATION: 300
    });

    this.transitionStore.startFadeChange = newValue => {
      setTimeout(() => {
        this.transitionStore.isFadingIn = newValue;
      }, 50);
      // The 50ms is to allow the list of routes to be sorted/altered before the fade in start
      // If I don't have this then the list seems to fade in instantly sometimes.
    };

    this.transitionStore.startFadeOut = () => {
      regStore.resetNoActivityTimeout();
      this.transitionStore.isFadingIn = false;
      this.transitionStore.stayVisibleMap = {};
    };

    this.transitionStore.startFadeIn = callBack => {
      setTimeout(() => {
        callBack();
        this.transitionStore.startFadeChange(true);
      }, this.FADE_OUT_DURATION);
    };

    this.transitionStore.changeSelection = (
      pageName,
      selectionFieldName,
      newSelectionValue
    ) => {
      this.transitionStore.startFadeOut();

      //Set the UI button state
      this[pageName].UISelection[selectionFieldName] = newSelectionValue;

      this.transitionStore.startFadeIn(() => {
        this[pageName].selection[selectionFieldName] = newSelectionValue;
      });
    };

    this.transitionStore.changeSectionSelectionForMapPage = (
      pageSelection,
      climbingSection,
      gradeType
    ) => {
      regStore.resetNoActivityTimeout();
      this.transitionStore.isFadingIn = false;
      this.transitionStore.stayVisibleMap = {};

      let { UISelection, selection } = pageSelection;
      //Set the UI button state
      let newValue = !UISelection.showSectionIds[climbingSection.id];

      UISelection.showSectionIds[climbingSection.id] = newValue;

      if (typeof gradeType !== "undefined" && newValue) {
        UISelection.showGradeTypes[gradeType] = true;
      }
      this.transitionStore.startFadeIn(() => {
        selection.showSectionIds[climbingSection.id] = newValue;

        if (typeof gradeType !== "undefined" && newValue) {
          selection.showGradeTypes[gradeType] = true;
        }
      });
    };

    this.transitionStore.toggleGradeTypeSelection = (
      pageName,
      gradeType,
      affectBothGrades
    ) => {
      console.log("Transition store changeGradeTypeSelection()");

      this.transitionStore.changeGradeTypeSelection(
        pageName,
        gradeType,
        !this[pageName].UISelection.showGradeTypes[gradeType],
        affectBothGrades
      );
    };

    this.transitionStore.changeGradeTypeSelection = (
      pageName,
      gradeType,
      newValue,
      affectBothGrades
    ) => {
      //Set the UI button state
      this[pageName].UISelection.showGradeTypes[gradeType] = newValue;

      if (affectBothGrades) {
        this[pageName].UISelection.showGradeTypes[
          getOppositeGradeType(gradeType)
        ] = !newValue;
      }
      // if (toggleBetween && !newValue) {
      //   this[pageName].UISelection.showGradeTypes[
      //     getOppositeGradeType(gradeType)
      //   ] = true;
      // }
      console.log(
        "asd.Set the UISelection of gradeType:" +
          gradeType +
          " to..." +
          newValue +
          ".About to start the timer."
      );
      this.transitionStore.startFadeIn(() => {
        this[pageName].selection.showGradeTypes[gradeType] = newValue;
        console.log(
          "asd.Timeout for UI fading has ended. About to set the selection to of gradeType:" +
            gradeType +
            " to..." +
            newValue
        );
        if (affectBothGrades) {
          this[pageName].selection.showGradeTypes[
            getOppositeGradeType(gradeType)
          ] = !newValue;
        }
        // if (!newValue) {
        //   this[pageName].selection.showGradeTypes[
        //     getOppositeGradeType(gradeType)
        //   ] = true;
        // }
      });
    };

    this.filteredListPage = computed(() => {
      return this.getFiltered(this.listPageSelection);
    });

    this.filteredMapPage = computed(() => {
      return this.getFiltered(this.mapPageSelection);
    });

    this.getFiltered = pageSelection => {
      let retVal = {
        climbingRouteAssemblies: [],
        filteredCount: 0
      };

      let { selection: currentSelection } = pageSelection;

      let showGradeTypes = currentSelection.showGradeTypes;

      if (!isCacheLoaded()) {
        return retVal;
      }
      let cache = personalRootStore.getCacheWithPersonalDataIfPossible();

      for (let climbingAssembly of cache.allClimbingAssemblies) {
        if (
          !currentSelection.showSectionIds[
            climbingAssembly.sectionAssembly.climbingSection.id
          ]
        ) {
          retVal.filteredCount++;
          continue;
        }

        if (!showGradeTypes[climbingAssembly.climbingGrade.gradeType]) {
          retVal.filteredCount++;
          continue;
        }

        if (
          currentSelection.orderPositionOfGradeEnds[
            climbingAssembly.climbingGrade.gradeType
          ] < climbingAssembly.climbingGrade.orderPosition
        ) {
          retVal.filteredCount++;
          continue;
        }

        if (
          currentSelection.orderPositionOfGradeStarts[
            climbingAssembly.climbingGrade.gradeType
          ] > climbingAssembly.climbingGrade.orderPosition
        ) {
          retVal.filteredCount++;
          continue;
        }

        if (
          typeof currentSelection.hasClimbed !== "undefined" &&
          this.transitionStore.stayVisibleMap[
            climbingAssembly.climbingRoute.id
          ] !== true
        ) {
          //Don't show any climbed routes when !hasClimbed and vice-versa
          let climberHasClimbed =
            typeof climbingAssembly.logEntryCount !== "undefined" &&
            climbingAssembly.logEntryCount > 0;

          if (currentSelection.hasClimbed === true && !climberHasClimbed) {
            retVal.filteredCount++;
            continue;
          }

          if (currentSelection.hasClimbed === false && climberHasClimbed) {
            retVal.filteredCount++;
            continue;
          }
        }
        let rating = climbingAssembly.climbingRoute.avgRating
          ? climbingAssembly.climbingRoute.avgRating
          : MIDDLE_RATING;
        if (
          currentSelection.publicRating.start > rating ||
          currentSelection.publicRating.end < rating
        ) {
          retVal.filteredCount++;
          continue;
        }

        let personalRating =
          climbingAssembly.userRating && climbingAssembly.userRating.rating
            ? climbingAssembly.userRating.rating
            : MIDDLE_RATING;
        if (
          currentSelection.personalRating.start > personalRating ||
          currentSelection.personalRating.end < personalRating
        ) {
          retVal.filteredCount++;
          continue;
        }

        retVal.climbingRouteAssemblies.push(climbingAssembly);
      }

      if (currentSelection.sortBy) {
        retVal.climbingRouteAssemblies.sort(currentSelection.sortBy);
      }
      return retVal;
    };

    this.clearSelectionAndCache = () => {
      this.clearSelection();
    };

    this.clearSelection = () => {
      let {
        sectionAssemblies: assemblies
      } = computedCacheStore.computedCache.get();
      setSelectionToDefaults(this.mapPageSelection.selection, assemblies, true);
      setSelectionToDefaults(
        this.mapPageSelection.UISelection,
        assemblies,
        true
      );
      setSelectionToDefaults(
        this.listPageSelection.selection,
        assemblies,
        false
      );
      setSelectionToDefaults(
        this.listPageSelection.UISelection,
        assemblies,
        false
      );
      this.listPageSelection.selection.showGradeTypes[
        gradeTypes.BOULDER
      ] = false;
      this.listPageSelection.UISelection.showGradeTypes[
        gradeTypes.BOULDER
      ] = false;
    };

    this.clearPersonalData = () => {
      this.clearSelection();
    };

    this.postCache = () => {
      this.postCacheCalled = true;
      let {
        sectionAssemblies: assemblies
      } = computedCacheStore.computedCache.get();

      console.log("Post cached starting lenght");
      resetSectionSelections(this.listPageSelection.selection, assemblies);

      resetSectionSelections(this.listPageSelection.UISelection, assemblies);
      resetSectionSelections(this.mapPageSelection.selection, assemblies);
      resetSectionSelections(this.mapPageSelection.UISelection, assemblies);
    };
  }
}
let resetSectionSelections = (selectionObj, sectionAssemblies) => {
  Object.keys(selectionObj.showSectionIds).forEach(key => {
    delete selectionObj.showSectionIds[key];
  });

  console.log(
    "Reseting the section selections. The lenght of sections is:" +
      sectionAssemblies.length
  );

  sectionAssemblies.forEach(sectionAssembly => {
    selectionObj.showSectionIds[sectionAssembly.climbingSection.id] = true;
  });
};

let setSelectionToDefaults = (selectionObj, assemblies, isMapPage) => {
  gradeTypes.ALL.forEach(gradeType => {
    selectionObj.orderPositionOfGradeStarts[gradeType] = undefined;
    selectionObj.orderPositionOfGradeEnds[gradeType] = undefined;
    selectionObj.showGradeTypes[gradeType] = isMapPage;
  });

  if (!isMapPage) {
    selectionObj.showGradeTypes[gradeTypes.ROUTE] = true;
  }

  selectionObj.hasClimbed = undefined;

  resetSectionSelections(selectionObj, assemblies);

  selectionObj.sortBy = sortFns.sortByGradeAscending;
  selectionObj.publicRating = { end: MAX_RATING, start: MIN_RATING };
  selectionObj.personalRating = { end: MAX_RATING, start: MIN_RATING };
};
let getSelectionObject = isMapPage => {
  let newObj = {
    showGradeTypes: [],
    orderPositionOfGradeStarts: [],
    orderPositionOfGradeEnds: [],

    /*
     * Note: If I try to store the sectionAssembly as a whole instead of having
     * sectionId and sectionHasGradeArray seperately, then I get an error like
     * "Too Much Recursion Mobx"
     * Not sure what is happening.
     * 05/12/19
     */
    // sectionHasGradeArrays: observable({}),
    showSectionIds: observable({})
  };

  // passing in an empty array to represent the sections.
  // When the inspirationStore is create and this getSelectionObject is called
  // The sections wont have been loaded.
  setSelectionToDefaults(newObj, [], isMapPage);

  newObj.orderPositionOfGradeEnds = observable(newObj.orderPositionOfGradeEnds);
  newObj.orderPositionOfGradeStarts = observable(
    newObj.orderPositionOfGradeStarts
  );
  newObj.showGradeTypes = observable(newObj.showGradeTypes);

  return observable(newObj);
};
