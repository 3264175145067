import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";

// Create a new context for the app
export const RootStoreContext = React.createContext("app");

class RootStoreProvider extends Component {
  // constructor(props) {
  //   super(props);
  //   // you must specify what you’re adding to the context
  //   this.childContextTypes = {
  //     rootStore: PropTypes.object.isRequired
  //   };
  // }
  // getChildContext() {
  //   const { rootStore } = this.props;
  //   return { rootStore };
  // }
  render() {
    // `Children.only` enables us not to add a <div /> for nothing
    return (
      <RootStoreContext.Provider value={this.props.rootStore}>
        {this.props.children}
      </RootStoreContext.Provider>
    );
  }
}

RootStoreProvider.propTypes = {
  rootStore: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default RootStoreProvider;
