import {
  TicksPage,
  CompletionPage,
  TicksGraphPage,
  InspirationPage,
  LeaderboardPage,
  DateSelectionPage,
  RegistrationUpdatePage,
  MapPage,
  HeightPage,
  OrganisationPage,
  TourPage
} from "shared-climber";

import { LoginPage, LogoutPage, RegisterPage } from "ticker-core";

import { Help } from "ticker-components";

// import ClimbSelectorPage from "@bit/routesettingrocks.shared.climbs-page";
// import RegistrationLogin from "@bit/routesettingrocks.shared.registration-login-page";
// import RegistrationRegister from "@bit/routesettingrocks.shared.registration-register-page";
// import RegistrationLogoutPage from "@bit/routesettingrocks.shared.registration-logout-page";

// import RegisterPage from "@bit/routesettingrocks.shared.register-page";
// import LoginPage from "@bit/routesettingrocks.shared.login-page";
// import LogoutPage from "@bit/routesettingrocks.shared.logout-page";

// import InspirationPage from "@bit/routesettingrocks.shared.inspiration-page";
// import LeaderboardPage from "@bit/routesettingrocks.shared.leaderboard-page";
// import DateSelectionPage from "@bit/routesettingrocks.shared.date-selection-page";
// import UpdatePage from "@bit/routesettingrocks.shared.registration-update-page";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import Check from "@material-ui/icons/Check";
import Settings from "@material-ui/icons/Settings";
import ListIcon from "@material-ui/icons/FormatListNumbered";
import Place from "@material-ui/icons/Place";
import AccessTime from "@material-ui/icons/AccessTime";
import List from "@material-ui/icons/List";
import BarChart from "@material-ui/icons/BarChart";
import Height from "@material-ui/icons/Height";

// import { LightBulbIcon } from "ticker-components";
import GymRegisterPage from "views/gym/GymRegisterPage";
import ClaimPage from "views/gym/ClaimPage";
import UpdateUserNamePage from "views/UpdateUserNamePage";
import CreateUserNamePage from "views/CreateUserNamePage";
var dashRoutes = [
  {
    path: "/register-page",
    name: "Sign up",
    icon: PersonAdd,
    component: RegisterPage,
    layout: "/auth"
  },
  {
    path: "/login-page",
    name: "Login",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
    icon: Fingerprint
  },
  {
    path: "/history",
    name: "My Stats",
    icon: Check,
    component: TicksPage,
    layout: "/main",
    collapse: true,
    views: [
      {
        path: "/timeGraph",
        name: "Time Graph",
        icon: BarChart,
        component: TicksGraphPage,
        layout: "/main"
      },
      {
        path: "/ticks",
        name: "Tick List",
        icon: Check,
        component: TicksPage,
        layout: "/main"
      },
      {
        path: "/completion",
        name: "Completion",
        icon: Check,
        component: CompletionPage,
        layout: "/main"
      }
    ]
  },
  // {
  //   path: "/climbs",
  //   name: "Climbs",
  //   icon: Search,
  //   component: ClimbSelectorPage,
  //   layout: "/main"
  // },
  {
    path: "/map",
    name: "Map View",
    smallName: "Map",
    icon: Place,
    component: MapPage,
    layout: "/main"
  },
  {
    path: "/inspiration",
    name: "List View",
    smallName: "List",
    icon: List,
    component: InspirationPage,
    layout: "/main"
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    smallName: "Leaders",
    icon: ListIcon,
    component: LeaderboardPage,
    layout: "/main"
  },
  {
    path: "/dateSelection",
    component: DateSelectionPage,
    layout: "/main",
    hideInNavBar: true
    // useDateOption: true,
    // icon: AccessTime
  },
  {
    path: "/update",
    name: "",
    icon: Settings,
    layout: "/main",
    useUserName: true,
    collapse: true,
    views: [
      {
        path: "/update",
        name: "Change UserName and Password",
        icon: Settings,
        layout: "/main",
        component: RegistrationUpdatePage,
        hidden: true,
        showToLoggedInRegistrationsOnly: true
      },
      {
        path: "/updateUsername",
        name: "Change UserName",
        icon: Settings,
        layout: "/main",
        component: UpdateUserNamePage,
        showToLoggedInRegistrationsOnly: true
      },
      {
        path: "/tour",
        name: "Instructions",
        component: TourPage,
        layout: "/main",
        icon: Help
      },

      {
        path: "/height",
        name: "Change Height",
        component: HeightPage,
        layout: "/main",
        icon: Height
      },
      {
        path: "/registrationClaim",
        name: "Claim",
        icon: Fingerprint,
        component: ClaimPage,
        layout: "/main",
        showToLoggedOutRegistrationsOnly: true
      },
      {
        path: "/gymRegister",
        name: "Register",
        icon: PersonAdd,
        component: GymRegisterPage,
        layout: "/main",
        showToLoggedOutRegistrationsOnly: true
      },
      {
        path: "/logout",
        name: "Logout",
        component: LogoutPage,
        layout: "/main",
        icon: LogoutIcon
      }
    ]
  },
  {
    path: "/createUsername",
    name: "Create UserName",
    icon: Settings,
    layout: "/blank",
    component: CreateUserNamePage,
    hideInNavBar: true
  },
  {
    path: "/organisations",
    name: "hidden",
    hideInNavBar: true,
    layout: "/main",
    component: OrganisationPage
  }
  // {
  //   path: "/tour",
  //   name: "Instructions",
  //   component: TourPage,
  //   icon: Help,
  //   layout: "/main",
  //   showToLoggedOutRegistrationsOnly: true
  // }
];
export default dashRoutes;
