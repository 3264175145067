import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components

import { withRootStore } from "ticker-core";

import "react-simple-keyboard/build/css/index.css";

import UserNameComponent from "./UserNameComponent";

function CreateUserNamePage({
  rootStore: {
    regStores: { regStore }
  }
}) {
  return <UserNameComponent onClick={regStore.registerRegistration} />;
}

CreateUserNamePage.propTypes = {
  rootStore: PropTypes.object
};

export default withRootStore(CreateUserNamePage);
