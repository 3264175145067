import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function DoubleDownIcon(props) {
  return (
    <SvgIcon {...props} transform="rotate(-180,0,0)">
      <path d="M 12,5 6,11 7.41,12.41 12,7.83 16.59,12.41 18,11 Z" />
      <path d="M 12,10 6,16 7.41,17.41 12,12.83 16.59,17.41 18,16 Z" />
    </SvgIcon>
  );
}

DoubleDownIcon.propTypes = {
  className: PropTypes.object
};
