import React from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";

import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import Delete from "@material-ui/icons/Delete";
import { constants, withCachedRootStore } from "ticker-core";

import makeStyles from "@material-ui/core/styles/makeStyles";

import StarRatings from "react-star-ratings";

let titles = constants.ratingTitles;
let controlStyles = theme => {
  return {
    typography: {
      padding: theme.spacing(2)
    },
    centeredDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      width: "100%",
      textAlign: "center"
    }
  };
};

let useControlStyles = makeStyles(controlStyles);

function RatingControl(props) {
  let { assembly, userRating, popUpRef, postSelect, rootStore } = props;

  let {
    regStores,
    personalRootStore,
    loginDialogStore,
    userStores: { profileStore }
  } = rootStore;

  let { regStore } = regStores;
  let {
    personalRatingStores: { ratingStore }
  } = personalRootStore;

  let deleteUserRating = () => {
    ratingStore.deleteUserRating(assembly);
  };

  const [isOpen, setIsOpen] = React.useState(false);

  let showPopup = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 1100);
  };

  // We must make sure that a number between 0 and 7 is passed in to
  // the rating component. If you pass in undefined as an initial value it
  // doesn't seem to work after that (even once the value is updated and no longer undefined)
  let currentRating;
  if (
    !userRating ||
    typeof userRating.rating === "undefined" ||
    userRating.rating === null
  ) {
    currentRating = 0;
  } else {
    currentRating = userRating.rating;
  }

  let classesX = useControlStyles();
  if (currentRating === null) {
    debugger;
  }

  let changeRating = async newValue => {
    if (
      !profileStore.hasLoaded ||
      (rootStore.isPublicSite && !regStores.isRegistered())
    ) {
      loginDialogStore.showDialog = true;
      loginDialogStore.instruction = "to rate the climbs";
      return;
    }
    regStore.resetNoActivityTimeout();
    showPopup();
    await ratingStore.updateRegistrationRating(
      {
        user_id: personalRootStore.getCurrentUserId(),
        rating: newValue
      },
      assembly
    );
    if (postSelect) {
      postSelect();
    }
  };

  return (
    <>
      <div>
        <small>How good is this climb?</small>
      </div>
      <div className={classesX.centeredDiv}>
        <StarRatings
          rating={currentRating}
          starRatedColor="#fdbc4b"
          starHoverColor="#fdbc4b"
          changeRating={changeRating}
          numberOfStars={7}
          starDimension="1.9em"
          starSpacing=".1em"
        />
        {userRating && <Delete onClick={deleteUserRating} />}
        {!userRating && <Delete style={{ color: "white" }} />}
      </div>
      <Popover
        open={isOpen}
        anchorEl={popUpRef.current}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        container={popUpRef.current ? popUpRef.current.parentNode : undefined}
      >
        <Typography className={classesX.typography}>
          {titles[currentRating]}
        </Typography>
      </Popover>
    </>
  );
}

RatingControl.propTypes = {
  assembly: PropTypes.object.isRequired,
  userRating: PropTypes.object,
  popUpRef: PropTypes.object.isRequired,
  postSelect: PropTypes.func,
  rootStore: PropTypes.object.isRequired
};
export default withCachedRootStore(observer(RatingControl));
