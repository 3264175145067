const config = {
  general: {
    title: "StarHackIt",
    description: "React Mobx Glamorous Node Starter Kit",
    apiUrl: "/api/v1/",
    analytics: {
      google: ""
    },
    socialAuth: ["facebook", "google"],
    debug: {
      log: true,
      i18n: false
    },
    tourPath: "/main/tour",
    userRegisterPath: "/auth/register-page",
    userLoginPath: "/auth/login-page",
    registrationLoginPath: "/main/registrationLogin",
    registrationRegisterPath: "/main/gymRegister",
    successFulLoginPath: "/main/map",
    successFulRegistrationLoginPath: "/main/map",
    viewRoutePath: "/main/map",
    welcomePath: "/main/map",
    notAfterLoginPaths: [
      "/main/registrationLogin",
      "/main/registrationRegister"
    ],
    floorPlanPNG: "/climber/FloorPlan.png",
    nginxURL: "/climber"
  },

  development: {
    env: "development",
    debug: {
      log: true,
      i18n: false
    }
  },

  production: {
    env: "production",
    debug: {
      log: true
    }
  }
};

/* globall process */
console.log("process.env.NODE_ENV ", process.env.NODE_ENV);
export default Object.assign({}, config.general, config[process.env.NODE_ENV]);
