export default class {
  constructor(context, asyncOpCreate) {
    let rest = context.rest;

    this.opGetRatingGraph = asyncOpCreate(
      payload => rest.get("ratingGraph", payload),
      "opRatingGraph"
    );
    this.getRatingGraph = () => {
      this.opGetRatingGraph.execute();
    };
  }
}
