import SuggestionAi from "../../utils/RouteAi/SuggestionAI";

import { computed } from "mobx";
import { gradeTypes, planTypes } from "../../variables/constants";
import { getPlanningQuantityMap } from "../../utils/ReportStoreUtility";

import {
  byPlanningDate,
  getUnusedClimbSlotList,
  basedOnStrippingUrgency
} from "./settingReportStoreFns";

export default class ComputedSettingReportStore {
  constructor(context, rootStore) {
    // These are accessed via id

    this.getWallsWithSpace = gradeType => {
      if (gradeType === gradeTypes.BOULDER) {
        return this.boulderWallsWithSpace.get();
      } else if (gradeType === gradeTypes.ROUTE) {
        return this.routeWallsWithSpace.get();
      }
    };
    this.boulderWallsWithSpace = computed(
      () => {
        return this.getWallsWithSpaceByType(gradeTypes.BOULDER);
      },
      { keepAlive: true }
    );
    this.routeWallsWithSpace = computed(
      () => {
        return this.getWallsWithSpaceByType(gradeTypes.ROUTE);
      },
      { keepAlive: true }
    );

    this.getWallsWithSpaceByType = gradeType => {
      console.log("COMPUTING BOULDER WALLS WITH SPACE");

      const planningAssemblies = this.settingPlansWithWallAssemblies.get();
      return this.getSuggestionsAssembly(
        rootStore,
        gradeType,
        planningAssemblies,
        {
          ignoreStrippingPlans: true,
          sortTheResult: true,
          includeOnHoldRoutes: true // true = show Concrete in generated settingPlans
          // includeOnHoldRoutes: false // false= show Concrete In Strip list
        }
      );
    };

    this.settingPlansWithWallAssemblies = computed(
      () => {
        const settingPlans =
          rootStore.settingPlanStores.settingPlanCacheStore.items;
        console.log(
          "gsgsx COMPUTING settingPlansWithWallAssemblies with this number of saved plans:",
          rootStore.settingPlanStores.settingPlanCacheStore.items.length
        );

        return this.getSettingPlansWithWallAssemblies(settingPlans);
      },
      { keepAlive: true }
    );

    this.getSettingPlansWithWallAssemblies = settingPlans => {
      const {
        computedCacheStore,
        markerColorStores,
        setterAliasStores
      } = rootStore;

      const { markerColorStore } = markerColorStores;

      const { setterAliasStore } = setterAliasStores;

      const { gradeStores } = rootStore.gradeStores;

      const computedCache = computedCacheStore.computedCache.get();

      const planAssemblies = {
        [gradeTypes.BOULDER]: [],
        [gradeTypes.ROUTE]: []
      };

      const notedAsSet = [];
      computedCache.sectionAssemblies.forEach(sectionAssembly => {
        sectionAssembly.settingPlanCount = {
          [gradeTypes.BOULDER]: 0,
          [gradeTypes.ROUTE]: 0
        };

        sectionAssembly.climbingWallAssemblies.forEach(wallAssembly => {
          wallAssembly.settingPlanAssemblies = {
            [gradeTypes.BOULDER]: [],
            [gradeTypes.ROUTE]: []
          };

          //
          // Then we need to create an assembly for this replacementPlan
          let gradeStore = gradeStores[wallAssembly.climbingWall.gradeType];

          settingPlans.forEach(settingPlan => {
            if (settingPlan.climbing_wall_id === wallAssembly.climbingWall.id) {
              let newSettingAssembly = {
                wallAssembly: wallAssembly,
                settingPlan: settingPlan,
                planningDate: settingPlan.planningDate,

                //Assume the route has not been set until it is found
                isAlreadySet: false,

                //Assume the route is stripped until it is found
                isAlreadyStripped: true
              };

              //If this setting plan is a replacement plan or stripping plan
              if (
                settingPlan.planType === planTypes.REPLACE ||
                settingPlan.planType === planTypes.STRIP
              ) {
                if (settingPlan.routeToStrip) {
                  newSettingAssembly.routeToStripGrade = gradeStore.getItemById(
                    settingPlan.routeToStrip.climbing_grade_id
                  );
                  newSettingAssembly.routeToStripColor = markerColorStore.getItemById(
                    settingPlan.routeToStrip.marker_color_id
                  );
                } else {
                  console.log("The route to strip is missing???");
                  debugger;
                }
              }
              if (
                settingPlan.planType === planTypes.SET ||
                settingPlan.planType === planTypes.REPLACE
              ) {
                newSettingAssembly.climbingGrade = gradeStore.getItemById(
                  settingPlan.climbing_grade_id
                );
                newSettingAssembly.markerColor = markerColorStore.getItemById(
                  settingPlan.marker_color_id
                );
                newSettingAssembly.setterAlias = setterAliasStore.getItemById(
                  settingPlan.setter_alias_id
                );
              }

              for (let routeAssembly of wallAssembly.climbingRouteAssemblies) {
                //NOTE: I just looked for a non-existant bug for ages. If you plan a new "replace" of a route
                // that was created in a currently "fulfulled" plan. Then, after you do the new "replace", you have archived the
                // target of the fulfulled plan. That means that the fulfilled plan is no longer fulfilled. The original fulfilled
                // will now appear in the unfulfilled list, and the new plan that you just fulfilled will appear (correctly) in the fulfilled list.
                // This is not very good for the user but it is kinda correct... could be improved.

                // If there is a route of the right grade that was set after this planning was made
                // then we are assuming that this was the route to set.
                if (
                  routeAssembly.parsedSettingDateTime >
                    settingPlan.planningDate &&
                  routeAssembly.climbingGrade.id ===
                    settingPlan.climbing_grade_id
                ) {
                  let alreadyNoted = false;
                  for (let notedId of notedAsSet) {
                    if (notedId === routeAssembly.climbingRoute.id) {
                      //This route has been already used to mark a different setting plan as
                      // alreadySet. That means we can't use this route for this settingPlan
                      alreadyNoted = true;
                      break;
                    }
                  }
                  if (!alreadyNoted) {
                    newSettingAssembly.isAlreadySet = true;
                    newSettingAssembly.setRouteAssembly = routeAssembly;
                    notedAsSet.push(routeAssembly.climbingRoute.id);
                  }
                }

                //
                //If the route still exists so it hasn't been stripped
                if (
                  routeAssembly.climbingRoute.id ===
                  settingPlan.route_to_strip_id
                ) {
                  newSettingAssembly.isAlreadyStripped = false;
                  if (settingPlan.route_to_strip_id === 737) {
                    console.log(
                      "qaz Setting the settingPlan 737 to isAlreadyStripped=false"
                    );
                  }
                }

                // For efficiency we can leave this loop if set and stripped detected
                if (
                  newSettingAssembly.isAlreadySet &&
                  newSettingAssembly.isAlreadyStripped
                ) {
                  break;
                }
              }

              sectionAssembly.settingPlanCount[
                wallAssembly.climbingWall.gradeType
              ]++;
              wallAssembly.settingPlanAssemblies[
                wallAssembly.climbingWall.gradeType
              ].push(newSettingAssembly);

              planAssemblies[wallAssembly.climbingWall.gradeType].push(
                newSettingAssembly
              );
            } // Check wall ID
          });
        });
      });

      planAssemblies[gradeTypes.BOULDER].sort(byPlanningDate);
      planAssemblies[gradeTypes.ROUTE].sort(byPlanningDate);

      return {
        // sectionAssemblies: computedCache.sectionAssemblies,
        planAssemblies
      };
    };

    this.getSuggestionsAssembly = (
      rootStore,
      gradeType,
      planningAssemblies,
      options
    ) => {
      // this is a little hack to force duplicates only on ROUTES
      options.allowDuplicates = gradeType === gradeTypes.BOULDER;

      let { ignoreStrippingPlans, sortTheResult, allowDuplicates } = options;
      const { computedCacheStore, gradeStores } = rootStore;

      const computedCache = computedCacheStore.computedCache.get();
      const gradeCounts = computedCacheStore.getComputedGradeCounts(gradeType);

      const planAssemblies = planningAssemblies.planAssemblies[gradeType];

      const climbingGrades = gradeStores.gradeStores[
        gradeType
      ].getAllItemsEvenArchived();

      let now = new Date();

      let planningGradeCountMap = getPlanningQuantityMap(
        gradeCounts,
        {
          ignoreStrippingPlans
        },
        planAssemblies
      );

      /*
       *
       * First we need to create a "routeList" based on the counts
       * of unset routes and unset boulders
       *
       */
      let unsetClimbAssemblyList = [];

      for (let cg = 0; cg < climbingGrades.length; cg++) {
        let grade = climbingGrades[cg];
        if (!grade.isArchived) {
          let counts = planningGradeCountMap[grade.id];

          let totalNeeded = counts.targetQuantity - counts.actualQuantity;

          for (let i = 0; i < totalNeeded; i++) {
            unsetClimbAssemblyList.push({ climbingGrade: grade });
          }
        }
      }

      let byGradeCounts = function(idA, idB) {
        if (
          typeof planningGradeCountMap[idB.climbingGrade.id] === "undefined"
        ) {
          return -1;
        }
        if (
          typeof planningGradeCountMap[idA.climbingGrade.id] === "undefined"
        ) {
          return 1;
        }
        return (
          planningGradeCountMap[idA.climbingGrade.id].actualQuantity /
            planningGradeCountMap[idA.climbingGrade.id].targetQuantity -
          planningGradeCountMap[idB.climbingGrade.id].actualQuantity /
            planningGradeCountMap[idB.climbingGrade.id].targetQuantity
        );
      };

      unsetClimbAssemblyList.sort(byGradeCounts);
      /*
       *
       * Now we need to create the list of available slots from each wall
       *
       */

      //let usedRouteSlotCount = 0;

      let unusedClimbSlotList = getUnusedClimbSlotList(
        computedCache,
        gradeType,
        now,
        planAssemblies,
        options,
        gradeStores,
        planningGradeCountMap,
        climbingGrades
      );

      unusedClimbSlotList.sort(basedOnStrippingUrgency);

      let optionsAI = { allowFailures: true, skipDuplicateCheck: false };

      options.skipDuplicateCheck = allowDuplicates;

      let suggestionsAssembly = SuggestionAi(
        unsetClimbAssemblyList,
        unusedClimbSlotList,
        optionsAI
      );

      if (sortTheResult) {
        suggestionsAssembly.suggestions.sort(byGradeCounts);
      }
      return suggestionsAssembly;
    };
  }
}
