import React from "react";
import PropTypes from "prop-types";

import DoubleUp from "../../icons/DoubleUp";
import DoubleDown from "../../icons/DoubleDown";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

function ModifierIcon({ modifier, className }) {
  if (typeof modifier === "undefined" || modifier === null) {
    modifier = 0;
  }

  if (modifier > 2) {
    if (modifier > 5) {
      return <DoubleUp className={className} />;
    }
    return <ExpandLess className={className} />;
  }

  if (modifier < -2) {
    if (modifier < -5) {
      return <DoubleDown className={className} />;
    }
    return <ExpandMore className={className} />;
  }
  return null;
}

ModifierIcon.propTypes = {
  modifier: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired
};

export default React.memo(ModifierIcon);
