import { observable } from "mobx";

export default class Cacher {
  constructor(cachables, cacheName) {
    if (!Array.isArray(cachables)) {
      console.log("The cachables are the wrong type");
      debugger;
      throw new Error("The cachables are the wront type: " + cachables);
    }
    this.cachables = cachables;
    this.cacheName = cacheName;

    this.cacheStatus = observable({
      isPrePostCacheLoaded: false
    });

    this.loadCache = async (params, postCacheFn) => {
      if (this.isCacheLoading()) {
        console.log(
          "Can't load the " +
            this.cacheName +
            " cache because it is already loading."
        );
        return false;
      }
      if (!params.forceReload && this.isCacheLoaded()) {
        console.log(
          "Can't load the " +
            this.cacheName +
            " cache because it is already loaded."
        );
        return false;
      }

      if (this.delayed) {
        console.log("Not loading the cache now because it is delayed");
        return false;
      }
      let promises = [];
      this.cachables.forEach(cachable => {
        if (!cachable.conditionFn || cachable.conditionFn()) {
          console.log("Loading cache " + cachable.store.getStoreName());
          promises.push(cachable.store.cacheItems(params));
        } else {
          console.log(
            "Skipping the cache '" +
              cachable.store.getStoreName() +
              "' because the condition wasn't met"
          );
        }
      });

      console.log(
        "Awaiting the " +
          this.cacheName +
          " cache to be loaded in loadCache. There are " +
          promises.length +
          " promises to wait on"
      );
      await Promise.all(promises).catch(() => {
        console.log(
          "Failed to load the cache. Delaying 20secs before trying again"
        );
        this.delayed = true;
        setTimeout(() => {
          this.delayed = false;
          //this.loadCache(params, postCacheFn);
        }, 20000);
      });

      this.cacheStatus.isPrePostCacheLoaded = true;
      if (postCacheFn) {
        postCacheFn();
      }
      return true;
    };

    this.isCacheLoading = () => {
      for (var i = 0; i < this.cachables.length; i++) {
        if (this.cachables[i].store.opCacheItems.executing === true) {
          return true;
        }
      }
      return false;
    };

    this.isCacheLoaded = () => {
      for (var i = 0; i < this.cachables.length; i++) {
        if (!this.cachables[i].conditionFn || this.cachables[i].conditionFn()) {
          if (this.cachables[i].store.opCacheItems.executed !== true) {
            console.log(
              "The cache is not loaded because at least this one isn't finished:" +
                this.cachables[i].store.opCacheItems.getFnName()
            );
            return false;
          }
        }
      }
      return true;
    };

    this.clearCache = () => {
      if (this.isCacheLoading()) {
        console.log("Can't clear the cache because it is still loading.");
        return false;
      }

      this.cacheStatus.isPrePostCacheLoaded = false;
      for (var i = 0; i < this.cachables.length; i++) {
        if (!this.cachables[i].store.clearCache) {
          debugger;
          console.log("THE cachable doesn't have a clearCache function.");
          throw new Error("THE cachable doesn't have a clearCache function.");
        }
        this.cachables[i].store.clearCache();
      }
      return true;
    };
  }
}
