import moment from "moment";
import { gradeTypes } from "../variables/constants";
import { getRelativeCombinedModifier } from "../utils/RegradeModifier";

export default function(rootStore, climbingRoute) {
  const {
    setterAliasStores,
    gradeStores,
    markerColorStores,
    gymStores: {
      gymStore: { gradeLongevityAdjustmentsJSON }
    }
  } = rootStore;

  const markerColor = markerColorStores.markerColorStore.getItemById(
    climbingRoute.marker_color_id
  );
  const setterAlias = setterAliasStores.setterAliasStore.getItemById(
    climbingRoute.setter_alias_id
  );
  let climbingGrade = gradeStores.gradeStores[gradeTypes.ROUTE].getItemById(
    climbingRoute.climbing_grade_id
  );

  if (climbingGrade === null) {
    climbingGrade = gradeStores.gradeStores[gradeTypes.BOULDER].getItemById(
      climbingRoute.climbing_grade_id
    );
  }

  if (climbingGrade === null) {
    console.log("The grade is null for some reason");
    console.log("The climbingRoute =" + JSON.stringify(climbingRoute));
    debugger;
  }

  /*
   * this call to getTextInfo() could be optimized a lot by caching the textInfo of the markerColors
   * instead of generating it for each route each time
   */
  let textInfo = getTextInfo(markerColor);
  if (!textInfo.color) {
    debugger;
  }

  let relativeCombinedModifier = climbingRoute.avgCombinedModifier
    ? getRelativeCombinedModifier(
        climbingRoute.avgCombinedModifier,
        climbingGrade.orderPosition
      )
    : undefined;

  let gradeAdjustment;

  for (let i = 0; i < gradeLongevityAdjustmentsJSON.length; i++) {
    if (
      gradeLongevityAdjustmentsJSON[i].climbing_grade_id === climbingGrade.id
    ) {
      gradeAdjustment = gradeLongevityAdjustmentsJSON[i].adjustment;
      break;
    }
  }

  if (typeof gradeAdjustment === "undefined") {
    console.error("The gradeAdjustment couldn't be found.");
    debugger;
    gradeAdjustment = 0;
  }

  let parsedSettingDateTime = moment(climbingRoute.settingDateTime);

  let adjustedSettingDateTime = moment(parsedSettingDateTime);

  if (gradeAdjustment) {
    // The call to moment here creates a copy of the parsed date before subtracting the gradeAdjustment
    adjustedSettingDateTime = adjustedSettingDateTime.subtract(
      gradeAdjustment,
      "d"
    );
  }

  let newAssembly = {
    markerColor,
    setterAlias,
    climbingRoute,
    climbingGrade,
    parsedSettingDateTime,
    adjustedSettingDateTime,
    markerTextColor: textInfo.color,
    markerTextNeedsShadow: textInfo.needsShadow,
    relativeCombinedModifier
  };

  return newAssembly;
}

function getTextInfo(markerColor) {
  // This shouldn't be possible but we may as well have a saftey
  if (!markerColor.primaryHEX) {
    return {
      color: "#000000",
      needsShadow: false
    };
  }

  let primaryHexColor = getCorrectTextColor(markerColor.primaryHEX);
  if (!markerColor.secondaryHEX) {
    return {
      color: primaryHexColor,
      needsShadow: false
    };
  }

  let secondaryHexColor = getCorrectTextColor(markerColor.secondaryHEX);
  if (secondaryHexColor === primaryHexColor) {
    return {
      color: primaryHexColor,
      needsShadow: false
    };
  }

  return {
    color: "#ffffff",
    needsShadow: true
  };
}

function getCorrectTextColor(hex) {
  if (!hex) {
    debugger;
  }
  const threshold = 140; /* about half of 256. Lower threshold equals more dark text on dark background  */

  const hRed = hexToR(hex);
  const hGreen = hexToG(hex);
  const hBlue = hexToB(hex);

  function hexToR(h) {
    return parseInt(cutHex(h).substring(0, 2), 16);
  }
  function hexToG(h) {
    return parseInt(cutHex(h).substring(2, 4), 16);
  }
  function hexToB(h) {
    return parseInt(cutHex(h).substring(4, 6), 16);
  }
  function cutHex(h) {
    return h.charAt(0) == "#" ? h.substring(1, 7) : h;
  }

  const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
  if (cBrightness > threshold) {
    return "#000000";
  } else {
    return "#ffffff";
  }
}
