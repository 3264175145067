import React from "react";
import { useObserver } from "mobx-react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Fade from "@material-ui/core/Fade";

// core components
import {
  GridItem,
  Card,
  CardBody,
  MoreListButton,
  GradeSlider,
  LabelledCheckBox,
  SortButton,
  LoginRequest,
  ClimbCard
} from "ticker-components";

import {
  withCachedRootStore,
  gradeTypes,
  getInspirationSortOptions
} from "ticker-core";

import styles from "../../assets/jss/material-dashboard-pro-react/views/inspirationPageStyle.js";

let useInspirationPageStyle = makeStyles(styles);

const InspirationPage = function InspirationPage(props) {
  let { rootStore } = props;

  let {
    personalRootStore: { inspirationStore, inspirationUIOptions },
    regStores: { regStore }
  } = rootStore;

  let {
    listPageSelection: { selection, UISelection },
    transitionStore
  } = inspirationStore;

  const classes = useInspirationPageStyle();

  let { personalRootStore } = rootStore;

  return useObserver(() => {
    if (!rootStore.isCacheLoaded()) {
      return <div>LOADING</div>;
    }

    console.log("asdd ------------ RENDERING THE INspiration page");

    let cache = personalRootStore.getCacheWithPersonalDataIfPossible();

    let allClimbingAssemblies = inspirationStore.filteredListPage.get()
      .climbingRouteAssemblies;

    const selections = [
      [
        {
          onClick: () => {
            inspirationStore.transitionStore.changeGradeTypeSelection(
              "listPageSelection",
              gradeTypes.ROUTE,
              !UISelection.showGradeTypes[gradeTypes.ROUTE],
              true
            );
          },
          label: "Routes",
          isSelected: UISelection.showGradeTypes[gradeTypes.ROUTE],
          showCross: false
        },
        {
          onClick: () => {
            inspirationStore.transitionStore.changeGradeTypeSelection(
              "listPageSelection",
              gradeTypes.BOULDER,
              !UISelection.showGradeTypes[gradeTypes.BOULDER],
              true
            );
          },
          label: "Boulder",
          isSelected: UISelection.showGradeTypes[gradeTypes.BOULDER],
          showCross: false
        }
      ],
      inspirationUIOptions.getSelectionOptions({
        allowNoSelection: true,
        fieldName: "hasClimbed",
        mapObj: {
          Unclimbed: false,
          Climbed: true
        },
        pageName: "listPageSelection",
        wellPadded: true
      })
    ];

    const sectionSelections = cache.sectionAssemblies.map(sectionAssembly => {
      let { climbingSection } = sectionAssembly;
      let { UISelection } = inspirationStore.listPageSelection;
      let { transitionStore } = inspirationStore;

      if (climbingSection.isArchived) return undefined;

      let gradeType = inspirationStore.getUISelectionGradeType(
        "listPageSelection"
      );

      if (!sectionAssembly.hasGradeType[gradeType]) {
        return undefined;
      }

      return {
        onClick: function() {
          transitionStore.changeSectionSelectionForMapPage(
            inspirationStore.listPageSelection,
            climbingSection,
            gradeType
          );
        },
        label: climbingSection.name,
        isSelected: UISelection.showSectionIds[climbingSection.id],
        showCross: true
      };
    });

    let sortOptions = getInspirationSortOptions(
      regStore,
      transitionStore,
      inspirationStore,
      "listPageSelection",
      personalRootStore.personalDataExists()
    );
    console.log("asdd -- The sortOptions=" + JSON.stringify(sortOptions));

    let selectionTools = (
      <>
        {selections.map((row, index) => (
          <div key={index} style={{ textAlign: "center" }}>
            {row.map((option, index) => {
              if (!option) return undefined;
              return (
                <LabelledCheckBox
                  key={index}
                  onChange={() => {
                    regStore.resetNoActivityTimeout();
                    option.onClick();
                  }}
                  checked={option.isSelected}
                  labelText={option.label}
                  labelClass={classes.text}
                  className={
                    option.wellPadded ? classes.wellPadded : classes.padded
                  }
                  noBorder
                />
              );
            })}
          </div>
        ))}

        <div style={{ textAlign: "center" }}>
          {sectionSelections.map((option, index) => {
            if (!option) return undefined;
            return (
              <LabelledCheckBox
                key={index}
                onChange={() => {
                  regStore.resetNoActivityTimeout();
                  option.onClick();
                }}
                checked={option.isSelected}
                labelText={option.label}
                labelClass={classes.text}
                className={classes.padded}
                noBorder
              />
            );
          })}
        </div>

        <GradeSlider
          gradeType={inspirationStore.getUISelectionGradeType(
            "listPageSelection"
          )}
          fadeDelay={transitionStore.FADE_OUT_DURATION}
          setFadeIn={transitionStore.startFadeChange}
          pageSelection={inspirationStore.listPageSelection}
          containerClass={classes.sliderContainer}
        />
        <div style={{ textAlign: "center" }}>
          {sortOptions.map((option, index) => {
            if (!option) return undefined;
            return (
              <SortButton
                key={index}
                onClick={() => {
                  regStore.resetNoActivityTimeout();
                  option.onClick();
                }}
                label={option.label}
                isSelected={option.isSelected}
                ascending={option.ascending}
              />
            );
          })}
        </div>
      </>
    );

    let renderAssembly = assembly => {
      let keepVisible = () => {
        console.log("Keeping visible");
        transitionStore.stayVisibleMap = {
          ...transitionStore.stayVisibleMap,
          [assembly.climbingRoute.id]: true
        };
      };

      return (
        <GridItem xs={12} sm={6} md={5} lg={3} key={assembly.climbingRoute.id}>
          <ClimbCard
            assembly={assembly}
            onTick={keepVisible}
            onDeleteTick={keepVisible}
            showTitle={true}
          />
        </GridItem>
      );
    };

    return (
      <>
        <div className={classes.slenderContainer}>
          <Card narrowWhite className={classes.highTop}>
            <CardBody narrowWhite pricing>
              {selectionTools}
            </CardBody>
          </Card>
          <Fade
            in={transitionStore.isFadingIn}
            timeout={{
              enter: transitionStore.FADE_IN_DURATION,
              exit: transitionStore.FADE_OUT_DURATION
            }}
          >
            <div>
              <MoreListButton
                fullArray={allClimbingAssemblies}
                childrenFn={renderAssembly}
                singular="climb"
                plural="climbs"
                missingMessage="No climbs match these filters."
                resetNoActivityTimeout={regStore.resetNoActivityTimeout}
              />
            </div>
          </Fade>
          {!personalRootStore.personalDataExists() &&
          selection.hasClimbed === true ? (
            <h5 style={{ textAlign: "center " }}>
              <LoginRequest instruction="to see climbs that you have climbed." />
            </h5>
          ) : (
            undefined
          )}
        </div>
      </>
    );
  });
};

InspirationPage.propTypes = {};

export default withCachedRootStore(InspirationPage);
