import { observable, action } from "mobx";

import rules from "../services/rules.js";
import validate from "validate.js";

export default class UserStores {
  constructor(context, hist, asyncOpCreate, initClimberSiteUserFn) {
    const rest = context.rest;

    this.hist = hist;

    function merge(profile, response) {
      profile.username = response.username;
      profile.email = response.email;
      if (response.gym_id !== null && typeof response.gym_id !== "undefined") {
        profile.gym_id = parseInt(response.gym_id, 10);
      }
      if (
        response.selected_gym_id !== null &&
        typeof response.selected_gym_id !== "undefined"
      ) {
        profile.selected_gym_id = parseInt(response.selected_gym_id, 10);
      }
      if (response.id) {
        profile.user_id = response.id;
      }
    }

    this.profileStore = observable({
      getStoreName: () => "profileStore",
      hasLoaded: false,
      language: "US",
      username: "",
      email: "",
      selected_gym_id: null,
      gym_id: null,
      user_id: null,
      opCacheItems: asyncOpCreate(() => rest.get("me"), "cacheMe"),
      cacheItems: action(async function({ forceReload }, callBack) {
        if (this.username === "" || forceReload === true) {
          try {
            console.log("Starting to cache the user profile");
            this.hasLoaded = false;
            await this.opCacheItems.execute({}, response => {
              merge(this, response);

              initClimberSiteUserFn(response.Registrations[0]);
              this.hasLoaded = true;
              if (callBack) {
                callBack();
              }
            });
          } catch (error) {
            if (error === null || typeof error === "undefined") {
              debugger;
              console.log(
                "The call to 'me' has failed inside the UserProfile Store. The error is undefined."
              );
            } else {
              console.log("The call to 'me' has an error: " + error);
              if (
                error.response &&
                typeof error.response.status !== undefined
              ) {
                const {
                  response: { status }
                } = error;
                if (401 === status) {
                  console.log(
                    "The call to 'me' has returned with 401. The user is probably not logged in"
                  );
                }
              } else {
                console.log("The call to 'me' has failed: " + error);
              }
            }
          }
        } else {
          console.log(
            "The user profile seems to be loaded already. Not caching."
          );
        }
      }),
      opUpdate: asyncOpCreate(payload => rest.patch("me", payload), "updateMe"),
      update: action(async function() {
        this.errors = {};
        const payload = {
          biography: this.profile.biography || ""
        };
        const constraints = {
          biography: rules.biography
        };
        const vErrors = validate(payload, constraints);
        if (vErrors) {
          this.errors = vErrors;
          return;
        }
        const response = await this.opUpdate.execute(payload);
        merge(this, response);
        //          context.alertStack.add(
        //            <Alert.Info message={tr.t("Profile updated")} />
        //          );
      })
    });
  }
}
