import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

let style = {
  transitionBox: {
    transition: "all .25s linear",
    display: "inline-block"
  },
  highlight: {
    transform: "scale(3)"
  }
};

let useStyles = makeStyles(style);

const HighlightChange = props => {
  let [monitoredValue, setMonitoredValue] = useState(props.monitoredValue);
  let [hasChanged, setHasChanged] = useState();

  let classes = useStyles();

  useEffect(() => {
    setMonitoredValue(props.monitoredValue);

    // Has Changed has been set to false already
    // then we can now assume a change is happening
    if (hasChanged === false) {
      setHasChanged(true);
      let timeoutHandle = setTimeout(() => {
        setHasChanged(false);
      }, 250);
      return () => {
        clearTimeout(timeoutHandle);
      };
    }

    // Has change hadn't been set to false yet so this must be the first change, we'll ignore it.
    // We are doing this to avoid the effect happening on page load for some reason
    setHasChanged(false);
  }, [props.monitoredValue]);

  let joinedClasses = classes.transitionBox;
  if (hasChanged) {
    joinedClasses = joinedClasses + " " + classes.highlight;
  }
  return <div className={joinedClasses}>{props.children}</div>;
};

HighlightChange.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  monitoredValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default HighlightChange;
