import React from "react";

import PropTypes from "prop-types";
import moment from "moment";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import { Button, GridContainer, GridItem } from "ticker-components";
import Archive from "@material-ui/icons/Archive";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function IssuesDialog({
  updateIssuesStore,
  assembly,
  onClose,
  open,
  postArchiveOnClick
}) {
  if (!assembly.unarchivedIssues) {
    // This can happen when the postArchiveOnClick deletes the archived issues
    // before the dialog is closed;
    return null;
  }
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down(500));

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullScreen={fullScreen}
    >
      <DialogTitle>Reported Issues of this climb</DialogTitle>
      <DialogContent>
        {assembly.unarchivedIssues.map(issue => {
          return (
            <DialogContentText key={issue.id}>
              <GridContainer>
                <GridItem xs={10}>
                  <h5>{issue["User.Registrations.userName"]}</h5>
                  <div>{issue.description}</div>
                  <h6>Reported on {moment(issue.create_at).format("LLL")}</h6>
                </GridItem>
                <GridItem xs={2}>
                  <p style={{ float: "right" }}>
                    <Button
                      justIcon
                      color="info"
                      round
                      onClick={async () => {
                        updateIssuesStore.issueId = issue.id;
                        updateIssuesStore.isArchived = !issue.isArchived;
                        await updateIssuesStore.updateIssue();

                        if (postArchiveOnClick) {
                          postArchiveOnClick(issue);
                        }
                      }}
                    >
                      <Archive />
                    </Button>
                  </p>
                </GridItem>
              </GridContainer>
            </DialogContentText>
          );
        })}
      </DialogContent>
      <div style={{ textAlign: "center" }}>
        <Button color="success" simple onClick={onClose}>
          Close
        </Button>
      </div>
    </Dialog>
  );
}

IssuesDialog.propTypes = {
  assembly: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  postArchiveOnClick: PropTypes.func,
  updateIssuesStore: PropTypes.object.isR
};

export default IssuesDialog;
