import React, { useEffect } from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import { withRootStore } from "ticker-core";

import loginPageStyle from "../assets/jss/material-dashboard-pro-react/views/loginPageStyle";

const logoutPage = function(props) {
  // react-hooks/rules-of-hooks
  // eslint-disable-next-line
  useEffect(() => {
    const lo = props.rootStore.authStores.getStores().logout;
    lo.logout();
  }, [props.rootStore.authStores]);

  const { classes } = props;
  return <div className={classes.container}>Logging Out</div>;
};

logoutPage.propTypes = {
  classes: PropTypes.object.isRequired,
  rootStore: PropTypes.shape({
    authStores: PropTypes.object.isRequired
  })
};

export default withRootStore(withStyles(loginPageStyle)(logoutPage));
