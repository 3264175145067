import Recycler from "./Recycler";

export default function createMappingsContainer() {
  let this__ = {};

  this__.mappings = [];
  this__.recycler = new Recycler();

  this__.addMapping = function addMapping(mapping) {
    this.mappings.push(mapping);
  };

  this__.popAndRecycle = function popAndRecycle() {
    this__.recycler.recycleMappingObject(this.mappings.pop());
  };
  this__.popWithoutRecycle = function popWithoutRecycle() {
    return this.mappings.pop();
  };

  this__.addMappingPropsMSC = function addMappingPropsMSC(
    isMapped,
    slotIndex,
    climbIndex
  ) {
    let recycledMapping = this__.recycler.getRecycledMappingObject();
    recycledMapping.isMapped = isMapped;
    recycledMapping.slotIndex = slotIndex;
    recycledMapping.climbIndex = climbIndex;
    this__.addMapping(recycledMapping);
  };

  this__.overwritePositionMSC = function overwritePositionMSC(
    isMapped,
    slotIndex,
    climbIndex,
    index
  ) {
    if (!this__.mappings[index]) {
      this__.mappings[index] = this__.recycler.getRecycledMappingObject();
    }

    this__.mappings[index].isMapped = isMapped;
    this__.mappings[index].slotIndex = slotIndex;
    this__.mappings[index].climbIndex = climbIndex;
  };

  this__.addMappingIfMissing = function addMapping(testSlotIndex) {
    let inDeepestStructure = false;
    for (let j = 0; j < this__.mappings.length; j++) {
      if (testSlotIndex === this__.mappings[j].slotIndex) {
        inDeepestStructure = true;
      }
    }
    if (inDeepestStructure === true) {
      //do nothing
      //console.log('SKIPPING ' + this.slots[i].slotStr);
    } else {
      let recycledMapping = this__.recycler.getRecycledMappingObject();
      recycledMapping.isMapped = false;
      recycledMapping.slotIndex = testSlotIndex;
      recycledMapping.climbIndex = undefined;
      this__.addMapping(recycledMapping);
    }
  };

  this__.getSlotsFilledCount = function getSlotsFilledCount() {
    let slotsFilled = 0;
    for (let i = 0; i < this__.mappings.length; i++) {
      if (this__.mappings[i].isMapped === true) {
        slotsFilled = slotsFilled + 1;
      }
    }
    return slotsFilled;
  };

  this__.hasClimb = function hasClimb(climbIndex) {
    for (let x = 0; x < this__.mappings.length; x++) {
      if (this__.mappings[x].climbIndex === climbIndex) {
        return true;
      }
    }
    return false;
  };

  this__.hasSlot = function hasSlot(slotIndex) {
    for (let j = 0; j < this__.mappings.length; j++) {
      if (this__.mappings[j].slotIndex === slotIndex) {
        return true;
      }
    }

    return false;
  };

  return this__;
}
