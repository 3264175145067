import moment from "moment";
import React from "react";
import Button from "@material-ui/core/Button";

class ArchiveUtility {
  async archiveClimb(climbId, rootStore, delay, isAlteration) {
    const {
      archivedStore,
      climbingRouteStore,
      climbingRouteCacheStore
    } = rootStore.climbingRouteStores;

    // if (typeof climbId === "number") {
    //   climb = climbingRouteCacheStore.getItemById(climb);
    // }

    const { notificationStore } = rootStore;

    this.myThisKey = notificationStore.enqueueNotification("Archiving", {
      variant: "info",
      persist: true
    });

    // if (climb.isArchived) {
    //   notificationStore.enqueueNotification(
    //     "Can't archive a climb that is already archived",
    //     {
    //       variant: "warning"
    //     }
    //   );
    //   console.log("ERROR: Can't archive a climb that is already archived");
    //   return;
    // }

    let payload = {
      id: climbId,
      isArchived: true,
      archiveDate: moment()
        .utc()
        .toISOString()
    };
    if (isAlteration) {
      payload.isAlteration = true;
    }

    console.log(
      "uuuxx ClimbingRouteControls.saveRoute() archiving route:" + payload.id
    );

    // climbingRouteStore.merge(payload, climbingRouteStore, true);
    try {
      await climbingRouteStore.opUpdateClimbingRoute
        .execute({ payload, id: payload.id })
        .then(updatedClimb => {
          archivedStore.prependClimbingRoute(updatedClimb);

          if (!delay) {
            climbingRouteCacheStore.removeItemById(updatedClimb.id);
          } else {
            //This delay is to allow the object to collapse out
            setTimeout(() => {
              climbingRouteCacheStore.removeItemById(updatedClimb.id);
            }, delay);
          }

          rootStore.notificationStore.removePersistedNotification(
            this.myThisKey
          );

          const action = (/*notiStackKey*/) => {
            return (
              <Button
                onClick={function() {
                  notificationStore.removePersistedNotification(
                    this.myNewArchivedKey
                  );
                  this.unArchiveClimb(updatedClimb.id, rootStore, 0);
                }.bind(this)}
              >
                Undo Archive
              </Button>
            );
          };

          this.myNewArchivedKey = notificationStore.enqueueNotification(
            "Archived",
            {
              variant: "success",
              action
            }
          );
        });
    } catch (error) {
      console.log(
        "there was an error while calling updateClimbingRoute() to archive the climb"
      );
      rootStore.notificationStore.removePersistedNotification(this.myThisKey);
    }
  }

  unArchiveClimb(climbId, rootStore, delay) {
    const {
      archivedStore,
      climbingRouteStore,
      climbingRouteCacheStore
    } = rootStore.climbingRouteStores;

    const { notificationStore } = rootStore;

    this.myThisKey = notificationStore.enqueueNotification("Un-archiving", {
      variant: "info",
      persist: true
    });

    let payload = {
      id: climbId,
      isArchived: false,
      archiveDate: moment()
        .utc()
        .toISOString()
    };

    // if (!climb.isArchived) {
    //   console.log("ERROR: Can't archive a climb that is already archived");
    //   debugger;
    //   return;
    // }

    // climbingRouteStore.merge(payload, climbingRouteStore, true);
    climbingRouteStore.opUpdateClimbingRoute
      .execute({ payload, id: payload.id })
      .then(unArchivedRoute => {
        climbingRouteCacheStore.items.push(unArchivedRoute);

        if (!delay) {
          archivedStore.removeItemById(unArchivedRoute.id);
        } else {
          //This delay is to allow the object to collapse out
          setTimeout(() => {
            archivedStore.removeItemById(unArchivedRoute.id);
          }, delay);
        }

        rootStore.notificationStore.removePersistedNotification(this.myThisKey);

        const notificationAction = notiStackKey => {
          return (
            <Button
              onClick={() => {
                notificationStore.removePersistedNotification(
                  this.myNewArchivedKey
                );
                this.archiveClimb(unArchivedRoute.id, rootStore, 0);
              }}
            >
              Re-archive
            </Button>
          );
        };
        this.myNewArchivedKey = notificationStore.enqueueNotification(
          "Un-Archived",
          {
            variant: "success",
            action: notificationAction
          }
        );
      });
  }
}

export default new ArchiveUtility();
