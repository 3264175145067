import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Fade from "@material-ui/core/Fade";

import "react-simple-keyboard/build/css/index.css";

import { makeStyles } from "@material-ui/core/styles";

// core components

import { withRootStore } from "ticker-core";
import {
  Button,
  NextPanel,
  UserNameKeyboard,
  PasscodeKeyboard,
  mockCursorStyle,
  LoginRequest
} from "ticker-components";

let FADE_DURATION = 300;

let style = {
  bigButton: { textTransform: "none", fontSize: "large" },
  ...mockCursorStyle
};

const useStyles = makeStyles(style);

function ClaimPage({ rootStore }) {
  const [userName, setUserName] = useState("");
  const [passcode, setPasscode] = useState("");
  let [registrationMessage, setRegistrationMessage] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [fadingOut, setFadingOut] = useState(false);
  const [clearNow, setClearNow] = useState(false);

  const classes = useStyles();

  const {
    regStores: { regStore },
    userStores: { profileStore },
    claimStore,
    gymStores: { gymStore }
  } = rootStore;

  useEffect(() => {
    if (regStore.isLoggedIn) {
      setFadingOut(true);
      setTimeout(() => {
        console.log(
          "ssss rootStore.history.hack_prevPushPath=" +
            rootStore.history.hack_prevPushPath
        );
        for (let NALP of rootStore.context.config.notAfterLoginPaths) {
          /*
           * The hack_prevPushPath is added in the hist.push() function
           * because the push() is overridden in index.js of the climber site.
           * If you need this in other sites too then we'll need to
           * put the hack code where push() is overridden elsewhere
           */
          if (
            !rootStore.history.hack_prevPushPath ||
            NALP === rootStore.history.hack_prevPushPath
          ) {
            rootStore.history.push("map");
            return;
          }
        }
        rootStore.history.goBack();
      }, FADE_DURATION);
    }
  }, [regStore.isLoggedIn, rootStore.history]);

  useEffect(() => {
    if (clearNow) {
      setClearNow(false);
    }
  });

  const onChange = newUserName => {
    setUserName(newUserName);
  };

  const passcodeOnChange = newCode => {
    setRegistrationMessage(null);
    if (newCode.length === 5) {
      setRegistrationMessage({
        type: "good",
        msg: "Click 'Claim' to claim this account."
      });
    }
    //NOTE: This wont get called if the newCode is longer than 5 digits
    // See PasscodeKeyboard.js
    setPasscode(newCode);
  };

  const onClearPasscode = () => {
    setPasscode("");
    setRegistrationMessage(null);
  };

  const backToUserNameClick = () => {
    setRegistrationMessage(null);
    pageTransition(0);
    setPasscode("");
    setUserName("");
  };

  const pageTransition = newIndex => {
    setFadingOut(true);
    setTimeout(() => {
      setActiveIndex(newIndex);
      setFadingOut(false);
    }, FADE_DURATION + 50);
  };

  const nextToPasscodeClick = () => {
    regStore.resetNoActivityTimeout();
    console.log(
      "The Username in nextToPasscodeClick for passcode is " + userName
    );

    if (userName.length < 3) {
      setRegistrationMessage({
        type: "warning",
        msg: "Your username must have at least 3 letters."
      });
      return;
    }
    changeViews(1);
  };

  const changeViews = newActiveIndex => {
    setActiveIndex(newActiveIndex);
    setRegistrationMessage(null);
  };

  const claim = () => {
    claimStore.gym_id = rootStore.userStores.profileStore.selected_gym_id;
    claimStore.userName = userName;
    claimStore.passcode = passcode;
    let callBack = result => {
      console.log("The result is :", result);
      if (result) {
        console.log("Success. Should Reload the page now.");
        rootStore.history.push("/");
        window.location.reload();
      }
    };
    let status404Callback = () => {
      setRegistrationMessage({
        type: "warning",
        msg: "A user with that username and passcode couldn't be found."
      });
    };
    claimStore.claim(callBack, status404Callback);
  };

  return useObserver(() => {
    if (!profileStore.hasLoaded) {
      return (
        <>
          <div style={{ padding: "2em" }}>
            <LoginRequest instruction=" to claim your account." />
          </div>
        </>
      );
    }
    let isLoggingIn =
      regStore.opLoginRegistration.executing || regStore.isLoggedIn;

    let contents;
    if (!isLoggingIn && activeIndex === 0) {
      contents = (
        <div>
          <NextPanel
            title={
              "Claim an account that you have already set up at " +
              gymStore.name
            }
            subTitle="Type in your full User Name for HangDog"
            input={
              <h2 className={classes.inputContainer}>
                {userName}
                <div className={classes.mockCursor}>|</div>
              </h2>
            }
            //BUG: There can only be one keyboard on the screen at a time
            //Therefore not displaying the keyboard if it is not the current activeIndex
            keyboard={
              activeIndex === 0 ? (
                <UserNameKeyboard
                  onChange={onChange}
                  showShift={false}
                  regStore={regStore}
                />
              ) : (
                undefined
              )
            }
            rightButton={
              <Button
                onClick={nextToPasscodeClick}
                color="success"
                className={classes.bigButton}
              >
                Continue
              </Button>
            }
            registrationMessage={registrationMessage}
          />
        </div>
      );
    } else {
      let input;
      if (!isLoggingIn) {
        input = (
          <h2 className={classes.inputContainer}>
            {passcode.replace(/./g, "*")}
            <div className={classes.mockCursor}>|</div>
          </h2>
        );
      }

      contents = (
        <div>
          <NextPanel
            title={userName}
            subTitle={"Please enter your 5 digit passcode."}
            //BUG: There can only be one keyboard on the screen at a time
            //Therefore not displaying the keyboard if it is not the current activeIndex
            keyboard={
              activeIndex === 1 ? (
                <PasscodeKeyboard
                  onChange={passcodeOnChange}
                  onClear={onClearPasscode}
                  clearNow={clearNow}
                  regStore={regStore}
                  codeLength={5}
                />
              ) : (
                undefined
              )
            }
            leftButton={
              <Button
                onClick={backToUserNameClick}
                simple
                color="info"
                className={classes.bigButton}
              >
                Not {userName}?
              </Button>
            }
            rightButton={
              <Button
                onClick={claim}
                color="success"
                className={classes.bigButton}
              >
                Claim Account
              </Button>
            }
            input={input}
            key={2}
            registrationMessage={registrationMessage}
          />
        </div>
      );
    }
    return (
      <>
        <Fade in={!fadingOut} timeout={FADE_DURATION}>
          {contents}
        </Fade>
      </>
    );
  });
}

ClaimPage.propTypes = {
  classes: PropTypes.object,
  rootStore: PropTypes.object
};

export default withRootStore(ClaimPage);
