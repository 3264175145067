import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";

// core components
import Button from "../CustomButtons/Button";

import styles from "../../assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // verifies if routeName is the one active (in browser input)
  const isActiveRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  const classes = useStyles();
  const { color, brandText, routes, layout } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  var list = (
    <List className={classes.list}>
      {routes.map(route => {
        if (layout !== route.layout) return null;

        const completeRoute = route.layout + route.path;
        return (
          <ListItem className={classes.listItem} key={completeRoute}>
            <NavLink
              to={completeRoute}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: isActiveRoute(completeRoute)
              })}
            >
              {route.icon && <route.icon className={classes.listItemIcon} />}
              <ListItemText
                primary={route.name}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        );
      })}
    </List>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button href="#" className={classes.title} color="transparent">
            Route Setting Rocks
          </Button>
        </div>
        {/* <Hidden smDown> */}
        {list}
        {/* </Hidden> */}
        {/* <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={open}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {list}
          </Drawer>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string,
      // component: PropTypes.element.isRequired,
      layout: PropTypes.string.isRequired,
      icon: PropTypes.elementType
    })
  ),
  layout: PropTypes.string
};
