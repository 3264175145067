import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { withRootStore } from "ticker-core";

import organisationPageStyle from "../assets/jss/material-dashboard-pro-react/views/organisationPageStyle";
import { makeStyles } from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card,
  CardBody,
  CustomInput,
  Button,
  GridItem,
  GridContainer,
  PasscodeKeyboard,
  Typography
} from "ticker-components";

import GroupAdd from "@material-ui/icons/GroupAdd";
import Group from "@material-ui/icons/Group";
import Delete from "@material-ui/icons/Delete";
import ExitToApp from "@material-ui/icons/ExitToApp";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

let dialogClasses = {
  paper: {
    minWidth: "20em"
  }
};
function padToFour(number) {
  if (number <= 9999) {
    number = ("000" + number).slice(-4);
  }
  return number;
}

let useStyles = makeStyles(organisationPageStyle);
let useDialogStyles = makeStyles(dialogClasses);

const OrganisationPage = function({ rootStore }) {
  let classes = useStyles();
  let dialogClasses = useDialogStyles();

  let [isOpenNew, setIsOpenNew] = useState(false);
  let [newName, setNewName] = useState();
  let [isOpenJoin, setIsOpenJoin] = useState(false);
  let [newJoinCode, setNewJoinCode] = useState("");
  let [errorMsg, setErrorMsg] = useState("");
  let [isOpenDelete, setIsOpenDelete] = useState(false);
  let [isOpenLeave, setIsOpenLeave] = useState(false);
  let [isOpenInstruction, setIsOpenInstruction] = useState(false);
  let [isOpenList, setIsOpenList] = useState(false);
  let [targetOrg, setTargetOrg] = useState("");

  const {
    personalRootStore: {
      organisationStores: {
        organisationStore,
        memberStore,
        cachedOrganisations
      }
    },
    regStores: { regStore }
  } = rootStore;

  useEffect(() => {
    if (isOpenList && targetOrg) {
      console.log("eeeeeeeeeeeeeeeeeee getting the list");
      memberStore.getMembers({ organisation_id: targetOrg.id });
    }
  }, [isOpenList, targetOrg]);

  let closeNewFn = () => {
    setErrorMsg("");
    setIsOpenNew(false);
  };

  let closeJoinFn = () => {
    setErrorMsg("");
    setIsOpenJoin(false);
  };

  let closeDeleteFn = () => {
    setIsOpenDelete(false);
    setTargetOrg("");
  };
  let closeLeaveFn = () => {
    setIsOpenLeave(false);
    setTargetOrg("");
  };

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down(500));

  return (
    <div className={classes.container} style={{ textAlign: "center" }}>
      <Dialog
        onClose={closeNewFn}
        aria-labelledby="simple-dialog-title"
        open={isOpenNew}
      >
        <DialogTitle>What is the name of your new Group?</DialogTitle>
        <div
          style={{
            paddingBottom: "2em",
            paddingLeft: "2em",
            paddingRight: "2em"
          }}
        >
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: e => {
                setNewName(e.target.value);
              },
              placeholder: "Group name..."
            }}
          />
          <Button
            style={{ float: "left" }}
            color="danger"
            simple
            onClick={closeNewFn}
          >
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            disabled={!newName}
            color="success"
            onClick={() => {
              organisationStore.createOrganisation({ name: newName }, () =>
                setIsOpenNew(false)
              );
            }}
          >
            Save
          </Button>
        </div>
      </Dialog>
      <Dialog
        onClose={closeJoinFn}
        aria-labelledby="simple-dialog-title"
        open={isOpenJoin}
        fullScreen={fullScreen}
      >
        <DialogTitle>What is the 4 digit code for the Group?</DialogTitle>
        <div
          style={{
            paddingLeft: "3em",
            paddingRight: "3em",
            paddingBottom: "1em",
            fontStyle: "italic"
          }}
        >
          If you don&apos;t know it, ask one of the group members.
        </div>
        <div
          style={{
            paddingBottom: "2em",
            paddingLeft: "2em",
            paddingRight: "2em"
          }}
        >
          <PasscodeKeyboard
            onChange={newVal => {
              setNewJoinCode(newVal);
              setErrorMsg("");
            }}
            onClear={() => {
              setNewJoinCode("");
              setErrorMsg("");
            }}
            // clearNow={
            //setJoinCode("")}
            regStore={regStore}
            codeLength={4}
            buttonTheme={[
              {
                class: "keyboardButtonClass",
                buttons: "1 2 3 4 5 6 7 8 9 Clear 0 {bksp}"
              }
            ]}
          />{" "}
          <h2
            style={{
              width: "100%",
              textAlign: "center",
              minHeight: "1.5em"
            }}
          >
            {newJoinCode}
          </h2>
          <h5
            style={{
              width: "100%",
              textAlign: "center",
              minHeight: "1.5em"
            }}
          >
            <Typography.Danger>{errorMsg}</Typography.Danger>
          </h5>
          <Button
            style={{ float: "left" }}
            color="danger"
            simple
            onClick={closeJoinFn}
          >
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            disabled={newJoinCode.length !== 4}
            color="success"
            onClick={() => {
              organisationStore.joinOrganisation(
                { joinCode: parseInt(newJoinCode, 10) },
                () => setIsOpenJoin(false),
                () => setErrorMsg("No group matches that join code.")
              );
            }}
          >
            Join
          </Button>
        </div>
      </Dialog>
      <Dialog
        onClose={closeDeleteFn}
        aria-labelledby="simple-dialog-title"
        open={isOpenDelete}
      >
        <DialogTitle>Delete {targetOrg.name}?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are the owner of this group. If you delete it, all members will
            be removed. This can not be undone.
          </DialogContentText>
          <Button
            style={{ float: "left" }}
            color="danger"
            simple
            onClick={closeDeleteFn}
          >
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="success"
            onClick={() => {
              organisationStore.deleteOrganisation(
                { id: targetOrg.id },
                closeDeleteFn
              );
            }}
          >
            Delete forever
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={closeLeaveFn}
        aria-labelledby="simple-dialog-title"
        open={isOpenLeave}
      >
        <DialogTitle>Leave {targetOrg.name}?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you leave this group you can rejoin later using the 4 digit code.
          </DialogContentText>
          <Button
            style={{ float: "left" }}
            color="danger"
            simple
            onClick={closeLeaveFn}
          >
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="success"
            onClick={() => {
              organisationStore.leaveOrganisation(
                {
                  id: targetOrg.id
                },
                closeLeaveFn
              );
            }}
          >
            Leave
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => setIsOpenInstruction(false)}
        aria-labelledby="simple-dialog-title"
        open={isOpenInstruction}
      >
        <DialogTitle>{targetOrg.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tell your friends to use this code to join this group.
            <h4 style={{ textAlign: "center" }}>
              {padToFour(targetOrg.joinCode)}
            </h4>
          </DialogContentText>
          <Button
            style={{ float: "right" }}
            color="success"
            onClick={() => setIsOpenInstruction(false)}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => setIsOpenList(false)}
        aria-labelledby="simple-dialog-title"
        open={isOpenList}
        classes={dialogClasses}
      >
        <DialogTitle>{targetOrg.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {memberStore.opGetMembers.executing && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <CircularProgress />
              </div>
            )}
            {!memberStore.opGetMembers.executing &&
              memberStore.opGetMembers.data &&
              memberStore.opGetMembers.data.map(member => {
                return (
                  <div key={member.registration_id}>
                    {member["Registration.userName"]}
                  </div>
                );
              })}
          </DialogContentText>
          <Button
            style={{ float: "right" }}
            color="success"
            onClick={() => setIsOpenList(false)}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Card style={{ maxWidth: "720px", margin: "auto" }}>
        <CardBody>
          <h4>
            Groups <Group />
          </h4>
          <div>
            {cachedOrganisations.items.length === 0 && (
              <>
                <div className={classes.message}>
                  You have no groups at the moment.
                </div>
                <div className={classes.messageBottom}>
                  You can either create a new one, or get a code from a friend
                  and join their group.
                </div>
              </>
            )}
            {cachedOrganisations.items.length > 0 && (
              <GridContainer justify="center">
                {cachedOrganisations.items.map(org => {
                  return (
                    <GridContainer
                      xs={12}
                      key={org.id}
                      alignItems="center"
                      justify="center"
                    >
                      <GridItem xs={3} style={{ textAlign: "left" }}>
                        <Button
                          onClick={() => {
                            setTargetOrg(org);
                            setIsOpenList(true);
                          }}
                          simple
                          color="info"
                        >
                          {org.name}
                        </Button>
                      </GridItem>
                      <GridItem xs={4}>
                        <Button
                          onClick={() => {
                            setTargetOrg(org);
                            setIsOpenInstruction(true);
                          }}
                          simple
                          color="info"
                        >
                          {padToFour(org.joinCode)}
                        </Button>{" "}
                      </GridItem>
                      <GridItem xs={3} style={{ textAlign: "right" }}>
                        {org.owner_id === regStore.id ? (
                          <Button
                            onClick={() => {
                              setTargetOrg(org);
                              setIsOpenDelete(true);
                            }}
                            simple
                            color="info"
                          >
                            Delete <Delete className={classes.icon} />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setTargetOrg(org);
                              setIsOpenLeave(true);
                            }}
                            simple
                            color="info"
                          >
                            Leave <ExitToApp className={classes.icon} />
                          </Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  );
                })}
              </GridContainer>
            )}
          </div>
          <div>
            <Button color="success" onClick={() => setIsOpenNew(true)}>
              New Group <Group className={classes.icon} />
            </Button>
            <Button color="success" onClick={() => setIsOpenJoin(true)}>
              Join Group <GroupAdd className={classes.icon} />
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

OrganisationPage.propTypes = {
  rootStore: PropTypes.object,
  classes: PropTypes.object
};
export default withRootStore(observer(OrganisationPage));
