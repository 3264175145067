import React from "react";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

let style = {
  whiteOut: {
    textAlign: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "100",
    backgroundColor: "rgba(255,255,255,0.7)",
    paddingTop: "1em"
  },
  parent: {
    position: "relative"
  }
};

class Spinner extends React.Component {
  render() {
    const { active, classes } = this.props;
    return (
      <div className={classes.parent}>
        {active && (
          <div className={classes.whiteOut}>
            <CircularProgress />
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}
Spinner.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(style)(Spinner);
