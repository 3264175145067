let gradeTypes = {
  ROUTE: 1,
  ROUTE_STR: "1",
  BOULDER: 2,
  BOULDER_STR: "2",
  ALL: []
};

gradeTypes.ALL.push(gradeTypes.ROUTE);
gradeTypes.ALL.push(gradeTypes.BOULDER);

let planTypes = {
  SET: 1,
  STRIP: 2,
  REPLACE: 3
};

let planOptions = {
  ALREADY_SET: -1,
  FILTER: -2,
  SET_OTHER: -3,
  FILTER_SECTION: -4,
  FILTER_WALL: -5,
  SET_GRADE: -6,
  FILTER_OTHER_SECTIONS: -7,
  FILTER_OTHER_WALLS: -8
};

let groups = {
  ADMIN: "Admin",
  USER: "User",
  TEST: "NotInDB"
};

let leaderboardDateSelections = [
  {
    label: "Today",
    days: 0
  },
  {
    label: "7 days",
    days: 7
  },
  {
    label: "14 days",
    days: 14
  },
  {
    label: "30 days",
    days: 30
  }
];

let ratingTitles = [
  "0", // This is unused because the scale is from 1-7
  "Much worse than Normal",
  "Worse than Normal",
  "A little worse than Normal",
  "Normal",
  "A little better than Normal",
  "Better than Normal",
  "Much better than Normal"
];

let MIDDLE_RATING = 4;
let MAX_RATING = 7;
let MIN_RATING = 1;

const HANGDOG_ID = 1;

const DOUBLE_TAP_DELAY = 350;

module.exports = {
  leaderboardDateSelections,
  gradeTypes,
  planTypes,
  planOptions,
  groups,
  ratingTitles,
  MIDDLE_RATING,
  MAX_RATING,
  MIN_RATING,
  HANGDOG_ID,
  DOUBLE_TAP_DELAY
};
