import { observable, action } from "mobx";

export default function draggableStore(
  context,
  urlPart,
  itemName,
  itemFilter,
  asyncOpCreate,
  getCurrentGymId,
  preSaveFn
) {
  if (!asyncOpCreate) {
    debugger;
  }
  let rest = context.rest;

  let unarchivedItems = [];
  return observable({
    /*
     * Do not replace the arrays. Use items.replace(items)
     */
    items: observable([]),

    itemsCached: false,
    getStoreName: () => {
      return itemName;
    },

    opSaveItems: asyncOpCreate(
      payload => rest.post(urlPart, payload),
      "opSave" + itemName
    ),
    putItems: action(async function() {
      try {
        if (preSaveFn) {
          this.items.forEach(preSaveFn);
        }
        let newItems = await this.opSaveItems.execute(this.items);

        if (itemFilter) {
          newItems = newItems.filter(itemFilter);
        }

        this.pushIntoObservableArrays(newItems);
        unarchivedItems = [];
      } catch (error) {
        console.log("Failed to create the items" + error);
        throw error;
      }
    }),

    opCacheItems: asyncOpCreate(
      () => rest.get(urlPart, { gym_id: getCurrentGymId() }),
      "cacheItem" + itemName
    ),
    cacheItems: action(async function({ forceReload }) {
      try {
        if (!this.itemsCached || forceReload) {
          this.itemsCached = false;
          console.log("Loading the items: " + itemName);
          await this.opCacheItems.execute({}, newItems => {
            if (itemFilter) {
              newItems = newItems.filter(itemFilter);
            }
            this.pushIntoObservableArrays(newItems);
            this.itemsCached = true;
          });
        } else {
          console.log(
            "The items '" + itemName + "' are already cached, or forced."
          );
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }),

    pushIntoObservableArrays: action(function(newItems) {
      //We must clear the observable arrays first
      this.items.clear();

      newItems.forEach(item => {
        this.items.push(item);
      });
    }),

    changeItem: action(async function(newItem, id, autoSave) {
      if (
        typeof newItem.id !== "undefined" &&
        newItem.id !== null &&
        newItem.id !== id
      ) {
        console.log(
          "The new item is different from the old item. Can't change it."
        );
        throw Error(
          "The new item is different from the old item. Can't change it."
        );
      }

      console.log("Changing Item id=:" + id);
      let oldItemIndex = this.getIndexById(id);
      let oldItem = this.items[oldItemIndex];

      let { isArchived, orderPosition } = oldItem;

      Object.assign(newItem, { isArchived, orderPosition, id });
      this.items[oldItemIndex] = observable(newItem);
      await this.autoSave(autoSave, () => {
        unarchivedItems = [];
      });
    }),

    addItem: action(async function(newItem, autoSave) {
      console.log("Adding Item");
      let createdItem = {
        ...newItem,
        id: undefined,
        orderPosition: this.items.length,
        isArchived: false
      };
      this.items.push(createdItem);
      unarchivedItems = [];

      await this.autoSave(autoSave);
      console.log("after adding The items =" + this.items);
    }),

    changeIsArchived: action(function(id, isArchived) {
      let itemIndex = this.getIndexById(id);

      console.log(
        "Changing item with index " + itemIndex + " to " + isArchived
      );
      this.items[itemIndex].isArchived = isArchived;

      /*
       * We must move the archived climbs to the back of the list.
       * Otherwise they mess with the drag and drop positions
       */
      if (isArchived) {
        this.reorderItems(itemIndex, this.items.length, false);
      }

      this.putItems();
    }),

    removeItem: action(function(itemIndex) {
      this.items.splice(itemIndex, 1);
      unarchivedItems = [];
    }),

    autoSave: async function(autoSave, callBack) {
      if (autoSave === true) {
        console.log("Auto saving the " + itemName);
        await this.putItems();
      }
      if (callBack) {
        callBack();
      }
      console.log("NOT auto saving " + itemName);
    },

    reorderItems: action(function(removeIndex, insertIndex, autoSave) {
      //reorder the list
      console.log(
        "THE " + itemName + " ITEMs" + JSON.stringify(this.items, null, 2)
      );
      console.log(itemName + "- Reordering the list at index" + removeIndex);
      console.log(itemName + "- Moving it too " + insertIndex);
      console.log(itemName + "-The length before =" + this.items.length);
      const [removed] = this.items.splice(removeIndex, 1);
      console.log("The length in between =" + this.items.length);
      console.log(itemName + "-The removed item =" + JSON.stringify(removed));
      this.items.splice(insertIndex, 0, removed);
      console.log("The length after =" + this.items.length);

      //Make sure that every item has the correct 'current' orderPosition
      this.items.forEach((item, index) => {
        item.orderPosition = index;
      });

      // Sort the array with the archived grades at the end
      // Mobx doesn't allow sorting of the observable array so we have to replace it
      this.items.replace(
        this.items.slice().sort((a, b) => {
          if (a.isArchived) {
            if (b.isArchived) {
              return a.orderPosition - b.orderPosition;
            }
            return 1;
          }
          if (b.isArchived) {
            return -1;
          }
          return a.orderPosition - b.orderPosition;
        })
      );

      //Make sure that every item has the correct 'final' orderPosition
      this.items.forEach((item, index) => {
        item.orderPosition = index;
      });

      this.autoSave(autoSave);
    }),

    getItemByOrderPosition(orderPosition) {
      return this.items[orderPosition];
    },

    // This function was added after some weird merge issues. It might be better deleted
    getHighestUnarchivedItem() {
      let highestSoFar = undefined;
      for (let item of this.items) {
        if (
          !item.isArchived &&
          (!highestSoFar || highestSoFar.orderPosition < item.orderPosition)
        ) {
          highestSoFar = item;
        }
      }
      return highestSoFar;
    },

    getItemById(id) {
      if (typeof id === "string") {
        console.log("The ID is a string");
        throw Error("The ID is a string");
      }
      for (let i = 0; i < this.items.length; i++) {
        if (id === this.items[i].id) {
          return this.items[i];
        }
      }
      return null;
    },
    getIndexById(id) {
      if (typeof id === "string") {
        console.log("The ID is a string");
        throw Error("The ID is a string");
      }
      for (let i = 0; i < this.items.length; i++) {
        if (id === this.items[i].id) {
          return i;
        }
      }
      return null;
    },

    getAllItemsEvenArchived: function() {
      return this.items;
    },

    getUnarchivedItems: function() {
      if (unarchivedItems.length === 0) {
        unarchivedItems = this.items.filter(item => item.isArchived !== true);
      }
      unarchivedItems.sort((a, b) => a.orderPosition - b.orderPosition);

      return unarchivedItems;
    }
  });
}
