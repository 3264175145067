import React from "react";
import { observer } from "mobx-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DragIndicator from "@material-ui/icons/DragIndicator";
import Archive from "@material-ui/icons/Archive";
import Unarchive from "@material-ui/icons/Unarchive";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Fade from "@material-ui/core/Fade";
import AddIcon from "@material-ui/icons/Add";

// core components
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import SnackbarContent from "../Snackbar/SnackbarContent";

import draggableStyle from "../../assets/jss/material-dashboard-pro-react/components/draggableStyle";

import { coreStyle } from "ticker-core";

import { withRootStore } from "ticker-core";

const style = {
  ...draggableStyle,
  icon: coreStyle.buttonIcon
};

class DraggableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fadingOutIndex: undefined
    };
  }

  //This is bind'ed to the stepStore object
  onDragEnd(draggableStore, autoSave, result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    draggableStore.reorderItems(
      result.source.index,
      result.destination.index,
      autoSave
    );
  }

  // Commenting this out because I'm not sure its used
  // changeIsArchived(labelIndex, isArchived, autoSave) {
  //   let newState = (this.getState().fadingOutIndex = labelIndex);
  //   this.setState(newState);
  //   const { draggableStore } = this.props;
  //   setTimeout(() => {
  //     if (autoSave) {
  //       draggableStore.changeArchived(labelIndex, isArchived);
  //     } else {
  //       draggableStore.removeItem(labelIndex);
  //     }
  //     this.setState({ fadingOutIndex: null });
  //   }, 200);
  // }

  doNothingFn() {}

  /*componentWillUnmount() {
    const { draggableStore, autoSave } = this.props;
    draggableStore.autoSave(autoSave);
  }*/

  render() {
    const {
      classes,
      autoSave,
      showArchived,
      draggableStore,
      itemName,
      onEditFn,
      onAddFn,
      getLabel
    } = this.props;

    /*
     * This is a hack to make mobx register the changes to list
     */

    if (draggableStore.getAllItemsEvenArchived().length > 0) {
      this.doNothingFn(draggableStore.getAllItemsEvenArchived().primaryLabel);
    }

    console.log(
      "Rendering the DraggableList for " +
        { itemName } +
        " with a count of " +
        draggableStore.getAllItemsEvenArchived().length
    );

    let ChangeArchiveIcon = Archive;
    if (showArchived) {
      ChangeArchiveIcon = Unarchive;
    }

    console.log("autosave = " + autoSave);
    if (autoSave !== true) {
      ChangeArchiveIcon = Delete;
    }

    return (
      <React.Fragment>
        {showArchived && (
          <SnackbarContent
            message="WARNING - Showing Archived Only"
            color="warning"
          />
        )}
        {draggableStore.getAllItemsEvenArchived().length === 0 && (
          <SnackbarContent
            message="There are none. Click on the Add button below to create one."
            color="info"
          />
        )}
        <DragDropContext
          onDragEnd={result => {
            this.onDragEnd(draggableStore, autoSave, result);
          }}
        >
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef} className={classes.draggable}>
                {draggableStore.getAllItemsEvenArchived().map((item, index) => {
                  if (item.isArchived !== showArchived) return null;

                  let itemLabel = getLabel(item);
                  return (
                    <Draggable
                      key={itemName + itemLabel + index}
                      draggableId={itemLabel + index}
                      index={index}
                    >
                      {provided => (
                        <Fade in={this.state.fadingOutIndex !== index}>
                          <div
                            className={classes.root + " " + classes.light}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <GridContainer>
                              <GridItem
                                xs={1}
                                className={classes.verticalAlign}
                              >
                                <DragIndicator />
                              </GridItem>
                              <GridItem
                                xs={6}
                                className={classes.verticalAlign}
                              >
                                {getLabel(item)}
                              </GridItem>{" "}
                              <GridItem
                                xs={4}
                                className={classes.verticalRight}
                              >
                                {!showArchived && onEditFn ? (
                                  <Button
                                    color="info"
                                    onClick={() => {
                                      onEditFn(
                                        draggableStore,
                                        item.id,
                                        autoSave
                                      );
                                    }}
                                    size="sm"
                                  >
                                    <Edit
                                      className={
                                        classes.icon + " " + classes.changeFont
                                      }
                                    />
                                    Editxx
                                  </Button>
                                ) : (
                                  undefined
                                )}
                              </GridItem>
                              <GridItem
                                xs={1}
                                className={classes.verticalAlign}
                              >
                                <ChangeArchiveIcon
                                  className={
                                    classes.icon + " " + classes.cursor
                                  }
                                  onClick={() => {
                                    draggableStore.changeIsArchived(
                                      item.id,
                                      !showArchived,
                                      autoSave
                                    );
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </div>
                        </Fade>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!showArchived && onAddFn ? (
          <div className={classes.centerText}>
            <Button
              onClick={() => {
                onAddFn(draggableStore, autoSave);
              }}
              color="primary"
            >
              <AddIcon />
              Add {itemName}
            </Button>
          </div>
        ) : (
          undefined
        )}
      </React.Fragment>
    );
  }
}

DraggableList.propTypes = {
  autoSave: PropTypes.bool.isRequired,
  showArchived: PropTypes.bool.isRequired,
  onEditFn: PropTypes.func.isRequired,
  onAddFn: PropTypes.func.isRequired,
  getLabel: PropTypes.func.isRequired,
  draggableStore: PropTypes.object,
  classes: PropTypes.object,
  itemName: PropTypes.string
};
export default withRootStore(withStyles(style)(observer(DraggableList)));
