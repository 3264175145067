import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import AlertInput from "../AlertInput/AlertInput.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { customCheckboxRadioSwitchStyle } from "ticker-core";

import { withRootStore } from "ticker-core";
import DraggableList from "./DraggableList.js";

class DraggableAlertList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertInputValue: null
    };
    this.onEditClickFn = this.onEditClickFn.bind(this);
    this.onAddClickFn = this.onAddClickFn.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  onEditClickFn(draggableStore, id, autoSave) {
    let item = draggableStore.getItemById(id);
    let currentLabel = this.props.getLabel(item);

    let confirmEditCallBack = newLabel => {
      this.props.onEditFn(draggableStore, id, autoSave, newLabel);
      this.closeAlert();
    };
    this.setState({
      showAlert: true,
      alertInputValue: currentLabel,
      confirmCallBack: confirmEditCallBack.bind(this)
    });
  }

  onAddClickFn(draggableStore, autoSave) {
    let confirmAddCallBack = newLabel => {
      this.props.onAddFn(draggableStore, autoSave, newLabel);
      this.closeAlert();
    };
    this.setState({
      showAlert: true,
      alertInputValue: "New Grade",
      confirmCallBack: confirmAddCallBack.bind(this)
    });
  }

  closeAlert() {
    this.setState({
      showAlert: false,
      alertInputValue: null
    });
  }

  render() {
    const {
      showArchived,
      draggableStore,
      autoSave,
      itemName,
      getLabel,
      alertTitle
    } = this.props;

    return (
      <React.Fragment>
        {this.state.showAlert && (
          <AlertInput
            alertTitle={alertTitle}
            inputValue={this.state.alertInputValue}
            cancelCallBack={this.closeAlert}
            confirmCallBack={this.state.confirmCallBack}
          />
        )}
        <DraggableList
          autoSave={autoSave}
          showArchived={showArchived}
          draggableStore={draggableStore}
          onAddFn={this.onAddClickFn}
          onEditFn={this.onEditClickFn}
          itemName={itemName}
          getLabel={getLabel}
        />
      </React.Fragment>
    );
  }
}

DraggableAlertList.propTypes = {
  classes: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
  showArchived: PropTypes.bool.isRequired,
  autoSave: PropTypes.bool.isRequired,
  draggableStore: PropTypes.object.isRequired,
  onAddFn: PropTypes.func.isRequired,
  onEditFn: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  getLabel: PropTypes.func.isRequired,
  alertTitle: PropTypes.string.isRequired
};

export default withRootStore(
  withStyles(customCheckboxRadioSwitchStyle)(observer(DraggableAlertList))
);
