import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import {
  withStyles,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";

/*const useAirBnBStyles = makeStyles(theme => ({
  root: {
    width: 300 + theme.spacing(3) * 2
  },
  margin: {
    height: theme.spacing(3)
  }
}));
*/

const themeBelowAppBar = createMuiTheme({
  zIndex: {
    tooltip: 1301
  }
});
function ValueLabelComponent(props) {
  const { children, value } = props;

  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  return (
    <ThemeProvider theme={themeBelowAppBar}>
      <Tooltip
        PopperProps={{
          popperRef
        }}
        open={true}
        enterTouchDelay={0}
        placement="top"
        title={value}
      >
        {children}
      </Tooltip>
    </ThemeProvider>
  );
}

const StyledSlider = withStyles({
  root: {
    height: 3,
    padding: "13px 0"
  },
  // thumb: {
  //   height: 27,
  //   width: 27,
  //   backgroundColor: "#fff",
  //   border: "1px solid currentColor",
  //   marginTop: -12,
  //   marginLeft: -13,
  //   boxShadow: "#ebebeb 0px 2px 2px",
  //   "&:focus,&:hover,&$active": {
  //     boxShadow: "#ccc 0px 2px 3px 1px"
  //   },
  //   "& .bar": {
  //     // display: inline-block !important;
  //     height: 9,
  //     width: 1,
  //     backgroundColor: "currentColor",
  //     marginLeft: 1,
  //     marginRight: 1
  //   }
  // },
  active: {},
  valueLabel: {
    left: "calc(-50% + 400px)"
  },
  track: {
    height: 3
  },
  rail: {
    color: "lightgray",
    opacity: 1,
    height: 3
  }
})(Slider);

// function AirbnbThumbComponent(props) {
//   return (
//     <span {...props}>
//       <span className="bar" />
//       <span className="bar" />
//       <span className="bar" />
//     </span>
//   );
// }

function AirbnbSlider(props) {
  return (
    <StyledSlider
      {...props}
      // ThumbComponent={AirbnbThumbComponent}
      ValueLabelComponent={ValueLabelComponent}
    />
  );
}
export default AirbnbSlider;
