import React, { useState, useRef } from "react";
import { useObserver } from "mobx-react";
import TickPopup from "./TickPopup";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";

import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Check from "@material-ui/icons/Check";

import CircularProgress from "@material-ui/core/CircularProgress";

import RatingControl from "./RatingControl";
import RegradeControl from "./RegradeControl";

import { withRootStore, coreStyle } from "ticker-core";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Button from "../CustomButtons/Button";
import Info from "../Typography/Info";

const SVGIcon = props => (
  <svg className={props.className} pointerEvents="none">
    <use xlinkHref={props.href} />
  </svg>
);

SVGIcon.propTypes = {
  className: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};

let controlStyles = () => {
  return {
    listItemIcon: coreStyle.listItemIconStyle,
    sliderBox: {
      width: "80%",
      margin: "auto"
    },
    tickButton: {
      verticalAlign: "middle",
      display: "inline"
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    },
    timeText: {
      fontSize: "x-small",
      textAlign: "center",
      display: "inline-block",
      paddingLeft: "1em",
      paddingRight: "1em"
    },
    instruction: {
      fontSize: "xx-small",
      textTransform: "none"
    }
  };
};

let useControlStyles = makeStyles(controlStyles);

function UserControls({
  assembly,
  rootStore,
  onTick,
  onDeleteTick,
  history,
  setActiveIndex,
  setShowReportDialog
}) {
  let classes = useControlStyles();

  let [tickIsDisabled, setTickIsDisabled] = useState(false);
  let [showTickPopup, setShowTickPopup] = useState(false);
  let [addedEntries, setAddedEntries] = useState([]);

  let popUpRef = useRef();

  let {
    regStores,
    personalRootStore,
    loginDialogStore,
    userStores: { profileStore }
  } = rootStore;

  let { regStore } = regStores;

  let {
    personalLogEntryStores: { logEntryStore },
    personalRatingStores: { ratingStore }
  } = personalRootStore;

  return useObserver(() => {
    let tickLabel;
    if (logEntryStore.dateStr && !logEntryStore.dateOption) {
      tickLabel = logEntryStore.dateStr;
    } else {
      tickLabel = "Other";
    }

    return (
      <div>
        {showTickPopup && (
          <TickPopup
            assembly={assembly}
            closeFn={() => {
              setShowTickPopup(false);
            }}
            onDeleteTick={onDeleteTick}
          />
        )}
        <ul>
          <li>
            <div>
              <small>You&apos;ve climbed this route this many times</small>
            </div>

            <GridContainer alignItems="center" data-tour="logEntryCount">
              <GridItem
                xs={4}
                justify="flex-end"
                onClick={() => {
                  if (tickIsDisabled || addedEntries.length === 0) {
                    return;
                  }
                  setTickIsDisabled(true);
                  setTimeout(() => {
                    setTickIsDisabled(false);
                  }, 600);
                  logEntryStore.deleteLogEntry(addedEntries.pop());
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "right"
                }}
              >
                {addedEntries.length > 0 && (
                  <Info>
                    {tickIsDisabled && (
                      <CircularProgress
                        size={20}
                        className={classes.progress}
                      />
                    )}
                    <Remove style={{ height: "1.5em", width: "1.5em" }} />
                  </Info>
                )}
              </GridItem>
              <GridItem
                xs={4}
                justify="center"
                onClick={() => {
                  setShowTickPopup(true);
                }}
                style={{
                  cursor: "pointer"
                }}
              >
                <Info>
                  <Check className={classes.listItemIcon} />
                  {assembly.logEntryCount}
                </Info>
              </GridItem>
              <GridItem
                xs={4}
                data-tour="tickButton"
                onClick={() => {
                  if (
                    !profileStore.hasLoaded ||
                    (rootStore.isPublicSite && !regStores.isRegistered())
                  ) {
                    loginDialogStore.showDialog = true;
                    loginDialogStore.instruction = "to record your climb.";
                    return;
                  }

                  console.log("Clicked");
                  if (tickIsDisabled) {
                    return;
                  }
                  console.log("HNot disabled");
                  setTickIsDisabled(true);
                  setTimeout(() => {
                    setTickIsDisabled(false);
                  }, 600);
                  logEntryStore.createLogEntry(
                    {
                      climbing_route_id: assembly.climbingRoute.id
                    },
                    result => {
                      setAddedEntries([...addedEntries, result]);
                    }
                  );
                  if (onTick) {
                    onTick();
                  }
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "left"
                }}
              >
                <Info>
                  {tickIsDisabled && (
                    <CircularProgress size={20} className={classes.progress} />
                  )}
                  <Add style={{ height: "1.5em", width: "1.5em" }} />
                </Info>
              </GridItem>
            </GridContainer>
          </li>
          <li data-tour="dateSelection">
            <div>
              <small>Any climbs you record from now will be recorded to </small>
            </div>
            <div>
              <SelectionBtn
                targetOption={logEntryStore.dateOptions[0]}
                selectedOption={logEntryStore.dateOption}
                classes={classes}
              />
              <SelectionBtn
                targetOption={logEntryStore.dateOptions[1]}
                selectedOption={logEntryStore.dateOption}
                classes={classes}
              />
              <Button
                color={logEntryStore.dateOption ? "gray" : "info"}
                size="text"
                simple
                className={classes.timeText}
                onClick={() => {
                  history.push("/main/dateSelection");
                }}
              >
                {tickLabel}
              </Button>
            </div>
          </li>
          <li data-tour="feedback" ref={popUpRef}>
            <Button
              simple
              color="info"
              onClick={() => {
                if (
                  !profileStore.hasLoaded ||
                  (rootStore.isPublicSite && !regStores.isRegistered())
                ) {
                  console.log("Setting showDialog");
                  loginDialogStore.showDialog = true;
                  loginDialogStore.instruction =
                    "to send feedback about a climb.";
                  return;
                }

                setActiveIndex(1);
                setShowReportDialog(true);
              }}
              size="sm"
            >
              More Feedback
            </Button>
          </li>
          <li data-tour="starRating">
            <RatingControl
              assembly={assembly}
              userRating={assembly.userRating}
              popUpRef={popUpRef}
              regStore={regStore}
              ratingStore={ratingStore}
              personalRootStore={personalRootStore}
            />
          </li>
          <li data-tour="regrades">
            <RegradeControl assembly={assembly} />
          </li>
        </ul>
      </div>
    );
  });
}

UserControls.propTypes = {
  rootStore: PropTypes.object.isRequired,
  assembly: PropTypes.object.isRequired
};

let SelectionBtn = ({ selectedOption, targetOption, classes }) => {
  return (
    <Button
      size="text"
      onClick={targetOption.onClick}
      simple
      color={selectedOption !== targetOption ? "gray" : "info"}
      className={classes.timeText}
    >
      {targetOption.label}
    </Button>
  );
};

export default withRootStore(UserControls);
