/*
 * This file contains all the direct uses of zoom level e.g. 1.7
 *
 * The bigger the number the deeper the current zoom
 */

/*
 * The double click boundary is the depth at which double clicking reverses
 * i.e. zooms out instead of zooming in
 */
export default {
  getDoubleClickBoundary: function getDoubleClickBoundary(dimensions) {
    const DOUBLE_CLICK_BOUNDARY = 1.7;
    if (dimensions.width > 1000 || dimensions.height > 800) {
      return DOUBLE_CLICK_BOUNDARY;
    } else {
      return DOUBLE_CLICK_BOUNDARY * 0.9;
    }
  },

  MAX_DETAIL_LEVEL: 4,

  getDetailLevel: function getDetailLevel(valueA) {
    if (valueA > 1.7) return 4;

    if (valueA > 1.3) return 3;

    if (valueA > 1) return 2;

    if (valueA > 0.65) return 1;

    return 0;
  },

  getScaleFactorLimits: function getScaleFactorLimits(value) {
    let { SVGWidth, SVGHeight, viewerWidth, viewerHeight } = value;

    let scaleX = viewerWidth / SVGWidth;
    let scaleY = viewerHeight / SVGHeight;

    let minScaleFactor = Math.min(scaleX, scaleY) * 0.8;
    let maxScaleFactor = Math.min(scaleX, scaleY) * 8;

    // This 'if' ignores when the SVG is first rendering and the numbers are messed up
    if (Number.isNaN(minScaleFactor) || minScaleFactor === 0) {
      minScaleFactor = undefined;
      maxScaleFactor = undefined;
    }

    return {
      minScaleFactor,
      maxScaleFactor
    };
  },

  PAN_DEPTH: 2
};
