import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function DoubleUpIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 5 l -6 6 l 1.41 1.41 l 4.59 -4.58 l 4.59 4.58 l 1.41 -1.41 z" />
      <path d="M 12 10 l -6 6 l 1.41 1.41 l 4.59 -4.58 l 4.59 4.58 l 1.41 -1.41 z" />
    </SvgIcon>
  );
}

DoubleUpIcon.propTypes = {
  className: PropTypes.string
};
