import React, { useRef, useState, memo } from "react";

import { makeStyles } from "@material-ui/core";
import { PropTypes } from "prop-types";

import { withRootStore } from "ticker-core";

import getObjectHandlers from "../handlers/getObjectHandlers";
import getRefCallBack from "./getRefCallBack";

import { styles } from "./svgStyles";

//   const svgTextSelection
//   svg text::selection {
//     background: none;
//   }

let useStyles = makeStyles(styles);

function LabelView(props) {
  let {
    fontSize,
    labelObject,
    isSelected,
    x,
    y,
    labelText,
    longPressIsDisabled,
    rootStore: { SVGStore, selectionStore },
    callableOnTouchEnd,
    callableOnTouchMove,
    isClickable,
    wasLongPressed,
    onClick,
    useDefaultHandlers
  } = props;

  let classes = useStyles();
  let textRef = useRef();
  let [lpTimeout, setStateLPTimeout] = useState({ value: null });
  let [hasPointerMoved, setHasPointerMovedxxx] = useState({ value: false });
  let [textDimensions, setTextDimensions] = useState({ w: 0, h: 0 });

  let setHasPointerMoved = newVal => {
    setHasPointerMovedxxx(newVal);
  };
  let refCallBack = getRefCallBack(
    SVGStore,
    textDimensions,
    setTextDimensions,
    textRef
  );
  if (!labelObject) {
    console.log("The labelObject is empty");
    debugger;
  }
  // if (labelText === "The Transformer Room") {
  //   console.log("yyyyThe label is TR");
  //   debugger;
  // }
  let preventAction = evt => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  let detailHandlers;
  if (useDefaultHandlers) {
    let handlers = getObjectHandlers(
      SVGStore,
      selectionStore,
      hasPointerMoved,
      setHasPointerMoved,
      labelObject,
      longPressIsDisabled,
      lpTimeout,
      setStateLPTimeout,
      callableOnTouchEnd,
      callableOnTouchMove,
      onClick
    );
    detailHandlers = handlers(false);
  } else if (onClick) {
    let both = evt => {
      preventAction(evt);
      onClick(labelObject);
    };
    detailHandlers = {
      onClick: both,
      onTouchEnd: both
    };
  }

  let paddingY = 0.1;
  let paddingX = 0.15;
  let paddedWidth = textDimensions.w * (1 + paddingX);
  let paddedHeight = textDimensions.h * (1 + paddingY);

  // The label corner is the top left corner where the label would start
  let labelCornerX = x - paddedWidth / 2;
  let labelCornerY = y - paddedHeight / 2;

  // The centered label is the bottom left corner after some small adjustments to center the text better
  let centeredLabelX = labelCornerX + paddedWidth * 0.1;
  let centeredLabelY = labelCornerY + paddedHeight * 0.8;

  let roundedCorners = 3;

  let TAPPING_PADDING = 6;

  return (
    <g className={classes.myFadeIn}>
      {/* The main label rectangle */}
      <rect
        x={labelCornerX}
        y={labelCornerY}
        rx={roundedCorners}
        ry={roundedCorners}
        width={paddedWidth}
        height={paddedHeight}
        fill="white"
        vectorEffect={"non-scaling-stroke"}
        // filter="url(#blurredRect)"
      />
      {isSelected && (
        <>
          {/* The ellipse that surrounds the LABEL when the route is selected */}
          <ellipse
            cx={x}
            cy={y}
            rx={paddedWidth}
            ry={paddedHeight}
            stroke="black"
            strokeWidth={2}
            fill={wasLongPressed ? "yellow" : "none"}
            strokeOpacity={0.5}
            fillOpacity={0.5}
          />
        </>
      )}
      {/* The label text */}
      <text
        x={centeredLabelX}
        y={centeredLabelY}
        // fontFamily="Calibri"
        fontSize={fontSize}
        fill={"black"}
        ref={refCallBack}
        onSelect={preventAction}
      >
        {labelText}
      </text>
      {
        <rect
          //Position the rect higher and to the left of the label corner
          // Because the tapping margin makes this rect bigger than the label
          x={isClickable ? labelCornerX : labelCornerX - 0.5 * TAPPING_PADDING}
          y={isClickable ? labelCornerY : labelCornerY - 0.5 * TAPPING_PADDING}
          width={isClickable ? paddedWidth : paddedWidth + TAPPING_PADDING}
          height={isClickable ? paddedHeight : paddedHeight + TAPPING_PADDING}
          fillOpacity={0}
          {...detailHandlers}
        />
      }
    </g>
  );
}

LabelView.propTypes = {
  labelText: PropTypes.string,
  labelObject: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  longPressIsDisabled: PropTypes.bool,
  rootStore: PropTypes.object.isRequired,
  callableOnTouchEnd: PropTypes.func,
  callableOnTouchMove: PropTypes.func,
  isClickable: PropTypes.bool.isRequired,
  fontSize: PropTypes.number.isRequired,
  wasLongPressed: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  useDefaultHandlers: PropTypes.bool.isRequired
};

export default memo(withRootStore(LabelView));
// , (prevProps, nextProps) => {
//   for (let key of Object.keys(prevProps)) {
//     if (prevProps[key] !== nextProps[key]) {
//       console.log("The key didn't match '" + key + "'");
//       return false;
//     }
//     console.log("The keys matched");
//   }
//   return true;
// });
