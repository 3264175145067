export default function getSlotStr(currentSlot, grades) {
  let slotStr = "(";
  let slotGradeList = [];

  //Note: Don't move currentSlot.forEach below grades.forEach
  // That will change the order of the slotGradeList
  // Which causes problems elsewhere
  currentSlot.forEach(function(gradeId) {
    grades.forEach(function(fullClimbingGradeObject) {
      if (!currentSlot.forEach) {
        debugger;
      }
      if (gradeId === fullClimbingGradeObject.id) {
        slotGradeList.push(fullClimbingGradeObject);
        if (fullClimbingGradeObject.isArchived) {
          slotStr += " archived" + fullClimbingGradeObject.primaryLabel + " or";
        } else {
          slotStr += fullClimbingGradeObject.primaryLabel + " or ";
        }
      }
    });
  });

  // take off the last " or "
  if (slotStr.length > 5) slotStr = slotStr.slice(0, -4);

  slotStr = slotStr + ")";

  return { slotStr, slotGradeList };
}
