import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    padding: "0 15px !important"
  },
  smallPadding: {
    padding: "0 5px !important"
  }
};

const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const classes = useStyles();
  const { children, className, smallPadding, ...rest } = props;
  return (
    <Grid
      item
      {...rest}
      className={
        (smallPadding ? classes.smallPadding : classes.grid) + " " + className
      }
    >
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  smallPadding: PropTypes.bool
};
