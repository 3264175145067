import { ErrorNotification } from "ticker-components";

import logger from "../utils/logger";

class HttpCodeHandlerFactory {
  constructor(errorStore, notificationStore, rootStore) {
    this.errorStore = errorStore;
    this.notificationStore = notificationStore;

    this.defaultHandler = this.getNewEmptyHandler();

    this.defaultHandler.handleStatusFns[401] = () => {
      console.log(
        "401: The user appears to be logged out so redirecting to the login page.. or at least would do if it wasn't commented out."
      );

      if (!rootStore.isClimberSite) {
        window.location.reload();
      }
    };

    this.defaultHandler.handleStatusFns[422] = () => {
      console.log(
        "422: For some reason the code is not doing anything with error code 422?"
      );
      logger.log("422 httpCodeHandlerDefault");
    };

    this.defaultHandler.defaultStatusFn = (error, fnName) => {
      console.log("BROOK - outputting the error.");

      logger.log(
        "httpCodeHandlerDefault",
        error.message && error.message ? error.message : error,
        fnName
      );
      new ErrorNotification(
        "Failed to complete the task '" +
          fnName +
          "'. Please check your connection and reload the page.",
        notificationStore,
        error
      );
    };
  }

  getDefaultCopy() {
    let copy = this.getNewEmptyHandler();

    Object.assign(copy.handleStatusFns, this.defaultHandler.handleStatusFns);
    copy.defaultStatusFn = this.defaultHandler.defaultStatusFn;
    return copy;
  }

  getDefault() {
    return this.defaultHandler;
  }

  getNewEmptyHandler() {
    let retVal = {
      handleStatusFns: {},
      handledElsewhereStatuses: [],
      errorStore: this.errorStore,
      notificationStore: this.notificationStore
    };

    retVal.handle = function(error, fnName, asycOpStore, payload, callback) {
      if (error === null || typeof error === "undefined") {
        console.log(
          "The api call has failed for " + fnName + ". The error is undefined."
        );
        console.log(
          "In the past this was caused by a timeout of the last api call."
        );
        console.log(
          "You can look inside the responsePromise object in the debugger to see the details."
        );
        console.log(
          "But you can't output the state of the Promise because promise doesn't reveal it's internals."
        );

        logger.log("Undefined error during async call", error, fnName);
        // The error will be rethrown at the bottom of this function
      } else {
        asycOpStore.error = error;
        if (error.response && typeof error.response.status !== "undefined") {
          console.log(
            "xxAn non-200 response has occured while making a request inside AsyncOp to " +
              fnName
          );
          const {
            response: { status }
          } = error;

          if (this.handledElsewhereStatuses.includes(status)) {
            return;
          }

          // If there is a handler for this status.
          if (this.handleStatusFns[status]) {
            //Then invoke it.
            this.handleStatusFns[status](error, fnName);
          } else if (this.defaultStatusFn) {
            this.defaultStatusFn(error, fnName);
          } else {
            console.error(
              "The error doesn't have a handler for the status:" + status
            );
            console.log("The response is:", error.response);
            logger.log(
              "Unhandled http status",
              error.message && error.message ? error.message : error,
              fnName
            );
            throw error;
          }
        } else {
          console.log(
            "The response is not usual or there is no status while making a request inside AsyncOp to " +
              fnName
          );

          if (error.message === "Network Error") {
            this.errorStore.hasErrorOccurred = true;
            this.errorStore.isNetworkError = true;
            console.log("A network error occured. The store is:", asycOpStore);

            let priorValue = this.errorStore.retryAsyncOpMap.get(asycOpStore);
            let retrying = priorValue && priorValue.retrying;
            this.errorStore.retryAsyncOpMap.set(asycOpStore, {
              payload,
              callback,
              retrying
            });
          } else {
            new ErrorNotification(
              "Failed to complete the task '" +
                fnName +
                "'. Please check your connection and reload the page.",
              this.notificationStore,
              error
            );
            logger.log(
              "Failed to complete the task",
              error.message && error.message ? error.message : error,
              fnName
            );
          }
          console.log(
            "There was no error.response field. Here is the error:" + error
          );
        }
      }
      throw error;
    }.bind(retVal);
    return retVal;
  }
}

export default HttpCodeHandlerFactory;
