function nearestWall(computedCache, coords) {
  let closestDistanceSoFar = 10000000;
  let closestWall = null;

  let x1 = coords.labelX ? coords.labelX : coords.x;
  let y1 = coords.labelY ? coords.labelY : coords.y;

  for (let sectionAssembly of computedCache.sectionAssemblies) {
    console.log(
      "Inside the section loop: " + sectionAssembly.climbingSection.name
    );
    if (sectionAssembly.climbingSection.isArchived) continue;
    console.log("Inside the section loop: but past the if");

    for (let wallAssembly of sectionAssembly.climbingWallAssemblies) {
      console.log("Inside the wall loop: " + wallAssembly.climbingWall.name);
      if (wallAssembly.climbingWall.isArchived) continue;
      console.log("Inside the section loop: but past the if");

      let xdiff = x1 - wallAssembly.climbingWall.labelX;
      let ydiff = y1 - wallAssembly.climbingWall.labelY;

      let distance = Math.sqrt(Math.pow(xdiff, 2) + Math.pow(ydiff, 2));
      if (distance < closestDistanceSoFar) {
        closestDistanceSoFar = distance;
        closestWall = wallAssembly;
      }
    }
  }

  console.log("Returngin the closest wall:" + closestWall.name);
  return closestWall;
}

export default {
  nearestWall
};
