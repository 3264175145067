import { computed } from "mobx";
import { gradeTypes } from "../../variables/constants";

export default class ComputedPersonalCacheStore {
  constructor(
    computedCacheStore,
    cachedLogCountStore,
    cachedRatings,
    cachedRegrades
  ) {
    this.computedPersonalCache = computed(() => {
      console.log("Before computedCacheStore.computedCache.get()");
      let cache = computedCacheStore.computedCache.get();
      console.log("After computedCacheStore.computedCache.get()");

      //Copying over the cache to a new object so that MobX works properly
      let retVal = {
        overviewStatusAssemblies: cache.overviewStatusAssemblies,
        sectionAssemblies: cache.sectionAssemblies,
        allClimbingAssemblies: cache.allClimbingAssemblies,
        totals: cache.totals,
        maxGrades: {},
        minGrades: {}
      };

      gradeTypes.ALL.forEach(gradeType => {
        retVal.maxGrades[gradeType] = undefined;
        retVal.minGrades[gradeType] = undefined;
      });

      for (let climbingAssembly of retVal.allClimbingAssemblies) {
        if (cachedRatings.items) {
          for (let userRating of cachedRatings.items) {
            if (
              userRating.climbing_route_id === climbingAssembly.climbingRoute.id
            ) {
              climbingAssembly.userRating = userRating;
            }
          }
        }

        if (cachedRegrades.items) {
          for (let userRegrade of cachedRegrades.items) {
            if (
              userRegrade.climbing_route_id ===
              climbingAssembly.climbingRoute.id
            ) {
              climbingAssembly.userRegrade = userRegrade;
            }
          }
        }

        //Add the log entry counts
        for (let countValues of cachedLogCountStore.items) {
          if (countValues[0] === climbingAssembly.climbingRoute.id) {
            climbingAssembly.logEntryCount = countValues[1];
          }
        }

        if (climbingAssembly.logEntryCount > 0) {
          if (
            typeof retVal.maxGrades[
              climbingAssembly.climbingGrade.gradeType
            ] === "undefined" ||
            retVal.maxGrades[climbingAssembly.climbingGrade.gradeType]
              .orderPosition > climbingAssembly.climbingGrade.orderPosition
          ) {
            console.log(
              "Setting the maxGrade for gradeType:" +
                climbingAssembly.climbingGrade.gradeType +
                " to " +
                climbingAssembly.climbingGrade.primaryLabel
            );
            retVal.maxGrades[climbingAssembly.climbingGrade.gradeType] =
              climbingAssembly.climbingGrade;
          }

          if (
            typeof retVal.minGrades[
              climbingAssembly.climbingGrade.gradeType
            ] === "undefined" ||
            retVal.minGrades[climbingAssembly.climbingGrade.gradeType]
              .orderPosition < climbingAssembly.climbingGrade.orderPosition
          ) {
            retVal.minGrades[climbingAssembly.climbingGrade.gradeType] =
              climbingAssembly.climbingGrade;
          }
        }
      }

      return retVal;
    });
  }
}
