import React from "react";

import { observer } from "mobx-react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components

import { withRootStore } from "ticker-core";
import {
  RegistrationUserNamePasscode,
  LoginRequest,
  Card,
  CardBody
} from "ticker-components";

function GymRegisterPage({
  rootStore: {
    regStores: { regStore },
    gymStores: { gymStore },
    userStores: { profileStore }
  }
}) {
  if (!profileStore.hasLoaded) {
    return (
      <>
        <Card>
          <CardBody>
            <div style={{ padding: "2em" }}>
              <LoginRequest instruction=" to register with your gym." />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }

  return (
    <RegistrationUserNamePasscode
      onComplete={regStore.registerRegistration}
      message="Registering"
      redirectPage="height"
      usernameTitle={"Create new user at " + gymStore.name}
      usernameSubTitle={"Type in a new user name"}
    />
  );
}

GymRegisterPage.propTypes = {
  rootStore: PropTypes.object
};

export default withRootStore(observer(GymRegisterPage));
