import {
  calculateStrippingUrgency,
  calculateSettingUrgency,
  createAcceptedWallAssembly,
  addSettingPlanToAcceptedWallAssembly
} from "./ReportStoreUtility";

import moment from "moment";

import { MIDDLE_RATING } from "../variables/constants";

export const NO_PLAN_PENALTY = -0.3;
export const FIXED_ADJUSTMENT = 0.2;
export const INFLUENCE_MIDWAY_POINT_3 = 3;

export function addLongevity(longevityObject, newLO) {
  longevityObject.explanations.push(newLO);
  longevityObject.rank = longevityObject.rank + newLO.days;
  if (isNaN(longevityObject.rank)) {
    console.log("The longevity is not NAN", longevityObject);
    debugger;
  }
}

export function addLongevityForSettingPlan(
  longevityBreakdown,
  settingPlan,
  gradeStore,
  planningQuantityMap,
  oldestRouteAgeInDays
) {
  if (settingPlan) {
    let gradeId = settingPlan.climbing_grade_id;

    let climbingGrade = gradeStore.getItemById(gradeId);
    let slotSettingPlanUrgencyAdjustment =
      calculateSettingUrgency(
        planningQuantityMap[gradeId].targetQuantity,
        planningQuantityMap[gradeId].actualQuantity
      ) * FIXED_ADJUSTMENT;

    let settingPlanDays = Math.floor(
      oldestRouteAgeInDays * slotSettingPlanUrgencyAdjustment
    );
    addLongevity(longevityBreakdown, {
      desc: "Setting the grade '" + climbingGrade.primaryLabel + "'",
      amount: Math.floor(slotSettingPlanUrgencyAdjustment * 100),
      days: settingPlanDays
    });
  } else {
    addLongevity(longevityBreakdown, {
      desc: "There is no setting plan after stripping this route.",
      amount: Math.floor(NO_PLAN_PENALTY * 100),
      days: Math.floor(oldestRouteAgeInDays * NO_PLAN_PENALTY)
    });
  }
}

export function getLongevityObject(climbAssembly, gym, oldestRouteAgeInDays) {
  let days = 0;
  /*
   * Work out what the base level of expected duration is
   */

  /*
   * The climbs age
   */
  if (!climbAssembly.parsedSettingDateTime) {
    console.log("Why isn't the date parsed");
    debugger;
  }
  let daysOld = Math.floor(
    (Date.now() - climbAssembly.parsedSettingDateTime.valueOf()) /
      1000 /
      60 /
      60 /
      24
  );

  let longevity = {
    explanations: [],
    daysOld: daysOld,
    rank: 0
  };

  addLongevity(longevity, {
    desc:
      "The climb's age (" +
      climbAssembly.parsedSettingDateTime.format("YYYY-MM-DD") +
      ") adjusted for the grade to (" +
      climbAssembly.adjustedSettingDateTime.format("YYYY-MM-DD") +
      ")",
    amount: "",
    days: daysOld
  });

  if (climbAssembly.climbingGrade.isArchived) {
    addLongevity(longevity, {
      desc: "This grade is archived so this climb needs to be stripped ASAP ",
      amount: 5000,
      days: 5000
    });
    return longevity;
  }

  /*
   *  Rating
   */
  let influence = gym.ratingLongevityInfluence;
  // climbAssembly.climbingRoute.ratingCount = 3;
  if (influence === 0) {
    addLongevity(longevity, {
      desc:
        "The influence is set to 0. So the rating of " +
        climbAssembly.climbingRoute.ratingCount +
        " is ignored.",
      amount: 0,
      days: 0
    });
  } else {
    // ASSIGN TEMPORARY VALUE until the ratings have been added to the DB
    climbAssembly.avgRating = MIDDLE_RATING;
    let avgRating = climbAssembly.avgRating;
    if (climbAssembly.climbingRoute.ratingCount < gym.minimumRatingCount) {
      addLongevity(longevity, {
        desc:
          "There are only " +
          climbAssembly.climbingRoute.ratingCount +
          " ratings for this route. No adjustment. " +
          avgRating,
        amount: 0,
        days: 0
      });
    } else if (avgRating < 1) {
      if (avgRating !== null && typeof avgRating !== "undefined") {
        console.log(
          "*************************************************************"
        );
        console.log(
          "WARNING: There may be corrupt data. This route is rated " + avgRating
        );
        console.log(
          "*************************************************************"
        );
        debugger;
      }
      addLongevity(longevity, {
        desc:
          "This route has a rating of " +
          avgRating +
          ". No adjustment applied.",
        amount: 0,
        days: 0
      });
    } else {
      // avgRating -4 gives negative numbers for the rating
      // e.g. a rating of 1 gets -3
      // Then by geting to to the power of 3 we can keep the negative number
      // e.g. -3^3 = -9
      // Then by dividing by 10 we get a percentage in decimal
      // e.g -9 = -90%
      let decimalPercentage = Math.pow(-(avgRating - 4), 3) / 10;

      //Change the influence to a fraction
      let fractionalInfluence = influence / INFLUENCE_MIDWAY_POINT_3;

      let ratingAdjustment =
        decimalPercentage * fractionalInfluence * FIXED_ADJUSTMENT;

      days = Math.floor(oldestRouteAgeInDays * ratingAdjustment);

      addLongevity(longevity, {
        desc:
          "This route has a rating of " +
          Math.round(avgRating * 10) / 10 +
          " from " +
          climbAssembly.climbingRoute.ratingCount +
          " ratings.",
        amount: Math.floor(ratingAdjustment * 100),
        days: days
      });
    }
  }
  /*
   *	The permanent wall adjustment
   */
  days = Math.floor(
    oldestRouteAgeInDays *
      (climbAssembly.wallAssembly.climbingWall.longevityAdjustment / 100)
  );
  addLongevity(longevity, {
    desc:
      "A permanent adjustment for the wall '" +
      climbAssembly.wallAssembly.climbingWall.name,
    amount: climbAssembly.wallAssembly.climbingWall.longevityAdjustment,
    days: days
  });

  /*
   * The grade adjustment
   */
  let gradeAdjustment = gym.getGradeAdjustment(climbAssembly.climbingGrade.id);

  /*  for (let i = 0; i < gym.gradeLongevityAdjustmentsJSON.length; i++) {
      if (
        gym.gradeLongevityAdjustmentsJSON[i].climbing_grade_id ===
        climbAssembly.climbingGrade.id
      ) {
        gradeAdjustment = gym.gradeLongevityAdjustmentsJSON[i].adjustment;
        break;
      }
    }*/

  //Grade adjustment might be zero if the grade has been archived.
  if (typeof gradeAdjustment !== "undefined") {
    days = Math.floor(oldestRouteAgeInDays * (gradeAdjustment / 100));
    addLongevity(longevity, {
      desc:
        "Each climb with grade '" +
        climbAssembly.climbingGrade.primaryLabel +
        "' gets this adjustment",
      amount: gradeAdjustment,
      days: days
    });
  }

  /*
   * Final scores
   */
  return longevity;
}

export function addToLongevityObjectBasedOnCurrentConditions(
  climbAssembly,
  mostRecentOfWall,
  wallMostRecentsettingDateTimeRange,
  oldestDateOfClimbInAllWalls,
  gym,
  allowDuplicates,
  now,
  validClimbAssemblies,
  mostRecentRoutesettingDateTimesByGrade,
  pretendQuantityMap,
  oldestRouteAgeInDays,
  longevity
) {
  /*
   * Deficit and Surplus of routes
   */

  let days;

  // The pretendQuantity can be undefined for archived grades.
  if (pretendQuantityMap[climbAssembly.climbingGrade.id]) {
    // if (climbAssembly.climbingGrade.id === 3) {
    //   //debugger;
    // }
    let actualQuantity =
      pretendQuantityMap[climbAssembly.climbingGrade.id].actualQuantity;
    let targetQuantity =
      pretendQuantityMap[climbAssembly.climbingGrade.id].targetQuantity;

    /*
     * Design flaw alert:
     * A route may be put at the top of the list because there are too many of that grade.
     * But the slot that the route comes from might not allow anything else. In which case
     * it'll be a bit screwed.
     */
    if (gym.shortageLongevityInfluence === 0) {
      addLongevity(longevity, {
        desc:
          "The shortage influence is set to zero. So the shortage of '" +
          actualQuantity +
          "' instead of '" +
          targetQuantity +
          "' is ignored.",
        amount: 0,
        days: 0
      });
    } else {
      let fractionalInfluence =
        gym.shortageLongevityInfluence / INFLUENCE_MIDWAY_POINT_3;

      let postStripCount = actualQuantity - 1;

      // if (climbAssembly.climbingRoute.id === 79) {
      //   //debugger;
      // }
      let strippingUrgency = calculateStrippingUrgency(
        targetQuantity,
        actualQuantity,
        fractionalInfluence
      );

      strippingUrgency = strippingUrgency * FIXED_ADJUSTMENT;

      days = Math.floor(oldestRouteAgeInDays * strippingUrgency);

      addLongevity(longevity, {
        desc:
          "After stripping this route there will be '" +
          postStripCount +
          "' instead of the desired '" +
          targetQuantity +
          "' routes of this grade.",
        amount: Math.floor(strippingUrgency * 100),
        days: days
      });
    }
  } else {
    console.log(
      "ERROR: We should have bailed out before we got to this point???"
    );
    debugger;
  }

  /*
   * Count of grades adjustment
   */

  if (!allowDuplicates) {
    let gradeCount = 0;
    for (let x = 0; x < validClimbAssemblies.length; x++) {
      let compareClimb = validClimbAssemblies[x];
      if (
        compareClimb.id !== climbAssembly.id &&
        compareClimb.climbingGradeId === climbAssembly.climbingGradeId
      ) {
        gradeCount++;
      }
    }

    let desc = null;
    let gradesAmount = null;
    days = null;

    if (gradeCount > 1) {
      desc =
        "There are '" +
        (gradeCount + 1) +
        "' duplicate routes of grade '" +
        climbAssembly.climbingGrade.primaryLabel +
        "' on the wall '" +
        climbAssembly.wallAssembly.climbingWall.name +
        "'";
      gradesAmount = gradeCount * gym.duplicateRouteLongevityAdjustment;
      days = Math.floor(oldestRouteAgeInDays * (gradesAmount / 100));
    } else {
      desc =
        "This grade '" +
        climbAssembly.climbingGrade.primaryLabel +
        "' is not doubled up.";
      gradesAmount = 0;
      days = 0;
    }

    addLongevity(longevity, {
      desc: desc,
      amount: gradesAmount,
      days: days
    });
  }

  /*
   *  Age of wall adjustment
   */

  if (gym.wallAgeLongevityInfluence === 0) {
    addLongevity(longevity, {
      desc: "The age of the wall influence is set to zero. None applied.",
      amount: 0,
      days: 0
    });
  } else {
    let wallAgeRecentivity = now - mostRecentOfWall;
    let proportionalAgeOfWall;

    // range can equal zero when there is only one remainingRoute left
    // so the youngest is the oldest.
    // In that case there isn't really an answer here so we'll just make it 0.5
    // so that it gets an adjustment of 0
    if (wallMostRecentsettingDateTimeRange === 0) {
      proportionalAgeOfWall = 0.5;
    } else {
      proportionalAgeOfWall =
        wallAgeRecentivity / wallMostRecentsettingDateTimeRange;
    }

    // If the oldestWallLongevity is %100 then the factor
    // will be 2
    // The oldestWall maximum adjusted proportionalAgeOfWall is 0.5,
    // so the oldest wall will be 2x0.5 which is 100%
    let fractionalInfluence =
      gym.wallAgeLongevityInfluence / INFLUENCE_MIDWAY_POINT_3;
    let proportion = proportionalAgeOfWall - 0.5;

    let proportionShouldBeNegative = true;
    if (proportion > 0) {
      proportionShouldBeNegative = false;
    }

    // Make the proportion larger than one,
    // then square it to make it bigger,
    // then reduce it again by one
    let ONE = 1;
    proportion = Math.pow(Math.abs(proportion) + ONE, 3) - ONE;

    proportion = proportion * fractionalInfluence;

    if (proportionShouldBeNegative) {
      proportion = -proportion;
    }

    let ageAmount = Math.floor(proportion * 100 * FIXED_ADJUSTMENT);

    days = Math.floor(oldestRouteAgeInDays * proportion * FIXED_ADJUSTMENT);

    let wallAgeDescription;
    let useLessVariable = false;
    //    if (wallAssembly.climbHasBeenStripped === true) {
    if (useLessVariable === true) {
      wallAgeDescription =
        "This wall will have been stripped today/soon and therefore probably set. So the age of the wall '" +
        moment(mostRecentOfWall).format("YYYY-MM-DD") +
        "' is being ignored.";
    } else {
      wallAgeDescription =
        "This wall has an age factor of " +
        ageAmount +
        ".  proportion=" +
        proportion +
        "..This wall's most recent date=" +
        moment(mostRecentOfWall).format("YYYY-MM-DD") +
        ". All wall's most recent date=" +
        moment(now).format("YYYY-MM-DD") +
        ". All wall's least recent date=" +
        moment(oldestDateOfClimbInAllWalls).format("YYYY-MM-DD") +
        "'";
    }

    addLongevity(longevity, {
      desc: wallAgeDescription,
      amount: ageAmount,
      days: days
    });
  }
  /*
   *  Age of Grade adjustment
   */
  if (gym.routeAgeLongevityInfluence === 0) {
    addLongevity(longevity, {
      desc: "The age of the route influence is set to zero. None applied.",
      amount: 0,
      days: 0
    });
  } else {
    let gradeAgeRecentivity =
      now -
      mostRecentRoutesettingDateTimesByGrade[climbAssembly.climbingGrade.id];

    let proportionalAgeOfGrade = 12;

    // range can equal zero when there is only one remainingRoute left
    // so the youngest is the oldest.
    // In that case there isn't really an answer here so we'll just make it 0.5
    // so that it gets an adjustment of 0
    if (wallMostRecentsettingDateTimeRange === 0) {
      proportionalAgeOfGrade = 0.5;
    } else {
      proportionalAgeOfGrade =
        gradeAgeRecentivity / wallMostRecentsettingDateTimeRange;
    }

    let routeAgeFractionalInfluence =
      gym.routeAgeLongevityInfluence / INFLUENCE_MIDWAY_POINT_3;

    let gradeAgeAmount = Math.floor(
      (proportionalAgeOfGrade - 0.5) * routeAgeFractionalInfluence * 100
    );
    /*
       * This longevity adjustment has been disabled.
       * There seems to be a bug here because it is using
       * wallMostRecentsettingDateTimeRange which is for the wall not for the grade
       * 
      days = Math.floor(expectedClimbDuration * (routeAgeAmount / 100));
      */
    days = 0;
    /*
      let proposedRouteGrades = {}; // ?????? What is this supposed to do
  
          let gradeAgeDescription;
      if (proposedRouteGrades[climbAssembly.climbingGradeId] === true) {
        gradeAgeDescription =
          "This grade will have been set today/soon based on the stripping list.";
      } else {
        gradeAgeDescription =
          "This grade was last set on " +
          moment(
            mostRecentRoutesettingDateTimesByGrade[climbAssembly.climbingGradeId]
          ).format("L");
      }
  */
    addLongevity(longevity, {
      //desc: gradeAgeDescription,
      desc: "This feature has been disabled because of bug.",
      amount: gradeAgeAmount,
      days: days
    });
  }
}

export function getOldestAdjustedDate(computedCache, gradeType, now) {
  let oldestDateOfClimbInAllWalls = now;
  for (let i = 0; i < computedCache.sectionAssemblies.length; i++) {
    let sectionAssembly = computedCache.sectionAssemblies[i];

    if (!sectionAssembly.climbingSection.isArchived) {
      for (let i = 0; i < sectionAssembly.climbingWallAssemblies.length; i++) {
        let wallAssembly = sectionAssembly.climbingWallAssemblies[i];
        if (
          !wallAssembly.climbingWall.isArchived &&
          wallAssembly.climbingWall.gradeType === gradeType
        ) {
          for (
            let i = 0;
            i < wallAssembly.climbingRouteAssemblies.length;
            i++
          ) {
            let routeAssembly = wallAssembly.climbingRouteAssemblies[i];
            if (!routeAssembly.climbingRoute.isOnHold) {
              if (
                oldestDateOfClimbInAllWalls.isAfter(
                  routeAssembly.adjustedSettingDateTime
                )
              ) {
                oldestDateOfClimbInAllWalls =
                  routeAssembly.adjustedSettingDateTime;
              }
            }
          }
        }
      }
    }
  }
  return oldestDateOfClimbInAllWalls;
}

export function getOldestDateInDays(now, oldestDateOfClimbInAllWalls) {
  const diffTime = now.diff(oldestDateOfClimbInAllWalls);

  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export function getAcceptedWallsWithPlanAssembliesAdded(
  computedCache,
  gradeType,
  now,
  planAssemblies,
  gradeStores,
  includeOnHoldRoutes
) {
  let retVal = [];
  for (let i = 0; i < computedCache.sectionAssemblies.length; i++) {
    let sectionAssembly = computedCache.sectionAssemblies[i];

    if (!sectionAssembly.climbingSection.isArchived) {
      for (let i = 0; i < sectionAssembly.climbingWallAssemblies.length; i++) {
        let wallAssembly = sectionAssembly.climbingWallAssemblies[i];
        if (
          !wallAssembly.climbingWall.isArchived &&
          wallAssembly.climbingWall.gradeType === gradeType
        ) {
          let acceptedWallAssembly = createAcceptedWallAssembly(
            wallAssembly,
            now,
            planAssemblies,
            {
              includeOnHoldRoutes: includeOnHoldRoutes
            }
          );

          let gradeStore = gradeStores[wallAssembly.climbingWall.gradeType];

          addSettingPlanToAcceptedWallAssembly(
            planAssemblies,
            acceptedWallAssembly,
            gradeStore
          );

          retVal.push(acceptedWallAssembly);
        }
      }
    }
  }
  return retVal;
}
