import React from "react";
import PropTypes from "prop-types";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

let padded = {
  marginTop: "20px"
};

function getSelect({
  instruction,
  value,
  onChange,
  inputName,
  inputId,
  items,
  labelGetterFn,
  classes
}) {
  if (instruction === "Choose Section") {
    // console.log(
    //   "aaaa- In the getselect for section. With value=" +
    //     value +
    //     " and type " +
    //     typeof value
    // );
  }
  return (
    <FormControl fullWidth style={padded}>
      <InputLabel htmlFor={inputId} className={classes.selectLabel}>
        {instruction}
      </InputLabel>
      <Select
        required={true}
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value={typeof value !== "undefined" && value !== null ? value : ""}
        onChange={onChange}
        id={inputId}
        inputProps={{
          name: inputName
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
        >
          {instruction}
        </MenuItem>

        {items.map((item, index) => {
          if (item.isArchived === true && value !== item.id) return null;
          return (
            <MenuItem
              key={index + labelGetterFn(item)}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={item.id}
            >
              {labelGetterFn(item)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

getSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  instruction: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  inputName: PropTypes.string.isRequired,
  inputId: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  labelGetterFn: PropTypes.func.isRequired
};

export default getSelect;
