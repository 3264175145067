import createFilterStore from "./CreateFilterStore.js";
import { gradeTypes } from "../variables/constants";

export default class FilterStores {
  constructor(gradeStores, setterAliasStore, computedCacheStore) {
    let boulderGradeToStripFilterStore = createFilterStore();
    let routeGradeToStripFilterStore = createFilterStore();
    this.gradeToStripFilterStores = [];
    this.gradeToStripFilterStores[
      gradeTypes.BOULDER
    ] = boulderGradeToStripFilterStore;
    this.gradeToStripFilterStores[
      gradeTypes.ROUTE
    ] = routeGradeToStripFilterStore;

    this.gradeToSetFilterStores = [];
    let boulderGradeToSetFilterStore = createFilterStore();
    let routeGradeToSetFilterStore = createFilterStore();
    this.gradeToSetFilterStores[
      gradeTypes.BOULDER
    ] = boulderGradeToSetFilterStore;
    this.gradeToSetFilterStores[gradeTypes.ROUTE] = routeGradeToSetFilterStore;

    let boulderSetterFilterStore = createFilterStore();
    let routeSetterFilterStore = createFilterStore();
    this.setterFilterStores = [];
    this.setterFilterStores[gradeTypes.BOULDER] = boulderSetterFilterStore;
    this.setterFilterStores[gradeTypes.ROUTE] = routeSetterFilterStore;

    let boulderWallFilterStore = createFilterStore();
    let routeWallFilterStore = createFilterStore();
    this.wallFilterStores = [];
    this.wallFilterStores[gradeTypes.BOULDER] = boulderWallFilterStore;
    this.wallFilterStores[gradeTypes.ROUTE] = routeWallFilterStore;

    let boulderSectionFilterStore = createFilterStore();
    let routeSectionFilterStore = createFilterStore();
    this.sectionFilterStores = [];
    this.sectionFilterStores[gradeTypes.BOULDER] = boulderSectionFilterStore;
    this.sectionFilterStores[gradeTypes.ROUTE] = routeSectionFilterStore;

    let filterOnlyThisSection = (
      targetSectionId,
      sectionFilterStore,
      wallFilterStore
    ) => {
      const computedCache = computedCacheStore.computedCache.get();

      for (let sectionAssembly of computedCache.sectionAssemblies) {
        let isFiltered = true;
        if (targetSectionId === sectionAssembly.climbingSection.id) {
          isFiltered = false;
        }
        sectionFilterStore.setFilter(
          sectionAssembly.climbingSection.id,
          isFiltered
        );
        for (let wallAssembly of sectionAssembly.climbingWallAssemblies) {
          wallFilterStore.setFilter(wallAssembly.climbingWall.id, isFiltered);
        }
      }
    };

    boulderSectionFilterStore.filterOnlyThis = targetSectionId => {
      filterOnlyThisSection(
        targetSectionId,
        boulderSectionFilterStore,
        boulderWallFilterStore
      );
    };
    routeSectionFilterStore.filterOnlyThis = targetSectionId => {
      filterOnlyThisSection(
        targetSectionId,
        routeSectionFilterStore,
        routeWallFilterStore
      );
    };

    let filterOnlyThisWall = (targetWallId, wallFilterStore) => {
      const computedCache = computedCacheStore.computedCache.get();

      for (let sectionAssembly of computedCache.sectionAssemblies) {
        for (let wallAssembly of sectionAssembly.climbingWallAssemblies) {
          let isFiltered = true;
          if (targetWallId === wallAssembly.climbingWall.id) {
            isFiltered = false;
          }
          wallFilterStore.setFilter(wallAssembly.climbingWall.id, isFiltered);
        }
      }
    };

    boulderWallFilterStore.filterOnlyThis = targetWallId => {
      filterOnlyThisWall(targetWallId, boulderWallFilterStore);
    };
    routeWallFilterStore.filterOnlyThis = targetWallId => {
      filterOnlyThisWall(targetWallId, routeWallFilterStore);
    };

    let filterOnlyThisSetter = (targetSetterId, setterFilterStore) => {
      for (let setter of setterAliasStore.getAllItemsEvenArchived()) {
        setterFilterStore.setFilter(setter.id, setter.id !== targetSetterId);
      }
    };

    boulderSetterFilterStore.filterOnlyThis = targetSetterId => {
      filterOnlyThisSetter(targetSetterId, boulderSetterFilterStore);
    };

    routeSetterFilterStore.filterOnlyThis = targetSetterId => {
      filterOnlyThisSetter(targetSetterId, routeSetterFilterStore);
    };

    let filterOnlyThisGrade = (
      targetGradeId,
      gradeToStripFilterStore,
      gradeStore
    ) => {
      for (let grade of gradeStore.getAllItemsEvenArchived()) {
        gradeToStripFilterStore.setFilter(grade.id, grade.id !== targetGradeId);
      }
    };

    boulderGradeToStripFilterStore.filterOnlyThis = targetGradeId => {
      filterOnlyThisGrade(
        targetGradeId,
        boulderGradeToStripFilterStore,
        gradeStores.gradeStores[gradeTypes.BOULDER]
      );
    };

    routeGradeToStripFilterStore.filterOnlyThis = targetGradeId => {
      filterOnlyThisGrade(
        targetGradeId,
        routeGradeToStripFilterStore,
        gradeStores.gradeStores[gradeTypes.ROUTE]
      );
    };

    boulderGradeToSetFilterStore.filterOnlyThis = targetGradeId => {
      filterOnlyThisGrade(
        targetGradeId,
        boulderGradeToSetFilterStore,
        gradeStores.gradeStores[gradeTypes.BOULDER]
      );
    };

    routeGradeToSetFilterStore.filterOnlyThis = targetGradeId => {
      filterOnlyThisGrade(
        targetGradeId,
        routeGradeToSetFilterStore,
        gradeStores.gradeStores[gradeTypes.ROUTE]
      );
    };

    this.clearAllFiltersFns = [];

    this.clearAllFiltersFns[gradeTypes.BOULDER] = () => {
      this.clearAllFilters(gradeTypes.BOULDER);
    };

    this.clearAllFiltersFns[gradeTypes.ROUTE] = () => {
      this.clearAllFilters(gradeTypes.ROUTE);
    };

    this.clearAllFilters = gradeType => {
      this.gradeToStripFilterStores[gradeType].clearFilter();
      this.gradeToSetFilterStores[gradeType].clearFilter();
      this.wallFilterStores[gradeType].clearFilter();
      this.sectionFilterStores[gradeType].clearFilter();
    };
  }
}
