import { observable } from "mobx";
import moment from "moment";
import createSettingPlanUIStore from "./createSettingPlanUIStore.js";
import { gradeTypes } from "../variables/constants";
import getCachable from "../utils/getCachable.js";

export default class PlanningStores {
  constructor(context, asyncOpCreate, getCurrentGymId) {
    const rest = context.rest;

    // let removeItemById = (list, id, isSameIdFn) => {
    //   if (typeof id === "string") {
    //     console.log("The ID is a string");
    //     throw Error("The ID is a string");
    //   }
    //   for (let i = 0; i < list.length; i++) {
    //     if (isSameIdFn(id, list[i])) {
    //       list.splice(i, 1);
    //       return true;
    //     }
    //   }
    //   return false;
    // };

    let convertPlanningDate = plan => {
      plan.planningDate = moment(plan.planningDate);
    };
    let convertIsExcludedFromRatings = plan => {
      plan.isExcludedFromRatings =
        plan.isExcludedFromRatings === null
          ? false
          : plan.isExcludedFromRatings;
    };

    // let getItemById = (list, id, isSameIdFn) => {
    //   if (typeof id === "string") {
    //     console.log("The ID is a string");
    //     throw Error("The ID is a string");
    //   }
    //   for (let i = 0; i < list.length; i++) {
    //     if (isSameIdFn(id, list[i])) {
    //       return list[i];
    //     }
    //   }
    //   return null;
    // };

    this.settingPlanCacheStore = getCachable(
      asyncOpCreate,
      rest,
      (a, b) => a.id === b.id,
      "settingPlans",
      response => {
        response.forEach(convertPlanningDate);
        response.forEach(convertIsExcludedFromRatings);
      },
      () => ({
        // This is the defaultPayload for the request
        isComplete: false,
        gym_id: getCurrentGymId()
      })
    );

    // observable({
    //   getStoreName: () => {
    //     return "settingPlanCacheStore";
    //   },
    //   settingPlans: observable([]),
    //   opCacheItems: asyncOpCreate(
    //     () => rest.get("settingPlans"),
    //     "cacheSettingPlans"
    //   ),
    //   cacheItems: action(async function({ forceReload }) {
    //     if (this.hasLoaded && forceReload !== true) {
    //       return;
    //     }
    //     this.hasLoaded = false;
    //     try {
    //       console.log("Starting to cache the route Store");
    //       this.opCacheItems.execute({}, response => {
    //         response.forEach(convertPlanningDate);
    //         response.forEach(convertIsExcludedFromRatings);
    //         this.settingPlans.clear();
    //         this.settingPlans.replace(response);
    //         this.hasLoaded = true;
    //       });
    //     } catch (error) {
    //       debugger;
    //       if (error === null || typeof error === "undefined") {
    //         console.log(
    //           "The call to 'planning' has failed inside the planningStore. The error is undefined."
    //         );
    //       } else {
    //         console.log("THe error is : " + error);
    //         const {
    //           response: { status }
    //         } = error;
    //         if (401 === status) {
    //           console.log(
    //             "The call to 'planning' has returned with 401. The user is probably not logged in"
    //           );
    //         } else {
    //           console.log("The call to 'planning' has failed: " + error);
    //         }
    //       }
    //       throw error;
    //     }
    //   }),

    //   getItemById(id) {
    //     return getItemById(
    //       this.settingPlans,
    //       id,
    //       (id, settingPlan) => id === settingPlan.id
    //     );
    //   },

    //   removeSettingPlanById(id) {
    //     return removeItemById(
    //       this.settingPlans,
    //       id,
    //       (id, settingPlan) => id === settingPlan.id
    //     );
    //   }
    // });

    this.settingPlanStore = observable({
      name: undefined,
      description: undefined,
      isExcludedFromRatings: false,
      setterNotes: undefined,
      planningDate: undefined,
      climbing_wall_id: undefined,
      climbing_grade_id: undefined,
      marker_color_id: undefined,
      gym_id: undefined,
      planType: undefined,
      isComplete: false,
      route_to_strip_id: undefined,
      route_set_id: undefined,
      id: undefined,

      // There are two ways for this field to be populated:
      // 1) the field is returned from the server by a join on the routes table.
      // 2) It is populated at the time of creation. This second method is a bit
      // of a hack to avoid having to search for the route using the id.
      //
      // It is removed before sending to the server.
      routeToStrip: undefined,

      setToDefaults() {
        this.name = undefined;
        this.description = undefined;
        this.isExcludedFromRatings = false;
        this.setterNotes = undefined;
        this.planningDate = undefined;
        this.climbing_wall_id = undefined;
        this.climbing_grade_id = undefined;
        this.marker_color_id = undefined;
        this.gym_id = undefined;
        this.planType = undefined;
        this.isComplete = false;
        this.route_set_id = undefined;
        this.route_to_strip_id = undefined;
        this.routeToStrip = undefined; // This is always removed? Why is it here? Should be in the assembly
        this.id = undefined;
      },
      opUpdatePlan: asyncOpCreate(
        ({ payload, id }) => rest.put("settingPlans/" + id, payload),
        "opUpdatePlan"
      ),
      opCreatePlan: asyncOpCreate(
        payload => rest.post("settingPlans", payload),
        "opCreatePlan"
      ),

      createSettingPlans: async newSettingPlans => {
        try {
          let copyOfRoutesToStrip = newSettingPlans.map(settingPlan => {
            let routeToStrip = settingPlan.routeToStrip;
            settingPlan.routeToStrip = undefined;
            return routeToStrip;
          });

          let callback = response => {
            try {
              for (let i = 0; i < response.length; i++) {
                let settingPlan = response[i];
                console.log(
                  "Beginning seearch for route to set: with ID" +
                    settingPlan.route_to_strip_id
                );
                for (let j = 0; j < copyOfRoutesToStrip.length; j++) {
                  let routeToStrip = copyOfRoutesToStrip[j];
                  if (routeToStrip) {
                    console.log(
                      "   comparing " +
                        routeToStrip.id +
                        " and " +
                        settingPlan.route_to_strip_id
                    );
                  } else {
                    console.log(
                      "Skipping the route to strip because it is undefined"
                    );
                  }
                  if (
                    routeToStrip &&
                    routeToStrip.id === settingPlan.route_to_strip_id
                  ) {
                    console.log("XXXXXXXXXX Found the route to Strip");
                    settingPlan.routeToStrip = routeToStrip;
                  }
                }
                console.log(
                  "Finished looking for route with ID: " +
                    settingPlan.route_to_strip_id
                );
                convertIsExcludedFromRatings(settingPlan);
                convertPlanningDate(settingPlan);
              }

              this.settingPlanCacheStore.items.push(...response);
            } catch (err) {
              console.log("Error caught");
              console.log(err);
              debugger;
            }
            console.log("Finsish comparing all routes");
          };

          await this.settingPlanStore.opCreatePlan.execute(
            newSettingPlans,
            callback
          );
        } catch (error) {
          console.log("Failed to save the plans" + error);
          debugger;
          throw error;
        }
      },
      createSettingPlan: async routeToStrip => {
        try {
          let payload = {};
          this.settingPlanStore.merge(this.settingPlanStore, payload);

          await this.settingPlanStore.opCreatePlan.execute(
            payload,
            response => {
              response.planningDate = moment(response.planningDate);
              response.routeToStrip = routeToStrip;
              convertPlanningDate(response);
              convertIsExcludedFromRatings(response);
              this.settingPlanCacheStore.items.push(response);
            }
          );
        } catch (error) {
          console.log("Failed to save the plans" + error);
          debugger;
          throw error;
        }
      },

      completeSettingPlan: settingPlan => {
        settingPlan.isComplete = true;
        this.settingPlanStore.merge(settingPlan, this.settingPlanStore);
        this.settingPlanStore.updateSettingPlan();
        this.settingPlanCacheStore.removeItemById(settingPlan.id);
      },
      updateSettingPlan: () => {
        let payload = this.settingPlanStore.getPayload();

        //We need to copy the values into the local cache
        // This could be done after the server update
        // But that would delay the user interface
        // So we are doing it immediately
        let existingPlan = this.settingPlanCacheStore.getItemById(
          this.settingPlanStore.id
        );

        if (payload.isComplete) {
          this.settingPlanCacheStore.removeItemById(payload.id);
        }
        if (existingPlan) {
          this.settingPlanStore.merge(this.settingPlanStore, existingPlan);
        } else {
          console.log("Not merging the route because it isn't in the cache");
        }

        return this.settingPlanStore.opUpdatePlan.execute({
          payload: payload,
          id: payload.id
        });
      },

      opDeletePlan: asyncOpCreate(
        payload => rest.delete("settingPlans/" + payload.id),
        "opDeletePlan"
      ),
      deleteSettingPlan: targetSettingPlan => {
        const settingPlans = this.settingPlanCacheStore.items;

        for (let i = 0; i < settingPlans.length; i++) {
          if (settingPlans[i] === targetSettingPlan) {
            settingPlans.splice(i, 1);
            this.settingPlanStore.opDeletePlan.execute({
              id: targetSettingPlan.id
            });
            return;
          }
        }
        let message = "Unable to find the setting plan";
        console.log(message);
        debugger;
        throw message;
      },

      getPayload: () => {
        return this.settingPlanStore.merge(this.settingPlanStore, {});
      },

      merge: (from, to) => {
        if (from === null || typeof from === "undefined") {
          console.log("ERROR: from is null or undefined:" + from);
          debugger;
        }
        if (from === null || typeof to === "undefined") {
          console.log("ERROR: to is null or undefined:" + from);

          debugger;
        }
        let fieldNames = [
          "id",
          "name",
          "description",
          "setterNotes",
          "route_status_id",
          "setter_alias_id",
          "marker_color_id",
          "climbing_grade_id",
          "climbing_wall_id",
          "gym_id",
          "isOnHold",
          "isExcludedFromRatings",
          "planningDate",
          "route_to_strip_id",
          "planType",
          "isComplete"
        ];

        fieldNames.forEach(name => {
          if (typeof from[name] !== "undefined" && from[name] !== null) {
            if (typeof from[name] === "string" && from[name].trim() === "") {
              to[name] = null;
            } else {
              to[name] = from[name];
            }
          }
        });
        return to;
      }
    });

    this.settingPlanUIStores = [];
    this.settingPlanUIStores[gradeTypes.ROUTE] = createSettingPlanUIStore();
    this.settingPlanUIStores[gradeTypes.BOULDER] = createSettingPlanUIStore();
  }
}
