import React, { useState } from "react";

import { PropTypes } from "prop-types";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import Datetime from "react-datetime";

import { useTheme } from "@material-ui/core/styles";

import { Button } from "ticker-components";

import { withCachedRootStore } from "ticker-core";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";

var isValid = function(current) {
  let now = moment();
  return current.isBefore(now);
};

function EditLogEntryPopUp({ isOpen, closeFn, entry, rootStore }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let [dateSelection, setDateSelection] = useState(
    moment(entry.dateOnlyClimbedTZ)
  );
  let defaultTimeSelection;
  if (entry.timeOnlyClimbedTZ) {
    defaultTimeSelection = moment(
      `${entry.dateOnlyClimbedTZ}T${entry.timeOnlyClimbedTZ}`
    );
  } else {
    defaultTimeSelection = moment(`${entry.dateOnlyClimbedTZ}`);
  }
  let [timeSelection, setTimeSelection] = useState(defaultTimeSelection);

  return (
    <Dialog
      onClose={closeFn}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      fullScreen={fullScreen}
    >
      <DialogTitle>What date did you climb this?</DialogTitle>
      <div style={{ maxWidth: "20em", margin: "auto" }}>
        <Datetime
          timeFormat={false}
          defaultValue={dateSelection}
          closeOnSelect={false}
          dateFormat={"YYYY-MM-DD"}
          onChange={value => {
            setDateSelection(value);
          }}
          input={false}
          isValidDate={isValid}
        />
      </div>
      <div style={{ maxWidth: "20em", margin: "auto" }}>
        <Datetime
          timeFormat={"HH:mm"}
          dateFormat={false}
          defaultValue={timeSelection}
          closeOnSelect={false}
          onChange={value => {
            setTimeSelection(value);
          }}
          input={false}
          isValidDate={isValid}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <Button color="warning" onClick={closeFn}>
          Cancel
        </Button>
        <Button
          color="success"
          onClick={() => {
            if (moment(dateSelection).isAfter(moment())) {
              newWarning = "You can't time travel from the future.";
            }

            rootStore.personalRootStore.personalLogEntryStores.logEntryStore.updateLogEntry(
              entry,
              dateSelection,
              timeSelection,
              closeFn
            );
          }}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
}

EditLogEntryPopUp.propTypes = {
  rootStore: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  closeFn: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withCachedRootStore(EditLogEntryPopUp);
