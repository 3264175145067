export default class {
  constructor(context, asyncOpCreate) {
    let rest = context.rest;

    this.opGetClimbReport = asyncOpCreate(
      payload => rest.get("userRatingReports/climb", payload),
      "opGetSettingHistory"
    );
    this.getClimbReport = climbing_route_id => {
      if (!climbing_route_id) {
        console.error("The id is missing");
      }
      let payload = { climbing_route_id };
      this.opGetClimbReport.execute(payload);
    };

    this.opGetUsersReport = asyncOpCreate(
      payload => rest.get("userRatingReports/users", payload),
      "opGetUsersReport"
    );

    this.getUsersReport = climbing_route_id => {
      if (!climbing_route_id) {
        console.error("The id is missing");
      }
      let payload = { climbing_route_id };
      this.opGetUsersReport.execute(payload);
    };
  }
}
