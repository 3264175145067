import { observable, action } from "mobx";

export default function createFilterStore() {
  let hack = {};

  /*
   * Do not replace the arrays. Use items.replace(items)
   */
  hack.isFilteredMap = observable(new Map());

  hack.setFilter = action((objectId, newValue) => {
    hack.isFilteredMap.set(objectId, newValue);
  });

  hack.toggle = objectId => {
    hack.setFilter(objectId, !hack.isFilteredMap.get(objectId));
    return hack.isFilteredMap.get(objectId);
  };

  hack.isFiltered = gradeId => {
    return hack.isFilteredMap.get(gradeId) === true;
  };

  hack.clearFilter = () => {
    hack.isFilteredMap.clear();
  };

  hack.getNumberOfFilters = () => {
    let iterator = hack.isFilteredMap.entries();
    let current = iterator.next();
    let count = 0;
    while (!current.done) {
      if (current.value[1]) {
        count++;
      }
      current = iterator.next();
    }
    return count;
  };

  return hack;
}
