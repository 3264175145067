import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { gradeTypes } from "ticker-core";

// import { customCheckboxRadioSwitchStyle } from "ticker-core";

import { withRootStore } from "ticker-core";
import DraggableAlertList from "./DraggableAlertList.js";

// import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// const style = {
//   infoText: {
//     fontWeight: "300",
//     margin: "10px 0 30px",
//     textAlign: "center"
//   },
//   ...customCheckboxRadioSwitchStyle,
//   ...sweetAlertStyle
// };

class Grades extends React.Component {
  constructor(props) {
    super(props);
    this.onAddFn = this.onAddFn.bind(this);
    this.onEditFn = this.onEditFn.bind(this);
  }
  componentDidMount() {
    console.log("About to call cache Grades");
    this.props.rootStore.gradeStores.gradeStores[gradeTypes.BOULDER].cacheItems(
      {}
    );
    this.props.rootStore.gradeStores.gradeStores[gradeTypes.ROUTE].cacheItems(
      {}
    );
  }

  onAddFn(draggableStore, autoSave, newLabel) {
    draggableStore.addItem(
      { primaryLabel: newLabel, gradeType: this.props.gradeType },
      autoSave
    );
  }

  onEditFn(draggableStore, id, autoSave, newLabel) {
    let newItem = {
      primaryLabel: newLabel,
      gradeType: this.props.gradeType
    };
    draggableStore.changeItem(newItem, id, autoSave);
  }

  render() {
    const { showArchived, draggableStore, autoSave } = this.props;
    let routeCacheItems = this.props.rootStore.gradeStores.gradeStores[
      gradeTypes.ROUTE
    ].opCacheItems;
    let boulderCacheItems = this.props.rootStore.gradeStores.gradeStores[
      gradeTypes.BOULDER
    ].opCacheItems;

    let body = null;
    if (routeCacheItems.executing || boulderCacheItems.executing) {
      body = <div>Loading Grades</div>;
    } else if (routeCacheItems.error || boulderCacheItems.error) {
      body = <div>Error</div>;
    } else {
      body = (
        <React.Fragment>
          <DraggableAlertList
            autoSave={autoSave}
            showArchived={showArchived}
            draggableStore={draggableStore}
            onAddFn={this.onAddFn}
            onEditFn={this.onEditFn}
            itemName="Route Grade"
            alertTitle="What is the label for the grade?"
            getLabel={item => item.primaryLabel}
          />
        </React.Fragment>
      );
    }
    return <React.Fragment>{body}</React.Fragment>;
  }
}

Grades.propTypes = {
  rootStore: PropTypes.object.isRequired,
  showArchived: PropTypes.bool.isRequired,
  autoSave: PropTypes.bool.isRequired,
  draggableStore: PropTypes.object.isRequired,
  gradeType: PropTypes.number.isRequired
};

export default withRootStore(observer(Grades));
