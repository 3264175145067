export const MODE_IDLE = "idle";
export const MODE_PANNING = "panning";
export const MODE_ZOOMING = "zooming";

export const TOOL_AUTO = "auto";
export const TOOL_NONE = "none";
export const TOOL_PAN = "pan";
export const TOOL_ZOOM_IN = "zoom-in";
export const TOOL_ZOOM_OUT = "zoom-out";

export const POSITION_NONE = "none";
export const POSITION_TOP = "top";
export const POSITION_RIGHT = "right";
export const POSITION_BOTTOM = "bottom";
export const POSITION_LEFT = "left";

export const ACTION_ZOOM = "zoom";
export const ACTION_PAN = "pan";

export const ALIGN_CENTER = "center";
export const ALIGN_LEFT = "left";
export const ALIGN_RIGHT = "right";
export const ALIGN_TOP = "top";
export const ALIGN_BOTTOM = "bottom";
export const ALIGN_COVER = "cover";

export const INITIAL_VALUE = {};

export default {
  MODE_IDLE,
  MODE_PANNING,
  MODE_ZOOMING,

  TOOL_AUTO,
  TOOL_NONE,
  TOOL_PAN,
  TOOL_ZOOM_IN,
  TOOL_ZOOM_OUT,

  POSITION_NONE,
  POSITION_TOP,
  POSITION_RIGHT,
  POSITION_BOTTOM,
  POSITION_LEFT,

  ACTION_ZOOM,
  ACTION_PAN,

  ALIGN_CENTER,
  ALIGN_LEFT,
  ALIGN_RIGHT,
  ALIGN_TOP,
  ALIGN_BOTTOM,
  ALIGN_COVER,

  INITIAL_VALUE
};
