import svgBackgroundPNG from "../../../img/square.png";
import { coreStyle } from "ticker-core";

let { defaultBoxShadow } = coreStyle;

let styles = {
  filterPanel: {
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 1
    // background: "white",
    // ...defaultBoxShadow
  },
  svgContainer: {
    position: "relative",
    overflowScrolling: "touch",
    backgroundImage: "url(" + svgBackgroundPNG + ")",
    backgroundColor: "#f1f1f1",
    backgroundSize: "30px",
    backgroundRepeat: "repeat"
  }
};

export default styles;
