import { MIDDLE_RATING } from "../variables/constants";

let sortByLowest = (a, b, middlePoint) => {
  if (a) {
    if (b) {
      return a - b;
    }
    if (a === middlePoint) {
      return 1;
    }
    return a - middlePoint;
  }
  if (b) {
    if (b === middlePoint) {
      return -1;
    }
    return middlePoint - b;
  }
  return 0;
};

let sortByHighest = (a, b, middlePoint) => {
  if (a) {
    if (b) {
      return b - a;
    }
    if (a === middlePoint) {
      return -1;
    }
    return middlePoint - a;
  }
  if (b) {
    if (b === middlePoint) {
      return 1;
    }
    return b - middlePoint;
  }
  return 0;
};

let sortByHighestRated = (assemblyA, assemblyB) =>
  sortByHighest(
    assemblyA.climbingRoute.avgRating,
    assemblyB.climbingRoute.avgRating,
    MIDDLE_RATING
  );

let sortByLowestRated = (assemblyA, assemblyB) =>
  sortByLowest(
    assemblyA.climbingRoute.avgRating,
    assemblyB.climbingRoute.avgRating,
    MIDDLE_RATING
  );

let sortByMyFavs = (assemblyA, assemblyB) =>
  sortByHighest(
    assemblyA.userRating ? assemblyA.userRating.rating : undefined,
    assemblyB.userRating ? assemblyB.userRating.rating : undefined,
    MIDDLE_RATING
  );

let sortByMyHates = (assemblyA, assemblyB) =>
  sortByLowest(
    assemblyA.userRating ? assemblyA.userRating.rating : undefined,
    assemblyB.userRating ? assemblyB.userRating.rating : undefined,
    MIDDLE_RATING
  );

let sortByAgeDescending = (a, b) => {
  console.log("Sorting Date by age descending");
  return b.parsedSettingDateTime.diff(a.parsedSettingDateTime);
};

let sortByAgeAscending = (a, b) => {
  console.log("Sorting Date by age AAAscending");
  return a.parsedSettingDateTime.diff(b.parsedSettingDateTime);
};

let sortByGradeAscending = (a, b) =>
  a.climbingGrade.orderPosition - b.climbingGrade.orderPosition;

let sortByGradeDescending = (a, b) =>
  b.climbingGrade.orderPosition - a.climbingGrade.orderPosition;

let sortByModifierAscending = (a, b) =>
  sortByLowest(
    Math.abs(a.relativeCombinedModifier),
    Math.abs(b.relativeCombinedModifier),
    0
  );

let sortByModifierDescending = (a, b) =>
  sortByHighest(
    Math.abs(a.relativeCombinedModifier),
    Math.abs(b.relativeCombinedModifier),
    0
  );

export default {
  sortByGradeAscending,
  sortByGradeDescending,
  sortByHighestRated,
  sortByLowestRated,
  sortByMyFavs,
  sortByMyHates,
  sortByAgeDescending,
  sortByAgeAscending,
  sortByModifierAscending,
  sortByModifierDescending
};
