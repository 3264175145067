import sortFns from "./sortFns";

let {
  sortByGradeAscending,
  sortByGradeDescending,
  sortByHighestRated,
  sortByLowestRated,
  sortByMyFavs,
  sortByMyHates,
  sortByAgeDescending,
  sortByAgeAscending,
  sortByModifierAscending,
  sortByModifierDescending
} = sortFns;

let noPersonalFns = [
  [sortByGradeAscending, sortByGradeDescending, "Grade", sortByGradeAscending],
  [sortByHighestRated, sortByLowestRated, "Public Rating", sortByHighestRated],
  [sortByAgeAscending, sortByAgeDescending, "Age", sortByAgeAscending]
];
let allGroupedFns = [
  ...noPersonalFns,
  [sortByMyFavs, sortByMyHates, "My Rating", sortByMyFavs]
];

let extraFns = [
  ...noPersonalFns,
  [
    sortByModifierAscending,
    sortByModifierDescending,
    "Grade Accuracy",
    sortByModifierDescending
  ]
];

export function getInspirationSortOptions(
  regStore,
  transitionStore,
  inspirationStore,
  pageName,
  personalDataExists
) {
  let sourceFns = personalDataExists ? allGroupedFns : noPersonalFns;

  let { UISelection } = inspirationStore[pageName];

  let sortOptions = sourceFns.map(
    ([ascending, descending, label, defaultOrder]) => {
      return {
        onClick: () => {
          regStore.resetNoActivityTimeout();

          let newSelection;
          if (
            UISelection.sortBy !== descending &&
            UISelection.sortBy !== ascending
          ) {
            newSelection = defaultOrder;
          } else if (UISelection.sortBy === ascending) {
            newSelection = descending;
          } else {
            newSelection = ascending;
          }
          transitionStore.changeSelection(pageName, "sortBy", newSelection);
        },
        label: label,
        isSelected:
          UISelection.sortBy === descending || UISelection.sortBy === ascending,
        ascending: UISelection.sortBy !== descending
      };
    }
  );
  return sortOptions;
}

export function getClimbSelectionSortOptions(climbSelectionStore) {
  let sortOptions = extraFns.map(
    ([ascending, descending, label, defaultOrder]) => {
      return {
        onClick: () => {
          if (
            climbSelectionStore.sortBy !== ascending &&
            climbSelectionStore.sortBy !== descending
          ) {
            climbSelectionStore.sortBy = defaultOrder;
          } else if (climbSelectionStore.sortBy === ascending) {
            climbSelectionStore.sortBy = descending;
          } else {
            climbSelectionStore.sortBy = ascending;
          }
        },
        label: label,
        isSelected:
          climbSelectionStore.sortBy === descending ||
          climbSelectionStore.sortBy === ascending,
        ascending: climbSelectionStore.sortBy !== descending
      };
    }
  );
  return sortOptions;
}

export default { getClimbSelectionSortOptions, getInspirationSortOptions };
