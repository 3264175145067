import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components

import "react-simple-keyboard/build/css/index.css";

import { withRootStore } from "ticker-core";
import { RegistrationUserNamePasscode } from "ticker-components";

function RegistrationUpdatePage({
  rootStore: {
    regStores: { regStore },
    getCurrentGymId,
    personalRootStore,
    history
  }
}) {
  return (
    <RegistrationUserNamePasscode
      onComplete={(newUserName, newPasscode) => {
        let updatedFields = {
          userName: newUserName,
          passcode: newPasscode,
          id: regStore.id,
          gym_id: getCurrentGymId(),
          user_id: personalRootStore.getCurrentUserId()
        };
        regStore.updateRegistration(updatedFields, () => {
          history.goBack();
        });
      }}
      initialUserName={regStore.userName}
      message="Updating your details"
      redirectPage="map"
    />
  );
}

RegistrationUpdatePage.propTypes = {
  rootStore: PropTypes.object
};

export default withRootStore(RegistrationUpdatePage);
