export function getSectionFontSize(zoomRatio) {
  let result = -10 * zoomRatio + 32;

  //round to one decimal place
  //result = Math.round(result * 10) / 10;

  result = Math.round(result);

  return result < 6 ? 6 : result;
}

export function getLabelFontSize(zoomRatio) {
  let result = -10 * zoomRatio + 28;
  //round to one decimal place
  //result = Math.round(result * 10) / 10;

  result = Math.round(result);

  return result < 6 ? 6 : result;
}
