import React, { useState } from "react";
import { useObserver } from "mobx-react";
// @material-ui/core components

import { Button, ClimbDialog } from "ticker-components";

import { withCachedRootStore } from "ticker-core";

import Tour from "reactour";

// core components

const TourPage = function TourPage({
  rootStore: {
    history,
    regStores: { regStore },
    personalRootStore
  }
}) {
  let [isTourOpen, setIsTourOpen] = useState(true);

  return useObserver(() => {
    let cache = personalRootStore.getCacheWithPersonalDataIfPossible();
    if (cache.allClimbingAssemblies.length === 0) {
      return "The gym has not set any climbs";
    }
    let currentAssembly = cache.allClimbingAssemblies[0];

    const steps = [
      {
        // selector: '[data-tour="logEntryCount"]',
        content: "This is a quick tour of the basics...",
        showCloseButton: true
      },
      {
        // selector: '[data-tour="logEntryCount"]',
        content:
          "It will explain how to rate climbs, record your climbs, and regrade climbs."
      },
      {
        selector: '[data-tour="wholeCard"]',
        content: "This is the view of a climb"
      },
      {
        selector: '[data-tour="gradeColor"]',
        content:
          "This is the grade and hold color. Click this to see other peoples grade selections."
      },
      {
        selector: '[data-tour="logEntryCount"]',
        content: "Here you can see how many times you've climbed this climb"
      },
      {
        selector: '[data-tour="tickButton"]',
        content:
          "You can tap this button once for each time you have climbed to the top cleanly."
      },
      {
        selector: '[data-tour="dateSelection"]',
        content: (
          <>
            <div>
              You can click here to change the date that you are recording your
              climbs for.
            </div>
            <div> e.g. you climbed this climb 2 days ago.</div>
          </>
        )
      },
      {
        selector: '[data-tour="feedback"]',
        content: "You can send your feedback about this route to the gym."
      },
      {
        selector: '[data-tour="starRating"]',
        content: "Give 4 stars for a climb that is a 'normal' amount of fun."
      },
      {
        selector: '[data-tour="regrades"]',
        content: "You can select the grade that you think the climb SHOULD be."
      },
      {
        selector: '[data-tour="avgRating"]',
        content: "... shows the public's rating of this climb"
      },
      {
        selector: '[data-tour="ratingCount"]',
        content:
          "... shows how many people have contributed to the public rating."
      },
      {
        selector: '[data-tour="mapPlace"]',
        content: "You can click here to see the climb in the map"
      },
      {
        selector: '[data-tour="reportIssue"]',
        content: "Click this to report an issue with a climb"
      },
      {
        content:
          "One last tip, Double Click on the map to zoom in and out faster."
      }
    ];

    let resetAction = () => {
      regStore.resetNoActivityTimeout();
    };
    steps.forEach(step => {
      step.action = resetAction;
    });

    let finish = () => {
      setIsTourOpen(false);
      history.push("/main/map");
    };
    return (
      <div>
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          // onRequestClose={finish}
          lastStepNextButton={
            <Button color="success" onClick={finish}>
              Go to the Map
            </Button>
          }
          nextButton={<Button color="success">Next</Button>}
          prevButton={
            <Button color="info" simple>
              Prev
            </Button>
          }
          showNavigation={false}
          showNumber={false}
          disableInteraction={true}
          showCloseButton={steps.showCloseButton}
          onRequestClose={finish}
        />
        <ClimbDialog
          isOpen={isTourOpen}
          climbAssembly={currentAssembly}
          // handleClose={handleClose}
        />
      </div>
    );
  });
};

TourPage.propTypes = {};

export default withCachedRootStore(TourPage);
