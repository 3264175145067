import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// import LockOutline from "@material-ui/icons/LockOutline";

import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import { GridContainer } from "ticker-components";
import { GridItem } from "ticker-components";
import { CustomInput } from "ticker-components";
import { Button } from "ticker-components";
import { Card } from "ticker-components";
import { CardBody } from "ticker-components";
import { CardFooter } from "ticker-components";

import { withRootStore, loginPageStyle } from "ticker-core";

class PasswordLogoutPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };

    this.alreadyLoggingInAutomatically = false;
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  render() {
    const { classes, rootStore } = this.props;

    const { userStores } = rootStore;
    const { logoutWithPassword } = rootStore.authStores.getStores();
    const { serverMessage } = logoutWithPassword;

    let email = userStores.profileStore.email;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            {logoutWithPassword.op.loading ? (
              <div>
                {" "}
                <CircularProgress size={100} />{" "}
              </div>
            ) : (
              <form>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardBody>
                    <h4>
                      Log out {email.substring(0, 3)}****
                      {email.substring(email.length - 3, email.length)}
                    </h4>
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => {
                          logoutWithPassword.setPassword(e.target.value);
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        )
                      }}
                    />
                  </CardBody>
                  {serverMessage && (
                    <h5 style={{ textAlign: "center" }}>{serverMessage}</h5>
                  )}
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      color="primary"
                      round
                      onClick={() => {
                        logoutWithPassword.logoutWithPassword();
                      }}
                    >
                      Log out the Gym
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PasswordLogoutPage.propTypes = {
  classes: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default withRootStore(
  withStyles(loginPageStyle)(observer(PasswordLogoutPage))
);
