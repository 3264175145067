import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";

import moment from "moment";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";

import Grade from "@material-ui/icons/Grade";
import Star from "@material-ui/icons/Star";

import ChartistGraph from "react-chartist";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import TitledDialog from "../TitledDialog/TitledDialog";
import { coreStyle, withCachedRootStore } from "ticker-core";

import RatingControl from "./RatingControl";
import LoginRequest from "../LoginRequest/LoginRequest";

import Box from "@material-ui/core/Box";

import StarRatings from "react-star-ratings";

let style = theme => ({
  padded: {
    [theme.breakpoints.up("sm")]: {
      paddingBottom: ".5em",
      paddingLeft: ".5em",
      paddingRight: ".5em"
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "2em",
      paddingLeft: "2em",
      paddingRight: "2em"
    }
  },
  left: { float: "left" },
  right: { float: "right" },

  icon: { color: "#fdbc4b" },
  listItemIcon: coreStyle.listItemIconStyle
});

let useStyles = makeStyles(style);

function RatingDetailsDialog({
  onClose,
  open,
  assembly,
  rootStore: {
    ratingReportStore,
    regStores,
    ratingStore,
    personalRootStore,
    isClimberSite
  }
}) {
  let classes = useStyles();

  useEffect(() => {
    // This is also called in postSelect() below
    ratingReportStore.getClimbReport(assembly.climbingRoute.id);
    ratingReportStore.getUsersReport(assembly.climbingRoute.id);
  }, [assembly.climbingRoute.ratingCount, assembly.climbingRoute.id]);

  let popUpRef = useRef();

  let graph = {
    data: {
      labels: ["1", "2", "3", "4", "5", "6", "7"],
      series: [[0, 0, 0, 0, 0, 0, 0]]
    },
    options: {
      high: 5,
      //   low: 0,
      width: "300px",
      height: "250px",
      axisY: {
        onlyInteger: true
      }
    },
    animation: {
      draw: function(data) {
        if (data.type === "bar") {
          data.element.animate({
            y2: {
              begin: 0,
              dur:
                (350 * data.series[data.index]) /
                Math.max.apply(null, data.series),
              from: data.y1,
              to: data.y2,
              easing: "linear"
            }
          });
        }
      }
    }
  };

  if (
    ratingReportStore.opGetClimbReport.executed &&
    ratingReportStore.opGetClimbReport.data
  ) {
    graph.data.series = [ratingReportStore.opGetClimbReport.data];
    for (let dataPoint of graph.data.series) {
      if (dataPoint + 5 > graph.options.high) {
        graph.options.high = dataPoint + 5;
      }
    }
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(400));

  let hasAtLeastOne = assembly.climbingRoute.ratingCount > 0;

  return (
    <>
      <TitledDialog
        onClose={onClose}
        open={open}
        fullScreen={fullScreen}
        title={
          hasAtLeastOne ? (
            <>
              <Grade className={classes.icon} /> Ratings of this climb{" "}
            </>
          ) : (
            "This climb hasn't been rated yet."
          )
        }
        iconClassName={classes.icon}
        dialogId="ratingsDialog"
      >
        <div className={classes.padded} style={{ textAlign: "center" }}>
          {hasAtLeastOne && (
            <>
              <ChartistGraph
                className="ct-chart"
                data={graph.data}
                type="Bar"
                options={graph.options}
                responsiveOptions={graph.responsiveOptions}
                listener={graph.animation}
              />
              Average rating of
              <Star
                style={{ color: "#fdbc4b" }}
                className={classes.listItemIcon}
              />
              {assembly.climbingRoute.avgRating
                ? assembly.climbingRoute.avgRating.toFixed(1)
                : "?"}
              {" from "}
              {assembly.climbingRoute.ratingCount}
              {assembly.climbingRoute.ratingCount === 1
                ? " climber."
                : " climbers."}
            </>
          )}

          {!(regStores.regStore.isLoggedIn || isClimberSite) ? (
            <div className={classes.login}>
              <LoginRequest instruction=" to rate this climb." />
            </div>
          ) : (
            <>
              <hr />
              <RatingControl
                assembly={assembly}
                userRating={assembly.userRating}
                popUpRef={popUpRef}
                regStore={regStores.regStore}
                ratingStore={ratingStore}
                personalRootStore={personalRootStore}
                postSelect={() => {
                  ratingReportStore.getClimbReport(assembly.climbingRoute.id);
                  ratingReportStore.getUsersReport(assembly.climbingRoute.id);
                }}
              />
            </>
          )}
          {ratingReportStore.opGetUsersReport.data && (
            <>
              <hr />
              {ratingReportStore.opGetUsersReport.data.map((rating, index) => {
                if (rating.rating === null) {
                  debugger;
                }
                return (
                  //pt is padding top
                  <Box pt={index === 0 ? "0em" : "1.5em"} key={rating.id}>
                    <div>{rating["user.Registrations.userName"]}</div>
                    <div>
                      <StarRatings
                        rating={rating.rating}
                        starRatedColor="#fdbc4b"
                        starHoverColor="#fdbc4b"
                        numberOfStars={7}
                        starDimension="1.9em"
                        starSpacing=".1em"
                      />
                    </div>
                    <div style={{ fontSize: "x-small" }}>
                      {moment(rating.updated_at).format("LLL")}
                    </div>
                  </Box>
                );
              })}
            </>
          )}
        </div>
      </TitledDialog>
    </>
  );
}

RatingDetailsDialog.propTypes = {
  onClose: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  assembly: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default withCachedRootStore(observer(RatingDetailsDialog));
