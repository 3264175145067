const mockCursorStyle = {
  mockCursor: {
    display: "inline-block",
    //color: white;
    "-webkit-animation": "$blink 1s infinite",
    animation: "$blink 1s infinite",
    //font-size: 1.2em;
    //font-weight:  lighter;
    width: 0
  },

  "@-webkit-keyframes blink": {
    "0%": { opacity: 0 },
    "49.9%": { opacity: 0 },
    "50%": { opacity: 1 },
    "99.9%": { opacity: 1 }
  },
  "@-moz-keyframes blink": {
    "0%": { opacity: 0 },
    "49.9%": { opacity: 0 },
    "50%": { opacity: 1 },
    "99.9%": { opacity: 1 }
  },
  "@keyframes blink": {
    "0%": { opacity: 0 },
    "49.9%": { opacity: 0 },
    "50%": { opacity: 1 },
    "99.9%": { opacity: 1 }
  },

  inputContainer: {
    width: "100%",
    textAlign: "center",
    minHeight: "1.5em"
  }
};

export default mockCursorStyle;
