import React from "react";
import Button from "@material-ui/core/Button";

export default (errMessage, notificationStore, details) => {
  let myThis = {};
  if (!notificationStore) {
    debugger;
  }
  const action = notiStackKey => {
    return (
      <React.Fragment>
        <Button
          onClick={() => {
            alert(details);
          }}
        >
          {"Details"}
        </Button>
        <Button
          onClick={() => {
            notificationStore.removePersistedNotification(myThis.myNewErrorKey);
          }}
        >
          {"Dismiss"}
        </Button>
      </React.Fragment>
    );
  };

  myThis.myNewErrorKey = notificationStore.enqueueNotification(errMessage, {
    variant: "warning",
    persist: true,
    action
  });
};
