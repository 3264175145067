import React, { useState, useCallback } from "react";

import {
  withCachedRootStore,
  ZoomLevels,
  SVGPanner,
  getGradeLabel
} from "ticker-core";

import {
  UncontrolledReactSVGPanZoom,
  TOOL_AUTO,
  TOOL_NONE
} from "./react-svg-pan-zoom";
import { useObserver } from "mobx-react";
import { MODE_PANNING, MODE_ZOOMING } from "./react-svg-pan-zoom/constants";

import RouteViewL2 from "./SVGObjects/RouteViewL2";
import LabelObjects from "./LabelObjects";
import MissingDialog from "./MissingDialog";

import { getMapHandlers, getOnMoveObject } from "./handlers/SVGMapHandlers";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { getSectionFontSize } from "./fontSize";

import { withResizeDetector } from "react-resize-detector";

let styles = {
  unselectableText: {
    "& text, & image, & g, & svg": {
      "-webkit-user-select": "none",
      "-moz-user-select": "none",
      "-ms-user-select": "none",
      "user-select": "none"
    }
  }
};

let useStyles = makeStyles(styles);

export default withResizeDetector(
  withCachedRootStore(function SVGRouteMapLevel2(props) {
    let {
      rootStore,
      longPressIsDisabled,
      onSelect,
      allowObjectCreation,
      newObject,
      callableOnTouchEndCB,
      callableOnTouchMoveCB,
      disableHandlers,
      allowShallowClicks,
      onCreate,
      showMissing
    } = props;

    let classes = useStyles();

    let setViewer = viewer => {
      SVGStore.viewer = viewer;
    };

    let {
      climbingRouteStores,
      regStores: { regStore },
      gymStores: { gymStore },
      sectionStores: { sectionStore },
      climbCoords,
      missingStores: { cachedMissingStore }
    } = rootStore;

    const {
      personalRootStore: { inspirationStore },
      SVGStore,
      selectionStore
    } = props.rootStore;

    let wrappedOnLabelClick = useCallback(
      climbingSection => {
        console.log("Inside the onclick of the section label");
        SVGPanner.zoomPanToSection(climbingSection, rootStore, SVGStore.viewer);
      },
      [rootStore, SVGStore.viewer]
    );

    let wrappedOnSelect = useCallback(
      climbingRoute => {
        selectionStore.currentSelectedId = climbingRoute.id;
        selectionStore.currentSelected = climbingRoute;
        selectionStore.showSelected = true;

        // If this is not a new climb then we have selected a different existing climb
        // so we must not be creating a new climb
        selectionStore.creatingNewObject =
          climbingRoute === selectionStore.newClimbingObject;
        if (onSelect) {
          onSelect(climbingRoute);
        }
      },
      [selectionStore]
    );

    // At the moment, panToCoordinates must be here in a useEffect() instead of in the SVGStore
    // because the store doesn't know about the dimensions of the map
    // useEffect(() => {
    //   SVGStore.executePanToCoordinates(dimensions);
    // }, [SVGStore.panToCoordinates]);

    let [drawnAlready] = useState({});

    let [showMissingDialog, setShowMissingDialog] = useState(false);
    let [selectedMissing, setSelectedMissing] = useState();

    return useObserver(() => {
      // This is a mobx hack. For some reason, when the user clicks the "zoom to climb" button on the
      // view/edit route page, the map isn't updated until the mouse scrolls over the SVGmap.
      isNaN(SVGStore.panToCoordinates);

      let { currentSelectedId } = selectionStore;

      let { climbingRouteStore, climbingRouteCacheStore } = climbingRouteStores;
      let filteredRoutesWrapper = inspirationStore.filteredMapPage.get();

      let assemblies = filteredRoutesWrapper.climbingRouteAssemblies;
      let filteredCount = filteredRoutesWrapper.filteredCount;

      let onChangeValue = newValue => {
        SVGStore.setValue({ ...newValue });
      };

      let currentDragClimbingRoute = selectionStore.getCurrentDragObject();

      let onMoveObject = getOnMoveObject(
        regStore,
        SVGStore,
        selectionStore,
        gymStore,
        longPressIsDisabled
      );

      // let onDoubleClick = () => {
      //   console.log("Calling onDoubleClick() inside SVGRouteMapLevel2. ");
      //   regStore.resetNoActivityTimeout();
      //   selectionStore.creatingNewObject = false;
      //   SVGStore.status = SVGStore.clearDoubleClickTimeout();
      // };

      let mapHandlers = getMapHandlers(
        regStore,
        SVGStore,
        selectionStore,
        allowObjectCreation,
        onCreate,
        evt => {
          console.log(
            "After clicking zoom levels =" +
              ZoomLevels.getDetailLevel(SVGStore.value.a) +
              " and value.a=" +
              SVGStore.value.a
          );
          if (
            !selectionStore.hasObjectMoved &&
            ZoomLevels.getDetailLevel(SVGStore.value.a) < 1
          ) {
            // let coords = evt.points ? evt.points[0] : evt.point;
            // if (!coords) {
            //   coords = evt.changedPoints[0];
            // }
            // SVGPanner.zoomPanToSection(rootStore, coords, SVGStore.viewer);
            // console.log("Should zoom to Section now");
          }

          /*
           * This if handles the case where the user:
           * 1) clicks on a route,
           * 2) Possibly changes the route form (but doesn't save it)
           * 3) and drags the route to a new space (which should save)
           *
           * The form needs to keep the possible changes, and the original route
           * needs to be saved with the new coordinates
           */
          if (
            selectionStore.hasObjectMoved &&
            typeof currentDragClimbingRoute.id !== "undefined" &&
            currentDragClimbingRoute.id !== null
          ) {
            console.log(
              "currentDragClimbingRoute.id=",
              currentDragClimbingRoute.id
            );
            console.log(
              "currentDragClimbingRoute.id typeof=",
              typeof currentDragClimbingRoute.id
            );
            console.log(
              "currentDragClimbingRoute.id equal=",
              typeof currentDragClimbingRoute.id !== "undefined"
            );

            console.log("szzzs MERGING the second on replaceAsNew() ");
            // Create a back up
            let backUp = {};
            climbingRouteStore.merge(climbingRouteStore, backUp, true);

            // Get the original route that has been moved
            let originalRoute = climbingRouteCacheStore.getItemById(
              currentDragClimbingRoute.id
            );
            // Put it in the working area (climbingRouteStore)
            climbingRouteStore.merge(originalRoute, climbingRouteStore, true);
            // Put the new coordinates into the working area (climbingRouteStore)
            copyCoordinates(climbingRouteStore, currentDragClimbingRoute);
            // Save the original route with the new coordinates
            climbingRouteStore.updateClimbingRoute();

            // Put the backUp into the working area (climbingRouteStore)
            climbingRouteStore.merge(backUp, climbingRouteStore, true);
            // Make sure that if this working area gets saved it has the new coordinates
            copyCoordinates(climbingRouteStore, currentDragClimbingRoute);
          }
        }, // onUpFinished
        allowShallowClicks
      );

      let climbingSections = sectionStore.getUnarchivedItems();

      let { value } = SVGStore;

      let zoomRatio =
        typeof value.a === "undefined" || value.a === null ? 1 : value.a;

      let sectionFontSize = getSectionFontSize(zoomRatio);

      let { minScaleFactor, maxScaleFactor } = ZoomLevels.getScaleFactorLimits(
        rootStore.SVGStore.value
      );

      return (
        <>
          <MissingDialog
            isOpen={showMissingDialog}
            onClose={() => {
              setShowMissingDialog(false);
            }}
            missing={selectedMissing}
          />
          <UncontrolledReactSVGPanZoom
            {...SVGStore.dimensions}
            // onClick={onClick}
            toolbarProps={{ position: "none" }}
            tool={SVGStore.disableMapTool ? TOOL_NONE : TOOL_AUTO}
            miniatureProps={{ position: "none" }}
            detectAutoPan={false}
            onChangeValue={onChangeValue}
            // onDoubleClick={onDoubleClick}
            // viewerDomCallBack={logSetViewerDOM}
            // Move an object if it is selected
            onMouseMove={onMoveObject}
            onTouchMove={onMoveObject}
            // Events that will clear the stored object to move
            // onMouseUp={clearObjectToMove}
            onMouseUp={mapHandlers.detectDoublePointer}
            onTouchEnd={mapHandlers.detectDoublePointer}
            background="none"
            //SVGBackground="none"
            targetZoomDepth={
              ZoomLevels.getDoubleClickBoundary(SVGStore.dimensions) + 0.2
            }
            value={value}
            setViewer={setViewer}
            className={classes.unselectableText}
            scaleFactorMax={maxScaleFactor}
            scaleFactorMin={minScaleFactor}
          >
            <svg width={gymStore.imageWidth} height={gymStore.imageHeight}>
              <defs>
                <filter id="dropshadow" height="130%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                  <feOffset dx="2" dy="2" result="offsetblur" />
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.5" />
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
                <filter id="blurMeViaSVG">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
                </filter>
              </defs>
              <g>
                <image
                  x={50}
                  y={50}
                  href={rootStore.context.config.floorPlanPNG}
                />
                {value.mode !== MODE_ZOOMING &&
                  !selectionStore.hideOtherObjects &&
                  assemblies.map(assembly => {
                    // A route might be marked as archived if it is being removed as part of a
                    // REPLACE settingPlan
                    if (assembly.climbingRoute.isArchived) {
                      return null;
                    }
                    let isSelected = !!(
                      currentSelectedId &&
                      currentSelectedId === assembly.climbingRoute.id
                    );

                    let x = climbCoords.getX(
                      assembly.climbingRoute,
                      assembly.sectionAssembly.climbingSection
                    );
                    let y = climbCoords.getY(
                      assembly.climbingRoute,
                      assembly.sectionAssembly.climbingSection
                    );

                    let lineEndX, lineEndY;
                    if (
                      typeof assembly.climbingRoute.lineEndX !== "undefined"
                    ) {
                      lineEndX = assembly.climbingRoute.lineEndX;
                      lineEndY = assembly.climbingRoute.lineEndY;
                    } else {
                      lineEndX = x;
                      lineEndY = y + 50;
                    }

                    if (
                      !SVGStore.isOnScreen(x, y) &&
                      !SVGStore.isOnScreen(lineEndX, lineEndY)
                    ) {
                      // Don't draw this route
                      drawnAlready[assembly.climbingRoute.id] = false;
                      return null;

                      // else if the route hasn't been drawn already
                    } else if (!drawnAlready[assembly.climbingRoute.id]) {
                      // and the pointer is down (therefore panning)
                      if (value.mode === MODE_PANNING) {
                        // console.log(
                        //   "//Don't draw this route until no longer panningx"
                        // );
                        return null;
                      } else {
                        // console.log(
                        //   "else the user is not panningx and this route needs to be drawn now"
                        // );
                        drawnAlready[assembly.climbingRoute.id] = true;
                      }
                    }

                    let detailLevel = ZoomLevels.getDetailLevel(value.a);

                    return (
                      <RouteViewL2
                        rootStore={rootStore}
                        key={assembly.climbingRoute.id}
                        primaryHEX={assembly.markerColor.primaryHEX}
                        secondaryHEX={assembly.markerColor.secondaryHEX}
                        labelText={getGradeLabel(assembly.climbingGrade)}
                        textColor={assembly.markerTextColor}
                        shadowText={assembly.markerTextNeedsShadow}
                        x={x}
                        y={y}
                        lineEndX={lineEndX}
                        lineEndY={lineEndY}
                        climbingRoute={assembly.climbingRoute}
                        isSelected={isSelected}
                        longPressIsDisabled={longPressIsDisabled}
                        isTicked={assembly.logEntryCount > 0}
                        detailLevel={detailLevel}
                        avgRating={
                          assembly.climbingRoute.avgRating
                            ? Math.round(assembly.climbingRoute.avgRating)
                            : "?"
                        }
                        relativeAvgCombinedModifier={
                          assembly.relativeCombinedModifier
                            ? assembly.relativeCombinedModifier
                            : 0
                        }
                        callableOnTouchEnd={callableOnTouchEndCB}
                        callableOnTouchMove={callableOnTouchMoveCB}
                        wasLongPressed={
                          !!selectionStore.wasLongPressed.get(
                            assembly.climbingRoute
                          )
                        }
                        onSelect={wrappedOnSelect}
                        disableHandlers={disableHandlers}
                      />
                    );
                  })}

                {newObject}
                <LabelObjects
                  labelObjects={climbingSections}
                  longPressIsDisabled={true}
                  selectionStore={selectionStore}
                  gymStore={gymStore}
                  isClickable={true}
                  useDefaultHandlers={false}
                  getLabelFn={getLabelFn}
                  coordsFactory={rootStore.sectionCoords}
                  fontSize={sectionFontSize}
                  onClick={wrappedOnLabelClick}
                />

                {showMissing &&
                  cachedMissingStore.items.map(missing => {
                    let onMissingClick = () => {
                      setShowMissingDialog(true);
                      setSelectedMissing(missing);
                    };

                    return (
                      <>
                        <circle
                          cx={missing.labelX}
                          cy={missing.labelY}
                          r={10 / SVGStore.value.a}
                          stroke="grey"
                          fill="yellow"
                          opacity="0.9"
                          strokeWidth="1.5"
                          vectorEffect="non-scaling-stroke"
                          style={{
                            filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, 1))"
                          }}
                          onClick={onMissingClick}
                        />
                        <circle
                          cx={missing.labelX}
                          cy={missing.labelY}
                          r={10 / SVGStore.value.a}
                          stroke="grey"
                          fill="none"
                          strokeWidth="1.5"
                          vectorEffect="non-scaling-stroke"
                          onClick={onMissingClick}
                        />
                      </>
                    );
                  })}
              </g>
            </svg>
          </UncontrolledReactSVGPanZoom>
          {filteredCount > 0 && (
            <div
              style={{
                position: "absolute",
                bottom: "2em",
                zIndex: 1,
                textAlign: "center",
                width: "100%"
              }}
            >
              {filteredCount + " "}
              climbs filtered
            </div>
          )}
        </>
      );
    });
  })
);

let copyCoordinates = (targetStore, newClimbingObject) => {
  targetStore.labelX = newClimbingObject.labelX;
  targetStore.labelY = newClimbingObject.labelY;
  targetStore.lineEndX = newClimbingObject.lineEndX;
  targetStore.lineEndY = newClimbingObject.lineEndY;
};

function getLabelFn(section) {
  return section.name;
}
