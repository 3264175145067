import React, { useState, useEffect } from "react";

import { useObserver } from "mobx-react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

import Fade from "@material-ui/core/Fade";

import UserNameKeyboard from "./UserNameKeyboard";
import PasscodeKeyboard from "./PasscodeKeyboard";

import "react-simple-keyboard/build/css/index.css";

import SwipeableViews from "react-swipeable-views";

import NextPanel from "../NextPanel/NextPanel";

import makeStyles from "@material-ui/core/styles/makeStyles";
import mockCursorStyle from "../../assets/jss/mockCursorStyle";

// core components

import { withCachedRootStore } from "ticker-core";
import { Button } from "ticker-components";

let useStyles = makeStyles(mockCursorStyle);

let FADE_DURATION = 300;

let forbiddenPasscodes = [
  "11111",
  "22222",
  "33333",
  "44444",
  "55555",
  "66666",
  "77777",
  "88888",
  "99999",
  "00000",
  "12345"
];
function RegistrationUserNamePasscode({
  rootStore,
  onComplete,
  initialUserName,
  message,
  redirectPage,
  usernameTitle,
  passcodeTitle,
  usernameSubTitle
}) {
  let classes = useStyles();
  let initialUserName_lc = initialUserName
    ? initialUserName.toLowerCase().trim()
    : "";

  const [userName, setUserName] = useState(initialUserName);
  const [passcode, setPasscode] = useState("");
  const [passcodeConf, setPasscodeConf] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [registrationMessage, setRegistrationMessage] = useState(null);
  const [fadeOut, setFadeOut] = useState(false);

  const {
    regStores: { regStore },
    gymStores: { gymStore }
  } = rootStore;

  useEffect(() => {
    regStore.getRegistrations();
  }, [regStore, rootStore]);

  const userNameExists = newUserName => {
    let newUserName_lc = newUserName.toLowerCase().trim();

    if (regStore.opGetRegistrations.data) {
      for (let registration of regStore.opGetRegistrations.data) {
        // If this isn't the user's already set user name
        // and it is found somewhere else,
        // then the user can't have it.
        if (
          initialUserName_lc !== newUserName_lc &&
          registration.userName.toLowerCase().trim() === newUserName_lc
        ) {
          let takenMsg = newUserName + " is already taken.";
          if (registrationMessage !== takenMsg) {
            setRegistrationMessage({
              type: "warning",
              msg: takenMsg
            });
          }
          return true;
        }
      }
    }

    setRegistrationMessage(null);
    return false;
  };

  const onChange = newUserName => {
    // Note to BROOK
    // I have left a lot of the comments in because there is an interesting bug
    // where the state userName does not here the correct value when this
    // onChange function is called.
    // The weird thing is that it does have the correct value
    // when the passcodeOnChange function is called.
    //
    // This isn't a problem.... it's just really weird so I've left it here to help debugging in the future.
    //console.log("The Username in onChange is " + userName);
    userNameExists(newUserName);
    setUserName(newUserName);
  };

  const changeViews = newActiveIndex => {
    setActiveIndex(newActiveIndex);
    setRegistrationMessage(null);
  };

  const passcodeOnChange = newCode => {
    //console.log("The Username in onChange for passcode is " + userName);
    //console.log("The passcode in onChange for passcode is " + passcode);

    //NOTE: This wont get called if the newCode is longer than 5 digits
    // See PasscodeKeyboard.js
    setPasscode(newCode);
  };

  const passcodeConfirmationOnChange = async confirmCode => {
    setPasscodeConf(confirmCode);
    if (confirmCode.length !== 5) {
      return;
    }
    if (confirmCode !== passcode) {
      setRegistrationMessage({
        type: "warning",
        msg: "The passcodes don't match."
      });
      return;
    }
    // changeViews(3);
    setRegistrationMessage({
      type: "good",
      msg: message
    });
    setFadeOut(true);
    await onComplete(userName, parseInt(passcode, 10));
    setTimeout(() => {
      if (regStore.isLoggedIn) {
        rootStore.history.push(redirectPage);
      }
    }, FADE_DURATION);
  };

  const nextToPasscodeClick = () => {
    regStore.resetNoActivityTimeout();
    console.log(
      "The Username in nextToPasscodeClick for passcode is " + userName
    );
    if (userNameExists(userName)) {
      return;
    }

    if (userName.length < 3) {
      setRegistrationMessage({
        type: "warning",
        msg: "Your username must have at least 3 letters."
      });
      return;
    }
    changeViews(1);
  };

  const nextToConfirmClick = () => {
    regStore.resetNoActivityTimeout();
    if (passcode.length !== 5) {
      setRegistrationMessage({
        type: "warning",
        msg: "Your passcode must have 5 digits."
      });
      return;
    }
    for (let forbidden of forbiddenPasscodes) {
      if (passcode === forbidden) {
        setRegistrationMessage({
          type: "warning",
          msg: "Your passcode may not be simple, e.g. 11111 or 12345"
        });
        return;
      }
    }
    changeViews(2);
  };

  const onClearPasscode = () => {
    regStore.resetNoActivityTimeout();
    setPasscode("");
  };

  const onClearConfirmation = () => {
    regStore.resetNoActivityTimeout();
    setPasscodeConf("");
  };

  const backToUserNameClick = () => {
    regStore.resetNoActivityTimeout();
    changeViews(0);
    setPasscode("");
  };

  const backToPasscodeClick = () => {
    regStore.resetNoActivityTimeout();
    changeViews(1);
    setPasscodeConf("");
    setPasscode("");
  };

  let views = [
    <NextPanel
      title={
        usernameTitle
          ? usernameTitle
          : "Type in a new User Name for use at " + gymStore.name
      }
      subTitle={usernameSubTitle}
      note="Please Note: it will be publicly visible."
      input={
        <h2 className={classes.inputContainer}>
          {userName}
          <div className={classes.mockCursor}>|</div>
        </h2>
      }
      //BUG: There can only be one keyboard on the screen at a time
      //Therefore not displaying the keyboard if it is not the current activeIndex
      keyboard={
        activeIndex === 0 && (
          <UserNameKeyboard
            onChange={onChange}
            showShift={true}
            regStore={regStore}
          />
        )
      }
      leftButton={null}
      rightButton={
        <Button color="rose" onClick={nextToPasscodeClick}>
          Next
        </Button>
      }
      key="1"
      registrationMessage={registrationMessage}
    />,
    <NextPanel
      title={
        passcodeTitle
          ? passcodeTitle
          : `Enter a 5 digit passcode for ${userName}`
      }
      subTitle={null}
      //BUG: There can only be one keyboard on the screen at a time
      //Therefore not displaying the keyboard if it is not the current activeIndex
      keyboard={
        activeIndex === 1 && (
          <PasscodeKeyboard
            onChange={passcodeOnChange}
            onClear={onClearPasscode}
            regStore={regStore}
            codeLength={5}
          />
        )
      }
      input={
        <h2 className={classes.inputContainer}>
          {passcode.replace(/./g, "*")}
          <div className={classes.mockCursor}>|</div>
        </h2>
      }
      leftButton={<Button onClick={backToUserNameClick}>Back</Button>}
      rightButton={
        <Button color="rose" onClick={nextToConfirmClick}>
          Next
        </Button>
      }
      registrationMessage={registrationMessage}
      key="2"
    />,
    <NextPanel
      title="Confirm your 5 digit passcode"
      subTitle={null}
      //BUG: There can only be one keyboard on the screen at a time
      //Therefore not displaying the keyboard if it is not the current activeIndex
      keyboard={
        activeIndex === 2 && (
          <PasscodeKeyboard
            onChange={passcodeConfirmationOnChange}
            onClear={onClearConfirmation}
            regStore={regStore}
            codeLength={5}
          />
        )
      }
      input={
        <h2 className={classes.inputContainer}>
          {passcodeConf.replace(/./g, "*")}
          <div className={classes.mockCursor}>|</div>
        </h2>
      }
      leftButton={<Button onClick={backToPasscodeClick}>Back</Button>}
      rightButton={null}
      registrationMessage={registrationMessage}
      key="3"
    />
  ];

  return useObserver(() => {
    return (
      <>
        <Fade in={!fadeOut} timeout={FADE_DURATION}>
          <div>
            <SwipeableViews
              axis={"x"}
              index={activeIndex}
              // onChangeIndex={handleChangeIndex}
              style={{ overflowY: "hidden" }}
            >
              {views}
            </SwipeableViews>
          </div>
        </Fade>
      </>
    );
  });
}

RegistrationUserNamePasscode.propTypes = {
  rootStore: PropTypes.object,
  initialUserName: PropTypes.string,
  onComplete: PropTypes.func.isRequired
};

export default withCachedRootStore(RegistrationUserNamePasscode);
