import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import LabelView from "./SVGObjects/LabelView";

function LabelObjects(props) {
  let {
    labelObjects,
    selectionStore,
    longPressIsDisabled,
    isClickable,
    fontSize,
    callableOnTouchEnd,
    callableOnTouchMove,
    getLabelFn,
    coordsFactory,
    onClick,
    useDefaultHandlers
  } = props;

  // console.log("Rendering the labelObjects");

  let { currentSelected } = selectionStore;

  coordsFactory.reset();
  return (
    <>
      {labelObjects.map(labelObject => {
        let isSelected = !!(currentSelected && currentSelected === labelObject);

        let x = coordsFactory.getX(labelObject);
        let y = coordsFactory.getY(labelObject);

        return (
          <LabelView
            key={labelObject.id}
            labelText={getLabelFn(labelObject)}
            x={x}
            y={y}
            fontSize={fontSize}
            labelObject={labelObject}
            isSelected={isSelected && isClickable}
            longPressIsDisabled={longPressIsDisabled}
            callableOnTouchEnd={callableOnTouchEnd}
            callableOnTouchMove={callableOnTouchMove}
            isClickable={isClickable}
            wasLongPressed={!!selectionStore.wasLongPressed.get(labelObject)}
            onClick={onClick}
            useDefaultHandlers={useDefaultHandlers}
          />
        );
      })}
    </>
  );
}

LabelObjects.propTypes = {
  labelObjects: PropTypes.array.isRequired,
  gymStore: PropTypes.object.isRequired,
  selectionStore: PropTypes.object.isRequired,
  longPressIsDisabled: PropTypes.bool.isRequired,
  Viewer: PropTypes.object,
  isClickable: PropTypes.bool.isRequired,
  getLabelFn: PropTypes.func.isRequired,
  fontSize: PropTypes.number.isRequired,
  callableOnTouchMove: PropTypes.func,
  callableOnTouchEnd: PropTypes.func,
  coordsFactory: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  useDefaultHandlers: PropTypes.bool.isRequired
};

export default observer(LabelObjects);
