import tr from "i18next";
//import createBrowserHistory from "history/createBrowserHistory";
import Rest from "../utils/rest.js";
import formatter from "../utils/formatter.js";
//import alertStackCreate from "components/alertStack";

export default ({ language = "en" }, config) => {
  if (!config) {
    console.log("The config must be set");
    debugger;
    throw new Error("The config must be set");
  }
  const context = {
    rest: Rest({}, config),
    //theme: theme(),
    tr,
    formatter: formatter(language),
    //history: createBrowserHistory(),
    config
  };
  //context.alertStack = alertStackCreate(context, { limit: 3 });
  return context;
};
