import { computed } from "mobx";
import createAssembly from "./AssemblyHelper.js";
import { gradeTypes } from "../variables/constants";
import addRegrades from "../utils/addRegrades";

let byClimbingGrade = (a, b) => {
  return a.climbingGrade.orderPosition - b.climbingGrade.orderPosition;
};

let addTogether = (acc, curr) => acc + curr;

export default class ComputedCacheStore {
  constructor(context, rootStore) {
    const getEmptyStatusAssemblies = () => {
      const newStatusAssembly = [];
      rootStore.routeStatusStores.routeStatusStore
        .getAllItemsEvenArchived()
        .forEach(routeStatus => {
          newStatusAssembly.push({
            climbingRouteAssemblies: [],
            routeStatus: routeStatus
          });
          //          console.log("aDDING STATUS");g
        });
      return newStatusAssembly;
    };

    const addToStatusAssemblies = (statusAssemblies, climbingRouteAssembly) => {
      statusAssemblies.forEach(statusAssembly => {
        if (
          statusAssembly.routeStatus.id ===
          climbingRouteAssembly.climbingRoute.route_status_id
        ) {
          statusAssembly.climbingRouteAssemblies.push(climbingRouteAssembly);
          climbingRouteAssembly.routeStatus = statusAssembly.routeStatus;
        }
      });
    };

    const getEmptyTotals = function() {
      return {
        maxBoulders: 0,
        actualBoulders: 0,
        maxRoutes: 0,
        actualRoutes: 0
      };
    };

    const addRouteToTotals = function(gradeType, totals) {
      if (gradeType === gradeTypes.ROUTE) {
        totals.actualRoutes++;
      } else {
        totals.actualBoulders++;
      }
    };

    const addWallToTotals = function(climbingWall, totals) {
      if (climbingWall.gradeType === gradeTypes.ROUTE) {
        totals.maxRoutes = totals.maxRoutes + climbingWall.slotCountTarget;
      } else {
        totals.maxBoulders = totals.maxBoulders + climbingWall.slotCountTarget;
      }
    };

    this.computedCache = computed(
      () => {
        const {
          climbingRouteStores,
          sectionStores,
          wallStores,
          gradeStores: { gradeStores }
        } = rootStore;

        let retVal = {
          overviewStatusAssemblies: [],
          sectionAssemblies: [],
          allClimbingAssemblies: [],
          totals: getEmptyTotals()
        };

        // This computed.get() can be called from inside the postCacheFn of the rootStore
        // So it needs to be available.
        if (rootStore.cacher.cacheStatus.isPrePostCacheLoaded) {
          //      console.log("The cache is loaded");

          retVal.overviewStatusAssemblies = getEmptyStatusAssemblies();

          //Get an array for the climbing routes for each status
          let overviewStatusAssemblies = retVal.overviewStatusAssemblies;

          //Put all the sections into the newSections Array
          let newSectionAssemblies = retVal.sectionAssemblies;
          sectionStores.sectionStore
            .getAllItemsEvenArchived()
            .forEach(climbingSection => {
              if (climbingSection.isArchived) return;

              let newSectionAssembly = {
                climbingSection: climbingSection,
                climbingWallAssemblies: [],
                climbingRouteAssemblies: [],
                statusAssemblies: getEmptyStatusAssemblies(),
                totals: getEmptyTotals(),
                hasGradeType: []
              };

              newSectionAssemblies.push(newSectionAssembly);
              //Put all the walls into the array from it's section
              wallStores.wallStore
                .getAllItemsEvenArchived()
                .forEach(climbingWall => {
                  if (climbingWall.isArchived) return;

                  if (climbingSection.id === climbingWall.climbing_section_id) {
                    let newWallAssembly = {
                      climbingWall: climbingWall,
                      climbingRouteAssemblies: [],
                      statusAssemblies: getEmptyStatusAssemblies(),
                      totals: getEmptyTotals(),
                      sectionAssembly: newSectionAssembly
                    };

                    newSectionAssembly.hasGradeType[
                      climbingWall.gradeType
                    ] = true;
                    addWallToTotals(climbingWall, newSectionAssembly.totals);
                    addWallToTotals(climbingWall, newWallAssembly.totals);

                    // This is the totals from
                    //addWallToTotals(climbingWall, retVal.totals);

                    newSectionAssembly.climbingWallAssemblies.push(
                      newWallAssembly
                    );

                    climbingRouteStores.climbingRouteCacheStore.items.forEach(
                      climbingRoute => {
                        if (
                          climbingRoute.climbing_wall_id === climbingWall.id
                        ) {
                          const newClimbingRouteAssembly = createAssembly(
                            rootStore,
                            climbingRoute
                          );

                          addIssues(
                            rootStore,
                            newClimbingRouteAssembly,
                            climbingRoute.id
                          );
                          addIssues(
                            rootStore,
                            newWallAssembly,
                            climbingRoute.id
                          );
                          addIssues(
                            rootStore,
                            newSectionAssembly,
                            climbingRoute.id
                          );

                          retVal.allClimbingAssemblies.push(
                            newClimbingRouteAssembly
                          );

                          newClimbingRouteAssembly.sectionAssembly = newSectionAssembly;
                          newClimbingRouteAssembly.wallAssembly = newWallAssembly;

                          addRouteToTotals(
                            newClimbingRouteAssembly.climbingGrade.gradeType,
                            newSectionAssembly.totals
                          );

                          //Add to the section status List
                          addToStatusAssemblies(
                            newSectionAssembly.statusAssemblies,
                            newClimbingRouteAssembly
                          );

                          //Add the climb to the Section
                          newSectionAssembly.climbingRouteAssemblies.push(
                            newClimbingRouteAssembly
                          );

                          addRouteToTotals(
                            newClimbingRouteAssembly.climbingGrade.gradeType,
                            newWallAssembly.totals
                          );

                          //Add to the wall status list
                          addToStatusAssemblies(
                            newWallAssembly.statusAssemblies,
                            newClimbingRouteAssembly
                          );

                          //Add the climb to the wall
                          newWallAssembly.climbingRouteAssemblies.push(
                            newClimbingRouteAssembly
                          );

                          addToStatusAssemblies(
                            overviewStatusAssemblies,
                            newClimbingRouteAssembly
                          );

                          addRouteToTotals(
                            newClimbingRouteAssembly.climbingGrade.gradeType,
                            retVal.totals
                          );

                          addRegrades(
                            newClimbingRouteAssembly,

                            gradeStores
                          );
                        }
                      }
                    );

                    newWallAssembly.climbingRouteAssemblies.sort(
                      byClimbingGrade
                    );
                  }
                });
            });

          // let newTimestamp = new Date().getTime();
          // console.log(
          //   "Finished making the assemblies:" + (newTimestamp - timeStamp)
          // );
        }

        const boulderGradeCounts = this.computedBoulderGradeCounts.get();
        const routeGradeCounts = this.computedRouteGradeCounts.get();

        retVal.totals.maxBoulders = boulderGradeCounts.targetQuantities.reduce(
          addTogether,
          0
        );
        retVal.totals.maxRoutes = routeGradeCounts.targetQuantities.reduce(
          addTogether,
          0
        );

        return retVal;
      },
      { keepAlive: true }
    );

    this.getComputedGradeCounts = gradeType => {
      if (gradeType === gradeTypes.BOULDER) {
        return this.computedBoulderGradeCounts.get();
      } else if (gradeType === gradeTypes.ROUTE) {
        return this.computedRouteGradeCounts.get();
      }

      console.log("No gradeType specified");
      debugger;
    };
    this.computedBoulderGradeCounts = computed(
      () => {
        const { gradeStores } = rootStore.gradeStores;

        return this.getGradeCounts(gradeStores[gradeTypes.BOULDER], rootStore);
      },
      { keepAlive: true }
    );

    this.computedRouteGradeCounts = computed(
      () => {
        const { gradeStores } = rootStore.gradeStores;

        return this.getGradeCounts(gradeStores[gradeTypes.ROUTE], rootStore);
      },
      { keepAlive: true }
    );
  }

  getGradeCounts(store, rootStore) {
    const grades = [];
    const actualQuantities = [];
    const targetQuantities = [];
    const gradeDifferences = [];
    const gradeLabels = [];

    let minDifference = 0;
    let maxDifference = 0;
    let maxActualOrTarget = 0;

    // The gradeToQuantityMap is currently used by the computedReport for tasks.
    let gradeToQuantityMap = {};

    store.getAllItemsEvenArchived().forEach(grade => {
      if (!grade.isArchived) {
        gradeLabels.push(grade.primaryLabel);
        grades.push(grade);

        let actualQuantity = 0;
        rootStore.climbingRouteStores.climbingRouteCacheStore.items.forEach(
          climb => {
            if (!climb.isArchived && climb.climbing_grade_id === grade.id) {
              actualQuantity++;
            }
          }
        );
        let difference = actualQuantity;
        let targetQuantity = 0;

        for (let quantity of rootStore.gymStores.gymStore.climbQuantitiesJSON) {
          if (quantity.climbing_grade_id === grade.id) {
            difference = difference - quantity.quantity;
            targetQuantity = quantity.quantity;
            break;
          }
        }

        actualQuantities.push(actualQuantity);
        targetQuantities.push(targetQuantity);

        gradeDifferences.push(difference);

        if (actualQuantity > maxActualOrTarget) {
          maxActualOrTarget = actualQuantity;
        }
        if (targetQuantity > maxActualOrTarget) {
          maxActualOrTarget = targetQuantity;
        }

        if (difference < minDifference) {
          minDifference = difference;
        }
        if (difference > maxDifference) {
          maxDifference = difference;
        }

        let gradeToQuantityObject = {
          grade,
          actualQuantity,
          difference,
          targetQuantity
        };
        gradeToQuantityMap[grade.id] = gradeToQuantityObject;
      }
    });

    return {
      gradeLabels,
      grades,
      actualQuantities,
      targetQuantities,
      gradeDifferences,
      maxDifference,
      minDifference,
      maxActualOrTarget,
      gradeToQuantityMap
    };
  }
}

function addIssues(rootStore, targetObj, climbing_route_id) {
  for (let issue of rootStore.reportedIssueStores.cachedIssuesStore.items) {
    if (issue["ClimbingRoute.id"] === climbing_route_id) {
      if (!targetObj.unarchivedIssues) {
        targetObj.unarchivedIssues = [];
      }
      targetObj.unarchivedIssues.push(issue);
    }
  }
}
