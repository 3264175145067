import React, { useState, useEffect, useRef } from "react";

import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { withRootStore } from "ticker-core";

import { Button, NextPanel } from "ticker-components";

const heights = [];
for (let i = 0; i < 103; i++) {
  let cm = 100 + i;
  var realFeet = (cm * 0.3937) / 12;
  var feet = Math.floor(realFeet);
  var inches = Math.round((realFeet - feet) * 12);

  if (inches === 12) {
    inches = 0;
    feet++;
  }

  heights.push(cm + "cm - " + feet + " foot " + inches + " inches");
}

let defaultHeight = 67; // default to 167cm

const HeightPage = function(props) {
  let { rootStore } = props;
  let {
    personalRootStore: {
      personalProfileStores: { updateProfileStore }
    },
    userStores: { profileStore },
    loginDialogStore,
    regStores
  } = rootStore;
  const [heightIndex, setHeightIndex] = useState(defaultHeight);

  let scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }, [scrollRef]);

  return (
    <NextPanel
      title="How tall are you?"
      subTitle="We will use this information to create the best climbs for you."
      keyboard={
        <div
          style={{
            textAlign: "center",
            maxHeight: "10em",
            overflow: "auto",
            marginTop: "1em",
            cursor: "pointer"
          }}
        >
          {heights.map((height, index) => {
            return (
              <div
                ref={index === heightIndex ? scrollRef : undefined}
                key={height}
                onClick={() => {
                  setHeightIndex(index);
                }}
                style={
                  index === heightIndex
                    ? { backgroundColor: "#00acc1", color: "white" }
                    : undefined
                }
              >
                {height}
              </div>
            );
          })}
        </div>
      }
      leftButton={
        <Button
          onClick={async () => {
            rootStore.history.push(rootStore.context.config.viewRoutePath);
          }}
          color="danger"
          simple
        >
          Cancel
        </Button>
      }
      rightButton={
        <Button
          onClick={async () => {
            if (
              !profileStore.hasLoaded ||
              (rootStore.isPublicSite && !regStores.isRegistered())
            ) {
              loginDialogStore.showDialog = true;
              loginDialogStore.instruction =
                " to let the setters know your height.";
              return;
            }
            updateProfileStore.height = 100 + heightIndex;
            await updateProfileStore.update();
            rootStore.history.push(rootStore.context.config.viewRoutePath);
          }}
          color="success"
        >
          Save
        </Button>
      }
      key="3"
    />
  );
};

HeightPage.propTypes = {
  rootStore: PropTypes.object,
  classes: PropTypes.object
};
export default withRootStore(observer(HeightPage));
