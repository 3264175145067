import React from "react";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";

import ArrowDownward from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpward from "@material-ui/icons/ArrowUpwardRounded";

let styles = {
  badge: {
    marginLeft: "1em"
  },
  crossContainer: {
    minWidth: "1.2em"
  }
};

let useStyles = makeStyles(styles);

function OptionButton(props) {
  let { isSelected, onClick, label, ascending } = props;

  let classes = useStyles();

  let style;
  let color = "gray";
  let isSimple = true;
  if (isSelected) {
    color = "info";
  }

  console.log("asdd --The sortButton is ascending:" + ascending);

  return (
    <Button
      color={color}
      round
      size="sm"
      onClick={onClick}
      style={style}
      simple={isSimple}
    >
      <span className={classes.crossContainer}>
        {isSelected && ascending && (
          <ArrowUpward
            onClick={e => {
              e.stopPropagation();
              onClick();
            }}
          />
        )}
        {isSelected && !ascending && (
          <ArrowDownward
            onClick={e => {
              e.stopPropagation();
              onClick();
            }}
          />
        )}
      </span>

      {label}
    </Button>
  );
}

OptionButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  ascending: PropTypes.bool.isRequired
};

export default OptionButton;
