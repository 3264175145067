import PropTypes from "prop-types";

import { Component } from "react";
import { withSnackbar } from "notistack";
import { observer } from "mobx-react";
import { autorun } from "mobx";

class Notifier extends Component {
  constructor(props) {
    super(props);
    this.displayed = [];
    this.storeDisplayed = id => {
      this.displayed = [...this.displayed, id];
    };
  }
  componentDidMount() {
    autorun(() => {
      const { notificationStore } = this.props.rootStore;

      const {
        enqueuedNotifications,
        persistedNotifications
      } = notificationStore;

      enqueuedNotifications.forEach(notification => {
        // Do nothing if snackbar is already displayed
        if (this.displayed.includes(notification.key)) return;

        // Display snackbar using notistack
        notification.notistackKey = this.props.enqueueSnackbar(
          notification.message,
          notification.options
        );

        // Keep track of snackbars that we've displayed
        this.storeDisplayed(notification.key);

        // Dispatch action to remove snackbar from mobx store
        //if (notification.options && notification.options.persist) {
        notificationStore.notifier_dequeueAndStoreNotification(notification);
        //}

        //notificationStore.removeNotification(notification);
      });

      persistedNotifications.forEach(notification => {
        if (notification.pleaseRemoveNow) {
          this.props.closeSnackbar(notification.notistackKey);
          notificationStore.notifier_clearPersistedNotification(notification);
        }
      });
    });
  }

  render() {
    return null;
  }
}

Notifier.propTypes = {
  rootStore: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired
};

export default withSnackbar(observer(Notifier));
