import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { withCachedRootStore } from "ticker-core";
import { Button } from "ticker-components";

import CenterFocusStrong from "@material-ui/icons/CenterFocusStrong";
import FilterList from "@material-ui/icons/FilterList";
import ReportProblem from "@material-ui/icons/ReportProblem";

import svgMapStyles from "../../assets/jss/material-dashboard-pro-react/components/svgMap";

import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

import SelectionFilterPopper from "./SelectionFilterPopper";
import MissingPopper from "./MissingPopper";

import SVGRouteMapLevel2 from "./SVGRouteMapLevel2";

let useStyles = makeStyles(svgMapStyles);

function SVGRouteMap(props) {
  let classes = useStyles();

  const { showReportButton, allowObjectCreation, showMissing } = props;
  const {
    SVGStore,
    climbingRouteStores: { climbingRouteStore },
    regStores: { regStore },
    selectionStore,
    computedCacheStore
  } = props.rootStore;

  let [showMissingPopper, setShowMissingPopper] = useState(false);
  let [filtersWereVisible, setFiltersWereVisible] = useState(
    SVGStore.showFilter
  );
  let [showMissingPopperNextStep, setShowMissingPopperNextStep] = useState(
    false
  );

  useEffect(() => {
    if (SVGStore.dimensions.width > 800) {
      SVGStore.showFilter = true;
    }
  }, [SVGStore.dimensions.width]);

  let computedCache = computedCacheStore.computedCache.get();

  return (
    <div className={classes.svgContainer}>
      <div
        className={classes.filterPanel}
        style={{
          maxHeight: SVGStore.dimensions.height,
          overflow: "auto",
          // This is just an arbitrary width to give space for the buttons
          minWidth: "300px",
          textAlign: "center"
        }}
      >
        <div>
          {showReportButton && (
            <Button
              color="info"
              size="xsm"
              onClick={() => {
                setFiltersWereVisible(SVGStore.showFilter);
                SVGStore.showFilter = false;
                setShowMissingPopper(true);
              }}
            >
              <ReportProblem /> Missing
            </Button>
          )}
          <Button color="info" onClick={() => SVGStore.recenter()} size="xsm">
            <CenterFocusStrong /> Recenter
          </Button>
          <Button
            color="info"
            onClick={() => {
              SVGStore.showFilter = !SVGStore.showFilter;
              setShowMissingPopper(false);
            }}
            size="xsm"
          >
            <FilterList />
            {SVGStore.showFilter ? "Hide filters" : "Show filters"}
          </Button>
        </div>
        <SelectionFilterPopper
          closeFilter={() => {
            SVGStore.showFilter = false;
          }}
        />
        <MissingPopper
          isOpen={showMissingPopper}
          onClose={() => {
            setShowMissingPopper(false);
            setShowMissingPopperNextStep(false);
            SVGStore.showFilter = filtersWereVisible;
            regStore.resetNoActivityTimeout();
            selectionStore.clearNewObject();
          }}
          showNextStep={showMissingPopperNextStep}
        />
      </div>
      <SVGRouteMapLevel2
        {...props}
        allowObjectCreation={allowObjectCreation || showMissingPopper}
        disableHandlers={showMissingPopper}
        allowShallowClicks={showMissingPopper}
        onCreate={() => {
          climbingRouteStore.clearMostDetails();
          selectionStore.addNearestWall(computedCache);
          if (showMissingPopper) {
            setShowMissingPopperNextStep(true);
          }
        }}
        showMissing={showMissing}
      />
    </div>
  );
}

SVGRouteMap.propTypes = {
  rootStore: PropTypes.object.isRequired,
  showReportButton: PropTypes.bool,
  allowObjectCreation: PropTypes.bool
};
export default withCachedRootStore(observer(SVGRouteMap));
