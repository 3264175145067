import React, { useState } from "react";
import { observer } from "mobx-react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slider from "@material-ui/core/Slider";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

import { withRootStore, coreStyle } from "ticker-core";

import RegradeButton from "./RegradeButton";
import Delete from "@material-ui/icons/Delete";

const { infoColor } = coreStyle;

let controlStyles = () => {
  return {
    sliderBox: {
      width: "80%",
      margin: "auto",
      minHeight: "3.8em"
    },
    deleteIcon: {
      float: "right",
      marginTop: "7px"
    },
    hiddenIcon: {
      opacity: 0
    }
  };
};

let iconStyles = {
  root: {
    fontSize: "1.4em"
  },
  abby: {
    position: "absolute !important"
  }
};

let useControlStyles = makeStyles(controlStyles);
let useIconStyles = makeStyles(iconStyles);

let sliderStyles = () => {
  return {
    markLabel: {
      fontSize: "x-small"
    },
    root: {
      color: infoColor[0]
    }
  };
};

let useSliderStyles = makeStyles(sliderStyles);

function RegradeControl({ assembly, rootStore, callBack }) {
  let classes = useControlStyles();
  let sliderClasses = useSliderStyles();
  let iconClasses = useIconStyles();

  let [regrade_id, setRegrade_id] = useState(
    assembly.userRegrade ? assembly.userRegrade.regrade_id : undefined
  );

  let [modifier, setModifier] = useState(
    assembly.userRegrade ? assembly.userRegrade.modifier : 0
  );

  let {
    regStores,
    personalRootStore,
    notificationStore,
    loginDialogStore,
    userStores: { profileStore }
  } = rootStore;

  let { regStore } = regStores;
  let {
    personalRegradeStores: { regradeStore }
  } = personalRootStore;

  let onRegrade = newRegrade_id => {
    if (
      !profileStore.hasLoaded ||
      (rootStore.isPublicSite && !regStores.isRegistered())
    ) {
      loginDialogStore.showDialog = true;
      loginDialogStore.instruction = "to suggest a grade.";
      return;
    }

    regStore.resetNoActivityTimeout();
    setRegrade_id(newRegrade_id);
    updateUsersGradeModifier(newRegrade_id, modifier);
  };

  let onModify = (e, newModifier) => {
    console.log("The modifier =:", assembly.userRegrade.modifier);

    if (
      !profileStore.hasLoaded ||
      (rootStore.isPublicSite && !regStores.isRegistered())
    ) {
      loginDialogStore.showDialog = true;
      loginDialogStore.instruction = "to suggest a grade.";
      return;
    }
    regStore.resetNoActivityTimeout();
    setModifier(newModifier);
    updateUsersGradeModifier(regrade_id, newModifier);
  };

  let updateUsersGradeModifier = async (newRegrade, newModifier) => {
    regStore.resetNoActivityTimeout();

    let adjustedModifier = 0;
    switch (newModifier) {
      case 1:
        adjustedModifier = 2;
        break;
      case 2:
        adjustedModifier = 4;
        break;
      case -1:
        adjustedModifier = -2;
        break;
      case -2:
        adjustedModifier = -4;
        break;
      default:
      // code block
    }
    await regradeStore.updateRegistrationRegrade(
      {
        user_id: personalRootStore.getCurrentUserId(),
        regrade_id: newRegrade,
        modifier: adjustedModifier,
        gym_id: assembly.climbingRoute.gym_id
      },
      assembly
    );
    notificationStore.enqueueNotification("Saving your suggestion", {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 1000
    });
    if (callBack) {
      callBack();
    }
  };

  let difficultyMarks;
  let showSlider = !!assembly.userRegrade;

  let sliderContainer;
  if (showSlider) {
    assembly.regrades.forEach(regrade => {
      if (regrade.id === assembly.userRegrade.regrade_id) {
        difficultyMarks = [
          {
            value: 0,
            label: "Normal " + regrade.primaryLabel
          },
          {
            value: 2,
            label: (
              <>
                Hard {" " + regrade.primaryLabel}
                <ExpandMore
                  classes={iconClasses}
                  className={iconClasses.abby}
                />
              </>
            )
          },
          {
            value: -2,
            label: (
              <>
                Easy {" " + regrade.primaryLabel}
                <ExpandLess
                  classes={iconClasses}
                  className={iconClasses.abby}
                />
              </>
            )
          }
        ];
      }
    });

    sliderContainer = (
      <Slider
        defaultValue={
          assembly.userRegrade ? getSliderPos(assembly.userRegrade.modifier) : 0
        }
        getAriaValueText={valuetext}
        step={1}
        marks={difficultyMarks}
        max={2}
        min={-2}
        valueLabelDisplay="off"
        onChangeCommitted={onModify}
        classes={sliderClasses}
        color="primary"
      />
    );
  }

  let length = assembly.regrades.reduce(
    (total, a) => total + a.primaryLabel.length,
    0
  );
  let buttonSize = length > 23 ? "xsm" : "sm";
  return (
    <>
      <div>
        <small>How hard is this climb for you?</small>
      </div>
      {assembly.regrades.map(regrade => (
        <RegradeButton
          regrade_id={regrade_id}
          regrade={regrade}
          key={regrade.id}
          onRegrade={onRegrade}
          buttonSize={buttonSize}
        />
      ))}
      {assembly.userRegrade ? (
        <Delete
          className={classes.deleteIcon}
          onClick={() => {
            setRegrade_id(undefined);
            regradeStore.deleteUserRegrade(assembly);
          }}
        />
      ) : (
        <Delete className={classes.deleteIcon + " " + classes.hiddenIcon} />
      )}

      <div className={classes.sliderBox}>{sliderContainer}</div>
    </>
  );
}

RegradeControl.propTypes = {
  rootStore: PropTypes.object.isRequired,
  assembly: PropTypes.object.isRequired,
  callBack: PropTypes.func
};

export default withRootStore(observer(RegradeControl));

function valuetext(value) {
  return `${value}`;
}

function getSliderPos(modifier) {
  if (modifier === 0) return 0;
  if (modifier > 2) return 2;
  if (modifier > 0) return 1;
  if (modifier < -2) return -2;
  return -1;
}
