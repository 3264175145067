import { computed } from "mobx";
import { gradeTypes } from "../variables/constants";
import getSlotStr from "../utils/getSlotStr";
import SuggestionAI from "../utils/RouteAi/SuggestionAI";
import RouteAiHelper from "../utils/RouteAi/RouteAiHelper";

let getPopulatedCountsByGrade = gradeStores => {
  let countsByGrades = [];

  gradeTypes.ALL.forEach(gradeType => {
    countsByGrades[gradeType] = [];
    gradeStores[gradeType].getAllItemsEvenArchived().forEach(grade => {
      if (!grade.isArchived) {
        let count = {
          fromSlots: 0,
          fromQuantities: 0,
          fullGrade: grade,
          usableSlots: [],
          hasEnoughSlotsForGrade: function() {
            return this.fromQuantities <= this.fromSlots;
          }
        };

        countsByGrades[gradeType][grade.id] = count;
      }
    });
  });
  return countsByGrades;
};

export default class ComputedGymCheckStore {
  constructor(context, rootStore) {
    this.computedGymCheck = computed(
      () => {
        const { gymStores, computedCacheStore } = rootStore;

        let { gradeStores } = rootStore.gradeStores;

        let computedCache = computedCacheStore.computedCache.get();

        let retVal = {
          gradeCounts: [],
          message: "No Message"
        };

        let unsetQuantitiesOrig = gymStores.gymStore.climbQuantitiesJSON;

        let allSlotsLists = [];
        let totalSlots = [];
        gradeTypes.ALL.forEach(gradeType => {
          allSlotsLists[gradeType] = [];
          totalSlots[gradeType] = 0;
        });

        let countsByGrades = getPopulatedCountsByGrade(gradeStores);

        let wallWithOpenSlots = [];

        for (let sectionAssembly of computedCache.sectionAssemblies) {
          for (let currentWallAssembly of sectionAssembly.climbingWallAssemblies) {
            let climbingWall = currentWallAssembly.climbingWall;

            if (!currentWallAssembly.climbingWall.isArchived) {
              RouteAiHelper.fillSlotsCopy(currentWallAssembly);
              let gradeType = climbingWall.gradeType;

              // This is only required when the wall hasn't had the slots viewed and saved yet.
              if (
                !(
                  climbingWall.slotConfigsJSON.length ===
                  climbingWall.slotCountTarget
                )
              ) {
                debugger;
                retVal.message =
                  "The wall " +
                  climbingWall.name +
                  " is not setup properly. It is missing the slots.";
                return retVal;
              }

              totalSlots[climbingWall.gradeType] =
                totalSlots[climbingWall.gradeType] +
                climbingWall.slotConfigsJSON.length;

              climbingWall.gradeListForValidityCheck = [];

              for (let s = 0; s < climbingWall.slotConfigsJSON.length; s++) {
                let slot = climbingWall.slotConfigsJSON[s];

                let slotCopy = [];

                //This loop ensures that we only copy unarchived grades to the slotCopy
                for (let originalGradeId of slot) {
                  let climbingGrade = gradeStores[gradeType].getItemById(
                    originalGradeId
                  );

                  if (!climbingGrade.isArchived) {
                    slotCopy.push(originalGradeId);
                  }
                }

                let slotStr = getSlotStr(
                  slotCopy,
                  gradeStores[gradeType].getAllItemsEvenArchived()
                );

                slotCopy.slotStr = slotStr.slotStr;
                slotCopy.slotGradeList = slotStr.slotGradeList;

                slotCopy.wallAssembly = currentWallAssembly;

                allSlotsLists[climbingWall.gradeType].push(slotCopy);

                // If this slot is open then it can apply to all grades of the same type
                if (slotCopy.length === 0) {
                  wallWithOpenSlots.push(climbingWall);
                  break;

                  /*
            var areaGradeType = areas[a].gradeType;
            gradeToSlotCounts.forEach(function (count){
              if (count.fullGrade.gradeType == areaGradeType)
              {
                count.fromSlots++;
                count.usableSlots.push(slot);
              }
            }) */
                } else {
                  // other increment the gradeToSlotCountMap from slots count
                  // for each grade that this slot can be used on.
                  slotCopy.forEach(function(slotGradeId) {
                    let climbingGrade = gradeStores[gradeType].getItemById(
                      slotGradeId
                    );

                    if (!climbingGrade.isArchived) {
                      countsByGrades[gradeType][slotGradeId].fromSlots++;
                      countsByGrades[gradeType][slotGradeId].usableSlots.push(
                        slotCopy
                      );
                    }
                  });
                }
              }
            }
          }
        }
        if (wallWithOpenSlots.length > 0) {
          let message = "These areas have open slots ";
          wallWithOpenSlots.forEach(function(area) {
            message = message + area.name + ",";
          });

          console.log(message);
          retVal.message = message;
          //return retVal;
        }

        let allGradesLists = [];
        let totalQuantities = [];
        gradeTypes.ALL.forEach(gradeType => {
          allGradesLists[gradeType] = [];
          totalQuantities[gradeType] = 0;
        });

        // I'm cloning this array because Mobx doesn't like me messing with the original
        let unsetQuantities = [];
        unsetQuantitiesOrig.forEach(q => unsetQuantities.push(q));

        //By sorting this list of grades we can optimize the RouteAi search.
        unsetQuantities.sort(function(unsetQuantityA, unsetQuantityB) {
          return unsetQuantityA.quantity - unsetQuantityB.quantity;
        });

        unsetQuantities.forEach(function(unsetQuantity) {
          let quantity = unsetQuantity.quantity;
          if (typeof quantity === "string") {
            quantity = parseInt(quantity);
          }

          let climbingGrade;

          console.log(
            "About to find the grade" + unsetQuantity.climbing_grade_id
          );
          for (let gradeType of gradeTypes.ALL) {
            climbingGrade = gradeStores[gradeType].getItemById(
              unsetQuantity.climbing_grade_id
            );
            if (climbingGrade) {
              break;
            }
          }
          if (!climbingGrade.isArchived) {
            totalQuantities[climbingGrade.gradeType] =
              totalQuantities[climbingGrade.gradeType] + quantity;

            if (
              !countsByGrades[climbingGrade.gradeType][
                unsetQuantity.climbing_grade_id
              ]
            ) {
              debugger;
            }
            countsByGrades[climbingGrade.gradeType][
              unsetQuantity.climbing_grade_id
            ].fromQuantities = quantity;

            // Add a "route" to the 'routelist' totaling the quantity of unset routes
            // NOTE: if the unsetRouteQuantity is zero or negative because there are too
            // many routes already then this for loop will terminate immediately
            for (let i = 0; i < unsetQuantity.quantity; i++) {
              allGradesLists[climbingGrade.gradeType].push({
                id: unsetQuantity.climbing_grade_id,
                climbingGrade: climbingGrade
              });
            }
          }
        });

        retVal.suggestionResults = [];

        gradeTypes.ALL.forEach(gradeType => {
          retVal.suggestionResults[gradeType] = SuggestionAI(
            allGradesLists[gradeType],
            allSlotsLists[gradeType],
            {
              allowFailures: false,
              useSlotsMaxDepth: false,
              checkEligibleSlots: false,
              checkPreExistingRoutes: false,
              skipDuplicateCheck: gradeTypes.ROUTE !== gradeType
              // debugMode: true
            }
          );
        });

        retVal.totalQuantities = totalQuantities;
        retVal.totalSlots = totalSlots;
        retVal.countsByGrades = countsByGrades;
        retVal.allSlotsLists = allSlotsLists;

        return retVal;
      },
      { keepAlive: true }
    );
  }
}
