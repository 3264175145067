import { observable, action } from "mobx";
import { gradeTypes } from "../variables/constants";
import moment from "moment";

export default class {
  constructor(context, asyncOpCreate) {
    const rest = context.rest;

    this.settingHistoryStore = observable({
      gradeType: gradeTypes.ROUTE,
      fromDateTime: moment()
        .subtract(6, "months")
        .utc()
        .format(),
      timezone: "notSureYet",
      opGetSettingHistory: asyncOpCreate(
        ({ payload }) => rest.get("settingHistory/", payload),
        "opGetSettingHistory"
      ),
      getSettingHistory: action(async function() {
        try {
          const payload = {
            gradeType: this.gradeType,
            months: this.months,
            fromDateTime: this.fromDateTime,
            timezone: this.timezone
          };
          //?? Should this be just (payload) i.e. with out the {}
          await this.opGetSettingHistory.execute({ payload });
        } catch (error) {
          debugger;
          if (error === null || typeof error === "undefined") {
            console.log(
              "The call to 'settingHistory' has failed inside the settingHistoryStore. The error is undefined."
            );
          } else {
            console.log("THe error is : " + error);
            const {
              response: { status }
            } = error;
            if (401 === status) {
              console.log(
                "The call to 'climbingRoutes' has returned with 401. The user is probably not logged in"
              );
            } else {
              console.log("The call to 'climbs' has failed: " + error);
            }
          }
          throw error;
        }
      })
    });
  }
}
