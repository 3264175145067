import { coreStyle } from "ticker-core";
const { whiteColor, grayColor, hexToRgb } = coreStyle;

import buttonStyle from "../components/buttonStyle.js";

import navBarStyle from "./navbarStyle";

const mainNavbarStyle = theme => ({
  ...navBarStyle,

  container: {
    ...navBarStyle.container,
    paddingLeft: 0,
    // This overrides the widths set in the container of material-dashboard-pro-react.js
    "@media (min-width: 768px)": {
      width: "unset"
    },
    "@media (min-width: 992px)": {
      width: "unset"
    },
    "@media (min-width: 1200px)": {
      width: "unset"
    },
    textAlign: "center"
  },
  alertButton: buttonStyle.button,
  alertSuccess: buttonStyle.success,
  alertDanger: buttonStyle.danger,
  appBar: {
    backgroundColor: "#eeeeee",
    borderBottom: "0",
    marginBottom: "0",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  listItem: {
    float: "left",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0"
    //,
    // [theme.breakpoints.down("sm")]: {
    //   zIndex: "999",
    //   width: "100%",
    //   paddingRight: "15px"
    // }
  },
  navLink: {
    color: grayColor[2],
    margin: "0 5px",
    paddingTop: "15px",
    paddingBottom: "15px",
    // fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    position: "relative",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      padding: "10px 15px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 4px"
    },
    [theme.breakpoints.down(400)]: {
      padding: 0
    },
    textDecoration: "none",
    "&:hover,&:focus": {
      color: whiteColor,
      background: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)"
    }
  },
  navLinkActive: {
    backgroundColor: "rgba(" + hexToRgb(grayColor[0]) + ", 0.1)"
  },
  navTitle: {
    color: grayColor[2],
    textTransform: "uppercase"
  },

  buttonLink: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "5px 15px 0",
      width: "auto",
      height: "auto",
      "& svg": {
        width: "30px",
        height: "24px",
        marginRight: "19px",
        marginLeft: "3px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        width: "30px",
        fontSize: "24px",
        lineHeight: "30px",
        marginRight: "19px",
        marginLeft: "3px"
      }
    }
  },
  sidebarButton: {
    "&,&:hover,&:focus": {
      color: grayColor[2]
    },
    top: "-2px"
  },
  listy: {
    margin: "auto"
  },
  smallName: {
    [theme.breakpoints.down(400)]: {
      fontSize: "x-small"
    }
  },
  centerIcon: {
    display: "block",
    margin: "auto"
  }
});

export default mainNavbarStyle;
