import { action } from "mobx";

import getCachable from "../../utils/getCachable";

import { getAbsoluteCombinedModifier } from "ticker-core";

let areEqual = (a, b) => a.climbing_route_id === b.climbing_route_id;

export default class /* PersonalRegradeStores */ {
  constructor(
    context,
    asyncOpCreate,
    regStore,
    gradeStores,
    overwriteIdentificationFieldsFn
  ) {
    this.cachedRegrades = getCachable(
      asyncOpCreate,
      context.rest,
      areEqual,
      "userRegrades",
      null,
      () => overwriteIdentificationFieldsFn({})
    );

    this.regradeStore = getRegradeStore(
      asyncOpCreate,
      context.rest,
      this.cachedRegrades,
      regStore,
      gradeStores,
      overwriteIdentificationFieldsFn
    );
  }
}

function getRegradeStore(
  asyncOpCreate,
  rest,
  cachedRegrades,
  regStore,
  gradeStores,
  overwriteIdentificationFieldsFn
) {
  const this__ = {};

  this__.opUpdateUserRegrade = asyncOpCreate(
    payload => rest.put("userRegrades", payload),
    "opupdateRegistrationRegrades"
  );

  this__.updateRegistrationRegrade = action(async function(
    payload,
    climbingAssembly
  ) {
    // typeof climbingAssembly.userRegrade !== "undefined"
    //   ? climbingAssembly.userRegrade.regrade
    //   : undefined;

    payload.climbing_route_id = climbingAssembly.climbingRoute.id;

    payload.passcode = regStore.passcode;

    updateRegradeTotals(
      climbingAssembly,
      gradeStores,
      payload.regrade_id,
      payload.modifier
    );

    //We'll assume that the network transmission works
    //and update the UI store now
    cachedRegrades.mergeOrInsert(payload);

    let callBack = response => {
      // Update the cachedRatings with the new ID and other details that may have been missed in the merge above
      cachedRegrades.mergeOrInsert(response);
    };
    await this__.opUpdateUserRegrade.execute(payload, callBack);
  });

  this__.opDeleteUserRegrade = asyncOpCreate(
    payload => rest.delete("userRegrades/" + payload.id, payload),
    "opupdateRegistrationRegrades"
  );

  this__.deleteUserRegrade = action(async function(climbingAssembly) {
    removeRegradeTotals(climbingAssembly, gradeStores);
    let payload = {
      id: climbingAssembly.userRegrade.id
    };
    overwriteIdentificationFieldsFn(payload);

    //Assume success and remove the regrade from the cache
    cachedRegrades.removeItemById(climbingAssembly.userRegrade.id);
    climbingAssembly.userRegrade = undefined;

    await this__.opDeleteUserRegrade.execute(payload);
  });

  this__.getStoreName = () => {
    return "regradeStore";
  };

  return this__;
}

function updateRegradeTotals(
  climbingAssembly,
  gradeStores,
  regrade_id,
  modifier
) {
  let originalCount = climbingAssembly.climbingRoute.combinedModifierCount;

  // Now we need to update the combined average regrade
  let originalPublicCombinedRegrade = parseFloat(
    climbingAssembly.climbingRoute.avgCombinedModifier
  );

  if (isNaN(originalPublicCombinedRegrade)) {
    originalPublicCombinedRegrade = getAbsoluteCombinedModifier(
      climbingAssembly.climbingGrade.orderPosition,
      0
    );
  }

  let newRegrade = gradeStores[
    climbingAssembly.climbingGrade.gradeType
  ].getItemById(regrade_id);

  let newUserCombinedRegrade = getAbsoluteCombinedModifier(
    newRegrade.orderPosition,
    modifier
  );

  let originalUserCombinedRegrade;
  if (climbingAssembly.userRegrade) {
    let originalRegrade = gradeStores[
      climbingAssembly.climbingGrade.gradeType
    ].getItemById(climbingAssembly.userRegrade.regrade_id);

    originalUserCombinedRegrade = getAbsoluteCombinedModifier(
      originalRegrade.orderPosition,
      climbingAssembly.userRegrade.modifier
    );
  }

  // If there is a new regrade
  let originalTotal = originalPublicCombinedRegrade * originalCount;
  if (typeof originalUserCombinedRegrade !== "undefined") {
    climbingAssembly.climbingRoute.avgCombinedModifier =
      (originalTotal + newUserCombinedRegrade - originalUserCombinedRegrade) /
      originalCount;
  } else {
    // Increase the count to include this new rating
    climbingAssembly.climbingRoute.combinedModifierCount++;

    climbingAssembly.climbingRoute.avgCombinedModifier =
      (originalTotal + newUserCombinedRegrade) / (originalCount + 1);
  }
}
function removeRegradeTotals(climbingAssembly, gradeStores) {
  let originalCount = climbingAssembly.climbingRoute.combinedModifierCount;

  // Now we need to update the combined average regrade
  let originalPublicCombinedRegrade = parseFloat(
    climbingAssembly.climbingRoute.avgCombinedModifier
  );

  if (isNaN(originalPublicCombinedRegrade)) {
    console.error(
      "The originalPublicCombinedRegrade isNAN?",
      originalPublicCombinedRegrade
    );
    return;
  }

  let originalUserCombinedRegrade;
  if (!climbingAssembly.userRegrade) {
    console.error("The assembly doesn't have a regrade for this user?");
    return;
  }

  let originalRegrade = gradeStores[
    climbingAssembly.climbingGrade.gradeType
  ].getItemById(climbingAssembly.userRegrade.regrade_id);

  originalUserCombinedRegrade = getAbsoluteCombinedModifier(
    originalRegrade.orderPosition,
    climbingAssembly.userRegrade.modifier
  );

  // If there is a new regrade
  let originalTotal = originalPublicCombinedRegrade * originalCount;

  // Decrease the count to exclude this new regrade
  climbingAssembly.climbingRoute.combinedModifierCount--;

  if (climbingAssembly.climbingRoute.combinedModifierCount === 0) {
    climbingAssembly.climbingRoute.avgCombinedModifier = undefined;
  } else {
    climbingAssembly.climbingRoute.avgCombinedModifier =
      (originalTotal - originalUserCombinedRegrade) /
      climbingAssembly.climbingRoute.combinedModifierCount;
  }
}
