import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import Button from "../CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  return (
    <Hidden mdUp implementation="css">
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          {/*
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
                        {props.miniActive ? (
              <Button
                justIcon
                round
                color="green"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            <Button
              justIcon
              round
              color="white"
              onClick={props.sidebarMinimize}
            >
              <MoreVert className={classes.sidebarMiniIcon} />
            </Button>
            )}
          </div>
        </Hidden>
*/}
          <div className={classes.flex}></div>
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Toolbar>
      </AppBar>
    </Hidden>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func
};
