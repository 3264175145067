import AuthStores from "./AuthStores.js";
import UserStores from "./UserStores.js";
import GymStores from "./GymStores.js";
import NotificationStore from "./NotificationStore.js";
import GymWizardStores from "./GymWizardStores.js";
import GradeStores from "./GradeStores.js";
import SectionStores from "./SectionStores.js";
import MarkerColorStores from "./MarkerColorStores.js";
import WallStores from "./WallStores.js";
import SetterAliasStores from "./SetterAliasStores.js";
import RouteStatusStores from "./RouteStatusStores.js";
import ClimbingRouteStores from "./ClimbingRouteStores.js";
import ComputedCacheStore from "./ComputedCacheStore.js";
import ComputedStrippingReportStore from "./strippingReportStore/ComputedStrippingReportStore.js";
import ComputedSettingReportStore from "./settingReportStore/ComputedSettingReportStore.js";
import ComputedGymCheckStore from "./ComputedGymCheckStore.js";
import SettingPlanStores from "./SettingPlanStores.js";
import SettingReportStores from "./SettingReportStores.js";
import FilterStores from "./FilterStores.js";
import OverviewStore from "./OverViewStore.js";
import GroupStores from "./GroupStores.js";
import createSelectionStore from "./createSelectionStore";
import ErrorStore from "./ErrorStore";
import getClaimStore from "./getClaimStore";
import ReportedIssueStores from "./ReportedIssueStores";
import RatingReportStore from "./RatingReportStore";
import RegradeReportStore from "./RegradeReportStore";

import EntryCountStore from "./reports/EntryCountStore";
import RatingGraphStore from "./reports/RatingGraphStore";

import constants, { gradeTypes } from "../variables/constants";
import ComputedFilteredReportStore from "./ComputedFilteredReportStore.js";

import PersonalRootStore from "./personal/PersonalRootStore";
import Cacher from "../utils/Cacher.js";
import RegStores from "./RegStores.js";

import AsyncOp from "../utils/asyncOp";
import getLeaderboardStore from "./LeaderboardStore.js";
import SVGStore from "./SVGStore.js";
import selectionStore from "./SelectionStore.js";
import MissingStores from "./MissingStores.js";
import getLoginDialogStore from "./LoginDialogStore.js";

import StatusHandlerFactory from "../utils/HttpCodeHandlerFactory.js";

import {
  getClimbCoords,
  getSectionCoords,
  getWallCoords
} from "../utils/coords";

//successFulLoginPath = "/admin/overview";
// successFulRegistrationLoginPath = "/main/registration/climbs"

export default class RootStore {
  constructor(context, hist, siteName) {
    this.isClimberSite = siteName === "climber";
    this.isPublicSite = siteName === "public";
    this.loadGymOnly = !this.isClimberSite;

    this.loadCache = async params => {
      if (typeof params === "undefined") {
        let err =
          "The parameters for loading the cache are missing. Particularly the gym_id";
        console.log(err);
        debugger;
        throw err;
      } else if (
        typeof params.gym_id === "undefined" ||
        params.gym_id === null
      ) {
        debugger;
        console.log(
          "The gym_id is missing from the request to load the cache. ABORTING the caching."
        );
        return;
      }

      await this.cacher.loadCache(params, this.postCache);
    };

    this.postCache = () => {
      this.personalRootStore.postCache();
    };

    this.isPrePostCacheLoaded = () => {
      return this.cacher.isPrePostCacheLoaded;
    };

    this.isCacheLoading = () => {
      return this.cacher.isCacheLoading();
    };

    this.isCacheLoaded = () => {
      return this.cacher.isCacheLoaded();
    };

    this.getCurrentGymId = function getCurrentGymId() {
      if (
        typeof this.userStores.profileStore.gym_id !== "undefined" &&
        this.userStores.profileStore.gym_id !== null
      ) {
        return this.userStores.profileStore.gym_id;
      }

      if (
        typeof this.userStores.profileStore.selected_gym_id !== "undefined" &&
        this.userStores.profileStore.selected_gym_id !== null
      ) {
        return this.userStores.profileStore.selected_gym_id;
      }

      return constants.HANGDOG_ID;
    }.bind(this);

    let { successFulLoginPath } = context.config;

    this.notificationStore = NotificationStore;

    this.errorStore = ErrorStore;
    this.httpCodeHandlerFactory = new StatusHandlerFactory(
      this.errorStore,
      this.notificationStore,
      this
    );

    let asyncOpCreate = AsyncOp(this.httpCodeHandlerFactory);

    this.authStores = new AuthStores(
      context,
      hist,
      successFulLoginPath,
      asyncOpCreate,
      this.httpCodeHandlerFactory
    );

    this.entryReportStore = new EntryCountStore(context, asyncOpCreate);
    this.ratingGraphStore = new RatingGraphStore(context, asyncOpCreate);

    this.ratingReportStore = new RatingReportStore(context, asyncOpCreate);

    this.regradeReportStore = new RegradeReportStore(context, asyncOpCreate);

    this.missingStores = new MissingStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );

    this.claimStore = getClaimStore(
      context,
      asyncOpCreate,
      this.httpCodeHandlerFactory
    );

    this.reportedIssueStores = new ReportedIssueStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );

    this.gradeStores = new GradeStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );

    this.loginDialogStore = getLoginDialogStore();

    let initClimberSiteUserFn = partRegistration => {
      if (this.isClimberSite) {
        this.personalRootStore.init();
        if (partRegistration) {
          this.regStores.initClimberSite(partRegistration);
        }
      }
    };

    this.userStores = new UserStores(
      context,
      hist,
      asyncOpCreate,
      initClimberSiteUserFn
    );
    this.gymStores = new GymStores(context, this, asyncOpCreate);
    this.gymWizardStores = new GymWizardStores(
      context,
      hist,
      this.gymStores,
      this.gradeStores
    );
    this.sectionStores = new SectionStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );
    this.setterAliasStores = new SetterAliasStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );
    this.wallStores = new WallStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );
    this.markerColorStores = new MarkerColorStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );
    this.routeStatusStores = new RouteStatusStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );
    this.climbingRouteStores = new ClimbingRouteStores(
      context,
      this,
      asyncOpCreate,
      this.getCurrentGymId
    );

    this.computedSettingReportStore = new ComputedSettingReportStore(
      context,
      this
    );
    this.computedGymCheckStore = new ComputedGymCheckStore(context, this);
    this.computedStrippingReportStore = new ComputedStrippingReportStore(
      context,
      this
    );
    this.computedCacheStore = new ComputedCacheStore(context, this);

    this.settingPlanStores = new SettingPlanStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );
    this.settingReportStores = new SettingReportStores(
      context,
      asyncOpCreate,
      this.getCurrentGymId
    );
    this.filterStores = new FilterStores(
      this.gradeStores,
      this.setterAliasStores.setterAliasStore,
      this.computedCacheStore
    );
    this.overviewStore = OverviewStore;
    this.computedFilteredReportStore = new ComputedFilteredReportStore(
      context,
      this
    );

    this.groupStores = new GroupStores(context, asyncOpCreate);

    let clearPersonalDataFn = () => {
      if (!this.personalRootStore.clearCache()) {
        console.log("Unable to log out the user. Please try again");
        debugger;
        return false;
      }
      this.personalRootStore.clearPersonalData();
      this.regStores.regStore.clearPersonalData();
      this.SVGStore.clearPersonalData();
      console.log(
        "ABOUT TO RESETxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
      );
      this.SVGStore.reset();
      this.climbSelectionStore.clearSelections();
    };
    let initUserFn = user => {
      this.regStores.regStore.initUser(user);
      this.personalRootStore.init();
    };
    this.regStores = new RegStores(
      context,
      hist,
      asyncOpCreate,
      // successFulRegistrationLoginPath,
      clearPersonalDataFn,
      initUserFn,
      this.getCurrentGymId,
      this.userStores.profileStore,
      this
    );

    this.climbSelectionStore = createSelectionStore(this.regStores.regStore);

    // Registration stores
    this.personalRootStore = new PersonalRootStore(
      context,
      asyncOpCreate,
      this.regStores,
      this.computedCacheStore,
      this.climbSelectionStore,
      this.gradeStores.gradeStores,
      this.userStores.profileStore,
      this.isCacheLoaded,
      this.getCurrentGymId,
      this.httpCodeHandlerFactory,
      this
    );

    this.leaderboardStore = getLeaderboardStore(
      asyncOpCreate,
      context.rest,
      this.getCurrentGymId
    );

    this.climbCoords = getClimbCoords(
      this.gymStores.gymStore,
      this.sectionStores.sectionStore,
      this.wallStores.wallStore
    );

    this.sectionCoords = getSectionCoords(
      this.gymStores.gymStore,
      this.sectionStores.sectionStore,
      this.wallStores.wallStore
    );

    this.wallCoords = getWallCoords(
      this.gymStores.gymStore,
      this.sectionStores.sectionStore,
      this.wallStores.wallStore
    );

    this.SVGStore = SVGStore;
    this.selectionStore = selectionStore;

    const cachables = [];
    cachables.push({ store: this.gymStores.gymStore });
    cachables.push({ store: this.setterAliasStores.setterAliasStore });
    cachables.push({ store: this.markerColorStores.markerColorStore });
    cachables.push({ store: this.wallStores.wallStore });
    cachables.push({ store: this.routeStatusStores.routeStatusStore });
    cachables.push({ store: this.sectionStores.sectionStore });
    cachables.push({ store: this.gradeStores.gradeStores[gradeTypes.ROUTE] });
    cachables.push({ store: this.gradeStores.gradeStores[gradeTypes.BOULDER] });
    cachables.push({
      store: this.userStores.profileStore,
      conditionFn: () => {
        this.regStores.regStore.isLoggedIn;
      }
    });
    cachables.push({ store: this.climbingRouteStores.climbingRouteCacheStore });
    cachables.push({
      store: this.settingPlanStores.settingPlanCacheStore,
      conditionFn: () => this.loadGymOnly
    });
    cachables.push({
      store: this.groupStores.groupStore,
      conditionFn: () => {
        this.regStores.regStore.isLoggedIn;
      }
    });
    cachables.push({ store: this.reportedIssueStores.cachedIssuesStore });
    cachables.push({ store: this.missingStores.cachedMissingStore });
    this.cacher = new Cacher(cachables, "rootCache");

    this.regStores.regStore.setLoadCacheFn(this.cacher.loadCache);
    this.context = context;
    this.history = hist;
  }
}
