import React from "react";

import { withRootStore, coords } from "ticker-core";
import { observer } from "mobx-react";

import PropTypes from "prop-types";

import SVGLabelsMap from "./SVGLabelsMap";

function SVGWallsMap(props) {
  let { rootStore, longPressIsDisabled } = props;

  const {
    wallStores: { wallStore },
    sectionStores: { sectionStore },
    selectionStore,
    gymStore
  } = rootStore;

  let climbingWalls = wallStore.items.filter(item => item.isArchived !== true);

  let onPointerUpFinishFn = () => {
    // Save anything that has moved
    if (selectionStore.hasObjectMoved) {
      wallStore.putItems();
    }
    // else if (selectionStore.creatingNewObject) {
    //   rootStore.history.push("/admin/settings/add-wall");

    //   // When we come back from adding the wall we don't want anything to be selected
    //   selectionStore.clearSelection();
    // }
  };

  return (
    <SVGLabelsMap
      isSections={false}
      onPointerUpFinishFn={onPointerUpFinishFn}
      longPressIsDisabled={longPressIsDisabled}
      labelObjects={climbingWalls}
      getLabelFn={section => section.name}
      newLabelText="New Wall"
      coordsFactory={coords.getWallCoords(gymStore, sectionStore, wallStore)}
    />
  );
}

SVGWallsMap.propTypes = {
  rootStore: PropTypes.object.isRequired,
  longPressIsDisabled: PropTypes.bool.isRequired
};
export default withRootStore(observer(SVGWallsMap));
