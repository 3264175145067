import React, { useState, useEffect } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components

import { withCachedRootStore } from "ticker-core";

import { useObserver } from "mobx-react";

import Fade from "@material-ui/core/Fade";

import "react-simple-keyboard/build/css/index.css";

import {
  NextPanel,
  UserNameKeyboard,
  mockCursorStyle
} from "ticker-components";

import makeStyles from "@material-ui/core/styles/makeStyles";

// core components
import { Button } from "ticker-components";

let useStyles = makeStyles(mockCursorStyle);

let FADE_DURATION = 300;

function RegistraterUserNamePage({
  rootStore: {
    regStores: { regStore },
    gymStores: { gymStore },
    history
  },
  onClick
}) {
  let classes = useStyles();

  const [userName, setUserName] = useState(regStore.userName);
  const [registrationMessage, setRegistrationMessage] = useState(null);

  useEffect(() => {
    regStore.getRegistrations();
  }, [regStore]);

  const userNameExists = newUserName => {
    let newUserName_lc = newUserName.toLowerCase().trim();

    let currentUserName_lc;
    if (regStore.userName) {
      currentUserName_lc = regStore.userName.toLowerCase().trim();
    }

    if (regStore.opGetRegistrations.data) {
      for (let registration of regStore.opGetRegistrations.data) {
        // If this is found somewhere else,
        // then the user can't have it.
        if (
          currentUserName_lc !== newUserName_lc &&
          registration.userName.toLowerCase().trim() === newUserName_lc
        ) {
          let takenMsg = newUserName + " is already taken.";
          if (registrationMessage !== takenMsg) {
            setRegistrationMessage({
              type: "warning",
              msg: takenMsg
            });
          }
          return true;
        }
      }
    }

    setRegistrationMessage(null);
    return false;
  };

  const onChange = newUserName => {
    // Note to BROOK
    // I have left a lot of the comments in because there is an interesting bug
    // where the state userName does not here the correct value when this
    // onChange function is called.
    // The weird thing is that it does have the correct value
    // when the passcodeOnChange function is called.
    //
    // This isn't a problem.... it's just really weird so I've left it here to help debugging in the future.
    //console.log("The Username in onChange is " + userName);
    userNameExists(newUserName);
    setUserName(newUserName);
  };

  const saveClick = () => {
    regStore.resetNoActivityTimeout();
    console.log(
      "The Username in nextToPasscodeClick for passcode is " + userName
    );
    if (userNameExists(userName)) {
      return;
    }

    if (userName.length < 3) {
      setRegistrationMessage({
        type: "warning",
        msg: "Your username must have at least 3 letters."
      });
      return;
    }
    let callBack = () => {
      history.push("/main/height");
    };
    onClick(userName, undefined, callBack);
  };

  return useObserver(() => {
    return (
      <>
        <Fade in={true} timeout={FADE_DURATION}>
          <div>
            <NextPanel
              title={"Type in a new User Name for use at " + gymStore.name}
              note="Please Note: it will be publicly visible."
              input={
                <h2 className={classes.inputContainer}>
                  {userName}
                  <div className={classes.mockCursor}>|</div>
                </h2>
              }
              //BUG: There can only be one keyboard on the screen at a time
              //Therefore not displaying the keyboard if it is not the current activeIndex
              keyboard={
                <UserNameKeyboard
                  onChange={onChange}
                  showShift={true}
                  regStore={regStore}
                />
              }
              leftButton={null}
              rightButton={
                <Button color="rose" onClick={saveClick}>
                  Save
                </Button>
              }
              key="1"
              registrationMessage={registrationMessage}
            />
          </div>
        </Fade>
      </>
    );
  });
}

RegistraterUserNamePage.propTypes = {
  rootStore: PropTypes.object
};

export default withCachedRootStore(RegistraterUserNamePage);
