import React, { useState } from "react";
import { useObserver } from "mobx-react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import Place from "@material-ui/icons/Place";

import SwipeableViews from "react-swipeable-views";

// core components
import {
  Card,
  CardBody,
  GridContainer,
  GridItem,
  OptionButton,
  CustomLinearProgress,
  SnackbarContent,
  Button,
  LoginRequest
} from "ticker-components";

import { withCachedRootStore, gradeTypes, SVGPanner } from "ticker-core";

import tickStyles from "../../../assets/jss/material-dashboard-pro-react/views/registration/ticksPageStyle.js";

const TicksPage = function({ classes, rootStore }) {
  let {
    personalRootStore: { computedPersonalCacheStore, inspirationStore },
    gradeStores: { gradeStores },
    SVGStore,
    userStores: { profileStore },
    regStores
  } = rootStore;

  let [gradeSelection, setGradeSelection] = useState(gradeTypes.BOULDER);

  let gotoMap = (climbingGrade, hasClimbed) => {
    let { selection, UISelection } = inspirationStore.mapPageSelection;

    setSelection(selection, climbingGrade, hasClimbed);
    setSelection(UISelection, climbingGrade, hasClimbed);

    let filteredRoutesWrapper = inspirationStore.filteredMapPage.get();

    let assemblies = filteredRoutesWrapper.climbingRouteAssemblies;

    let enclosingCoords = SVGPanner.getEnclosingCoords(assemblies);

    SVGStore.encloseTheseCoords = enclosingCoords;
    console.log("AFter assigning to the panToTheseClimbs");

    rootStore.history.push("map");
  };

  return useObserver(() => {
    let computedCache = computedPersonalCacheStore.computedPersonalCache.get();
    let boulderGrades = gradeStores[
      gradeTypes.BOULDER
    ].getAllItemsEvenArchived();
    let routeGrades = gradeStores[gradeTypes.ROUTE].getAllItemsEvenArchived();

    let statsByGrade = [
      getGradeStats(boulderGrades, computedCache),
      getGradeStats(routeGrades, computedCache)
    ];

    debugger;
    return (
      <>
        <div className={classes.slenderContainer}>
          <Card narrowWhite className={classes.highTop}>
            <CardBody narrowWhite>
              <GridContainer justify="center" alignItems="center">
                <GridItem>
                  <OptionButton
                    label="Boulders"
                    showCross={false}
                    isSelected={gradeSelection === gradeTypes.BOULDER}
                    onClick={() => {
                      setGradeSelection(gradeTypes.BOULDER);
                    }}
                  />
                  <OptionButton
                    label="Routes"
                    showCross={false}
                    isSelected={gradeSelection === gradeTypes.ROUTE}
                    onClick={() => {
                      setGradeSelection(gradeTypes.ROUTE);
                    }}
                  />
                </GridItem>
              </GridContainer>
              {!profileStore.hasLoaded ||
              (rootStore.isPublicSite && !regStores.isRegistered()) ? (
                <div style={{ padding: "2em" }}>
                  <LoginRequest instruction=" to view your climbing history." />
                </div>
              ) : (
                undefined
              )}

              <SwipeableViews
                index={gradeSelection === gradeTypes.BOULDER ? 0 : 1}
                onChangeIndex={index => {
                  if (index === 0) {
                    setGradeSelection(gradeTypes.BOULDER);
                    return;
                  }
                  setGradeSelection(gradeTypes.ROUTE);
                }}
                style={{ overflowY: "hidden" }}
              >
                {statsByGrade.map((statsObj, key) => {
                  return (
                    <div key={key}>
                      {statsObj.map(gradeStat => {
                        if (gradeStat.total === 0) {
                          return (
                            <SnackbarContent
                              message={
                                "There are no " +
                                (gradeStat.climbingGrade.gradeType ===
                                gradeTypes.BOULDER
                                  ? gradeStat.climbingGrade.primaryLabel +
                                    " tag boulders."
                                  : " grade " +
                                    gradeStat.climbingGrade.primaryLabel +
                                    " routes.")
                              }
                              color="success"
                            />
                          );
                        }
                        if (gradeStat.total === gradeStat.completed) {
                          return (
                            <div
                              onClick={() => {
                                gotoMap(gradeStat.climbingGrade, true);
                              }}
                              style={{ width: "100%" }}
                            >
                              <SnackbarContent
                                message={
                                  <>
                                    <Button justIcon simple>
                                      <Place />
                                    </Button>
                                    You have completed all {gradeStat.total} of
                                    the{" "}
                                    {gradeStat.climbingGrade.gradeType ===
                                    gradeTypes.BOULDER
                                      ? gradeStat.climbingGrade.primaryLabel +
                                        " tag boulders."
                                      : " grade " +
                                        gradeStat.climbingGrade.primaryLabel +
                                        " routes."}
                                  </>
                                }
                                color="success"
                              />
                            </div>
                          );
                        }
                        return (
                          <>
                            <div
                              key={gradeStat.climbingGrade.id}
                              onClick={() => {
                                gotoMap(gradeStat.climbingGrade, false);
                              }}
                            >
                              <GridContainer
                                style={{ padding: "1em" }}
                                alignItems="center"
                              >
                                <GridItem>
                                  <Button color="info" simple justIcon>
                                    <Place />
                                  </Button>
                                  {gradeStat.climbingGrade.primaryLabel}
                                </GridItem>
                                <GridItem
                                  style={{ flexGrow: 1, textAlign: "right" }}
                                >
                                  {gradeStat.completed}/{gradeStat.total}
                                </GridItem>
                              </GridContainer>
                              <Box display="flex" alignItems="center">
                                <Box
                                  width={
                                    Math.floor(
                                      (gradeStat.total / statsObj.maxTotal) *
                                        100
                                    ) + "%"
                                  }
                                  mr={1}
                                >
                                  <CustomLinearProgress
                                    variant="determinate"
                                    color="info"
                                    value={
                                      (gradeStat.completed / gradeStat.total) *
                                      100
                                    }
                                  />
                                </Box>
                              </Box>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  );
                })}
              </SwipeableViews>
            </CardBody>
          </Card>
        </div>
      </>
    );
  });
};

TicksPage.propTypes = {
  classes: PropTypes.object,
  rootStore: PropTypes.object
};

function setSelection(selection, climbingGrade, hasClimbed) {
  selection.orderPositionOfGradeStarts[climbingGrade.gradeType] =
    climbingGrade.orderPosition;
  selection.orderPositionOfGradeEnds[climbingGrade.gradeType] =
    climbingGrade.orderPosition;

  selection.hasClimbed = hasClimbed;

  selection.showGradeTypes[gradeTypes.BOULDER] = false;
  selection.showGradeTypes[gradeTypes.ROUTE] = false;
  selection.showGradeTypes[climbingGrade.gradeType] = true;
}

function getGradeStats(boulderGrades, computedCache) {
  let gradeStats = [];
  gradeStats.maxTotal = 0;
  boulderGrades.forEach(grade => {
    if (grade.isArchived) {
      return;
    }
    let gradeStat = {
      total: 0,
      completed: 0,
      climbingGrade: grade
    };

    gradeStats.push(gradeStat);
    computedCache.allClimbingAssemblies.forEach(climbAssembly => {
      if (climbAssembly.climbingGrade.id === grade.id) {
        gradeStat.total++;
        if (gradeStat.total > gradeStats.maxTotal) {
          gradeStats.maxTotal = gradeStat.total;
        }
        if (climbAssembly.logEntryCount > 0) {
          gradeStat.completed++;
        }
      }
    });
  });

  return gradeStats;
}

export default withCachedRootStore(withStyles(tickStyles)(TicksPage));
