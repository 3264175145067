import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";

import { makeStyles } from "@material-ui/core/styles";

import {
  UserNameKeyboard,
  PasscodeKeyboard,
  NextPanel,
  Button,
  mockCursorStyle
} from "ticker-components";

import "react-simple-keyboard/build/css/index.css";

// core components

import { withCachedRootStore } from "ticker-core";

let FADE_DURATION = 300;

let useStyles = makeStyles(mockCursorStyle);

function RegistrationLoginPage({ rootStore }) {
  const [userName, setUserName] = useState("");
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [passcode, setPasscode] = useState("");
  let [registrationMessage, setRegistrationMessage] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [fadingOut, setFadingOut] = useState(false);
  const [clearNow, setClearNow] = useState(false);

  const { regStore } = rootStore.regStores;

  const classes = useStyles();

  useEffect(() => {
    regStore.getRegistrations();
  }, [regStore]);

  useEffect(() => {
    if (regStore.isLoggedIn) {
      setFadingOut(true);
      setTimeout(() => {
        console.log(
          "ssss rootStore.history.hack_prevPushPath=" +
            rootStore.history.hack_prevPushPath
        );
        for (let NALP of rootStore.context.config.notAfterLoginPaths) {
          /*
           * The hack_prevPushPath is added in the hist.push() function
           * because the push() is overridden in index.js of the climber site.
           * If you need this in other sites too then we'll need to
           * put the hack code where push() is overridden elsewhere
           */
          if (
            !rootStore.history.hack_prevPushPath ||
            NALP === rootStore.history.hack_prevPushPath
          ) {
            rootStore.history.push("map");
            return;
          }
        }
        rootStore.history.goBack();
      }, FADE_DURATION);
    }
  }, [regStore.isLoggedIn, rootStore.history]);

  useEffect(() => {
    if (clearNow) {
      setClearNow(false);
    }
  });

  const onChange = newUserName => {
    setUserName(newUserName);
  };

  const loginFailedCallBack = () => {
    setRegistrationMessage({ type: "warning", msg: "Incorrect passcode" });
    setPasscode("");
    setClearNow(true);
  };

  const passcodeOnChange = newCode => {
    setRegistrationMessage(null);
    if (newCode.length === 5) {
      setRegistrationMessage({ type: "good", msg: "Logging in" });
      // setTimeout(() => {
      //   // if (regStore.isLoggedIn) {
      //   //   console.log(
      //   //     "sssss notAfterLoginPaths=",
      //   //     rootStore.context.config.notAfterLoginPaths
      //   //   );
      //   //   console.log("sssss BEFORE BACK location=", rootStore.history);
      //   //   rootStore.history.goBack();
      //   //   console.log("sssss AFTER BACK location=", rootStore.history);
      //   //   for (let NALP of rootStore.context.config.notAfterLoginPaths) {
      //   //     console.log("sssss NALP=" + NALP);
      //   //     console.log("sssss location=", rootStore.history);
      //   //     if (NALP === rootStore.history.location.pathname) {
      //   //       rootStore.history.push("map");
      //   //     }
      //   //   }
      //   //   rootStore.history.goBack();
      //   // }
      // }, FADE_DURATION);
      regStore.loginRegistration(
        selectedRegistration,
        newCode,
        loginFailedCallBack
      );
    }
    //NOTE: This wont get called if the newCode is longer than 5 digits
    // See PasscodeKeyboard.js
    setPasscode(newCode);
  };

  const selectRegistration = selectedRegistration => {
    setSelectedRegistration(selectedRegistration);
    pageTransition(1);
  };

  const onClearPasscode = () => {
    setPasscode("");
    setRegistrationMessage(null);
  };

  const backToUserNameClick = () => {
    setRegistrationMessage(null);
    pageTransition(0);
    setPasscode("");
    setUserName("");
    setSelectedRegistration(null);
  };

  const pageTransition = newIndex => {
    setFadingOut(true);
    setTimeout(() => {
      setActiveIndex(newIndex);
      setFadingOut(false);
    }, FADE_DURATION + 50);
  };

  return useObserver(() => {
    let isLoggingIn =
      regStore.opLoginRegistration.executing || regStore.isLoggedIn;

    let contents;
    if (!isLoggingIn && activeIndex === 0) {
      contents = (
        <div>
          <NextPanel
            title="Type in at least 3 letters of your User Name"
            subTitle="and then select your name from the list"
            input={
              <AutoComplete
                id="combo-box-demo"
                options={regStore.opGetRegistrations.data}
                // className={classes.keyArea}
                inputValue={userName}
                open={userName.length > 2 && selectedRegistration === null}
                popupIcon={null}
                loading={!regStore.opGetRegistrations.data}
                getOptionLabel={registrationOption =>
                  registrationOption.userName
                }
                onChange={(e, selectedRegistration) =>
                  selectRegistration(selectedRegistration)
                }
                disabled
                renderInput={params => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
                noOptionsText={
                  "Whoops. No User Name matches '" + userName + "'."
                }
              />
            }
            //BUG: There can only be one keyboard on the screen at a time
            //Therefore not displaying the keyboard if it is not the current activeIndex
            keyboard={
              activeIndex === 0 ? (
                <UserNameKeyboard
                  onChange={onChange}
                  showShift={false}
                  regStore={regStore}
                />
              ) : (
                undefined
              )
            }
          />
        </div>
      );
    } else {
      let input;
      if (!isLoggingIn) {
        input = (
          <h2 className={classes.inputContainer}>
            {passcode.replace(/./g, "*")}
            <div className={classes.mockCursor}>|</div>
          </h2>
        );
      }

      contents = (
        <div>
          <NextPanel
            title={selectedRegistration && selectedRegistration.userName}
            subTitle={"Please enter your 5 digit passcode."}
            //BUG: There can only be one keyboard on the screen at a time
            //Therefore not displaying the keyboard if it is not the current activeIndex
            keyboard={
              activeIndex === 1 ? (
                <PasscodeKeyboard
                  onChange={passcodeOnChange}
                  onClear={onClearPasscode}
                  clearNow={clearNow}
                  regStore={regStore}
                  codeLength={5}
                />
              ) : (
                undefined
              )
            }
            leftButton={
              <Button
                onClick={backToUserNameClick}
                simple
                color="info"
                style={{ textTransform: "none", fontSize: "large" }}
              >
                Not {selectedRegistration && selectedRegistration.userName}?
              </Button>
            }
            input={input}
            key={2}
            registrationMessage={registrationMessage}
          />
        </div>
      );
    }
    return (
      <>
        <Fade in={!fadingOut} timeout={FADE_DURATION}>
          {contents}
        </Fade>
      </>
    );
  });
}

RegistrationLoginPage.propTypes = {
  classes: PropTypes.object,
  rootStore: PropTypes.object
};

export default withCachedRootStore(RegistrationLoginPage);
