import React, { useEffect, useState, useRef } from "react";
import { useObserver } from "mobx-react";
import { action } from "mobx";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GroupAdd from "@material-ui/icons/GroupAdd";

// core components
import {
  Table,
  Button,
  Card,
  CardBody,
  OptionButton,
  LoginRequest
} from "ticker-components";

import {
  gradeTypes,
  leaderboardDateSelections,
  withCachedRootStore
} from "ticker-core";

import styles from "../../assets/jss/material-dashboard-pro-react/views/leaderboardPageStyle.js";

let useLeaderboardPageStyle = makeStyles(styles);

const scrollToRef = ref => {
  ref.current.scrollIntoView({
    behavior: "smooth",
    block: "start"
  });
};

const Leaderboard = function Leaderboard(props) {
  let [showUsersCounts, setShowUsersCounts] = useState(false);
  let [userAggregate, setUserAggregate] = useState();

  let { rootStore } = props;

  let { leaderboardStore, personalRootStore, regStores } = rootStore;

  let { cachedOrganisations } = personalRootStore.organisationStores;

  let { regStore } = regStores;
  useEffect(() => {
    leaderboardStore.getLeaderboard();
  }, [
    leaderboardStore,
    leaderboardStore.dateSelection,
    leaderboardStore.gradeType,
    leaderboardStore.leaderboardStyle,
    leaderboardStore.organisation_id
  ]);

  const userRef = useRef();
  const executeScroll = () => {
    regStore.resetNoActivityTimeout();
    scrollToRef(userRef);
  };

  const classes = useLeaderboardPageStyle();

  return useObserver(() => {
    const selections = [
      [
        {
          onClick: action(function() {
            leaderboardStore.gradeType = gradeTypes.ROUTE;
            regStore.resetNoActivityTimeout();
          }),
          label: "Routes",
          isSelected: gradeTypes.ROUTE === leaderboardStore.gradeType
        },
        {
          onClick: action(function() {
            leaderboardStore.gradeType = gradeTypes.BOULDER;
            regStore.resetNoActivityTimeout();
          }),
          label: "Boulders",
          isSelected: gradeTypes.BOULDER === leaderboardStore.gradeType
        }
      ],

      leaderboardDateSelections.map(option => {
        return {
          onClick: action(function() {
            leaderboardStore.dateSelection = option;
            regStore.resetNoActivityTimeout();
          }),
          label: option.label,
          isSelected: option.label === leaderboardStore.dateSelection.label
        };
      }),
      [
        {
          onClick: action(function() {
            leaderboardStore.leaderboardStyle = "DISTINCT";
            regStore.resetNoActivityTimeout();
          }),
          label: "Distinct Climbs",
          isSelected: "DISTINCT" === leaderboardStore.leaderboardStyle
        },
        {
          onClick: action(function() {
            leaderboardStore.leaderboardStyle = "QUANTITY";
            regStore.resetNoActivityTimeout();
          }),
          label: "Number of Climbs",
          isSelected: "QUANTITY" === leaderboardStore.leaderboardStyle
        }
      ],
      [
        {
          onClick: action(function() {
            leaderboardStore.sortByCount();
            leaderboardStore.viewTotals = true;
            regStore.resetNoActivityTimeout();
          }),
          label: "Totals",
          isSelected: leaderboardStore.viewTotals
        },
        {
          onClick: action(function() {
            leaderboardStore.sortByCount();
            leaderboardStore.viewTotals = false;
            regStore.resetNoActivityTimeout();
          }),
          label: "Separated by Grade",
          isSelected: !leaderboardStore.viewTotals
        }
      ]
    ];

    let mobxDataArray = [];
    let currentUserIsIncluded = false;
    let currentUserId = personalRootStore.getCurrentUserId();

    if (leaderboardStore.opGetLeaderboard.data) {
      //[{ user_id: 4, userName: "zzzz", gradeCounts: { "61": 1 } }];
      let grades =
        rootStore.gradeStores.gradeStores[leaderboardStore.gradeType].items;

      const maxDigitsOfCounts = {};
      let highestGradeDisplayedIndex = -1;
      leaderboardStore.opGetLeaderboard.data.forEach(userAggregate => {
        if (userAggregate.user_id === currentUserId) {
          currentUserIsIncluded = true;
        }
        // For every grade
        grades.forEach((climbingGrade, gradeIndex) => {
          if (typeof maxDigitsOfCounts[climbingGrade.id] === "undefined") {
            maxDigitsOfCounts[climbingGrade.id] = 0;
          }
          // For every registration
          // if the score for this grade for this user is
          // not 0 then we need to note this grade as shouldBeDisplayed
          // And we need to record the max number digits for displaying this grade e.g. 10 is 2 digits long
          //
          let gradeIdKeys = Object.keys(userAggregate.gradeCounts).map(key =>
            parseInt(key, 10)
          );
          gradeIdKeys.forEach(gradeIdKey => {
            if (climbingGrade.id !== gradeIdKey) {
              return;
            }

            const logEntryCount = userAggregate.gradeCounts[gradeIdKey];
            let numberDigitsInScore = 0;
            if (logEntryCount !== "0") {
              if (gradeIndex > highestGradeDisplayedIndex) {
                highestGradeDisplayedIndex = gradeIndex;
              }
              numberDigitsInScore = logEntryCount.toString().length;
            }
            if (maxDigitsOfCounts[climbingGrade.id] < numberDigitsInScore) {
              maxDigitsOfCounts[climbingGrade.id] = numberDigitsInScore;
            }
          });
        });
      });

      let descGrades = rootStore.gradeStores.gradeStores[
        leaderboardStore.gradeType
      ].items.slice(0, highestGradeDisplayedIndex + 1);

      descGrades.sort((a, b) => b.orderPosition - a.orderPosition);

      leaderboardStore.opGetLeaderboard.data.forEach(userAggregate => {
        let newAggregate = Object.assign(
          {
            allGradeCounts: [],
            scoreStr: "",
            lowestTickIndex: 0,
            totalCounts: 0
          },
          userAggregate
        );
        //        userAggregate.allGradeCounts = [];
        //        userAggregate.scoreStr = "";

        let gradeIdKeys = Object.keys(userAggregate.gradeCounts).map(key =>
          parseInt(key, 10)
        );

        descGrades.forEach((grade, index) => {
          let gradeFound = false;
          let numberOfDigits = maxDigitsOfCounts[grade.id];
          let newGradeCount = {
            id: grade.id,
            primaryLabel: grade.primaryLabel
          };
          gradeIdKeys.forEach(key => {
            if (grade.id === key) {
              newGradeCount.rawCount = userAggregate.gradeCounts[key];
              gradeFound = true;
            }
          });
          if (!gradeFound) {
            newGradeCount.rawCount = 0;
          }

          newGradeCount.paddedCount = getPaddedCount(
            newGradeCount.rawCount,
            numberOfDigits
          );
          if (newGradeCount.rawCount > 0) {
            newAggregate.lowestTickIndex = index;
          }
          newAggregate.allGradeCounts.push(newGradeCount);
          newAggregate.totalCounts =
            newAggregate.totalCounts + newGradeCount.rawCount;
        });
        mobxDataArray.push(newAggregate);
      });
    }

    if (leaderboardStore.viewTotals) {
      mobxDataArray.sort((a, b) => {
        return b.totalCounts - a.totalCounts;
      });
    }
    let screenWidth = document.body.clientWidth;

    let buttonSize = undefined;
    if (screenWidth < 500) {
      buttonSize = "xsm";
    }

    let rows = [];
    if (showUsersCounts) {
      for (let i = 0; i <= userAggregate.lowestTickIndex; i++) {
        rows.push(
          <>
            <tr>
              <td style={{ textAlign: "center" }}>
                {userAggregate.allGradeCounts[i].primaryLabel}
              </td>
              <td>{userAggregate.allGradeCounts[i].rawCount}</td>
            </tr>
          </>
        );
      }
    }
    return (
      <>
        {showUsersCounts && (
          <SweetAlert
            show={true}
            position="top"
            //            style={{ display: "block" }}
            title={userAggregate.userName}
            onConfirm={() => {
              regStore.resetNoActivityTimeout();
              setShowUsersCounts(false);
            }}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmButtonText="close"
          >
            <table style={{ margin: "auto" }}>
              <thead>
                <tr>
                  <th className={classes.scoreHeader}>Grade</th>
                  <th className={classes.scoreHeader}>Ticks</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </SweetAlert>
        )}
        <div className={classes.slenderContainer}>
          <Card narrowWhite className={classes.highTop}>
            <CardBody narrowWhite>
              {selections.map((row, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  {row.map((option, index) => (
                    <OptionButton
                      key={index}
                      onClick={option.onClick}
                      label={option.label}
                      isSelected={option.isSelected}
                    />
                  ))}
                </div>
              ))}
              {regStores.isRegistered() && (
                <div style={{ textAlign: "center" }}>
                  {cachedOrganisations.items.map((org, index) => (
                    <OptionButton
                      key={index}
                      onClick={() => {
                        leaderboardStore.organisation_id =
                          org.id === leaderboardStore.organisation_id
                            ? undefined
                            : org.id;
                        regStore.resetNoActivityTimeout();
                      }}
                      label={org.name}
                      isSelected={org.id === leaderboardStore.organisation_id}
                    />
                  ))}
                  <Button
                    onClick={() => {
                      rootStore.history.push("/main/organisations");
                    }}
                    justIcon
                    simple
                    color="info"
                  >
                    <GroupAdd />
                  </Button>
                </div>
              )}
            </CardBody>
            {mobxDataArray.length === 0 && (
              <div className={classes.emptyMessage}>
                <h5>
                  There are no climbers with ticks matching the selected
                  filters.
                </h5>
                <h6>Please select a different filter.</h6>
              </div>
            )}
            {mobxDataArray.length > 0 && (
              <>
                {!regStores.isRegistered() && (
                  <div className={classes.message}>
                    <h6>
                      <LoginRequest instruction=" to view your counts and groups." />
                    </h6>
                  </div>
                )}
                {!currentUserIsIncluded &&
                regStores.isRegistered() &&
                typeof currentUserId !== "undefined" ? (
                  <div className={classes.emptyMessage}>
                    <h6>You have no ticks with the current filter</h6>
                  </div>
                ) : (
                  undefined
                )}
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "",
                    <>
                      Username{" "}
                      {currentUserIsIncluded && (
                        <Button onClick={executeScroll} size="sm" color="info">
                          Move to me
                        </Button>
                      )}
                      {typeof currentUserId === "undefined" && (
                        <Button
                          onClick={() =>
                            rootStore.history.push(
                              rootStore.context.config.registrationLoginPath
                            )
                          }
                          size="sm"
                          color="info"
                        >
                          Log in
                        </Button>
                      )}
                    </>,
                    "Counts"
                  ]}
                  tableData={mobxDataArray.map((userAggregate, index) => {
                    let data = {
                      data: [
                        index + 1,
                        userAggregate.userName,
                        leaderboardStore.viewTotals ? (
                          <span
                            style={{
                              paddingRight: "30px",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              display: "inline-flex"
                            }}
                          >
                            {userAggregate.totalCounts}
                          </span>
                        ) : (
                          <Button
                            size={buttonSize}
                            ref={
                              userAggregate.user_id === currentUserId
                                ? userRef
                                : undefined
                            }
                            key={userAggregate.user_id}
                            simple
                            color="info"
                            onClick={() => {
                              regStore.resetNoActivityTimeout();
                              setShowUsersCounts(true);
                              setUserAggregate(userAggregate);
                            }}
                          >
                            {userAggregate.allGradeCounts.map(
                              gradeCount => gradeCount.paddedCount + "  "
                            )}
                          </Button>
                        )
                      ],
                      color:
                        userAggregate.user_id === currentUserId
                          ? "info"
                          : undefined
                    };
                    return data;
                  })}
                  customCellClasses={[
                    classes.rank,
                    classes.userName,
                    classes.right
                  ]}
                  customClassesForCells={[0, 1, 2]}
                  customHeadCellClasses={[
                    classes.rank,
                    classes.userName,
                    classes.rightHeader
                  ]}
                  customHeadClassesForCells={[0, 1, 2]}
                />
              </>
            )}
          </Card>
        </div>
      </>
    );
  });
};

function getPaddedCount(count, desiredLength) {
  if (desiredLength === 0) {
    return count + "";
  }
  let numberOfDigits = count.toString().length;
  if (desiredLength === numberOfDigits) {
    return count + "";
  }
  if (numberOfDigits < desiredLength) {
    return new Array(desiredLength - (count + "").length + 1).join("0") + count;
  }
  throw Error(
    "The count:" +
      count +
      " is longer than the desired length: " +
      desiredLength
  );
}

Leaderboard.propTypes = {};

export default withCachedRootStore(Leaderboard);
