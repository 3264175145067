export default class PersonalMissingStores {
  constructor(context, asyncOpCreate, overwriteIdentificationFieldsFn) {
    const rest = context.rest;

    this.createMissingStore = {
      marker_color_id: null,
      labelX: null,
      labelY: null,

      opCreateMissing: asyncOpCreate(
        payload => rest.post("missing", payload),
        "createMissingClimbs"
      ),
      createMissing: () => {
        let payload = {
          marker_color_id: this.createMissingStore.marker_color_id,
          labelX: this.createMissingStore.labelX,
          labelY: this.createMissingStore.labelY
        };

        overwriteIdentificationFieldsFn(payload);

        console.log(
          "The payload for the missing is:" + JSON.stringify(payload)
        );
        return this.createMissingStore.opCreateMissing.execute(payload);
      }
    };
  }
}
