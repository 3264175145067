import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Badge from "../Badge/Badge.js";

import styles from "../../assets/jss/material-dashboard-pro-react/components/timelineStyle.js";

const useStyles = makeStyles(styles);

export default function Timeline(props) {
  const classes = useStyles();
  const { stories, simple } = props;
  const timelineClass =
    classes.timeline +
    " " +
    cx({
      [classes.timelineSimple]: simple
    });
  return (
    <ul className={timelineClass}>
      {stories.map((story, key) => {
        if (!story) return;
        const panelClasses =
          classes.timelinePanel +
          " " +
          cx({
            [classes.timelinePanelInverted]: story.inverted || simple,
            [classes.timelineSimplePanel]: simple
          });
        const timelineBadgeClasses =
          classes.timelineBadge +
          " " +
          classes[story.badgeColor] +
          " " +
          cx({
            [classes.timelineSimpleBadge]: simple
          });
        return (
          <li className={classes.item} key={key}>
            {story.badgeIcon ? (
              <div className={timelineBadgeClasses}>
                <story.badgeIcon className={classes.badgeIcon} />
              </div>
            ) : null}
            <div className={panelClasses}>
              {story.title ? (
                <div className={classes.timelineHeading}>
                  <Badge color={story.titleColor}>{story.title}</Badge>
                </div>
              ) : null}
              <div className={classes.timelineBody}>{story.body}</div>
              {story.footerTitle ? (
                <h6 className={classes.footerTitle}>{story.footerTitle}</h6>
              ) : null}
              {story.footer ? <hr className={classes.footerLine} /> : null}
              {story.footer ? (
                <div className={classes.timelineFooter}>{story.footer}</div>
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

Timeline.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool
};
