import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

class ProfileAutoLoader extends React.Component {
  constructor(props) {
    super(props);
    this.loggedOutPaths = ["/auth/login-page", "/auth/register-page"];
  }

  render() {
    const {
      registrationPagePath,
      profileStore,
      stores,
      history,
      regStore
    } = this.props;
    const { auth, checkLoggedIn } = stores;
    console.log("Rendering the auto Loader..");

    //If this client thinks it is not logged in then trust it
    if (!auth.isAuthenticated) {
      console.log("Not isAuthenticated. About to checkLoggedIn");
      if (!checkLoggedIn.isChecking && !checkLoggedIn.hasChecked) {
        checkLoggedIn.checkNow();

        //The check now will redirect to login if needed
        return null;
      }
      const { pathname } = window.location;
      console.log("The pathname " + pathname);

      let isAllowed = false;
      this.loggedOutPaths.forEach(path => {
        if (pathname.includes(path)) {
          isAllowed = true;
        }
      });

      if (!isAllowed) {
        console.log("INSIDE the component did mount - redirecting to loginyyy");
        //history.push(registrationPagePath);
        return null;
      } else {
        return null;
      }
    }

    // let callBackAutoLogin = () => {
    //   if (window.location.hostname === "localhost") {
    //     if (!regStore.isLoggedIn) {
    //       let selectedRegistration = {
    //         user_id: 4,
    //         gym_id: 1,
    //         userName: "Da Boss",
    //         passcode: "12312",
    //         id: 1
    //       };
    //       let newCode = "12312";

    //       regStore.loginRegistration(selectedRegistration, newCode, () => {
    //         debugger;
    //         alert("The auto login failed");
    //       });
    //     }
    //   }
    // };
    //If the profile has not been loaded (either succesfully or otherwise)
    if (profileStore.opCacheItems.executed !== true) {
      //If it is not loading then start the cache
      if (profileStore.opCacheItems.executing !== true) {
        console.log("INSIDE the comopnent did mount - caching profile");
        profileStore.cacheItems({ forceReload: true } /* ,callBackAutoLogin */);
      } else {
        console.log("The profile is executing now so not restarting cache");
      }
      return null;
    }

    if (!regStore.userName) {
      console.log("There is no username. pushing to createUserName");
      history.push("/blank/createUsername");
    }
    //Else the user is logged in but there is no gym so go to the createGymWizard
    console.log("INSIDE the comopnent did mount - Defaulting to nowhere");
    return null;
  }
}

ProfileAutoLoader.propTypes = {
  registrationPagePath: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  profileStore: PropTypes.object.isRequired,
  regStore: PropTypes.object.isRequired,
  stores: PropTypes.object.isRequired
};

export default observer(ProfileAutoLoader);
