import React from "react";
import PropTypes from "prop-types";

import Button from "../CustomButtons/Button.js";
import withStyles from "@material-ui/core/styles/withStyles";

import { coreStyle, withRootStore } from "ticker-core";

let style = {
  buttonIcon: coreStyle.buttonIcon
};

import Edit from "@material-ui/icons/Edit";

function EditClimbButton({ rootStore, climbingRoute, classes }) {
  let editClimb = () => {
    let {
      SVGStore,
      selectionStore,
      personalRootStore: { inspirationStore }
    } = rootStore;
    selectionStore.currentSelectedId = climbingRoute.id;
    selectionStore.currentSelected = climbingRoute;

    inspirationStore.clearSelection();

    SVGStore.panToCoordinates = {
      x: climbingRoute.labelX,
      y: climbingRoute.labelY
    };
    // if (SVGStore.value.viewerWidth) {
    //   SVGStore.value.e =
    //     -climbingRoute.labelX * SVGStore.value.a +
    //     SVGStore.value.viewerWidth / 2;

    //   SVGStore.value.f =
    //     -climbingRoute.labelY * SVGStore.value.a +
    //     SVGStore.value.viewerHeight / 2;
    // } else {
    //   SVGStore.value.e = -climbingRoute.labelX * SVGStore.value.a;

    //   SVGStore.value.f = -climbingRoute.labelY * SVGStore.value.a;
    // }
    // SVGStore.value = zoom(
    //   SVGStore.value,
    //   climbingRoute.labelX,
    //   climbingRoute.labelY,
    //   3
    // );
    rootStore.history.push("/admin/settings/view-climbingRoute/");
  };

  return (
    <Button
      justIcon
      round
      disabled={climbingRoute.isArchived}
      size="sm"
      color="info"
      className={classes.marginRight}
      onClick={editClimb}
    >
      <Edit className={classes.icons} />
    </Button>
  );
}

EditClimbButton.propTypes = {
  classes: PropTypes.object.isRequired,
  climbingRoute: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default withRootStore(withStyles(style)(EditClimbButton));
