import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/mainStyle.js";

import { withRootStore } from "ticker-core";

const useStyles = makeStyles(styles);

export default withRootStore(function Pages(props) {
  const { rootStore } = props;
  // ref for the wrapper div
  const mainPanel = React.createRef();
  // styles
  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [rootStore.history.location.pathname]);

  const getRoutes = routes => {
    return routes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.views);
      }
      if (route.layout === "/blank") {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  // Could use react router's match object instead of windo.location.href
  // let myReg = /.*main\/(.*)/g;
  // let match = myReg.exec(window.location.href);

  // if (window.location.href && match[1]) {
  //   for (let route of routes) {
  //     if (route.path === "/registration") {
  //       debugger;
  //       for (let registrationRoute of route.views) {
  //         if (
  //           !rootStore.regStores.isRegistered() &&
  //           registrationRoute.path === match[1] &&
  //           registrationRoute.showToLoggedInRegistrationsOnly
  //         ) {
  //           return <Redirect to="/main/registration/registrationLogin" />;
  //         }
  //       }
  //       break;
  //     }
  //   }
  // }

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel} ref={mainPanel}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/blank" to="/main/map" />
        </Switch>
        {/* <Footer /> */}
      </div>
    </div>
  );
});
