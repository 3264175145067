import React, { memo } from "react";

import { PropTypes } from "prop-types";

let starScale = 0.35;

let ratingPath = [
  "M",
  -111, //placeholder
  -111, //placeholder
  "l",
  6 * starScale,
  17 * starScale,
  "h",
  18 * starScale,
  "l",
  -14 * starScale,
  11 * starScale,
  5 * starScale,
  17 * starScale,
  -15 * starScale,
  -10 * starScale,
  -15 * starScale,
  10 * starScale,
  5 * starScale,
  -17 * starScale,
  -14 * starScale,
  -11 * starScale,
  "h",
  18 * starScale,
  "z"
];

function RatingStar(props) {
  let { labelCornerX, labelCornerY, avgRating } = props;

  let RATING_X_ADJUSTMENT = 0;
  let RATING_Y_ADJUSTMENT = 6.9;
  ratingPath[1] = labelCornerX + RATING_X_ADJUSTMENT;
  ratingPath[2] = labelCornerY - RATING_Y_ADJUSTMENT;

  // If the rating is three characters long (e.g. 2.0)
  // then use these defaults
  // let ratingTextX = labelCornerX - 4;
  // let ratingTextY = labelCornerY + 4;

  // let avgRatingStr = avgRating + "";
  // // if the rating is only one character long e.g. ?
  // if (avgRatingStr && avgRatingStr.length === 1) {
  // ratingTextX = ratingTextX + 2.5;
  // }

  let ratingTextX = labelCornerX - 2.1;
  let ratingTextY = labelCornerY + 5;

  return (
    <>
      <path
        // className="star"
        fill="rgb(253, 188, 75)"
        transition="fill 0.2s ease-in-out 0s"
        d={ratingPath.join(" ")}
        vectorEffect="non-scaling-stroke"
      ></path>
      <text x={ratingTextX} y={ratingTextY} fontSize={8}>
        {avgRating}
      </text>
    </>
  );
}

RatingStar.propTypes = {
  labelCornerX: PropTypes.number.isRequired,
  labelCornerY: PropTypes.number.isRequired,
  avgRating: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default memo(RatingStar);
