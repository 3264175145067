import { observable, action } from "mobx";

export default observable({
  enqueuedNotifications: observable([]),
  persistedNotifications: observable([]),

  enqueueNotification: action(function(message, options) {
    const newKey = new Date().getTime() + Math.random();
    this.enqueuedNotifications.push({
      key: newKey,
      message,
      options
    });

    return newKey;
  }),

  /*
  The only call to this function is commented out in Notifiers
  removeNotification: action(function(notification) {
    this.enqueuedNotifications.remove(notification);
  }),
*/

  removePersistedNotification: action(function(key) {
    for (let i = 0; i < this.persistedNotifications.length; i++) {
      if (this.persistedNotifications[i].key === key) {
        this.persistedNotifications[i].pleaseRemoveNow = true;
        return;
      }
    }
    //couldn't find the notification???
    debugger;
  }),

  /*
   * The following two functions are only called by the Notfier Component.
   * It will pop the enqueuedNotifications off and pass them to notistack.
   * Then it will remove the notification from the enqueued list to the
   * persisted list.
   */
  notifier_dequeueAndStoreNotification: action(function(notification) {
    this.enqueuedNotifications.remove(notification);
    this.persistedNotifications.push(notification);
  }),

  notifier_clearPersistedNotification: action(function(notification) {
    this.persistedNotifications.remove(notification);
  })
});
