import React from "react";
import { useObserver } from "mobx-react";

// core components
//import Slider from "@material-ui/core/Slider";

import { AirBnBSlider } from "ticker-components";

import { withCachedRootStore } from "ticker-core";

let getVisibleGradeIndex = (visibleGrades, orderPositionOfSelection) => {
  // But if a grade has been selected already find it and use it's index
  if (orderPositionOfSelection !== "undefined") {
    for (let i = 0; i < visibleGrades.length; i++) {
      if (visibleGrades[i].orderPosition === orderPositionOfSelection) {
        return i;
      }
    }
  }
  //default to 0
  return 0;
};

const GradeSlider = function GradeSlider(props) {
  let {
    rootStore,
    fadeDelay,
    setFadeIn,
    gradeType,
    pageSelection,
    containerClass
  } = props;

  let {
    gradeStores: { gradeStores }
  } = rootStore;

  let { UISelection, selection } = pageSelection;

  let {
    regStores: { regStore }
  } = rootStore;

  // This effect loads in the users personal defaults for the sliders
  // The auto run is used to force mobx to update straight away
  // It would be ideal to put the useEffect instide the useObserver
  // But that isn't allowed
  // useEffect(
  //   () =>
  //     autorun(reaction => {
  //       console.log("Autorunning");
  //       let cache;
  //       if (personalRootStore.personalDataExists()) {
  //         console.log("There is personal data;");
  //         cache = computedPersonalCacheStore.computedPersonalCache.get();
  //       }

  //       gradeTypes.ALL.forEach(gradeType => {
  //         let unarchivedGrades = gradeStores[gradeType].getUnarchivedItems();

  //         debugger;
  //         //
  //         // If there is no high end position of the slide selected
  //         //
  //         if (
  //           typeof selection.orderPositionOfGradeEnds[gradeType] === "undefined"
  //         ) {
  //           // then we must use either the users personal highest route
  //           if (
  //             personalRootStore.personalDataExists() &&
  //             typeof cache.maxGrades[gradeType] !== "undefined"
  //           ) {
  //             for (let i = 0; i < unarchivedGrades.length; i++) {
  //               if (unarchivedGrades[i] === cache.maxGrades[gradeType]) {
  //                 selection.orderPositionOfGradeEnds[gradeType] =
  //                   unarchivedGrades[i].orderPosition;
  //               }
  //             }

  //             // selection.orderPositionOfGradeEnds[gradeType] =
  //             //   cache.maxGrade[gradeType].orderPosition;
  //           }
  //           // or else we must use the maximum grade
  //           else {
  //             selection.orderPositionOfGradeEnds[gradeType] =
  //               unarchivedGrades[unarchivedGrades.length - 1].orderPosition;
  //           }
  //         }

  //         //
  //         // If there is no low end position of the slide set
  //         //
  //         if (
  //           typeof selection.orderPositionOfGradeStarts[gradeType] ===
  //           "undefined"
  //         ) {
  //           // If there is personalData and the user has a minGrade
  //           // then use that as the start position
  //           if (
  //             personalRootStore.personalDataExists() &&
  //             typeof cache.minGrades[gradeType] !== "undefined"
  //           ) {
  //             // We need to find the grade's position in the visibleArray
  //             for (let i = 0; i < unarchivedGrades.length; i++) {
  //               if (unarchivedGrades[i] === cache.minGrades[gradeType]) {
  //                 selection.orderPositionOfGradeStarts[gradeType] =
  //                   unarchivedGrades[i].orderPosition;
  //               }
  //             }
  //             // selection.orderPositionOfGradeStarts[gradeType] =
  //             //   cache.minGrade[gradeType].orderPosition;
  //           }
  //           // or else we must use the minimum
  //           else {
  //             selection.orderPositionOfGradeStarts[gradeType] = 0;
  //           }
  //         }
  //       });
  //       console.log(
  //         "GRADE SLIDER STARTS IN AUTORUN: " +
  //           JSON.stringify(selection.orderPositionOfGradeStarts.slice())
  //       );
  //       setOrderPositionOfStarts(selection.orderPositionOfGradeStarts.slice());
  //       setOrderPositionOfEnds(selection.orderPositionOfGradeEnds.slice());
  //       console.log("Disposing of the autorun");
  //       reaction.dispose();
  //     }),
  //   []
  // );

  return useObserver(() => {
    let visibleGradesForUI = gradeStores[gradeType].getUnarchivedItems();

    let getSliderLabelText = index => {
      if (index < 0) {
        return "negInd" + index;
      }
      return visibleGradesForUI[index].primaryLabel;
    };

    let onChange = (e, newIndexes) => {
      //Set the local state so that the slider changes immediately
      let tempStarts = UISelection.orderPositionOfGradeStarts.slice();
      tempStarts[gradeType] = visibleGradesForUI[newIndexes[0]].orderPosition;
      UISelection.orderPositionOfGradeStarts = tempStarts;

      let tempEnds = UISelection.orderPositionOfGradeEnds.slice();
      tempEnds[gradeType] = visibleGradesForUI[newIndexes[1]].orderPosition;
      UISelection.orderPositionOfGradeEnds = tempEnds;

      regStore.resetNoActivityTimeout();
    };

    let onChangeCommitted = (e, newIndexes) => {
      setFadeIn(false);

      //Set the store state after the fadeout has happened
      setTimeout(() => {
        setFadeIn(true);
        let visibleGradesForFilter = gradeStores[
          gradeType
        ].getUnarchivedItems();

        selection.orderPositionOfGradeStarts[gradeType] =
          visibleGradesForFilter[newIndexes[0]].orderPosition;
        selection.orderPositionOfGradeEnds[gradeType] =
          visibleGradesForFilter[newIndexes[1]].orderPosition;
        console.log("The newValue is:" + JSON.stringify(newIndexes));
      }, fadeDelay);
    };

    let sliderStart =
      typeof UISelection.orderPositionOfGradeStarts[gradeType] === "undefined"
        ? 0
        : getVisibleGradeIndex(
            visibleGradesForUI,
            UISelection.orderPositionOfGradeStarts[gradeType]
          );
    let sliderEnd =
      typeof UISelection.orderPositionOfGradeEnds[gradeType] === "undefined"
        ? visibleGradesForUI.length - 1
        : getVisibleGradeIndex(
            visibleGradesForUI,
            UISelection.orderPositionOfGradeEnds[gradeType]
          );

    // There was a weird bug here where if I passed in null or undefined values on
    // the first render, subsequent renders were not made well and the slider didn't
    // work properly
    let sliderValues = [sliderStart, sliderEnd];

    // let gradeMarks = [
    //   {
    //     value: 0,
    //     label: visibleGradesForUI[0].primaryLabel
    //   }
    // ];

    // if (showPersonalMarks) {
    //   if (cache.minGrades && cache.minGrades[gradeType]) {
    //     let minGradeIndex = getVisibleGradeIndex(
    //       visibleGradesForUI,
    //       cache.minGrades[gradeType].orderPosition
    //     );
    //     gradeMarks.push({
    //       value: minGradeIndex,
    //       label: cache.minGrades[gradeType].primaryLabel
    //     });

    //     let maxGradeIndex = getVisibleGradeIndex(
    //       visibleGradesForUI,
    //       cache.maxGrades[gradeType].orderPosition
    //     );

    //     gradeMarks.push({
    //       value: maxGradeIndex,
    //       label: cache.maxGrades[gradeType].primaryLabel
    //     });
    //   } else {
    //     let halfwayIndex = Math.floor(visibleGradesForUI.length / 2);
    //     gradeMarks.push({
    //       value: halfwayIndex,
    //       label: visibleGradesForUI[halfwayIndex].primaryLabel
    //     });
    //   }
    // }
    // gradeMarks.push({
    //   value: visibleGradesForUI.length - 1,
    //   label: visibleGradesForUI[visibleGradesForUI.length - 1].primaryLabel
    // });

    return (
      <div className={containerClass}>
        <AirBnBSlider
          value={sliderValues}
          valueLabelFormat={getSliderLabelText}
          step={1}
          // marks={gradeMarks}
          max={visibleGradesForUI.length - 1}
          min={0}
          valueLabelDisplay="on"
          onChangeCommitted={onChangeCommitted}
          onChange={onChange}
        />
      </div>
    );
  });
};

GradeSlider.propTypes = {};

export default withCachedRootStore(GradeSlider);
