import React from "react";
import { useObserver } from "mobx-react";

import PropTypes from "prop-types";

import { GridContainer, GridItem } from "ticker-components";

import { withRootStore } from "ticker-core";

import { makeStyles } from "@material-ui/styles";

let routeFilterStyle = {
  centered: {
    textAlign: "center"
  }
};

let useRouteFilterStyles = makeStyles(routeFilterStyle);

let RouteFilter = props => {
  let { label, showSlider, prelabel } = props;

  return useObserver(() => {
    let classes = useRouteFilterStyles();
    return (
      <>
        <GridContainer>
          <GridItem xs={2}>{prelabel}</GridItem>
          <GridItem xs={8}>
            <div className={classes.centered}>{label}</div>
          </GridItem>
        </GridContainer>
        {showSlider ? props.children : <div style={{ minHeight: "5em" }}></div>}
      </>
    );
  });
};

RouteFilter.propTypes = {
  showSlider: PropTypes.bool,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default withRootStore(RouteFilter);
