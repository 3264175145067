import "moment-timezone";

import PersonalRatingStores from "./PersonalRatingStores.js";
import PersonalRegradeStores from "./PersonalRegradeStores.js";
import PersonalLogEntryStores from "./PersonalLogEntryStores.js";

import Cacher from "../../utils/Cacher";
import ComputedPersonalCacheStore from "./ComputedPersonalCacheStore.js";
import FilterAnalysisStore from "./FilterAnalysisStore";
import InspirationStore from "./InspirationStore.js";
import getInspirationUIOptions from "./InspirationUIOptions.js";
import OrganisationStore from "./OrganisationStore.js";
import PersonalReportedIssuesStores from "./PersonalReportedIssuesStores.js";
import PersonalMissingStores from "./PersonalMissingStores.js";
import PersonalProfileStores from "./PersonalProfileStores.js";

export default class PersonalRootStore {
  constructor(
    context,
    asyncOpCreate,
    regStores,
    computedCacheStore,
    climbSelectionStore,
    gradeStores,
    profileStore,
    isWholeCacheLoaded,
    getCurrentGymId,
    httpCodeHandlerFactory,
    rootStore
  ) {
    let { regStore } = regStores;

    this.getCurrentUserId = () => {
      if (this.regStore.isLoggedIn) {
        return regStore.user_id;
      }
      return profileStore.user_id;
    };

    let overwriteIdentificationFieldsFn = (payload, options) => {
      if (!options || !options.excludeGym) {
        payload.gym_id = getCurrentGymId();
      }
      payload.user_id = this.getCurrentUserId();
      payload.passcode = regStore.passcode;
      return payload;
    };

    this.overwriteIdentificationFieldsFn = overwriteIdentificationFieldsFn;

    this.regStore = regStore;

    this.personalReportedIssuesStores = new PersonalReportedIssuesStores(
      context,
      asyncOpCreate,
      overwriteIdentificationFieldsFn
    );

    this.personalRatingStores = new PersonalRatingStores(
      context,
      asyncOpCreate,
      regStore,
      getCurrentGymId,
      this.getCurrentUserId
    );

    this.personalRegradeStores = new PersonalRegradeStores(
      context,
      asyncOpCreate,
      regStore,
      gradeStores,
      overwriteIdentificationFieldsFn
    );

    this.personalProfileStores = new PersonalProfileStores(
      context,
      asyncOpCreate,
      overwriteIdentificationFieldsFn
    );

    this.personalLogEntryStores = new PersonalLogEntryStores(
      context,
      asyncOpCreate,
      regStore,
      this, //potential bug? passing "this" in before it is constructed?,
      getCurrentGymId,
      this.getCurrentUserId
    );

    this.computedPersonalCacheStore = new ComputedPersonalCacheStore(
      computedCacheStore,
      this.personalLogEntryStores.cachedLogCountStore,
      this.personalRatingStores.cachedRatings,
      this.personalRegradeStores.cachedRegrades
    );

    this.filterAnalysisStore = new FilterAnalysisStore(
      climbSelectionStore,
      this.computedPersonalCacheStore,
      isWholeCacheLoaded
    );

    this.organisationStores = new OrganisationStore(
      context,
      asyncOpCreate,
      regStore,
      gradeStores,
      overwriteIdentificationFieldsFn,
      httpCodeHandlerFactory
    );

    this.personalMissingStores = new PersonalMissingStores(
      context,
      asyncOpCreate,
      overwriteIdentificationFieldsFn
    );

    this.personalDataExists = () => {
      return regStore.isLoggedIn || rootStore.isClimberSite;
    };

    this.getCacheWithPersonalDataIfPossible = () => {
      let cache;
      if (this.personalDataExists()) {
        cache = this.computedPersonalCacheStore.computedPersonalCache.get();
      } else {
        cache = computedCacheStore.computedCache.get();
      }
      return cache;
    };

    this.inspirationStore = new InspirationStore(
      this,
      isWholeCacheLoaded,
      computedCacheStore,
      regStore
    );

    this.inspirationUIOptions = getInspirationUIOptions(
      this.inspirationStore,
      this.regStore
    );

    const cachables = [];
    cachables.push({ store: this.personalRatingStores.cachedRatings });
    cachables.push({ store: this.personalRegradeStores.cachedRegrades });
    cachables.push({ store: this.personalLogEntryStores.cachedLogCountStore });
    cachables.push({ store: this.organisationStores.cachedOrganisations });

    this.cacher = new Cacher(cachables, "personalCache");

    this.clearData = () => {
      this.personalLogEntryStores.clearSelectionAndCache();
      this.inspirationStore.clearSelectionAndCache();
      this.cacher.clearCache();
    };

    this.init = () => {
      this.personalLogEntryStores.clearSelectionAndCache();
      this.inspirationStore.clearSelectionAndCache();
      this.loadCache();
    };

    this.loadCache = () => {
      if (
        typeof this.getCurrentUserId() === "undefined" ||
        this.getCurrentUserId() === null
      ) {
        let err =
          "The parameters for loading the cache are missing. Particularly the user_id. Aborting the caching.";
        console.log(err);
        debugger;
        throw err;
      }

      let params = {
        forceReload: true
      };
      this.cacher.loadCache(params);
    };

    this.clearCache = () => {
      return this.cacher.clearCache();
    };

    this.isCacheLoading = () => {
      return this.cacher.isCacheLoading();
    };

    this.isCacheLoaded = () => {
      console.log("Checking the cache for personalRootStore");
      return this.cacher.isCacheLoaded();
    };

    this.clearPersonalData = () => {
      this.inspirationStore.clearPersonalData();
    };
    this.postCache = () => {
      this.inspirationStore.postCache();
    };
  }
}
