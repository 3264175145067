import { computed } from "mobx";
import { gradeTypes, planTypes } from "../../variables/constants";
import {
  getPlanningQuantityMap,
  getMostRecentRouteSettingDateTimesByGrade,
  basedOnLongevityRank
} from "../../utils/ReportStoreUtility";

import moment from "moment";
import GeneratePlan from "../../utils/GeneratePlan";

import getOrderedLongevityAssemblies from "./getOrderedLongevityAssembliesFn";

import {
  addLongevityForSettingPlan,
  getLongevityObject,
  addToLongevityObjectBasedOnCurrentConditions,
  getOldestAdjustedDate,
  getOldestDateInDays,
  getAcceptedWallsWithPlanAssembliesAdded
} from "../../utils/longevityFns";

export default class ComputedStrippingReportStore {
  constructor(context, rootStore) {
    // These are accessed via id

    this.getLongevityAssembliesForBoulders = computed(
      () => {
        console.log("COMPUTING getLongevityForBoulders");

        return this.getLongevityAssemblies(gradeTypes.BOULDER, true);
      },
      { keepAlive: true }
    );

    this.getLongevityAssembliesForRoutes = computed(
      () => {
        console.log("COMPUTING getLongevityForRoutes");

        return this.getLongevityAssemblies(gradeTypes.ROUTE, false);
      },
      { keepAlive: true }
    );

    this.getSerialLongevityForBoulders = computed(
      () => {
        console.log("COMPUTING getLongevityForBoulders");

        return this.getSerialLongevity(gradeTypes.BOULDER, true);
      },
      { keepAlive: true }
    );

    this.getSerialLongevityForRoutes = computed(
      () => {
        console.log("COMPUTING getLongevityForRoutes");

        return this.getSerialLongevity(gradeTypes.ROUTE, false);
      },
      { keepAlive: true }
    );

    this.getLongevityAssemblies = (gradeType, allowDuplicates) => {
      const { computedCacheStore, computedSettingReportStore } = rootStore;

      const planningAssemblies = computedSettingReportStore.settingPlansWithWallAssemblies.get();

      const planAssemblies = planningAssemblies.planAssemblies[gradeType];

      const gradeCounts = computedCacheStore.getComputedGradeCounts(gradeType);

      const planningQuantityMap = getPlanningQuantityMap(
        gradeCounts,
        null,
        planAssemblies
      );

      let now = moment();

      let retVal = {
        parallelLongevityOrderedClimbAssemblies: undefined,
        ageOrderedLongevityClimbAssemblies: [],
        sectionGroupedLongevityClimbAssemblies: []
      };

      retVal.parallelLongevityOrderedClimbAssemblies = getOrderedLongevityAssemblies(
        rootStore,
        now,
        planAssemblies,
        gradeType,
        allowDuplicates,
        planningQuantityMap,
        true
      );

      for (let longevityClimbAssembly of retVal.parallelLongevityOrderedClimbAssemblies) {
        retVal.ageOrderedLongevityClimbAssemblies.push(longevityClimbAssembly);

        //This next code block builds up the arrays that point from section to wall to climb assemblies.
        let sectionId =
          longevityClimbAssembly.climbAssembly.sectionAssembly.climbingSection
            .id;
        let wallId =
          longevityClimbAssembly.climbAssembly.wallAssembly.climbingWall.id;
        if (
          typeof retVal.sectionGroupedLongevityClimbAssemblies[sectionId] ===
          "undefined"
        ) {
          retVal.sectionGroupedLongevityClimbAssemblies[sectionId] = [];
        }

        if (
          typeof retVal.sectionGroupedLongevityClimbAssemblies[sectionId][
            wallId
          ] === "undefined"
        ) {
          retVal.sectionGroupedLongevityClimbAssemblies[sectionId][wallId] = [];
        }
        retVal.sectionGroupedLongevityClimbAssemblies[sectionId][wallId][
          longevityClimbAssembly.climbAssembly.climbingRoute.id
        ] = longevityClimbAssembly;
      }

      retVal.ageOrderedLongevityClimbAssemblies.sort(basedOnSettingDate);

      return retVal;
    };

    this.getSerialLongevity = (gradeType, allowDuplicates) => {
      const {
        computedCacheStore,
        computedSettingReportStore,
        gradeStores: { gradeStores },
        gymStores: { gymStore }
      } = rootStore;

      const planningAssemblies = computedSettingReportStore.settingPlansWithWallAssemblies.get();

      // I'm copying the array here because I've had lots of side effects with changing the computed array
      const planAssemblies = planningAssemblies.planAssemblies[gradeType].slice(
        0
      );

      const gradeCounts = computedCacheStore.getComputedGradeCounts(gradeType);

      console.log("gradeCounts=", gradeCounts);
      let now = moment();

      let topLongevityOrderedClimbAssembliesToBeStripped = [];
      let retVal_longevities = [];

      let SERIAL_QUANTITY = 18;

      let planningQuantityMap = getPlanningQuantityMap(
        gradeCounts,
        null,
        planAssemblies
      );

      for (let x = 0; x < SERIAL_QUANTITY; x++) {
        let longevityOrderedClimbAssemblies = getOrderedLongevityAssemblies(
          rootStore,
          now,
          planAssemblies,
          gradeType,
          allowDuplicates,
          planningQuantityMap,
          false //x === 0
        );

        // There is a bug where the boulder or route setting page is reloaded
        // some race condition happens probably because something hasn't finished caching.
        // This bug means that there are no longevityOrderedClimbAssemblies
        // In that case, just give up for now
        // Search for this text to find a related if statement: 'gsgsddgsdgg23'
        if (longevityOrderedClimbAssemblies.length === 0) {
          break;
        }

        let bestLongevityClimbAssembly = longevityOrderedClimbAssemblies[0];

        // Add the climb with the highest prority to the serial list
        topLongevityOrderedClimbAssembliesToBeStripped.push(
          bestLongevityClimbAssembly
        );

        let climbingRoute =
          bestLongevityClimbAssembly.climbAssembly.climbingRoute;

        /*
         * Now we need to pretend like the route has been stripped so that we can get the next serially ordered climb assembly
         */

        let fakeSettingPlan = {
          climbing_wall_id: climbingRoute.climbing_wall_id,
          climbing_grade_id:
            bestLongevityClimbAssembly.proposedGradeReplacement,
          planType: bestLongevityClimbAssembly.proposedGradeReplacement
            ? planTypes.REPLACE
            : planTypes.STRIP,
          route_to_strip_id: climbingRoute.id,
          routeToStrip: climbingRoute,
          planningDate: now.utc().toISOString()
        };

        let fakeSettingPlanAssembly = {
          debugIsFake: true,
          settingPlan: fakeSettingPlan,
          planningDate: now,
          acceptedWallAssembly: bestLongevityClimbAssembly.acceptedWallAssembly,
          wallAssembly:
            bestLongevityClimbAssembly.acceptedWallAssembly.wallAssembly,
          bestClimbAssembly: bestLongevityClimbAssembly
        };

        //
        // Put the fake plan into the planAssemblies so that on the next pass through
        // of this loop the route "will be considered stripped"
        planAssemblies.push(fakeSettingPlanAssembly);

        // We need to recalculate the PQM because our quantities have changed because
        // of the fakeSettingPlan
        planningQuantityMap = getPlanningQuantityMap(
          gradeCounts,
          null,
          planAssemblies
        );
        bestLongevityClimbAssembly.acceptedWallAssembly.debugHasFakePlan = true;
      } // End of SERIAL_QUANTITY loop

      let climbAssembliesToStrip = topLongevityOrderedClimbAssembliesToBeStripped.map(
        longevityAssembly => longevityAssembly.climbAssembly
      );

      // Now we get the list of settingSuggestions based on stripping all the topLongevityOrderedClimbAssembliesToBeStripped
      // These settingPlans will be REPLACE plans where possible or STRIP plans if no suggestions were found
      let newTasks = GeneratePlan.generateSettingPlans(
        rootStore,
        climbAssembliesToStrip.length, //numberToSet,
        climbAssembliesToStrip,
        gradeType,
        false //allowSETPlans
      );

      let computedCache = computedCacheStore.computedCache.get();

      let oldestAdjustedDateOfClimbInAllWalls = getOldestAdjustedDate(
        computedCache,
        gradeType,
        now
      );
      let oldestRouteAgeInDays = getOldestDateInDays(
        now,
        oldestAdjustedDateOfClimbInAllWalls
      );

      /*
       * Now we must undo all the changes that we made to quantities and plans
       */

      for (let i = 0; i < SERIAL_QUANTITY; i++) {
        let fakePlanAssembly = planAssemblies.pop();

        // There is a bug. Search for the text 'gsgsddgsdgg23' to see the related comment
        if (!fakePlanAssembly) {
          break;
        }
        // planningQuantityMap[fakePlanAssembly.settingPlan.climbing_grade_id]
        //   .actualQuantity++;

        // What does this line do??? It seems unuseful. 06/05/20
        fakePlanAssembly.acceptedWallAssembly.acceptedClimbAssemblies.push(
          fakePlanAssembly.bestClimbAssembly
        );
      }

      let wallMostRecentsettingDateTimeRange =
        now - oldestAdjustedDateOfClimbInAllWalls;

      let initialNumbersOfClimbsIncludingOtherPlans;
      // For each of the climbing Routes find it's matching settingPlan if it exists
      for (let oldLongevityAssembly of topLongevityOrderedClimbAssembliesToBeStripped) {
        //
        // We need a fresh quantity map to work from
        planningQuantityMap = getPlanningQuantityMap(
          gradeCounts,
          null,
          planAssemblies
        );

        let mostRecentRoutesettingDateTimesByGrade = getMostRecentRouteSettingDateTimesByGrade(
          computedCache.allClimbingAssemblies,
          now,
          planAssemblies
        );

        let acceptedWallAssemblies = getAcceptedWallsWithPlanAssembliesAdded(
          computedCache,
          gradeType,
          now,
          planAssemblies,
          gradeStores,
          true //includeOnHoldRoutes
        );

        if (!initialNumbersOfClimbsIncludingOtherPlans) {
          initialNumbersOfClimbsIncludingOtherPlans = {};
          acceptedWallAssemblies.forEach(awa => {
            // if (awa.wallAssembly.climbingWall.name === "Bones") {
            //   debugger;
            // }
            initialNumbersOfClimbsIncludingOtherPlans[
              awa.wallAssembly.climbingWall.id
            ] = awa.acceptedClimbAssemblies.length;
          });
        }

        let acceptedWallAssembly;
        for (let awa of acceptedWallAssemblies) {
          if (
            awa.wallAssembly.climbingWall.id ===
            oldLongevityAssembly.climbAssembly.climbingRoute.climbing_wall_id
          ) {
            acceptedWallAssembly = awa;
          }
        }
        if (!acceptedWallAssembly) {
          console.error("The wall doesn't have an accepted wallAssembly???");
          debugger;
        }

        // Create a new longevity object that will have the values based on the climbToStrip from oldLongevityAssembly
        // And it will also have the values based on the climbToSet from the generated plans (in the code further down)
        let longevityBreakdown = getLongevityObject(
          oldLongevityAssembly.climbAssembly,
          gymStore,
          oldestRouteAgeInDays
        );

        addToLongevityObjectBasedOnCurrentConditions(
          oldLongevityAssembly.climbAssembly,
          acceptedWallAssembly.mostRecentOfWall,
          wallMostRecentsettingDateTimeRange,
          oldestAdjustedDateOfClimbInAllWalls,
          gymStore,
          allowDuplicates,
          now,
          acceptedWallAssembly.acceptedClimbAssemblies,
          mostRecentRoutesettingDateTimesByGrade,
          planningQuantityMap,
          oldestRouteAgeInDays,
          longevityBreakdown
        );

        let newLongevityAssembly = {
          climbAssembly: oldLongevityAssembly.climbAssembly,
          longevityBreakdown: longevityBreakdown,
          acceptedWallAssembly
        };

        // If the wall is too full then we wont need a settingPlan
        // let wallIsTooFull =
        //   newLongevityAssembly.acceptedWallAssembly
        //     .numberOfClimbsIncludingPlans >
        //   newLongevityAssembly.climbAssembly.wallAssembly.climbingWall
        //     .slotConfigsJSON.length;

        // if (!wallIsTooFull) {
        let matchedTask = newTasks.find(
          settingPlan =>
            settingPlan.route_to_strip_id ===
            newLongevityAssembly.climbAssembly.climbingRoute.id
        );
        if (!matchedTask) {
          console.error(
            "Is this an error? should there always be a matching settingPlan?"
          );
          // addLongevity(newLongevityAssembly.longevityBreakdown, {
          //   desc:
          //     "No route can be set because 1) There are too many of this grade, but only this grade can be put back up 2) There is not enough space on the wall 3) Nothing will be able to be put up after taking this down because of the wall configurations.",
          //   amount: Math.floor(NO_PLAN_PENALTY * 100),
          //   days: Math.floor(oldestRouteAgeInDays * NO_PLAN_PENALTY)
          // });
        } else {
          newLongevityAssembly.numberOfClimbsIncludingPlans =
            acceptedWallAssembly.acceptedClimbAssemblies.length;

          addLongevityForSettingPlan(
            newLongevityAssembly.longevityBreakdown,
            matchedTask,
            gradeStores[gradeType],
            planningQuantityMap,
            oldestRouteAgeInDays
          );
          newLongevityAssembly.settingPlan = matchedTask;
          retVal_longevities.push(newLongevityAssembly);

          //
          // Now we need to update all the quantities as if the settingPlan had been completed
          //
          let fakeSettingPlanAssembly = {
            debugIsFakeSecondRound: true,
            settingPlan: matchedTask,
            planningDate: now,
            acceptedWallAssembly: acceptedWallAssembly,
            wallAssembly: acceptedWallAssembly.wallAssembly
            //bestClimbAssembly: bestLongevityClimbAssembly
          };
          planAssemblies.push(fakeSettingPlanAssembly);
        }
      }
      // }

      // We must remove the added fake settingPlans
      planAssemblies.splice(
        -topLongevityOrderedClimbAssembliesToBeStripped.length
      );

      // printAssembly(retVal_longevities);

      // Resort the list now that we have added the setting plan
      retVal_longevities.sort(basedOnLongevityRank);
      // printAssembly(retVal_longevities);

      retVal_longevities.forEach(longevityAssembly => {
        if (longevityAssembly.settingPlan.planType === planTypes.STRIP) {
          if (
            typeof longevityAssembly.numberOfClimbsIncludingPlans ===
            "undefined"
          ) {
            debugger;
          }
          initialNumbersOfClimbsIncludingOtherPlans[
            longevityAssembly.acceptedWallAssembly.wallAssembly.climbingWall.id
          ]--;
          console.log(
            "The plan is a strip plan. For wall:" +
              longevityAssembly.acceptedWallAssembly.wallAssembly.climbingWall
                .name
          );
        }
        longevityAssembly.numberOfClimbsIncludingPlans =
          initialNumbersOfClimbsIncludingOtherPlans[
            longevityAssembly.acceptedWallAssembly.wallAssembly.climbingWall.id
          ];
      });
      return retVal_longevities;
    }; // end getSerialLongevity()
  }
}

function basedOnSettingDate(assemblyA, assemblyB) {
  if (
    assemblyA.climbAssembly.climbingRoute.settingDateTime >
    assemblyB.climbAssembly.climbingRoute.settingDateTime
  )
    return 1;
  if (
    assemblyA.climbAssembly.climbingRoute.settingDateTime <
    assemblyB.climbAssembly.climbingRoute.settingDateTime
  )
    return -1;
  return 0;
}
