import React from "react";

import PropTypes from "prop-types";

import { withCachedRootStore, coords } from "ticker-core";
import { observer } from "mobx-react";

import SVGLabelsMap from "./SVGLabelsMap";

function SVGSectionMap(props) {
  let { rootStore, longPressIsDisabled } = props;

  const {
    sectionStores: { sectionStore },
    gymStore,
    selectionStore,
    wallStores: { wallStore }
  } = rootStore;

  // Becareful. The draggableStore has a function getArchived() but it doesn't trigger mobx
  let climbingSections = sectionStore.items.filter(
    item => item.isArchived !== true
  );

  let onFinish = () => {
    if (selectionStore.hasObjectMoved) {
      sectionStore.putItems();
    }
  };
  console.log("Rendering the SVGSectionMap");

  let sectionCoords = coords.getSectionCoords(
    gymStore,
    sectionStore,
    wallStore
  );

  return (
    <SVGLabelsMap
      isSections={true}
      onPointerUpFinishFn={onFinish}
      longPressIsDisabled={longPressIsDisabled}
      labelObjects={climbingSections}
      getLabelFn={section => section.name}
      newLabelText="New Section"
      coordsFactory={sectionCoords}
    />
  );
}

SVGSectionMap.propTypes = {
  rootStore: PropTypes.object.isRequired,
  longPressIsDisabled: PropTypes.bool.isRequired
};
export default withCachedRootStore(observer(SVGSectionMap));
