import { coreStyle } from "ticker-core";
const {
  container,
  whiteColor,
  cardTitle,
  getSlenderContainer,
  highTop
} = coreStyle;

const ticksPageStyle = theme => ({
  container,
  highTop,
  slenderContainer: getSlenderContainer(theme),
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + " !important"
  },
  cardSubTitleWhite: {
    color: whiteColor + " !important"
  },
  icon: {
    paddingRight: "2em",
    color: whiteColor
  },
  navPills: {
    textAlign: "center"
  }
});

export default ticksPageStyle;
