import { observable, action } from "mobx";
import papa from "papaparse";
import createGymStore from "./CreateGymStore.js";

export default class GymStores {
  constructor(context, rootStore, asyncOpCreate) {
    const rest = context.rest;

    this.rootStore = rootStore;

    this.gymStore = createGymStore(
      context,
      rootStore.userStores,
      asyncOpCreate
    );

    this.gymCsvStore = observable({
      originalCSVFile: "unset",
      csvData: null,
      csvAnalysis: null,
      receiveFile(file) {
        this.originalCSVFile = file;
        debugger;
        papa.parse(file, {
          dynamicTyping: true,
          header: true,
          delimiter: ",",
          skipEmptyLines: true,
          complete: results => {
            console.log("parsed the file");
            this.csvData = results;
            this.createAnalysis();
            debugger;
          },
          error: error => {
            console.log("Something went wrong while parsing the file");
            console.log(error);

            debugger;
          }
        });
      },
      opUploadCSV: asyncOpCreate(formData => {
        try {
          const result = rest.upload("gyms/uploadCSV", formData);
          return result;
        } catch (error) {
          console.log(
            "Inside the error of the asyncop that is created in testStore"
          );
          throw error;
        }
      }, "uploadCSV"),
      uploadCSV: action(async function() {
        const payload = new FormData();
        const file = this.myFile;
        payload.append("file", file);
        payload.append("name", file.name);
        payload.append("file-type", file.type);
        payload.append("category", "profile-picture");
        const response = this.opUploadCSV.execute(payload);

        const newResponse = await response;

        this.responseFromServer = JSON.stringify(newResponse);
      }),
      createAnalysis() {
        /*
         *
         * BROOK: This isn't finished yet.
         * The goal was to validate each column using the DB_FIELDS below.
         * But maybe it'll be easier with jsonschema.
         */

        this.csvAnalysis = { errors: [], missingItems: {} };

        if (this.csvData === null || typeof this.csvData === "undefined") {
          this.csvAnalysis = { errors: ["No CSV Data"] };
          return;
        }

        if (this.csvData.length === 0) {
          this.csvAnalysis = { errors: "There are no rows" };
          return;
        }

        this.validateDBColumn(
          rootStore.markerColorStores.markerColorStore,
          "color",
          "name"
        );

        this.validateDBColumn(
          rootStore.routeStatusStores.routeStatusStore,
          "route-status",
          "name"
        );
        this.validateDBColumn(
          rootStore.setterAliasStores.setterAliasStore,
          "setter-name",
          "alias"
        );
        this.validateDBColumn(
          rootStore.wallStores.wallStore,
          "wall-name",
          "name"
        );
        this.validateDBColumn(
          rootStore.sectionStores.sectionStore,
          "section-name",
          "name"
        );
      },
      validateDBColumn(store, csvFieldName, storeFieldName) {
        let exactCSVName = this.validateExactCSVName(csvFieldName);
        if (exactCSVName === null) {
          return;
        }

        this.csvAnalysis.missingItems[csvFieldName] = [];
        this.csvData.data.forEach(csvRouteRow => {
          let found = false;

          store.getAllItemsEvenArchived().forEach(item => {
            if (csvRouteRow[exactCSVName] === item[storeFieldName]) {
              found = true;
            }
          });

          //Copy the value over so we have the lower case name always
          if (typeof csvRouteRow[csvFieldName] === "undefined") {
            csvRouteRow[csvFieldName] = csvRouteRow[exactCSVName];
            csvRouteRow[exactCSVName] = undefined;
          }

          if (!found) {
            this.csvAnalysis.missingItems[csvFieldName].push(
              csvRouteRow[csvFieldName]
            );
          }
        });
      },
      validateExactCSVName(csvFieldName) {
        for (let field of this.csvData.meta.fields) {
          if (field.toLowerCase() === csvFieldName) {
            return field;
          }
        }
        this.csvAnalysis.errors.push(
          "There is no colum with the header '" +
            csvFieldName +
            "'. Please add it to your CSV and reload."
        );
        return null;
      }
      /*

      DB_FEILDS: [
        {
          store: rootStore.markerColorStores.markerColorStore,
          csvFieldName: "color",
          storeFieldName: "name",
          type: "DB"
        },
        {
          store: rootStore.routeStatusStores.routeStatusStore,
          csvFieldName: "route-status",
          storeFieldName: "name",
          type: "DB"
        },
        {
          store: rootStore.setterAliasStores.setterAliasStore,
          csvFieldName: "setter-name",
          storeFieldName: "alias",
          type: "DB"
        },
        {
          store: rootStore.wallStores.wallStore,
          csvFieldName: "wall-name",
          storeFieldName: "name",
          type: "DB"
        },
        {
          store: rootStore.sectionStores.sectionStore,
          csvFieldName: "section-name",
          storeFieldName: "name",
          type: "DB"
        },
        {
          csvFieldName: "date-set",
          type: "DATE"
        },
        {
          csvFieldName: "",
          type: "String"
        }
      ]*/
    });

    this.checkGymStore = {
      checkGym() {
        return "Yat";
      }
    };
  }
}
