import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";

import {
  Button,
  GridContainer,
  GridItem,
  TitledDialog
} from "ticker-components";
import { withRootStore } from "ticker-core";

function MissingDialog(props) {
  let {
    missing,
    onClose,
    isOpen,
    rootStore: {
      missingStores: { deleteMissingStore },
      notificationStore,
      markerColorStores: { markerColorStore },
      SVGStore,
      selectionStore,
      computedCacheStore
    }
  } = props;

  let computedCache = computedCacheStore.computedCache.get();

  let colorName;

  if (missing) {
    colorName = markerColorStore.getItemById(missing.marker_color_id).name;
  }

  return (
    <TitledDialog
      onClose={onClose}
      open={isOpen}
      title="A climber suggests that this route is missing?"
      dialogId="missingDialog"
    >
      <DialogContent>
        <DialogContentText>
          <GridContainer alignItems="center">
            <GridItem xs={10}>
              <h5>
                Suggested by {missing && missing["Registration.userName"]}
              </h5>
              <div>Hold Color {missing && colorName}</div>
              <h6>
                Reported on {moment(missing && missing.create_at).format("LLL")}
              </h6>
            </GridItem>
            <GridItem xs={2}>
              <p style={{ float: "right" }}>
                <Button
                  justIcon
                  color="info"
                  round
                  onClick={async () => {
                    notificationStore.enqueueNotification("Deleting", {
                      variant: "info"
                    });
                    onClose();
                    await deleteMissingStore.deleteMissing(missing);
                  }}
                >
                  <Delete />
                </Button>
              </p>
              <p style={{ float: "right" }}>
                <Button
                  justIcon
                  color="info"
                  round
                  onClick={async () => {
                    let coords = {
                      x: missing.labelX,
                      y: missing.labelY
                    };
                    notificationStore.enqueueNotification(
                      "Deleting Missing Climb",
                      {
                        variant: "info"
                      }
                    );
                    onClose();
                    deleteMissingStore.deleteMissing(missing);
                    SVGStore.panToCoordinates = coords;
                    selectionStore.createNewObjectInStore(coords);
                    selectionStore.addNearestWall(computedCache);
                    selectionStore.newClimbingObject.marker_color_id =
                      missing.marker_color_id;
                  }}
                >
                  <Add />
                </Button>
              </p>
            </GridItem>
          </GridContainer>
        </DialogContentText>
      </DialogContent>
    </TitledDialog>
  );
}

MissingDialog.propTypes = {
  missing: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default withRootStore(MissingDialog);
