import { planTypes } from "../variables/constants";

function getPlanningQuantityMap(gradeCounts, options, settingPlanAssemblies) {
  //let settingPlans =
  //  rootStore.settingPlanStores.settingPlanCacheStore.settingPlans;

  let planningQuantityMap = {};
  gradeCounts.grades.forEach((grade, gradeIndex) => {
    let newMap = {
      grade: grade,
      actualQuantity: gradeCounts.actualQuantities[gradeIndex],
      targetQuantity: gradeCounts.targetQuantities[gradeIndex]
    };
    planningQuantityMap[grade.id] = newMap;
  });

  settingPlanAssemblies.forEach(settingPlanAssembly => {
    if (
      (settingPlanAssembly.settingPlan.planType === planTypes.SET ||
        settingPlanAssembly.settingPlan.planType === planTypes.REPLACE) &&
      planningQuantityMap[settingPlanAssembly.settingPlan.climbing_grade_id]
    ) {
      planningQuantityMap[settingPlanAssembly.settingPlan.climbing_grade_id]
        .actualQuantity++;
    }

    if (!options || options.ignoreStrippingPlans !== true) {
      if (
        (settingPlanAssembly.settingPlan.planType === planTypes.REPLACE ||
          settingPlanAssembly.settingPlan.planType === planTypes.STRIP) &&
        !settingPlanAssembly.settingPlan.routeToStrip
      ) {
        console.log("BUG: There should be a settingPlan.routeToStrip???");
        debugger;
      }
      if (
        (settingPlanAssembly.settingPlan.planType === planTypes.REPLACE ||
          settingPlanAssembly.settingPlan.planType === planTypes.STRIP) &&
        planningQuantityMap[
          settingPlanAssembly.settingPlan.routeToStrip.climbing_grade_id
        ]
      ) {
        planningQuantityMap[
          settingPlanAssembly.settingPlan.routeToStrip.climbing_grade_id
        ].actualQuantity--;
      }
    }
  });
  return planningQuantityMap;
}

function addSettingPlanToAcceptedWallAssembly(
  incompleteSettingPlanAssemblies,
  acceptedWallAssembly,
  gradeStore
) {
  // Add each of the pretend routes to the list of assemblies for the wall. The pretend route will exist
  // after the current setting has been done.
  // UNLESS it hasBeenSetAlready, in which case we want to ignore it.
  let notedAsSet = [];
  for (let settingPlanAssembly of incompleteSettingPlanAssemblies) {
    // let hasBeenSetAlready = false;
    // for (let climbAssembly of acceptedWallAssembly.wallAssembly
    //   .climbingRouteAssemblies) {
    //   /*
    //    * If this route is the completion of this settingPlan then we need to know it.
    //    * But we can only use a route once to mark the completion of a settingPlan.
    //    * e.g. two settingPlans to set a grade 19 can not be "completed" by setting one grade 19.
    //    */
    //   if (
    //     climbAssembly.parsedSettingDateTime >
    //       settingPlanAssembly.settingPlan.planningDate &&
    //     climbAssembly.climbingGrade.id ===
    //       settingPlanAssembly.settingPlan.climbing_grade_id
    //   ) {
    //     let alreadyNoted = false;
    //     for (let notedId of notedAsSet) {
    //       if (notedId === climbAssembly.climbingRoute.id) {
    //         //This route has been already used to mark a different setting plan as
    //         // alreadySet. That means we can't use this route for this settingPlan
    //         alreadyNoted = true;
    //         break;
    //       }
    //     }
    //     if (!alreadyNoted) {
    //       hasBeenSetAlready = true;
    //       notedAsSet.push(climbAssembly.climbingRoute.id);
    //       break;
    //     }
    //   }
    // }

    if (
      (settingPlanAssembly.settingPlan.planType === planTypes.SET ||
        settingPlanAssembly.settingPlan.planType === planTypes.REPLACE) &&
      settingPlanAssembly.settingPlan.climbing_wall_id ===
        acceptedWallAssembly.wallAssembly.climbingWall.id
    ) {
      let pretendClimbAssembly = {
        settingPlan: settingPlanAssembly.settingPlan,
        wallAssembly: acceptedWallAssembly.wallAssembly,
        isSettingPlan: true,
        climbingGrade: gradeStore.getItemById(
          settingPlanAssembly.settingPlan.climbing_grade_id
        )
      };

      if (pretendClimbAssembly.climbingGrade === null) {
        console.log("Bug: There should be a climbingGrade???");
        debugger;
      }
      acceptedWallAssembly.acceptedClimbAssemblies.push(pretendClimbAssembly);
    }
  }
}

//putDatesIntoWallAssemblyAndCreatePretendAssemblies
function createAcceptedWallAssembly(
  wallAssembly,
  now,
  settingPlanAssemblies,
  options
) {
  let acceptedClimbAssemblies = [];
  let mostRecentOfWall = null;

  let climbAssemblies = wallAssembly.climbingRouteAssemblies;

  climbAssemblies.forEach(routeAssembly => {
    if (!routeAssembly.climbingRoute.isArchived) {
      let stillExistsAfterStripping = stillExistsAfterStrippingFn(
        routeAssembly,
        settingPlanAssemblies,
        options
      );

      if (stillExistsAfterStripping) {
        // We only want to include the routes that can be changed
        // in the acceptedClimbAssemblies
        if (
          routeAssembly.climbingRoute.isOnHold !== true ||
          (options && options.includeOnHoldRoutes)
        ) {
          acceptedClimbAssemblies.push(routeAssembly);
        }
        // The route could be empty if this is a pretendSet route. ie. a fake assembly
        // If it is a fake assembly then the date should be now.
        if (typeof routeAssembly.climbingRoute === "undefined") {
          mostRecentOfWall = now;
        } else {
          if (
            mostRecentOfWall === null ||
            mostRecentOfWall < routeAssembly.parsedSettingDateTime
          ) {
            mostRecentOfWall = routeAssembly.parsedSettingDateTime;
          }
        }
      }
    }
  });

  // Add each of the pretend routes to the list of assemblies for the wall. The pretend route will exist
  // after the current setting has been done.
  for (let settingPlanAssembly of settingPlanAssemblies) {
    if (
      (settingPlanAssembly.settingPlan.planType === planTypes.SET ||
        settingPlanAssembly.settingPlan.planType === planTypes.REPLACE) &&
      settingPlanAssembly.settingPlan.climbing_wall_id ===
        wallAssembly.climbingWall.id
    ) {
      mostRecentOfWall = now;
      break;
    }
  }

  return {
    wallAssembly,
    acceptedClimbAssemblies,
    mostRecentOfWall
  };
}

//putDatesIntoWallAssemblyAndCreatePretendAssemblies
function getMostRecentRouteSettingDateTimesByGrade(
  climbAssemblies,
  now,
  settingPlanAssemblies,
  options
) {
  let retVal = {};
  climbAssemblies.forEach(routeAssembly => {
    if (!routeAssembly.climbingRoute.isArchived) {
      let stillExistsAfterStripping = stillExistsAfterStrippingFn(
        routeAssembly,
        settingPlanAssemblies,
        options
      );

      if (stillExistsAfterStripping) {
        // The route could be empty if this is a pretendSet route. ie. a fake assembly
        // If it is a fake assembly then the date should be now.
        if (typeof routeAssembly.climbingRoute === "undefined") {
          // If the currently recorded most recent route is younger than
          // the one we are iterating over (now), then use the one we are iterating over. i.e. use now
          retVal[routeAssembly.climbingGrade.id] = now;
        } else {
          // If the currently recorded most recent route is younger than
          // the one we are iterating over, then use the one we are iterating over.
          if (
            retVal[routeAssembly.climbingGrade.id] <
              routeAssembly.parsedSettingDateTime ||
            typeof retVal[routeAssembly.climbingGrade.id] === "undefined"
          ) {
            retVal[routeAssembly.climbingGrade.id] =
              routeAssembly.parsedSettingDateTime;
          }
        }
      }
    }
  });

  return retVal;
}

function stillExistsAfterStrippingFn(
  routeAssembly,
  settingPlanAssemblies,
  options
) {
  if (!options || !options.ignoreStrippingPlans) {
    for (let settingPlanAssembly of settingPlanAssemblies) {
      // if (settingPlanAssembly.debugIsFake) {
      //   debugger;
      // }
      if (
        (settingPlanAssembly.settingPlan.planType === planTypes.REPLACE ||
          settingPlanAssembly.settingPlan.planType === planTypes.STRIP) &&
        settingPlanAssembly.settingPlan.route_to_strip_id ===
          routeAssembly.climbingRoute.id
      ) {
        return false;
      }
    }
  }
  return true;
}

/*
	 * Returns the stripping urgency.
	 * The number will be between about -7 and about 5 (resonably)
	 * E.g. desired = 99, count = 1, returns -6.88
	 *      desired = 10, count = 1, returns -5.859
	 *      desired = 5, count = 10, returns 3
	 * 	    desired = 1, count = 10, returns 99
	 
function calculateStrippingUrgency(
  targetQuantity,
  actualQuantity,
  fractionalInfluence
) {
  if (targetQuantity === 0) {
    // there aren't suppose to be any of this route so give it a high adjustment
    return 2;
  }

  if (actualQuantity === 0) {
    console.log(
      "The actualQuantity is zero. Why is this route available to be stripped?"
    );
    debugger;
  }
  if (!fractionalInfluence) {
    fractionalInfluence = 1;
  }

  let proportion = actualQuantity / targetQuantity;

  let shouldBeNegative = false;
  if (proportion <= 1) {
    shouldBeNegative = true;
  }

  // e.g. 1/10 0.1 gets changed to 0.9
  // or 12/12 1 gets changed to 0
  // or 14/10 1.4 gets changed to 0.4
  // or 10/5 2 gets changed to 1
  // or 10/1 10 gets changed to 9
  let proportionShifted = Math.abs(1 - proportion);

  // e.g. 0.9 gets changed to 1.9
  // 9 gets changed to 10
  let enlargedShortageProportion = proportionShifted + 1;

  //Change the influence to a fraction
  // e.g. 5/3 = 1.66 *2 = 2.32
  // or 1/3 = .33 * 2 = 0.66

  // So enlargedShortageProportion will be between 1 and 2
  // We square it to make it stronger
  // then we need to take off 1 so that if we have the right number of routes

  if (shouldBeNegative) {
    return -(Math.pow(enlargedShortageProportion, 3) * fractionalInfluence - 1);
  } else {
    return Math.pow(enlargedShortageProportion, 2) * fractionalInfluence - 1;
  }
}
*/
/*
 * Returns the setting urgency.
 * The number will be between about 1 and about -1 (reasonably)
 * E.g. desired = 1, count = 2, returns .5
 *      desired = 2, count = 2, returns -.5
 *      desired = 3, count = 2, returns -1
 * 	    desired = 4, count = 2, returns -1.5
 *
 */
function calculateSettingUrgency(
  targetQuantity,
  actualQuantity,
  fractionalInfluence
) {
  if (targetQuantity === 0) {
    // there aren't suppose to be any of this route so give it a high adjustment
    return -2;
  }

  if (!fractionalInfluence) {
    fractionalInfluence = 1;
  }

  let proportion = actualQuantity / targetQuantity;

  //If there are not enough routes, then setting is a good idea
  //so return a positive number
  if (proportion < 1) {
    //increase by one to make the cubing below work properly.
    // e.g. 0.1 becomes 1.1, when cubed 1.1 gets bigger not smaller
    let enlargedProportion = 1 - proportion + 1;
    return (Math.pow(enlargedProportion, 3) - 1) * fractionalInfluence;
  }

  // else there are too many routes, so return a negative number
  // else there are too many routes, so stripping is good, so return a positive number
  return -(Math.pow(proportion, 3) * fractionalInfluence);

  //return (-proportion + (1 - 1 / targetQuantity)) * fractionalInfluence;
}

/*
 * Returns the stripping urgency.
 * The number will be between about 1 and about -1 (reasonably)
 * E.g. desired = 1, count = 2, returns .5
 *      desired = 2, count = 2, returns -.5
 *      desired = 3, count = 2, returns -1
 * 	    desired = 4, count = 2, returns -1.5
 *
 */
function calculateStrippingUrgency(
  targetQuantity,
  actualQuantity,
  fractionalInfluence
) {
  if (targetQuantity === 0) {
    // there aren't suppose to be any of this route so give it a high adjustment
    return 2;
  }

  // actual quantity can be zero when you are calculating the stripping urgency of a slotgrade
  // not a route grade

  if (!fractionalInfluence) {
    fractionalInfluence = 1;
  }

  let proportion = actualQuantity / targetQuantity;

  // if we have the right number of routes now then we don't want to strip
  if (proportion === 1) {
    return -1 / targetQuantity;
  }

  //If there are not enough routes, then stripping is a bad idea
  //so return a negative number
  if (proportion < 1) {
    //increase by one to make the cubing below work properly.
    // e.g. 0.1 becomes 1.1, when cubed 1.1 gets bigger not smaller
    let enlargedProportion = 1 - proportion + 1;
    return -(Math.pow(enlargedProportion, 3) - 1) * fractionalInfluence;

    //return -(1 - proportion * fractionalInfluence);
  }

  // else there are too many routes, so stripping is good, so return a positive number
  return Math.pow(proportion, 3) * fractionalInfluence;
}

function byLengthOfSlot(a, b) {
  if (a.length > b.length) return 1;
  if (a.length < b.length) return -1;
  return 0;
}

function basedOnLongevityRank(climbA, climbB) {
  // let differenceA =
  //   climbA.acceptedWallAssembly.wallAssembly.climbingWall.slotCountTarget -
  //   climbA.acceptedWallAssembly.longevityClimbAssemblies.length;

  // let differenceB =
  //   climbB.acceptedWallAssembly.wallAssembly.climbingWall.slotCountTarget -
  //   climbB.acceptedWallAssembly.longevityClimbAssemblies.length;

  // if (differenceA === differenceB) {
  return climbB.longevityBreakdown.rank - climbA.longevityBreakdown.rank;
  // }

  // return differenceA - differenceB;
}
export {
  calculateStrippingUrgency,
  calculateSettingUrgency,
  createAcceptedWallAssembly,
  addSettingPlanToAcceptedWallAssembly,
  getPlanningQuantityMap,
  byLengthOfSlot,
  getMostRecentRouteSettingDateTimesByGrade,
  basedOnLongevityRank
};
