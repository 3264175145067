import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components

import { withRootStore } from "ticker-core";

import "react-simple-keyboard/build/css/index.css";

import UserNameComponent from "./UserNameComponent";

function RegistraterUserNamePage({
  rootStore: {
    regStores: { regStore },
    history,
    personalRootStore
  }
}) {
  return (
    <UserNameComponent
      onClick={userName => {
        let payload = { userName, id: regStore.id };

        personalRootStore.overwriteIdentificationFieldsFn(payload);

        regStore.updateRegistration(payload, () => {
          history.goBack();
        });
      }}
    />
  );
}

RegistraterUserNamePage.propTypes = {
  rootStore: PropTypes.object
};

export default withRootStore(RegistraterUserNamePage);
