import { computed } from "mobx";
import { gradeTypes } from "../variables/constants";

export default class ComputedFilteredReportStore {
  constructor(context, rootStore) {
    const {
      computedSettingReportStore,
      computedStrippingReportStore,
      filterStores,
      computedCacheStore
    } = rootStore;

    this.filteredRouteSuggestionsAssembly = computed(
      () => {
        let suggestionsAssembly = computedSettingReportStore.routeWallsWithSpace.get();

        return this.filteredSuggestionsAssembly(
          gradeTypes.ROUTE,
          suggestionsAssembly
        );
      },
      { keepAlive: true }
    );

    this.filteredBoulderSuggestionsAssembly = computed(
      () => {
        let suggestionsAssembly = computedSettingReportStore.boulderWallsWithSpace.get();

        return this.filteredSuggestionsAssembly(
          gradeTypes.BOULDER,
          suggestionsAssembly
        );
      },
      { keepAlive: true }
    );

    this.filteredSuggestionsAssembly = (gradeType, suggestionsAssembly) => {
      const gradeToStripFilterStore =
        filterStores.gradeToStripFilterStores[gradeType];
      const sectionFilterStore = filterStores.sectionFilterStores[gradeType];
      const wallFilterStore = filterStores.wallFilterStores[gradeType];

      const filteredSuggestions = [];
      let filteredSuggestionsCount = 0;

      for (let grouping of suggestionsAssembly.suggestionsGroupedBySection) {
        grouping.contentsAreFiltered = true;
        for (let wall of grouping.suggestionListsGroupedByWall) {
          wall.contentsAreFiltered = true;
          wall.filteredCount = 0;
          for (let suggestion of wall.suggestionList) {
            console.log("The suggestion:", suggestion);
            if (
              !gradeToStripFilterStore.isFiltered(
                suggestion.climbingGrade.id
              ) &&
              // NOTE: Suggestions can't be filtered by setter
              !sectionFilterStore.isFiltered(
                grouping.sectionAssembly.climbingSection.id
              ) &&
              !wallFilterStore.isFiltered(wall.wallAssembly.climbingWall.id)
            ) {
              wall.contentsAreFiltered = false;
              grouping.contentsAreFiltered = false;
              suggestion.contentsAreFiltered = false;
            } else {
              suggestion.contentsAreFiltered = true;
              filteredSuggestionsCount++;
              wall.filteredCount++;
            }
          }
        }
      }

      const filteredSuggestionsGroupedBySection = [];
      for (let grouping of suggestionsAssembly.suggestionsGroupedBySection) {
        if (!grouping.contentsAreFiltered) {
          filteredSuggestionsGroupedBySection.push(grouping);
          grouping.filteredSuggestionsGroupedByWall = [];

          for (let wall of grouping.suggestionListsGroupedByWall) {
            if (!wall.contentsAreFiltered) {
              grouping.filteredSuggestionsGroupedByWall.push(wall);
              wall.filteredSuggestionList = [];
              for (let suggestion of wall.suggestionList) {
                if (!suggestion.contentsAreFiltered) {
                  wall.filteredSuggestionList.push(suggestion);
                }
              }
            }
          }
        }
      }

      for (let suggestion of suggestionsAssembly.suggestions) {
        if (!suggestion.contentsAreFiltered) {
          filteredSuggestions.push(suggestion);
        }
      }

      return {
        filteredSuggestionsGroupedBySection,
        filteredSuggestions,
        filteredSuggestionsCount,
        unfilteredSuggestionsCount: suggestionsAssembly.suggestions.length
      };
    };

    this.getFilteredSerialLongevityObject = gradeType => {
      console.log("qwqw CALLING SERIAL - getFilteredSerialLongevityObject()");
      if (gradeType === gradeTypes.BOULDER) {
        return this.filteredBoulderSerialLongevityAssemblies.get();
      } else if (gradeType === gradeTypes.ROUTE) {
        return this.filteredRouteSerialLongevityAssemblies.get();
      }

      console.log("No gradeType specified");
      debugger;
    };

    this.filteredRouteLongevityObject = computed(
      () => {

        console.time("test");
        let longevityObject = computedStrippingReportStore.getLongevityAssembliesForRoutes.get();
        console.timeEnd("test");

        //This next assignment needs to be seperated to it's own section for dealing with Serial.
        //I've got it here just to get the code running
        console.time("test2");
        let serialLongevityOrderedClimbAssemblies = computedStrippingReportStore.getSerialLongevityForRoutes.get();
        console.timeEnd("test2");

        console.time("test3");
        let retVal = this.filteredLongevityObject(
          longevityObject,
          serialLongevityOrderedClimbAssemblies,
          gradeTypes.ROUTE
        );
        console.timeEnd("test3");
        debugger;
        return retVal;
      },
      { keepAlive: true }
    );

    this.filteredBoulderLongevityObject = computed(
      () => {
        let longevityObject = computedStrippingReportStore.getLongevityAssembliesForBoulders.get();

        //This next assignment needs to be seperated to it's own section for dealing with Serial.
        //I've got it here just to get the code running
        let serialLongevityOrderedClimbAssemblies = computedStrippingReportStore.getSerialLongevityForBoulders.get();

        return this.filteredLongevityObject(
          longevityObject,
          serialLongevityOrderedClimbAssemblies,
          gradeTypes.BOULDER
        );
      },
      { keepAlive: true }
    );

    this.filteredBoulderSerialLongevityAssemblies = computed(
      () => {
        let serialLongevityForBoulders = computedStrippingReportStore.getSerialLongevityForBoulders.get();

        return this.filteredSerialLongevityObject(
          serialLongevityForBoulders,
          gradeTypes.BOULDER
        );
      },
      { keepAlive: true }
    );

    this.filteredRouteSerialLongevityAssemblies = computed(
      () => {
        console.log("qwqw CALLING filteredRouteSerialLongevityAssemblies()");
        let serialLongevityForRoutes = computedStrippingReportStore.getSerialLongevityForRoutes.get();

        return this.filteredSerialLongevityObject(
          serialLongevityForRoutes,
          gradeTypes.ROUTE
        );
      },
      { keepAlive: true }
    );

    this.filteredSerialLongevityObject = (
      serialLongevityAssemblies,
      gradeType
    ) => {
      const filteredSerialLongevityOrderedClimbAssemblies = [];

      const retVal = {
        filteredSerialLongevityOrderedClimbAssemblies,
        serialClimbsFilteredCount: 0
      };

      if (serialLongevityAssemblies.length === 0) {
        return retVal;
      }

      const computedCache = computedCacheStore.computedCache.get();

      const gradeToStripFilterStore =
        filterStores.gradeToStripFilterStores[gradeType];
      const sectionFilterStore = filterStores.sectionFilterStores[gradeType];
      const wallFilterStore = filterStores.wallFilterStores[gradeType];
      const setterFilterStore = filterStores.setterFilterStores[gradeType];
      const gradeToSetFilterStore =
        filterStores.gradeToSetFilterStores[gradeType];

      retVal.serialClimbsFilteredCount = markAsFiltered(
        computedCache,
        sectionFilterStore,
        wallFilterStore,
        gradeToStripFilterStore,
        gradeToSetFilterStore,
        setterFilterStore,
        serialLongevityAssemblies
      );

      serialLongevityAssemblies.forEach(climbingAssembly => {
        if (!climbingAssembly.hasBeenFiltered) {
          retVal.filteredSerialLongevityOrderedClimbAssemblies.push(
            climbingAssembly
          );
        }
      });

      return retVal;
    };
    this.filteredLongevityObject = (
      longevityObject,
      serialLongevityOrderedClimbAssemblies,
      gradeType
    ) => {
      const filteredParallelLongevityOrderedClimbAssemblies = [];
      const filteredSerialLongevityOrderedClimbAssemblies = [];
      const filteredAgeOrderedLongevityClimbAssemblies = [];
      const filteredsectionGroupedLongevityClimbAssemblies = [];

      const retVal = {
        filteredSerialLongevityOrderedClimbAssemblies,
        filteredParallelLongevityOrderedClimbAssemblies,
        filteredAgeOrderedLongevityClimbAssemblies,
        filteredsectionGroupedLongevityClimbAssemblies,
        climbsFilteredCount: 0
      };

      const {
        parallelLongevityOrderedClimbAssemblies,
        ageOrderedLongevityClimbAssemblies,
        sectionGroupedLongevityClimbAssemblies
      } = longevityObject;

      if (parallelLongevityOrderedClimbAssemblies.length === 0) {
        return retVal;
      }

      const computedCache = computedCacheStore.computedCache.get();

      const gradeToStripFilterStore =
        filterStores.gradeToStripFilterStores[gradeType];
      const sectionFilterStore = filterStores.sectionFilterStores[gradeType];
      const wallFilterStore = filterStores.wallFilterStores[gradeType];
      const setterFilterStore = filterStores.setterFilterStores[gradeType];
      const gradeToSetFilterStore =
        filterStores.gradeToSetFilterStores[gradeType];

      retVal.climbsFilteredCount = markAsFiltered(
        computedCache,
        sectionFilterStore,
        wallFilterStore,
        gradeToStripFilterStore,
        gradeToSetFilterStore,
        setterFilterStore,
        parallelLongevityOrderedClimbAssemblies
      );

      computedCache.sectionAssemblies.forEach(sectionAssembly => {
        if (
          !sectionAssembly.hasBeenFiltered &&
          !sectionAssembly.climbingSection.isArchived &&
          typeof sectionGroupedLongevityClimbAssemblies[
            sectionAssembly.climbingSection.id
          ] !== "undefined"
        ) {
          let groupedSectionAssembly = {
            sectionAssembly: sectionAssembly,
            filteredListOfWallAssemblies: []
          };

          let unfilteredWallFound = false;
          sectionAssembly.climbingWallAssemblies.forEach(wallAssembly => {
            if (
              !wallAssembly.hasBeenFiltered &&
              !wallAssembly.climbingWall.isArchived &&
              typeof sectionGroupedLongevityClimbAssemblies[
                sectionAssembly.climbingSection.id
              ][wallAssembly.climbingWall.id] !== "undefined"
            ) {
              let groupedWallAssembly = {
                wallAssembly: wallAssembly,
                filteredListofLongevityClimbAssemblies: []
              };

              let unfilteredRouteFound = false;

              sectionGroupedLongevityClimbAssemblies[
                sectionAssembly.climbingSection.id
              ][wallAssembly.climbingWall.id].forEach(
                longevityClimbingAssembly => {
                  let isSettingPlan =
                    typeof sectionGroupedLongevityClimbAssemblies[
                      sectionAssembly.climbingSection.id
                    ][wallAssembly.climbingWall.id][
                      longevityClimbingAssembly.climbAssembly.climbingRoute.id
                    ] === "undefined";

                  if (
                    !isSettingPlan &&
                    !longevityClimbingAssembly.climbAssembly.climbingRoute
                      .isOnHold &&
                    !longevityClimbingAssembly.hasBeenFiltered
                  ) {
                    unfilteredRouteFound = true;
                    groupedWallAssembly.filteredListofLongevityClimbAssemblies.push(
                      longevityClimbingAssembly
                    );
                  }
                }
              );

              if (unfilteredRouteFound) {
                groupedSectionAssembly.filteredListOfWallAssemblies.push(
                  groupedWallAssembly
                );
                unfilteredWallFound = true;
              }
            }
          });
          if (unfilteredWallFound) {
            retVal.filteredsectionGroupedLongevityClimbAssemblies.push(
              groupedSectionAssembly
            );
          }
        }
      });

      parallelLongevityOrderedClimbAssemblies.forEach(climbingAssembly => {
        if (!climbingAssembly.hasBeenFiltered) {
          retVal.filteredParallelLongevityOrderedClimbAssemblies.push(
            climbingAssembly
          );
        }
      });

      serialLongevityOrderedClimbAssemblies.forEach(climbingAssembly => {
        if (!climbingAssembly.hasBeenFiltered) {
          retVal.filteredSerialLongevityOrderedClimbAssemblies.push(
            climbingAssembly
          );
        }
      });

      ageOrderedLongevityClimbAssemblies.forEach(climbingAssembly => {
        if (!climbingAssembly.hasBeenFiltered) {
          retVal.filteredAgeOrderedLongevityClimbAssemblies.push(
            climbingAssembly
          );
        }
      });

      return retVal;
    };
  }
}

function markAsFiltered(
  computedCache,
  sectionFilterStore,
  wallFilterStore,
  gradeToStripFilterStore,
  gradeToSetFilterStore,
  setterFilterStore,
  longevityClimbAssemblies
) {
  console.log(
    "asd - Marking as filtered",
    gradeToSetFilterStore.isFilteredMap,
    longevityClimbAssemblies
  );
  let climbsFilteredCount = 0;

  let gradeToSetFiltersApplied = gradeToSetFilterStore.getNumberOfFilters();

  computedCache.sectionAssemblies.forEach(sectionAssembly => {
    let sectionId = sectionAssembly.climbingSection.id;
    if (sectionFilterStore.isFiltered(sectionAssembly.climbingSection.id)) {
      sectionAssembly.hasBeenFiltered = true;
    } else {
      sectionAssembly.hasBeenFiltered = false;
    }

    /*
    for (let longevityClimbAssembly of longevityClimbAssemblies) {
      // Mark the longevityClimbAssembly if it is filtered by gradeToSet
      if (
        longevityClimbAssembly.settingPlan &&
        gradeToSetFilterStore.isFiltered(
          longevityClimbAssembly.settingPlan.climbing_grade_id
        )
      ) {
        longevityClimbAssembly.isFilteredByGradeToSet = true;
      }

      debugger;
      if (
        longevityClimbAssembly.climbAssembly.climbingRoute
          .climbing_section_id === sectionId &&
        !longevityClimbAssembly.isFilteredByGradeToSet
      ) {
        sectionIsNeeded = true;
      }
    }*/
    sectionAssembly.climbingWallAssemblies.forEach(wallAssembly => {
      let wallId = wallAssembly.climbingWall.id;

      if (
        wallAssembly.climbingRouteAssemblies.length === 0 ||
        wallFilterStore.isFiltered(wallAssembly.climbingWall.id)
      ) {
        wallAssembly.hasBeenFiltered = true;
      } else {
        wallAssembly.hasBeenFiltered = false;
      }

      for (let longevityClimbAssembly of longevityClimbAssemblies) {
        if (
          // the climb is on this section
          longevityClimbAssembly.climbAssembly.sectionAssembly.climbingSection
            .id === sectionId &&
          // and The climb is on this wall
          longevityClimbAssembly.climbAssembly.climbingRoute
            .climbing_wall_id === wallId &&
          // and The climb is not on hold
          !longevityClimbAssembly.climbAssembly.climbingRoute.isOnHold
        ) {
          if (
            // The suggestion is not filtered by what to strip
            !gradeToStripFilterStore.isFiltered(
              longevityClimbAssembly.climbAssembly.climbingGrade.id
            ) &&
            //and the suggestion is not filtered by the setter
            !setterFilterStore.isFiltered(
              longevityClimbAssembly.climbAssembly.setterAlias.id
            ) &&
            //and the suggestion is not filtered because the section is filtered
            !sectionAssembly.hasBeenFiltered &&
            //and the suggestion is not filtered because the wall is filtered
            !wallAssembly.hasBeenFiltered &&
            //and either there are no filters on the grade to set
            (gradeToSetFiltersApplied === 0 ||
              // or the climb is not filtered by what grade is in the setting plan
              (longevityClimbAssembly.settingPlan &&
                !gradeToSetFilterStore.isFiltered(
                  longevityClimbAssembly.settingPlan.climbing_grade_id
                )))
          ) {
            longevityClimbAssembly.hasBeenFiltered = false;
            // wallAssembly.hasBeenFiltered = false;
            // sectionAssembly.hasBeenFiltered = false;
            console.log("asd - The climbing assembly is not filtered");
          } else {
            console.log(
              "asd - This climbing assembly is filtered:",
              longevityClimbAssembly
            );
            longevityClimbAssembly.hasBeenFiltered = true;
            climbsFilteredCount++;
          }
        }
      }
    });
  });
  return climbsFilteredCount;
}
