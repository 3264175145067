import React, { useRef, useState, memo } from "react";

import { makeStyles } from "@material-ui/core";
import { PropTypes } from "prop-types";

import getObjectHandlers from "../handlers/getObjectHandlers";
import getRefCallBack from "./getRefCallBack";

import { styles } from "./svgStyles";

import RatingStar from "./decorations/RatingStar";
import Tick from "./decorations/Tick";
import Arrows from "./decorations/Arrows";

//   const svgTextSelection
//   svg text::selection {
//     background: none;
//   }

// function useTraceUpdate(props) {
//   const prev = useRef(props);
//   useEffect(() => {
//     console.log("xxxxxxxxxxxxxxxxxxxxxx inside the useTraceProps");
//     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//       if (prev.current[k] !== v) {
//         ps[k] = [prev.current[k], v];
//       }
//       return ps;
//     }, {});
//     if (Object.keys(changedProps).length > 0) {
//       console.log("xxxxxxxxxxxxxxxxxxxx Changed props:", changedProps);
//     }
//     prev.current = props;
//   });
// }
let useStyles = makeStyles(styles);

function RouteViewL2(props) {
  let {
    climbingRoute,
    isSelected,
    x,
    y,
    lineEndX,
    lineEndY,
    primaryHEX,
    secondaryHEX,
    textColor,
    shadowText,
    labelText,
    longPressIsDisabled,
    rootStore: { SVGStore, selectionStore },
    isTicked,
    avgRating,
    detailLevel,
    callableOnTouchEnd,
    callableOnTouchMove,
    relativeAvgCombinedModifier,
    wasLongPressed,
    onSelect,
    disableHandlers
  } = props;

  //console.log("Rendering RouteViewL2");
  // if (wasLongPressed) {
  //   console.log("Rendering the route view with LONG PRESS");
  // }
  // NOTE: I spent a while trying to figure out why routes were being re-rendered when zooming out
  // It isn't a bug or an inefficiency (much). Its that new routes are coming onto the screen and therefore
  // being rendered.
  // useTraceUpdate(props);

  let classes = useStyles();
  let textRef = useRef();
  let [lpTimeout, setStateLPTimeout] = useState({ value: null });
  let [hasPointerMoved, setHasPointerMoved] = useState({ value: false });
  let [textDimensions, setTextDimensions] = useState({ w: 0, h: 0 });

  let refCallBack = getRefCallBack(
    SVGStore,
    textDimensions,
    setTextDimensions,
    textRef
  );

  let getHandlers;
  let lineEndHandlers = {};
  let handlers = {};

  if (!disableHandlers) {
    getHandlers = getObjectHandlers(
      SVGStore,
      selectionStore,
      hasPointerMoved,
      setHasPointerMoved,
      climbingRoute,
      longPressIsDisabled,
      lpTimeout,
      setStateLPTimeout,
      callableOnTouchEnd,
      callableOnTouchMove,
      onSelect
    );

    lineEndHandlers = getHandlers(true);
    handlers = getHandlers(false);
  }

  let paddingY = 0.1;
  let paddingX = 0.15;
  let paddedWidth = textDimensions.w * (1 + paddingX);
  let paddedHeight = textDimensions.h * (1 + paddingY);

  // The label corner is the top left corner where the label would start
  let labelCornerX = x - paddedWidth / 2;
  let labelCornerY = y - paddedHeight / 2;

  // The centered label is the bottom left corner after some small adjustments to center the text better
  let centeredLabelX = labelCornerX + paddedWidth * 0.1;
  let centeredLabelY = labelCornerY + paddedHeight * 0.8;

  let roundedCorners = 3;

  let trianglePath;

  //Only create the path if we are going to use it
  if (secondaryHEX) {
    trianglePath = [
      "M",
      labelCornerX + roundedCorners / 2,
      labelCornerY + roundedCorners / 2,
      "L",
      labelCornerX + paddedWidth - roundedCorners / 2,
      labelCornerY + paddedHeight - roundedCorners / 2,
      "L",
      labelCornerX + paddedWidth - roundedCorners / 2,
      labelCornerY + roundedCorners / 2,
      "Z"
    ];
  }

  let linePath = ["M", x, y, "L", lineEndX, lineEndY];

  let details;

  if (detailLevel >= 3) {
    details = (
      <>
        {isTicked && (
          <Tick
            labelCornerY={labelCornerY}
            labelCornerX={labelCornerX}
            paddedWidth={paddedWidth}
          />
        )}
        {avgRating && (
          <RatingStar
            labelCornerX={labelCornerX}
            labelCornerY={labelCornerY}
            avgRating={avgRating}
          />
        )}
        <Arrows
          labelCornerX={labelCornerX}
          labelCornerY={labelCornerY}
          paddedHeight={paddedHeight}
          paddedWidth={paddedWidth}
          relativeAvgCombinedModifier={relativeAvgCombinedModifier}
          detailLevel={detailLevel}
        />
      </>
    );
  }

  let preventAction = evt => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  let TAPPING_PADDING = 6;

  let showEndPoint = detailLevel > 1 && !(x === lineEndX && y === lineEndY);

  return (
    <g className={classes.myFadeIn}>
      {isSelected && wasLongPressed && (
        <>
          <ellipse
            cx={x}
            cy={y}
            rx={paddedWidth}
            ry={paddedHeight}
            fill={primaryHEX}
            fillOpacity={0.4}
            // className={classes.blurMe}
            // filter="url(#blurred)"
          />
        </>
      )}

      {isSelected && showEndPoint && wasLongPressed && (
        <circle
          cx={lineEndX}
          cy={lineEndY}
          r={15}
          fill={primaryHEX}
          fillOpacity={0.4}
        />
      )}
      {/* The line joining the circle and the label */
      showEndPoint && (
        <path
          d={linePath.join(" ")}
          stroke={"grey"}
          strokeWidth="1px"
          vectorEffect="non-scaling-stroke"
        />
      )}

      {/* The pointer circle*/
      showEndPoint && (
        <circle
          cx={lineEndX}
          cy={lineEndY}
          r={5}
          fill={primaryHEX}
          stroke={"grey"}
          strokeWidth="1px"
          vectorEffect="non-scaling-stroke"
        />
      )}

      {/* An opaque circle over the pointer to allow for easier clicking */
      detailLevel > 1 && (
        <circle
          cx={lineEndX}
          cy={lineEndY}
          r={12}
          fillOpacity={0}
          {...lineEndHandlers}
        />
      )}

      {isSelected && (
        <>
          {/* The circle that surrounds the END POINT when the route is selected */
          showEndPoint && (
            <circle
              cx={lineEndX}
              cy={lineEndY}
              r={15}
              stroke="black"
              strokeWidth={2}
              fill="none"
              strokeOpacity={0.5}
              fillOpacity={0.5}
            />
          )}

          {/* The ellipse that surrounds the LABEL when the route is selected */}
          <ellipse
            cx={x}
            cy={y}
            rx={paddedWidth}
            ry={paddedHeight}
            stroke="black"
            strokeWidth={2}
            fill={wasLongPressed ? primaryHEX : "none"}
            strokeOpacity={0.5}
            fillOpacity={0.5}
          />
        </>
      )}
      {/* The main label rectangle */}
      <rect
        x={labelCornerX}
        y={labelCornerY}
        rx={roundedCorners}
        ry={roundedCorners}
        width={paddedWidth}
        height={paddedHeight}
        fill={primaryHEX}
        fillOpacity={primaryHEX ? 1 : 0}
        stroke={detailLevel > 0 ? "black" : undefined}
        strokeWidth={detailLevel > 0 ? "1px" : undefined}
        vectorEffect={detailLevel > 0 ? "non-scaling-stroke" : undefined}
        // filter="url(#blurredRect)"
      />
      {/* The tringle if there is a secondary color */}
      {secondaryHEX && (
        <path
          d={trianglePath.join(" ")}
          fill={secondaryHEX}
          strokeLinejoin="round"
          strokeWidth={roundedCorners}
          stroke={secondaryHEX}
        />
      )}
      {/* This is the shadow beneath the text */}
      {shadowText && detailLevel > 1 && (
        <rect
          x={centeredLabelX}
          y={centeredLabelY - textDimensions.h * 0.6}
          width={textDimensions.w}
          height={textDimensions.h * 0.7}
          fill="black"
          fillOpacity={0.25}
          filter="url(#blurMeViaSVG)"
        />
      )}
      {/* The label text */}
      <text
        x={centeredLabelX}
        y={centeredLabelY}
        fontSize="12"
        fill={detailLevel > 0 ? textColor : primaryHEX}
        ref={refCallBack}
        onSelect={preventAction}
      >
        {labelText}
      </text>
      {details}
      {/* This is an opaque cover to stop the user from selecting the text */}
      {
        <rect
          //Position the rect higher and to the left of the label corner
          // Because the tapping margin makes this rect bigger than the label
          x={labelCornerX - 0.5 * TAPPING_PADDING}
          y={labelCornerY - 0.5 * TAPPING_PADDING}
          width={paddedWidth + TAPPING_PADDING}
          height={paddedHeight + TAPPING_PADDING}
          fillOpacity={0}
          {...handlers}
        />
      }
    </g>
  );
}

RouteViewL2.propTypes = {
  labelText: PropTypes.string,
  fill: PropTypes.string,
  climbingRoute: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  lineEndX: PropTypes.number.isRequired,
  lineEndY: PropTypes.number.isRequired,
  primaryHEX: PropTypes.string,
  secondaryHEX: PropTypes.string,
  textColor: PropTypes.string,
  longPressIsDisabled: PropTypes.bool,
  rootStore: PropTypes.object.isRequired,
  isTicked: PropTypes.bool,
  avgRating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  relativeAvgCombinedModifier: PropTypes.number,
  showDetail: PropTypes.bool,
  shadowText: PropTypes.bool,
  detailLevel: PropTypes.number,
  callableOnTouchEnd: PropTypes.func,
  callableOnTouchMove: PropTypes.func,
  wasLongPressed: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  disableHandlers: PropTypes.bool
};

export default memo(RouteViewL2);
