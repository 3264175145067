import React, { useState } from "react";
import Button from "../CustomButtons/Button";
import GridContainer from "../Grid/GridContainer";
import PropTypes from "prop-types";

export default function MoreListButton({
  fullArray,
  childrenFn,
  singular,
  plural,
  missingMessage,
  resetNoActivityTimeout
}) {
  let [visibleRows, setVisibleRows] = useState(10);

  if (fullArray.length === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <h5>{missingMessage}</h5>
      </div>
    );
  }

  let showMoreVisibleRows = () => {
    resetNoActivityTimeout();
    setVisibleRows(visibleRows + 15);
  };

  let slicedArray = fullArray.slice(0, visibleRows);

  let partArray = fullArray;
  let showButton = false;
  if (visibleRows < fullArray.length) {
    partArray = fullArray.slice(0, visibleRows);
    showButton = true;
  }
  let numHidden = fullArray.length - slicedArray.length;
  return (
    <>
      <GridContainer justify="center">
        {partArray.map(childrenFn)}
      </GridContainer>
      {showButton && (
        <div style={{ textAlign: "center" }}>
          <h5>
            <div>
              {numHidden} more {numHidden === 1 ? singular : plural} available
            </div>
            <div>
              <Button
                simple
                color="info"
                size="lg"
                onClick={showMoreVisibleRows}
              >
                Show More
              </Button>
            </div>
          </h5>
        </div>
      )}
    </>
  );
}

MoreListButton.propTypes = {
  fullArray: PropTypes.array.isRequired,
  childrenFn: PropTypes.func.isRequired,
  plural: PropTypes.string.isRequired,
  singular: PropTypes.string.isRequired,
  missingMessage: PropTypes.string.isRequired,
  resetNoActivityTimeout: PropTypes.func.isRequired
};
