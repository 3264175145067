import { observable, action } from "mobx";

let OverviewStore = observable({
  overviewTab: null,
  sectionTab: null,
  wallTab: null,
  sectionId: null,
  wallId: null,
  sectionIsVisible: {},
  wallIsVisible: {}
});

OverviewStore.setOverviewTab = action(
  function(overviewTabName) {
    this.overviewTab = overviewTabName;
  }.bind(OverviewStore)
);

OverviewStore.setSectionTab = action(
  function(sectionTab) {
    this.sectionTab = sectionTab;
  }.bind(OverviewStore)
);

OverviewStore.setWallTab = action(
  function(wallTab) {
    this.wallTab = wallTab;
  }.bind(OverviewStore)
);

OverviewStore.setSectionVisibility = action(
  function(sectionId, isVisible) {
    this.sectionIsVisible[sectionId] = isVisible;
  }.bind(OverviewStore)
);

OverviewStore.setWallVisibility = action(
  function(wallId, isVisible) {
    this.wallIsVisible[wallId] = isVisible;
  }.bind(OverviewStore)
);

OverviewStore.getSectionVisibility = function(sectionId) {
  return this.sectionIsVisible[sectionId];
}.bind(OverviewStore);

OverviewStore.getWallVisibility = wallId => {
  return this.wallIsVisible[wallId];
};

OverviewStore.toggleSectionVisibility = action(
  function(sectionId) {
    this.sectionIsVisible[sectionId] = !this.sectionIsVisible[sectionId];
  }.bind(OverviewStore)
);

OverviewStore.toggleWallVisibility = action(
  function(wallId) {
    this.wallIsVisible[wallId] = !this.wallIsVisible[wallId];
  }.bind(OverviewStore)
);

OverviewStore.setSectionFocusId = action(
  function(sectionFocusId) {
    this.sectionFocusId = sectionFocusId;
  }.bind(OverviewStore)
);

OverviewStore.setWallFocusId = action(
  function(wallFocusId) {
    this.wallFocusId = wallFocusId;
  }.bind(OverviewStore)
);

export default OverviewStore;
