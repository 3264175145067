import React from "react";
import { observer } from "mobx-react";

import { Button } from "ticker-components";

let Regrade = observer(function Regrade({
  regrade,
  onRegrade,
  regrade_id,
  buttonSize
}) {
  //let classes = useRegradeStyles();

  let style = { paddingLeft: ".5em", paddingRight: ".5em" };

  return (
    <>
      <Button
        key={regrade.id}
        color={regrade_id === regrade.id ? "info" : "gray"}
        simple
        size={buttonSize}
        style={style}
        onClick={() => {
          onRegrade(regrade.id);
        }}
      >
        {regrade.primaryLabel}
      </Button>
    </>
  );
});

export default Regrade;
