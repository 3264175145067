function getNextUnarchivedGrade(
  orderPosition,
  gradeType,
  direction,
  gradeStores
) {
  while (
    orderPosition >= 0 &&
    orderPosition < gradeStores[gradeType].items.length - 1
  ) {
    if (direction === "HARDER") orderPosition += 1;
    else orderPosition -= 1;

    let grade = gradeStores[gradeType].getItemByOrderPosition(orderPosition);
    if (typeof grade !== "undefined" && !grade.isArchived) {
      return grade;
    }
  }
  return null;
}

function addTwoRegrades(direction, climbingGrade, regrades, gradeStores) {
  let gradeType = climbingGrade.gradeType;

  let oneAway = getNextUnarchivedGrade(
    climbingGrade.orderPosition,
    gradeType,
    direction,
    gradeStores
  );
  if (oneAway) {
    let twoAway = getNextUnarchivedGrade(
      oneAway.orderPosition,
      gradeType,
      direction,
      gradeStores
    );
    if (direction === "EASIER") {
      if (twoAway) {
        regrades.push(twoAway);
      }
      regrades.push(oneAway);
    } else {
      regrades.push(oneAway);
      if (twoAway) {
        regrades.push(twoAway);
      }
    }
  }
}

export default function addRegrades(assembly, gradeStores) {
  let regrades = [];
  assembly.regrades = regrades;
  // The climbing Grades may not have loaded just yet
  if (assembly.climbingGrade) {
    addTwoRegrades("EASIER", assembly.climbingGrade, regrades, gradeStores);

    regrades.push(assembly.climbingGrade);

    addTwoRegrades("HARDER", assembly.climbingGrade, regrades, gradeStores);
  }
}
