import {
  transition,
  containerFluid
} from "../../material-dashboard-pro-react.js";

const coreLayoutStyle = {
  wrapper: {
    position: "relative",
    top: "0",
    // removed by brook to remove an annoying scrollbar.
    // probably needed if the footer is reinstated. Search for text 'disgdsgdsds'
    // height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    // Brook removed this overflow auto to help get the SVG working properly
    // overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%"
  },
  content: {
    minHeight: "calc(100vh - 123px)"
  },
  container: {
    ...containerFluid
  },
  map: {
    marginTop: "70px"
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  }
};

export default coreLayoutStyle;
