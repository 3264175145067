/*
 *  print the deepest structure
 */
export default function printMappings(mappings, slotsArg, climbAssembliesArg) {
  console.log("-----------------------------------------");
  console.log("----------- DEEPEST STRUCTURE -----------");
  console.log("-----------------------------------------");
  console.log("------------ Depth = " + mappings.length + "---------");

  for (let d = 0; d < mappings.length; d++) {
    let map = mappings[d];
    console.log(
      "isMapped:" +
        map.isMapped +
        " \t slotIndex:" +
        map.slotIndex +
        " \t slotStr: " +
        slotsArg[map.slotIndex].slotStr +
        " \t wall: " +
        slotsArg[map.slotIndex].wallAssembly.climbingWall.name +
        " \t climbIndex:" +
        map.climbIndex +
        " \t climb:" +
        climbAssembliesArg[map.climbIndex].climbingGrade.primaryLabel
    );
  }

  //Now we print out the missing slots

  console.log("           ----------------------------------");
  console.log("            Slots with no climb");
  console.log("            ----------------------------------");

  let totalMissingSlots = 0;
  for (let i = 0; i < slotsArg.length; i++) {
    let inDeepestStructure = false;
    for (let j = 0; j < mappings.length; j++) {
      if (mappings[j].slotIndex === i) {
        inDeepestStructure = true;
        break;
      }
    }

    if (inDeepestStructure) {
      //do nothing
      //console.log('SKIPPING ' + slotsArg[i].slotStr);
    } else {
      totalMissingSlots++;
      console.log(
        "isMapped: false \t slotIndex: " +
          i +
          " \t slotStr: " +
          slotsArg[i].slotStr +
          " \t climbIndex: NONE \t climb: N/A"
      );
    }
  }
  /*for (var y = 0; y < slotsArg.length; y++)
              {
              }*/
  console.log("The total missing slots count is " + totalMissingSlots);
  console.log(
    " sum of totals = " +
      totalMissingSlots +
      " + " +
      mappings.length +
      " = " +
      (totalMissingSlots + mappings.length)
  );
  console.log("");

  /*
   * Now we need to print any slotless climbs to the end
   */

  console.log("           ----------------------------------");
  console.log("            Climbs with no slots");
  console.log("            ----------------------------------");

  let totalMissingClimbs = 0;
  for (let l = 0; l < climbAssembliesArg.length; l++) {
    let climbIncludedAlready = false;
    for (let x = 0; x < mappings.length; x++) {
      if (mappings[x].climbIndex === l) {
        climbIncludedAlready = true;
        break;
      }
    }

    if (!climbIncludedAlready) {
      totalMissingClimbs++;
      console.log(
        "isMapped: false \t\t slot: NONE \t climbIndex: " +
          l +
          " \t climb: " +
          climbAssembliesArg[l].climbingGrade.primaryLabel
      );
    }
  }

  console.log("The total missing climbs is " + totalMissingClimbs);
  console.log("");
  console.log("");
}
