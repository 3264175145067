import React from "react";
import { useObserver } from "mobx-react";

// core components
import { AirBnBSlider } from "ticker-components";

import { withCachedRootStore } from "ticker-core";

const RatingSlider = function RatingSlider(props) {
  let {
    rootStore,
    fadeDelay,
    setFadeIn,
    pageSelection,
    ratingType,
    containerClass
  } = props;

  let { UISelection, selection } = pageSelection;

  let {
    regStores: { regStore }
  } = rootStore;

  return useObserver(() => {
    // let getSliderLabelText = index => {
    //   return index;
    // };

    let onChange = (e, newIndexes) => {
      //Set the local state so that the slider changes immediately
      UISelection[ratingType] = { start: newIndexes[0], end: newIndexes[1] };
      regStore.resetNoActivityTimeout();
      console.log("Changed to " + JSON.stringify(UISelection[ratingType]));
    };

    let onChangeCommitted = (e, newIndexes) => {
      setFadeIn(false);

      //Set the store state after the fadeout has happened
      setTimeout(() => {
        setFadeIn(true);

        console.log("sss - setting the rating start to:" + newIndexes[0]);
        selection[ratingType] = { start: newIndexes[0], end: newIndexes[1] };
        console.log("The newValue is:" + JSON.stringify(newIndexes));
      }, fadeDelay);
    };

    // There was a weird bug here where if I passed in null or undefined values on
    // the first render, subsequent renders were not made well and the slider didn't
    // work properly
    let sliderValues = [
      UISelection[ratingType].start,
      UISelection[ratingType].end
    ];

    return (
      <div className={containerClass}>
        <AirBnBSlider
          value={sliderValues}
          // valueLabelFormat={getSliderLabelText}
          step={1}
          max={7}
          min={1}
          valueLabelDisplay="on"
          onChangeCommitted={onChangeCommitted}
          onChange={onChange}
        />
      </div>
    );
  });
};

RatingSlider.propTypes = {};

export default withCachedRootStore(RatingSlider);
