import { coreStyle } from "ticker-core";
const { roseColor, blackColor, successColor, hexToRgb, container } = coreStyle;

const registrationStyle = theme => ({
  container: {
    ...container,
    maxWidth: "40em"
  },
  keyArea: {
    margin: "auto",
    width: "20em"
  },
  rose: {
    backgroundColor: roseColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.4)"
  },
  footer: {
    padding: "0 15px"
  },
  left: {
    float: "left!important"
  },
  right: {
    float: "right!important"
  },
  middle: {
    margin: "auto"
  },
  clearfix: {
    "&:after,&:before": {
      display: "table",
      content: '" "'
    },
    clear: "both"
  },
  header: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      padding: "25px 0 35px"
    }
  },
  title: {
    margin: "0"
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "medium"
    // }
  },
  subtitle: {
    margin: "5px 0 0"
  },
  note: {
    paddingTop: "1em"
  },
  message: {
    height: "1.1em",
    textAlign: "center",
    width: "100%"
  },
  warning: {
    color: roseColor[0]
  },
  good: {
    color: successColor[0]
  }
});

export default registrationStyle;
