import { registrationStyle } from "ticker-components";

const registrationLoginPageStyle = () => ({
  keyArea: {
    ...registrationStyle,
    paddingBottom: "12em"
  }
});

export default registrationLoginPageStyle;
