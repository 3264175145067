import { observable, action } from "mobx";
import moment from "moment";
import getCachable from "../../utils/getCachable";

let dateFormat = "YYYY-MM-DD";
let timeFormat = "k:mm";

export default class LogEntryStores {
  constructor(
    context,
    asyncOpCreate,
    regStore,
    personalRootStore,
    getCurrentGymId,
    getCurrentUserId
  ) {
    const rest = context.rest;

    this.clearSelectionAndCache = () => {
      this.logEntryStore.clearSelection();
      this.logEntriesList.clearSelection();
      this.cachedLogCountStore.clearCache();
    };
    this.logEntryStore = observable({
      dateOption: undefined,
      dateStr: undefined,
      timeStr: undefined,

      dateOptions: [
        {
          label: "Today",
          value: "Today",
          onClick: () => {
            this.logEntryStore.dateOption = this.logEntryStore.dateOptions[0];
            this.logEntryStore.dateStr = moment().format(dateFormat);
          }
        },
        {
          label: "Yesterday",
          value: "Yesterday",
          onClick: () => {
            this.logEntryStore.dateOption = this.logEntryStore.dateOptions[1];
            this.logEntryStore.dateStr = moment()
              .subtract(1, "days")
              .format(dateFormat);
          }
        },
        {
          label: "2 days ago",
          value: "2_days_ago",
          onClick: () => {
            this.logEntryStore.dateOption = this.logEntryStore.dateOptions[2];
            this.logEntryStore.dateStr = moment()
              .subtract(2, "days")
              .format(dateFormat);
          }
        }
      ],

      setDate: date => {
        this.logEntryStore.dateOption = undefined;
        this.logEntryStore.dateStr = date;
      },

      setTime: time => {
        this.logEntryStore.timeStr = time;
      },

      clearSelection: () => {
        this.logEntryStore.dateOption = this.logEntryStore.dateOptions[0];
        this.logEntryStore.dateStr = undefined;
        this.logEntryStore.timeStr = undefined;
      },

      opCreateLogEntry: asyncOpCreate(
        payload => rest.post("logEntries", payload),
        "oplogEntries"
      ),

      createLogEntry: function(payload, callBack) {
        payload.user_id = personalRootStore.getCurrentUserId();

        if (!payload.user_id) {
          console.log("The user Id is missing for the request to logEntries");
          debugger;
        }

        // ZZ gives an offset e.g. -07:00 -06:00 ... +06:00 +07:00
        payload.userTZOffset = moment().format("Z");

        console.log("The chosen date is.." + this.dateOption);

        payload.chosenDateOnlyStr = this.dateStr;
        payload.chosenTimeOnlyStr = this.timeStr;

        payload.passcode = regStore.passcode;

        this.opCreateLogEntry.execute(payload, callBack);

        regStore.resetNoActivityTimeout();

        this.updateLogCount(payload.climbing_route_id, 1);
      },
      getStoreName: () => {
        return "logEntryStore";
      },
      opDeleteLogEntry: asyncOpCreate(
        payload => rest.delete("logEntries/" + payload.id, payload),
        "opDeleteLogEntry"
      ),

      deleteLogEntry: async function(entry, logEntries, callBack) {
        let payload = {
          user_id: personalRootStore.getCurrentUserId(),
          passcode: regStore.passcode,
          id: entry.id
        };
        if (!payload.user_id) {
          console.log(
            "The user Id is missing for the delete request to logEntries"
          );
          debugger;
        }

        if (logEntries) {
          // We'll assume success and delete the logEntry in memory
          // let logEntries = this.logEntriesHandle.reportData.logEntries;
          let i = 0;
          for (; i < logEntries.length; i++) {
            if (logEntries[i].id === payload.id) {
              break;
            }
          }
          logEntries.splice(i, 1);
        }

        this.updateLogCount(entry.climbing_route_id, -1);

        await this.opDeleteLogEntry.execute(payload, callBack);
        regStore.resetNoActivityTimeout();
      },

      opUpdateLogEntry: asyncOpCreate(
        payload => rest.put("logEntries/" + payload.id, payload),
        "opUpdateLogEntry"
      ),

      updateLogEntry: async function(logEntry, newDate, newTime, callBack) {
        let userTZ = moment().format("Z");
        let payload = {
          user_id: personalRootStore.getCurrentUserId(),
          passcode: regStore.passcode,
          id: logEntry.id,
          userTZOffset: userTZ,
          dateOnlyClimbedTZ: newDate.format(dateFormat),
          timeOnlyClimbedTZ: newTime.format(timeFormat)
        };
        if (!payload.user_id) {
          console.log(
            "The user Id is missing for the delete request to logEntries"
          );
          debugger;
        }

        // NOTE: We can't assume success because the UTC date needs to come from the server
        // logEntry.dateOnlyClimbedTZ = newDate.format(dateFormat);
        // logEntry.timeOnlyClimbedTZ = newTime.format(timeFormat);
        // logEntry.userTZOffset = userTZ;

        let result = await this.opUpdateLogEntry.execute(payload, callBack);

        logEntry.dateOnlyClimbedTZ = result.dateOnlyClimbedTZ;
        logEntry.timeOnlyClimbedTZ = result.timeOnlyClimbedTZ;
        logEntry.dateClimbedUTC = result.dateClimbedUTC;
        logEntry.userTZOffset = userTZ;

        regStore.resetNoActivityTimeout();
      },

      updateLogCount: function(routeId, adjustment) {
        // This updates the log count for this route
        for (let i = 0; i < this.cachedLogCountHandle.items.length; i++) {
          if (routeId === this.cachedLogCountHandle.items[i][0]) {
            let newLogCount =
              parseInt(this.cachedLogCountHandle.items[i][1]) + adjustment;
            this.cachedLogCountHandle.items[i] = [routeId, newLogCount];
          }
        }
      }
    });

    this.logEntriesByTimeReport = {
      parameters: observable({
        gradeType: undefined,
        startDateStr: undefined,
        endDateStr: undefined
      }),

      reportData: observable({
        series: undefined,
        labels: undefined
      }),

      opGetLogEntriesByTime: asyncOpCreate(
        payload => rest.get("logEntries/logEntriesByTimeReport", payload),
        "opGetLogEntriesByTime"
      ),

      getLogEntriesByTime: async function() {
        regStore.resetNoActivityTimeout();

        let payload = {
          user_id: personalRootStore.getCurrentUserId(),
          passcode: regStore.passcode,
          gradeType: this.parameters.gradeType,
          startDateStr: this.parameters.startDateStr,
          endDateStr: this.parameters.endDateStr
        };

        let callback = action(response => {
          let labels = [];
          let series = [];

          let currentUTCDate = moment(response.startDateStr);
          let endDate = moment(response.endDateStr);

          let numberOfColumns = endDate.diff(currentUTCDate, "days");

          while (!currentUTCDate.isAfter(endDate)) {
            let dateLabel;
            let dateFormatedStr;

            if (numberOfColumns < 14) {
              dateFormatedStr = currentUTCDate.format("YYYY-MM-DD");
              dateLabel = currentUTCDate.format("dddd");
            } else {
              dateLabel = currentUTCDate.format("YYYY-MM-DD");
              dateFormatedStr = dateLabel;
            }

            labels.push(dateLabel);
            if (typeof response.mapToCounts[dateFormatedStr] !== "undefined") {
              series.push(parseInt(response.mapToCounts[dateFormatedStr]));
            } else {
              series.push(0);
            }
            currentUTCDate.add(1, "days");
          }

          this.reportData.labels = labels;
          this.reportData.series = [series];
        });

        this.opGetLogEntriesByTime.execute(payload, callback);
      }
    };

    this.logEntriesList = {
      parameters: observable({
        gradeType: undefined,
        endDateStr: moment().format(dateFormat),
        startDateStr: moment()
          .add(-1, "week")
          .format(dateFormat),
        sortColumn: "climbed"
      }),

      clearSelection: () => {
        this.logEntriesList.parameters.gradeType = undefined;
        this.logEntriesList.parameters.endDateStr = moment().format(dateFormat);
        this.logEntriesList.parameters.startDateStr = moment()
          .add(-1, "week")
          .format(dateFormat);
        this.logEntriesList.parameters.sortColumn = "climbed";
      },

      reportData: observable({
        logEntries: []
      }),

      opGetLogEntriesList: asyncOpCreate(
        payload => rest.get("logEntries/logEntriesList", payload),
        "opGetLogEntriesList"
      ),

      getLogEntriesList: async function(offset, limit) {
        regStore.resetNoActivityTimeout();

        let payload = {
          user_id: personalRootStore.getCurrentUserId(),
          passcode: regStore.passcode,
          gradeType: this.parameters.gradeType,
          endDateStr: this.parameters.endDateStr,
          startDateStr: this.parameters.startDateStr,
          sortColumn: this.parameters.sortColumn,
          offset: offset,
          limit: limit,
          order: "desc"
        };

        let callback = action(response => {
          //          this.parameters.reportData.logEntries.clear();
          this.reportData.logEntries.replace(response.logEntries);
        });

        this.opGetLogEntriesList.execute(payload, callback);
      }
    };

    this.logEntriesOfRoute = {
      MAX_LIMIT: 10,

      logEntries: observable([]),

      parameters: observable({
        sortColumn: "climbed"
      }),

      opGetLogEntriesOfRoute: asyncOpCreate(
        payload => rest.get("logEntries/logEntriesOfRoute", payload),
        "opGetLogEntriesOfRoute"
      ),

      loadLogEntriesOfRoute: async function(climbing_route_id, callBackArg) {
        regStore.resetNoActivityTimeout();

        let payload = {
          user_id: personalRootStore.getCurrentUserId(),
          passcode: regStore.passcode,
          sortColumn: this.parameters.sortColumn,
          climbing_route_id,
          offset: 0,
          limit: this.MAX_LIMIT,
          order: "desc"
        };

        let callback = action(response => {
          this.logEntries.replace(response);
          if (callBackArg) {
            callBackArg();
          }
        });

        this.opGetLogEntriesOfRoute.execute(payload, callback);
      }
    };

    let areEqual = (a, b) => a.id === b.id;

    this.cachedLogCountStore = getCachable(
      asyncOpCreate,
      context.rest,
      areEqual,
      "logEntries/logEntryCounts",
      null,
      () => ({
        user_id: getCurrentUserId(),
        passcode: regStore.passcode,
        gym_id: getCurrentGymId()
      })
    );

    this.logEntryStore.logEntriesHandle = this.logEntriesList;
    this.logEntryStore.cachedLogCountHandle = this.cachedLogCountStore;
  }
}
