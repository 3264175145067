import React, { useEffect } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";

import controller from "./DialogController";

import { withRootStore } from "ticker-core";

let style = theme => ({
  padded: {
    [theme.breakpoints.up("sm")]: {
      paddingBottom: ".5em",
      paddingLeft: ".5em",
      paddingRight: ".5em"
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "2em",
      paddingLeft: "2em",
      paddingRight: "2em"
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

let useStyles = makeStyles(style);

function TitleDialog({
  onClose,
  open,
  title,
  children,
  dialogId,
  rootStore: { history },
  fullScreen,
  centeredTitle
}) {
  let classes = useStyles();

  const theme = useTheme();
  if (typeof fullScreen === "undefined" || fullScreen === null) {
    fullScreen = useMediaQuery(theme.breakpoints.down(400));
  }

  useEffect(() => {
    history.listen((data, action) => {
      if (action === "POP") {
        if (controller.isCurrent(dialogId)) {
          controller.removeId(dialogId);
          onClose();
        }
      }
    });
  }, []);

  useEffect(() => {
    if (open) {
      controller.addId(dialogId);
      history.push(`#${controller.getIdList().join("&")}`);
    } else {
      if (controller.isCurrent(dialogId)) {
        controller.removeId(dialogId);
        history.replace(`#${controller.getIdList().join("&")}`);
      }
    }
  }, [open]);

  let superOnClose = () => {
    controller.removeId(dialogId);
    if (onClose) {
      onClose();
    }
    history.replace(`#${controller.getIdList().join("&")}`);
  };

  return (
    <>
      <Dialog onClose={superOnClose} open={open} fullScreen={fullScreen}>
        <DialogTitle>
          <GridContainer>
            {centeredTitle && <GridItem></GridItem>}
            <GridItem style={{ flexGrow: 1 }}>{title}</GridItem>
            <GridItem>
              <IconButton
                edge="start"
                color="inherit"
                onClick={superOnClose}
                aria-label="close"
                className={classes.closeButton}
              >
                <CloseIcon />
              </IconButton>
            </GridItem>
          </GridContainer>
        </DialogTitle>
        {children}
      </Dialog>
    </>
  );
}

TitleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  dialogId: PropTypes.string.isRequired,
  rootStore: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool
};

export default withRootStore(TitleDialog);
