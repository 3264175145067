import React from "react";
import { observer } from "mobx-react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

import { withRootStore } from "ticker-core";

import { LoginRequest, TitledDialog } from "ticker-components";

function LoginDialog({ rootStore: { loginDialogStore } }) {
  let onClose = () => {
    loginDialogStore.showDialog = false;
    loginDialogStore.instruction = false;
  };

  return (
    <>
      <TitledDialog
        open={loginDialogStore.showDialog}
        dialogId="loginDialog"
        onClose={onClose}
        fullScreen={false}
      >
        <div style={{ textAlign: "center", padding: "2em" }}>
          <LoginRequest
            instruction={loginDialogStore.instruction}
            onClick={onClose}
          />
        </div>
      </TitledDialog>
    </>
  );
}

LoginDialog.propTypes = {
  onClose: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  assembly: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default withRootStore(observer(LoginDialog));
