// You'll also need to change the definition of the #blurred filter
let shadowOffset = -5;
let styles = {
  svgText: {
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none"
  },
  blurMe: {
    filter: "blur(1px)"
  },
  shadowed: {
    "-webkit-filter":
      "drop-shadow(" +
      shadowOffset +
      "px " +
      shadowOffset +
      "px 3px rgba(0,0,0,0.9))",
    filter:
      "drop-shadow(" +
      shadowOffset +
      "px " +
      shadowOffset +
      "px 3px rgba(0,0,0,0.9))"
  },

  /* make keyframes that tell the start state and the end state of our object */
  // "@-webkit-keyframes fadeIn": { from: { opacity: 0 }, to: { opacity: 1 } },
  // "@-moz-keyframes fadeIn": { from: { opacity: 0 }, to: { opacity: 1 } },
  "@keyframes fadeIn": { from: { opacity: 0 }, to: { opacity: 1 } },

  myFadeIn: {
    opacity: 0 /* make things invisible upon start */,
    "-webkit-animation":
      "$fadeIn ease-in 1" /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */,
    "-moz-animation": "$fadeIn ease-in 1",
    animation: "$fadeIn ease-in 1",

    "-webkit-animation-fill-mode":
      "forwards" /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/,
    "-moz-animation-fill-mode": "forwards",
    "animation-fill-mode": "forwards",

    "-webkit-animation-duration": ".2s",
    "-moz-animation-duration": ".2s",
    "animation-duration": ".2s"
  }
};

export { shadowOffset, styles };
