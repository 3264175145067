import React, { useEffect } from "react";

import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";

import { withRootStore } from "ticker-core";

function RegistrationLogoutPage({ rootStore }) {
  const { regStores } = rootStore;

  useEffect(() => {
    regStores.regStore.logoutRegistration();
  }, [regStores.regStore]);

  return <Redirect to="welcome" />;
}

RegistrationLogoutPage.propTypes = {
  classes: PropTypes.object,
  rootStore: PropTypes.object
};

export default withRootStore(RegistrationLogoutPage);
