import { observable, action, autorun } from "mobx";

import ZoomLevels from "../utils/ZoomLevels";

import SVGPanner from "../utils/SVGPanner";

let SVGStore = observable({
  value: {},
  detailLevel: 0,
  panToCoordinates: null,
  disableMapTool: false,
  dimensions: { width: 0, height: 0 },
  viewer: undefined,
  encloseTheseCoords: null
});

SVGStore.clearPanning = action(function() {
  console.log("Clearing the panning");
  SVGStore.panToCoordinates = null;
  SVGStore.disableMapTool = false;
  // Clear the observable array
});

// The detailLevel is save separatly from the value
// so that the rightPanel of pages like viewClimbingRoute
// doesn't re-render just because the user zoomed in a little bit
SVGStore.setValue = action(function(newValue) {
  SVGStore.value = newValue;
  if (typeof newValue.a !== "undefined") {
    SVGStore.detailLevel = ZoomLevels.getDetailLevel(newValue.a);
  } else {
    SVGStore.detailLevel = 0;
  }
});

// Maybe the CDM is causing a rerender
SVGStore.reset = action(function() {
  console.log("Reseting the SVGStore:");
  SVGStore.setValue({});
  SVGStore.recenter();
  SVGStore.showFilter = false;
});

SVGStore.recenter = action(function() {
  console.log("qwqwwq recentering", SVGStore.viewer);
  SVGStore.clearPanning();
  if (SVGStore.viewer) {
    SVGStore.viewer.fitToViewer("center", "center");
  }
});

SVGStore.clearPersonalData = () => {
  SVGStore.reset();
};

autorun(() => {
  if (SVGStore.panToCoordinates && typeof SVGStore.value.e !== "undefined") {
    // untracked(() => {
    // Create a copy of value so that mobx detects the change
    let newValue = Object.assign({}, SVGStore.value);
    newValue.a = ZoomLevels.PAN_DEPTH;
    newValue.d = ZoomLevels.PAN_DEPTH;
    newValue.e =
      -SVGStore.panToCoordinates.x * newValue.a + SVGStore.dimensions.width / 2;

    newValue.f =
      -SVGStore.panToCoordinates.y * newValue.a +
      SVGStore.dimensions.height / 2;

    // This could be optimized to be declared out of the function
    action(() => {
      SVGStore.setValue(Object.assign({}, newValue));
      // });
      SVGStore.panToCoordinates = null;
    })();
  }
});

autorun(() => {
  if (SVGStore.encloseTheseCoords && SVGStore.viewer) {
    console.log(
      "In the autorun for the panToTheseClimbs" +
        JSON.stringify(SVGStore.encloseTheseCoords)
    );

    console.log("In the action in the autorun for panToTheseClimbs");
    SVGPanner.zoomPanToArea(
      SVGStore.encloseTheseCoords,
      SVGStore.value,
      SVGStore.viewer
    );

    SVGStore.encloseTheseCoords = null;
  }
});
// autorun(() => {
//   console.log("The auto run value is ");
//   console.log(JSON.stringify(SVGStore.value, null, 3));
//   console.trace("The auto run values are");
// });
SVGStore.setDimensions = action((width, height) => {
  // If the window is very small, i.e. the page is just starting
  // then go ahead and note the dimensions
  if (SVGStore.dimensions.width < 50 || SVGStore.dimensions.height < 50) {
    SVGStore.dimensions = { width, height };
    return;
  }
  // There is some twitching going on
  // The number 15 is arbitrarily selected to avoid the twitching.
  if (
    Math.abs(SVGStore.dimensions.width - width) > 15 ||
    Math.abs(SVGStore.dimensions.height - height) > 15
  ) {
    SVGStore.dimensions = { width, height };
  }
});

SVGStore.isOnScreen = (x, y) => {
  let value = SVGStore.value;
  let leftX = -value.e / value.a;
  let topY = -value.f / value.a;
  let rightX = leftX + value.viewerWidth / value.a;
  let bottomY = topY + value.viewerHeight / value.a;
  // The label is not on screen
  return x > leftX && x < rightX && y > topY && y < bottomY;
};

export default SVGStore;
