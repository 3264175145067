import React, { useState } from "react";
import { useObserver } from "mobx-react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";

import { HighlightChange, Button } from "ticker-components";

import ModifierIcon from "../ModifierIcon/ModifierIcon";

import { withRootStore, getGradeLabel } from "ticker-core";

import climbCoreStyle from "../../assets/jss/material-dashboard-pro-react/components/climbCoreStyle.js";
import RegradeDetailsDialog from "./RegradeDetailsDialog";

let useCardStyles = makeStyles(climbCoreStyle);

function ClimbCore({ rootStore, assembly }) {
  let classes = useCardStyles();

  let [showRegradeDetailsDialog, setShowRegradeDetailsDialog] = useState(false);

  return useObserver(() => {
    if (!assembly) {
      return null;
    }
    let btnStyle = {
      backgroundColor: assembly.markerColor.primaryHEX,
      color: assembly.markerTextColor
    };

    if (assembly.markerColor.secondaryHEX) {
      btnStyle.border = "solid 2px" + assembly.markerColor.secondaryHEX;
      btnStyle.textShadow = "2px 2px #aaaaaa";
    }
    return (
      <React.Fragment>
        {showRegradeDetailsDialog && (
          <RegradeDetailsDialog
            onClose={() => setShowRegradeDetailsDialog(false)}
            open={showRegradeDetailsDialog}
            assembly={assembly}
            rootStore={rootStore}
          />
        )}
        <div
          onClick={() => {
            setShowRegradeDetailsDialog(true);
          }}
          style={{
            cursor: "pointer",
            textAlign: "center"
          }}
          data-tour="gradeColor"
        >
          <Button style={btnStyle} size="sm">
            {getGradeLabel(assembly.climbingGrade)}
            <HighlightChange
              monitoredValue={assembly.climbingRoute.avgCombinedModifier}
            >
              <ModifierIcon
                className={classes.listItemIcon}
                modifier={assembly.relativeCombinedModifier}
              />
            </HighlightChange>
          </Button>
        </div>
      </React.Fragment>
    );
  });
}

ClimbCore.propTypes = {
  rootStore: PropTypes.object.isRequired,
  assembly: PropTypes.object.isRequired
};

export default withRootStore(ClimbCore);
