import React from "react";

import { withCachedRootStore, gradeTypes, SVGPanner } from "ticker-core";

import {
  Button,
  LoginRequest,
  RatingSlider,
  GradeSlider,
  Card,
  CardBody,
  GridItem,
  GridContainer,
  TitledDialog
} from "ticker-components";

import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

import Collapse from "@material-ui/core/Collapse";

import RouteFilter from "./RouteFilter";
import LabelledCheckBox from "../LabelledCheckBox/LabelledCheckBox";

import { customCheckboxRadioSwitchStyle } from "ticker-core";
import routeFilterStyle from "./routeFilterStyle";

import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Place from "@material-ui/icons/Place";

import useMediaQuery from "@material-ui/core/useMediaQuery";

let styles = {
  ...customCheckboxRadioSwitchStyle,
  ...routeFilterStyle,
  breaker: {
    borderBottom: "solid 1px #ddd",
    paddingBottom: "1em",
    paddingTop: "1em"
  },

  smallGap: {
    paddingLeft: "0.3em",
    margin: "auto !important",
    maxWidth: "20em"
  },
  centeredStyle: {
    position: "absolute",
    top: "50%",
    marginTop: "-2em",
    zIndex: 1,
    width: "100%",
    textAlign: "center"
  },
  typography: {
    padding: "1em"
  },
  padded: {
    paddingLeft: "1em",
    paddingRight: "1em"
  },
  blocking: {
    display: "block",
    textAlign: "center"
  },
  paper: {
    background: "white",
    width: "100%"
  },
  list: {
    margin: "0px",
    listStyleType: "none"
  },
  sliderContainer: {
    textAlign: "center",
    maxWidth: "50em",
    margin: "auto",
    paddingTop: "3em",
    paddingLeft: "2em",
    paddingRight: "2em"
  }
};

let useStyles = makeStyles(styles);

export default withCachedRootStore(
  observer(function SelectionFilterPopper(props) {
    let { rootStore, closeFilter } = props;

    let classes = useStyles();

    // ??? Theme is always null???
    // const theme = useTheme();
    // const viewingDialog = useMediaQuery(theme.breakpoints.down("sm"));
    const viewingDialog = useMediaQuery("(max-width:600px)");

    let {
      personalRootStore,
      regStores,
      SVGStore,
      selectionStore,
      isClimberSite
    } = rootStore;

    let { regStore } = regStores;

    let { inspirationStore, inspirationUIOptions } = personalRootStore;

    let { transitionStore } = inspirationStore;

    let { UISelection } = inspirationStore.mapPageSelection;

    let selections = [];
    if (regStore.isLoggedIn || isClimberSite) {
      selections = inspirationUIOptions.getSelectionOptions({
        allowNoSelection: true,
        fieldName: "hasClimbed",
        mapObj: {
          Unclimbed: false,
          Climbed: true
        },
        pageName: "mapPageSelection"
      });
    }

    /*
     * This JSON.stringify is a hack to force mobx to recognise the changes in the showSectionIds object
     * Do not delete
     */
    JSON.stringify(UISelection.hasClimbed);
    /*
     * End Hack
     */

    let getRouteCheckbox = (boxGradeType, label) => (
      <LabelledCheckBox
        SVGStore={rootStore.SVGStore}
        onChange={() => {
          console.log("The checkbox was changed");
          rootStore.regStores.regStore.resetNoActivityTimeout();
          transitionStore.toggleGradeTypeSelection(
            "mapPageSelection",
            boxGradeType,
            false
          );
        }}
        checked={
          inspirationStore.mapPageSelection.UISelection.showGradeTypes[
            boxGradeType
          ]
        }
        labelText={
          inspirationStore.mapPageSelection.UISelection.showGradeTypes[
            boxGradeType
          ]
            ? undefined
            : label
        }
        className={classes.padded}
        labelClass={classes.plainLabel + " " + classes.smallLabel}
      />
    );

    let getGradeSliderContainer = (
      containerGradeType,
      checkBoxLabel,
      sliderLabel
    ) => {
      if (
        inspirationStore.mapPageSelection.UISelection.showGradeTypes[
          containerGradeType
        ]
      ) {
        let routeCheckbox = getRouteCheckbox(containerGradeType);
        return (
          <RouteFilter
            label={sliderLabel}
            showSlider={SVGStore.showFilter && !selectionStore.showSelected}
            prelabel={routeCheckbox}
          >
            <>
              <GradeSlider
                gradeType={containerGradeType}
                fadeDelay={transitionStore.FADE_OUT_DURATION}
                setFadeIn={transitionStore.startFadeChange}
                pageSelection={inspirationStore.mapPageSelection}
                containerClass={classes.sliderContainer}
              />
            </>
          </RouteFilter>
        );
      } else {
        return (
          <>
            <GridContainer style={{ textAlign: "left" }}>
              <GridItem xs={12}>
                {getRouteCheckbox(containerGradeType, checkBoxLabel)}
              </GridItem>
            </GridContainer>
          </>
        );
      }
    };
    let routeSliderContainer = getGradeSliderContainer(
      gradeTypes.ROUTE,
      "Show Routes",
      "Filter routes by grade"
    );
    let boulderSliderContainer = getGradeSliderContainer(
      gradeTypes.BOULDER,
      "Show Boulders",
      "Filter boulders by grade"
    );

    let zoomToSelection = () => {
      let filteredRoutesWrapper = inspirationStore.filteredMapPage.get();

      let assemblies = filteredRoutesWrapper.climbingRouteAssemblies;

      let enclosingCoords = SVGPanner.getEnclosingCoords(assemblies);

      SVGStore.encloseTheseCoords = enclosingCoords;
      console.log("AFter assigning to the panToTheseClimbs");

      if (viewingDialog) {
        closeFilter();
      }
    };
    let content = (
      <>
        <div className={classes.typography}>
          <ul className={classes.list + "  " + classes.smallGap}>
            <li className={classes.breaker} style={{ textAlign: "center" }}>
              <span>
                {selections.map((option, index) => {
                  if (!option) return undefined;
                  return (
                    <LabelledCheckBox
                      key={index}
                      SVGStore={SVGStore}
                      onChange={() => {
                        regStore.resetNoActivityTimeout();
                        option.onClick();
                      }}
                      checked={option.isSelected}
                      labelText={option.label}
                      className={classes.padded}
                      labelClass={classes.plainLabel + " " + classes.smallLabel}
                    />
                  );
                })}
              </span>
              {!regStore.isLoggedIn && !rootStore.isClimberSite && (
                <div className={classes.label + " " + classes.blocking}>
                  <LoginRequest instruction=" to filter based on your history." />
                </div>
              )}
            </li>
            <li className={classes.breaker}>{routeSliderContainer}</li>
            <li className={classes.breaker}>{boulderSliderContainer}</li>
            <li className={classes.breaker}>
              <RouteFilter
                label="Filter by public rating"
                showSlider={SVGStore.showFilter && !selectionStore.showSelected}
              >
                <RatingSlider
                  fadeDelay={transitionStore.FADE_OUT_DURATION}
                  setFadeIn={transitionStore.startFadeChange}
                  pageSelection={inspirationStore.mapPageSelection}
                  ratingType="publicRating"
                  containerClass={classes.sliderContainer}
                />
              </RouteFilter>
            </li>
            <li className={classes.breaker}>
              {!regStore.isLoggedIn && !rootStore.isClimberSite && (
                <div style={{ textAlign: "center" }}>
                  <LoginRequest instruction=" to filter your favourites." />
                </div>
              )}
              {(regStore.isLoggedIn || rootStore.isClimberSite) && (
                <RouteFilter
                  label="Filter by my rating"
                  showSlider={
                    SVGStore.showFilter && !selectionStore.showSelected
                  }
                >
                  <RatingSlider
                    fadeDelay={transitionStore.FADE_OUT_DURATION}
                    setFadeIn={transitionStore.startFadeChange}
                    pageSelection={inspirationStore.mapPageSelection}
                    ratingType="personalRating"
                    containerClass={classes.sliderContainer}
                  />
                </RouteFilter>
              )}
            </li>
          </ul>
        </div>
        <GridContainer>
          <GridItem xs={2}></GridItem>
          <GridItem xs={8}>
            <Button
              color="info"
              simple
              onClick={inspirationStore.clearSelection}
            >
              Clear filters
            </Button>
          </GridItem>
          <GridItem xs={2}>
            <Button onClick={zoomToSelection} justIcon simple color="info">
              <Place />
            </Button>
          </GridItem>
        </GridContainer>
      </>
    );

    return (
      <>
        <Hidden xsDown>
          <Collapse in={SVGStore.showFilter}>
            <Card popUp raised>
              <CardBody>{content}</CardBody>
            </Card>
          </Collapse>
        </Hidden>
        <Hidden smUp>
          <TitledDialog
            open={SVGStore.showFilter}
            dialogId="filters"
            onClose={closeFilter}
          >
            {content}
          </TitledDialog>
        </Hidden>
      </>
    );
  })
);
