import React, { memo } from "react";

import { PropTypes } from "prop-types";

let tickScale = 0.4;
let tickPath2 = [
  "M",
  -111, //placeholder
  -111, //placeholder
  "l",
  -4.17 * tickScale,
  -4.17 * tickScale,
  "l",
  -1.42 * tickScale,
  1.41 * tickScale,
  "l",
  5.59 * tickScale,
  5.59 * tickScale,
  12 * tickScale,
  -12 * tickScale,
  "l",
  -1.41 * tickScale,
  -1.41 * tickScale,
  "z"
];

function RouteViewL2(props) {
  let { labelCornerX, labelCornerY, paddedWidth } = props;

  let roundedCorners = 3;

  tickPath2[1] = labelCornerX + paddedWidth - roundedCorners / 2;
  tickPath2[2] = labelCornerY + 2;

  let circleXCenter = labelCornerX + paddedWidth - roundedCorners / 2 + 1.3;

  return (
    <>
      <circle
        cx={circleXCenter}
        cy={labelCornerY + 0.35}
        r="5"
        stroke="white"
        strokeWidth="1.5"
        fill="green"
        vectorEffect="non-scaling-stroke"
      />
      <path d={tickPath2.join(" ")} fill="white" />
    </>
  );
}

RouteViewL2.propTypes = {
  labelCornerY: PropTypes.number.isRequired,
  labelCornerX: PropTypes.number.isRequired,
  paddedWidth: PropTypes.number.isRequired
};

export default memo(RouteViewL2);
