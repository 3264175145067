import React from "react";
import { useObserver } from "mobx-react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { Card, CardBody, LoginRequest } from "ticker-components";

import { withRootStore } from "ticker-core";

import ByTime from "./ByTime";

import tickStyles from "../../../assets/jss/material-dashboard-pro-react/views/registration/ticksPageStyle.js";

const TicksPage = function({
  classes,
  rootStore: {
    userStores: { profileStore }
  }
}) {
  return useObserver(() => {
    return (
      <>
        <div className={classes.slenderContainer}>
          <Card narrowWhite className={classes.highTop}>
            <CardBody narrowWhite>
              {profileStore.hasLoaded ? (
                <ByTime />
              ) : (
                <div style={{ padding: "2em" }}>
                  <LoginRequest instruction=" to view your climbing history." />
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </>
    );
  });
};

TicksPage.propTypes = {
  classes: PropTypes.object
};

export default withRootStore(withStyles(tickStyles)(TicksPage));
