import { byLengthOfSlot } from "../ReportStoreUtility";

let fillSlotsCopy = function fillSlotsCopy(wallAssembly) {
  if (!wallAssembly.slotConfigsCopy) {
    // I'm doing this array copy manually to try to avoid getting the observable fluff.
    // So don't use slice.
    wallAssembly.slotConfigsCopy = [];
    for (let slot of wallAssembly.climbingWall.slotConfigsJSON) {
      let newSlot = [];
      for (let gradeObj of slot) {
        newSlot.push(gradeObj);
      }
      wallAssembly.slotConfigsCopy.push(newSlot);
    }
  }

  // If not all slots have been defined for this wall
  // then we'll fill up the remaining slotConfigs as 'unassigned'
  // which is represented by an empty array
  if (
    wallAssembly.slotConfigsCopy.length <
    wallAssembly.climbingWall.slotCountTarget
  ) {
    for (
      let i = wallAssembly.slotConfigsJSON.length;
      i < wallAssembly.slotCountTarget;
      i++
    ) {
      wallAssembly.slotConfigsCopy.push([]);
    }
  }

  // The executeRouteAi.isValidStructure method requires each slot to have an wall.
  wallAssembly.slotConfigsCopy.forEach(function(slot) {
    slot.wallAssembly = wallAssembly;
  });

  /* By ordering the slots from shortest to longest we can increase the chance of the
   * optimum slot being used.
   * e.g. if we have two slots [blue, orange] and [blue], then we want to have the [blue] one listed first.
   * That way it will get mapped first by the executeRouteAi leaving the more flexible [blue, orange] slot open.
   */
  wallAssembly.slotConfigsCopy.sort(byLengthOfSlot);
};

let fillDefaultRoutesAndSlots = function fillDefaultRoutesAndSlots(
  wallAssembly
) {
  fillSlotsCopy(wallAssembly);

  if (!wallAssembly.routeList) {
    wallAssembly.routeList = [];
  }

  // This validity check is used in the isValidStructure method in executeRouteAi
  // The check (at the moment) only checks for duplicates within an wall.
  wallAssembly.gradesOfPreexistingRoutes = [];

  // We need to use the pretendClimbAsseblies if they exist
  // otherwise we need to default to the climbingRouteAssemblies
  let bestViewOfWallAssemblies = wallAssembly.climbingRouteAssemblies;
  if (wallAssembly.pretendClimbAssemblies) {
    bestViewOfWallAssemblies = wallAssembly.pretendClimbAssemblies;
  }

  bestViewOfWallAssemblies.forEach(function(routeAssembly) {
    wallAssembly.gradesOfPreexistingRoutes.push(routeAssembly.climbingRoute.id);
  });
};

export default {
  fillSlotsCopy,
  fillDefaultRoutesAndSlots
};
