import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import ClimbCore from "../ClimbCore/ClimbCore.js";

function ClimbCard(props) {
  let { narrowTransparent } = props;
  return (
    <>
      <Card narrowTransparent={narrowTransparent} route>
        <CardBody narrowTransparent={narrowTransparent} route>
          <ClimbCore {...props} />
        </CardBody>
      </Card>
    </>
  );
}

ClimbCard.propTypes = {
  narrowWhite: PropTypes.bool,
  narrowTransparent: PropTypes.bool
};

export default ClimbCard;
