import React from "react";

import ReactResizeDetector from "react-resize-detector";

import { withCachedRootStore } from "ticker-core";

export default withCachedRootStore(function SizedMap(props) {
  let { SVGStore } = props.rootStore;

  return (
    <ReactResizeDetector handleHeight handleWidth>
      {({ width, height }) => {
        if (width && height) {
          if (
            width !== SVGStore.dimensions.width ||
            height !== SVGStore.dimensions.height
          ) {
            SVGStore.setDimensions(width, height);
          }
        }
        console.log(
          "Rerendering the Sized Map with dimensions:" + width + "... " + height
        );
        return (
          <div id="hereWeAre" style={{ height: "100vh", overflow: "hidden" }}>
            {width && height && props.children}
          </div>
        );
      }}
    </ReactResizeDetector>
  );
});
