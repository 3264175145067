export default class {
  constructor(context, asyncOpCreate) {
    let rest = context.rest;

    this.opGetEntryGraph = asyncOpCreate(
      payload => rest.get("entryGraph", payload),
      "opGetEntryGraph"
    );
    this.getEntryGraph = () => {
      this.opGetEntryGraph.execute();
    };
  }
}
