import snackbarContentStyle from "../../material-dashboard-pro-react/components/snackbarContentStyle.js";

const draggableStyle = {
  draggable: {
    width: "100%"
  },
  thin: {
    padding: 0,
    margin: 0
  },
  marginRight: {
    marginRight: "1em !important"
  },
  changeFont: {
    fontSize: "20px"
  },
  centerText: {
    textAlign: "center"
  },
  verticalAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  verticalRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "right"
  },
  cursor: {
    cursor: "pointer"
  },
  ...snackbarContentStyle
};

export default draggableStyle;
