import React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Check from "@material-ui/icons/Check";

import { makeStyles } from "@material-ui/styles";

import { customCheckboxRadioSwitchStyle } from "ticker-core";

let useCheckBoxStyles = makeStyles(customCheckboxRadioSwitchStyle);

let LabelledCheckBox = props => {
  let classes = useCheckBoxStyles();
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={props.disabled}
          onChange={props.onChange}
          checked={props.checked}
          checkedIcon={
            <Check
              className={
                props.noBorder
                  ? classes.checkedIconNoBorder
                  : classes.checkedIcon
              }
            />
          }
          icon={
            <Check
              className={
                props.noBorder
                  ? classes.uncheckedIconNoBorder
                  : classes.uncheckedIcon
              }
            />
          }
          classes={{
            checked: classes.checked,
            root: classes.checkRootMinimal
          }}
        />
      }
      classes={{
        label: props.small ? classes.smallLabel : props.labelClass,
        root: classes.labelRoot
      }}
      label={props.labelText}
      className={props.className}
    />
  );
};

LabelledCheckBox.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  labelText: PropTypes.string.isRequired,
  className: PropTypes.string,
  noBorder: PropTypes.bool,
  labelClass: PropTypes.string,
  small: PropTypes.bool
};
export default LabelledCheckBox;
