import { action } from "mobx";

export default function getInspirationUIOptions(inspirationStore, regStore) {
  return {
    getSelectionOptions: config => {
      let {
        allowNoSelection,
        pageName,
        fieldName,
        mapObj,
        wellPadded
      } = config;
      let { UISelection } = inspirationStore[pageName];
      let { transitionStore } = inspirationStore;

      return Object.keys(mapObj).map(key => ({
        onClick: action(function() {
          regStore.resetNoActivityTimeout();
          let newSelection;
          if (allowNoSelection) {
            newSelection =
              UISelection[fieldName] === mapObj[key] ? undefined : mapObj[key];
          } else {
            newSelection = mapObj[key];
          }
          transitionStore.changeSelection(pageName, fieldName, newSelection);
        }),
        label: key,
        isSelected: mapObj[key] === UISelection[fieldName],
        showCross: allowNoSelection ? true : false,
        wellPadded: wellPadded
      }));
    }
  };
}
