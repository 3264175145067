import React, { useState } from "react";

import PropTypes from "prop-types";

import Datetime from "react-datetime";

import { withRootStore } from "ticker-core";
import moment from "moment";

import dateSelectionPageStyle from "../../assets/jss/material-dashboard-pro-react/views/registration/dateSelectionPageStyle";
import { makeStyles } from "@material-ui/core";

import { Card, CardBody, Button } from "ticker-components";

let useStyles = makeStyles(dateSelectionPageStyle);

let timeRegExp = /[0-1]?\d|2[0-3]:[0-5]?\d/g;
let dateRegExp = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

var isValid = function(current) {
  let now = moment();
  return current.isBefore(now);
};

const DateSelectionPage = function({ rootStore }) {
  let [dateSelection, setDateSelection] = useState(
    moment().format("YYYY-MM-DD")
  );
  let [timeSelection, setTimeSelection] = useState("12:00:00");
  let [warning, setWarning] = useState();

  let classes = useStyles();

  const {
    personalRootStore: {
      personalLogEntryStores: { logEntryStore }
    },
    regStores: { regStore }
  } = rootStore;

  let submitDateAndTime = () => {
    let newWarning = "";
    if (!dateSelection) {
      newWarning = "You must select a date. ";
    } else {
      if (typeof dateSelection === "string") {
        if (!dateRegExp.test(dateSelection)) {
          newWarning = "The date must be in the format YYYY-MM-DD.";
        }
      } else {
        dateSelection = dateSelection.format("YYYY-MM-DD");
      }
    }
    if (moment(dateSelection).isAfter(moment())) {
      newWarning = "You can't time travel from the future.";
    }

    if (!timeSelection) {
      newWarning += "You must select a time. ";
    } else {
      if (typeof timeSelection === "string") {
        if (!timeRegExp.test(timeSelection)) {
          newWarning += "The time must be in the format HH:MM:ss";
        }
      } else {
        timeSelection = timeSelection.format("HH:mm:ss");
      }
    }

    if (newWarning !== "") {
      setWarning(newWarning);
      return false;
    }

    logEntryStore.setDate(dateSelection);
    logEntryStore.setTime(timeSelection);
    return true;
  };
  let goBack = () => {
    regStore.resetNoActivityTimeout();
    rootStore.history.goBack();
  };

  return (
    <div className={classes.container} style={{ textAlign: "center" }}>
      <Card>
        <CardBody>
          <h4>When did you climb the climbs that you are about to record?</h4>
          <div>
            You can change the date here to record climbs that you did in the
            past.
          </div>
          {logEntryStore.dateOptions.map(dateOption => {
            return (
              <Button
                key={dateOption.label}
                className={classes.button + " " + classes.success}
                onClick={() => {
                  dateOption.onClick();
                  goBack();
                }}
                size="sm"
              >
                {dateOption.label}
              </Button>
            );
          })}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h4>Or select a date and time </h4>
          <div style={{ maxWidth: "20em", margin: "auto" }}>
            <Datetime
              timeFormat={false}
              defaultValue={moment(logEntryStore.dateStr)}
              closeOnSelect={false}
              dateFormat={"YYYY-MM-DD"}
              onChange={value => {
                setDateSelection(value);
                setWarning("");
              }}
              input={false}
              isValidDate={isValid}
            />
          </div>
          <div style={{ maxWidth: "20em", margin: "auto" }}>
            <Datetime
              timeFormat={"HH:mm"}
              dateFormat={false}
              defaultValue={
                logEntryStore.dateStr ? logEntryStore.timeStr : "12:00"
              }
              closeOnSelect={false}
              onChange={value => {
                setTimeSelection(value);
                setWarning("");
              }}
              input={false}
              isValidDate={isValid}
            />
          </div>
          <div className={classes.warning}>{warning && warning}</div>
          <div>
            <Button
              color="success"
              onClick={() => {
                if (submitDateAndTime()) {
                  goBack();
                }
              }}
            >
              Go
            </Button>
            <Button color="danger" onClick={goBack}>
              Cancel
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

DateSelectionPage.propTypes = {
  rootStore: PropTypes.object,
  classes: PropTypes.object
};
export default withRootStore(DateSelectionPage);
