import { observable, action } from "mobx";
import Axios from "axios";

import constants from "../variables/constants";

export default function createGymStore(context, userStores, asyncOpCreate) {
  const rest = context.rest;

  return observable({
    name: "unset",
    id: null,
    duplicateRouteLongevityInfluence: 3,
    wallAgeLongevityInfluence: 3,
    routeAgeLongevityInfluence: 3,
    expectedRouteDuration: 0,
    expectedBoulderDuration: 0,
    minimumRatingCount: 5,
    shortageLongevityInfluence: 3,
    climbQuantitiesJSON: [],
    gradeLongevityAdjustmentsJSON: [],
    ratingLongevityInfluence: 3,
    default_route_status_id: "",
    hasLoaded: false,
    imageWidth: 1224,
    imageHeight: 2000,

    getStoreName: () => {
      return "gymStore";
    },
    opCreateGym: asyncOpCreate(
      payload => rest.post("gyms", payload),
      "createGym"
    ),
    createGym: action(async function(name) {
      const payload = { name: name };
      try {
        await this.opCreateGym.execute(payload, newGym => {
          this.mergeGym(this, newGym);
        });
      } catch (error) {
        console.error("Failed to create the gym" + error);
        throw error;
      }
    }),
    opUpdateGym: asyncOpCreate(
      ({ payload, id }) => rest.put("gyms/" + id, payload),
      "updateGym"
    ),

    updateGym: action(async function() {
      const payload = {
        name: this.name,
        id: this.id,
        duplicateRouteLongevityInfluence: this.duplicateRouteLongevityInfluence,
        wallAgeLongevityInfluence: this.wallAgeLongevityInfluence,
        routeAgeLongevityInfluence: this.routeAgeLongevityInfluence,
        expectedRouteDuration: this.expectedRouteDuration,
        expectedBoulderDuration: this.expectedBoulderDuration,
        minimumRatingCount: this.minimumRatingCount,
        shortageLongevityInfluence: this.shortageLongevityInfluence,
        climbQuantitiesJSON: this.climbQuantitiesJSON,
        gradeLongevityAdjustmentsJSON: this.gradeLongevityAdjustmentsJSON,
        ratingLongevityInfluence: this.ratingLongevityInfluence,
        default_route_status_id: this.default_route_status_id
      };
      try {
        await this.opUpdateGym.execute({ payload, id: payload.id });
      } catch (error) {
        console.error("Failed to create the gym" + error);
        throw error;
      }
    }),
    opDeleteGym: asyncOpCreate(
      payload => rest.delete("gyms", payload),
      "deleteGym"
    ),
    deleteGym: action(async function() {
      try {
        await this.opDeleteGym.execute({ id: this.id });
        this.id = null;
        this.name = null;
        this.hasLoaded = false;
        userStores.profileStore.gym_id = null;
      } catch (error) {
        console.error("Failed to create the gym" + error);
        throw error;
      }
    }),
    //This cacheItems wont work for getting another gym obviously
    opCacheItems: asyncOpCreate(({ id }) => {
      return rest.get("gyms/" + id);
    }, "cacheGyms"),

    // This is to start loading the image into the browser before it is required
    opCacheFloorPlan: asyncOpCreate(
      () => {
        return Axios({
          method: "GET",
          url: "/FloorPlan.png",
          // params: [],
          // data,
          withCredentials: true,
          // headers,
          timeout: 30e3
          // paramsSerializer(params) {
          //   return Qs.stringify(params, { arrayFormat: "brackets" });
          // }
        });
      },
      //   () => rest.get("/FloorPlan.png"),
      "cacheFloorPlan"
    ),
    getGradeAdjustment: function(gradeId) {
      for (let i = 0; i < this.gradeLongevityAdjustmentsJSON.length; i++) {
        if (
          this.gradeLongevityAdjustmentsJSON[i].climbing_grade_id === gradeId
        ) {
          return this.gradeLongevityAdjustmentsJSON[i].adjustment;
        }
      }
    },

    cacheItems: action(function() {
      this.hasLoaded = false;
      try {
        let targetGym =
          userStores.profileStore.gym_id !== null &&
          typeof userStores.profileStore.gym_id !== "undefined"
            ? userStores.profileStore.gym_id
            : userStores.profileStore.selected_gym_id;

        if (targetGym === null || typeof targetGym === "undefined") {
          console.log("Defaulting to HangDog: " + constants.HANGDOG_ID);
          targetGym = constants.HANGDOG_ID;
        }

        //this.opCacheFloorPlan.execute();
        return this.opCacheItems.execute(
          {
            id: targetGym
          },
          response => {
            this.mergeGym(this, response);
            this.hasLoaded = true;
          }
        );
        // } else {
        //   console.log(
        //     "Not the caching the gym because there is no gym_id in the profile. The profile auto loader might not have finished yet."
        //   );
        // }
      } catch (error) {
        if (error === null || typeof error === "undefined") {
          console.log(
            "The call to 'ownGym' has failed inside the GymStore. The error is undefined."
          );
        }
        if (error.response) {
          const {
            response: { status }
          } = error;
          if (401 === status) {
            console.log(
              "The call to 'ownGym' has returned with 401. The user is probably not logged in"
            );
          } else {
            console.log("The call to 'ownGym' has failed: " + error);
          }
        } else {
          console.log("The error doesn't have a response: " + error);
        }
        throw error;
      }
    }),

    /*
     *
     * WARNING BROOK: If you try to access the gymStore values using 'this'
     * it doesn't work for some reason. That is why gymStore is passed in as an object.
     */
    mergeGym: action((gymStore, newGym) => {
      gymStore.name = newGym.name;
      gymStore.id = parseInt(newGym.id, 10);
      gymStore.duplicateRouteLongevityInfluence = parseInt(
        newGym.duplicateRouteLongevityInfluence,
        10
      );
      gymStore.wallAgeLongevityInfluence = parseInt(
        newGym.wallAgeLongevityInfluence,
        10
      );
      gymStore.routeAgeLongevityInfluence = parseInt(
        newGym.routeAgeLongevityInfluence,
        10
      );
      gymStore.expectedRouteDuration = parseInt(
        newGym.expectedRouteDuration,
        10
      );
      gymStore.expectedBoulderDuration = parseInt(
        newGym.expectedBoulderDuration,
        10
      );
      gymStore.minimumRatingCount = parseInt(newGym.minimumRatingCount, 10);
      gymStore.shortageLongevityInfluence = parseInt(
        newGym.shortageLongevityInfluence,
        10
      );
      gymStore.climbQuantitiesJSON = newGym.climbQuantitiesJSON;
      gymStore.gradeLongevityAdjustmentsJSON =
        newGym.gradeLongevityAdjustmentsJSON;
      gymStore.ratingLongevityInfluence = parseInt(
        newGym.ratingLongevityInfluence,
        10
      );
      gymStore.default_route_status_id = parseInt(
        newGym.default_route_status_id,
        10
      );
    })
  });
}
