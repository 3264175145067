import { observable, action, autorun } from "mobx";

import constants from "../variables/constants";

import SVGLocator from "../utils/SVGLocator";

let selectionStore = observable({
  currentDragObject: null,
  currentSelectedId: null,
  currentSelected: null,
  isMovingLineEnd: false,
  objectWasClicked: false,
  creatingNewObject: false,
  newClimbingObject: null,
  // newObjectPositioned: false,
  hasObjectMoved: false,
  showSelected: false,
  showFilter: false,
  changeApprovalNeeded: false,
  onDiscardApprovedFn: null,
  hideOtherObjects: false,
  pointerMoved: false,
  wasLongPressed: observable(new Map()),
  changesWereSaved: false,
  creatingDoubleClickTimeout: null
});

let prev = null;
autorun(() => {
  console.log(
    "The creatingDoubleClickTimeout is changing from:" +
      prev +
      " to " +
      selectionStore.creatingDoubleClickTimeout
  );
}, [selectionStore.creatingDoubleClickTimeout]);

// let prev = {};
// autorun(() => {
//   const changedProps = Object.entries(SVGStore).reduce((ps, [k, v]) => {
//     if (prev[k] !== v) {
//       ps[k] = [prev[k], v];
//       console.log("yyyyyyxxxxxDifferent", k);
//     } else {
//       console.log("yyyyyyxxxxxThe same", k);
//     }
//     return ps;
//   }, {});
//   if (Object.keys(changedProps).length > 0) {
//     console.log("yyyyyyyyyyyxxxxxxxxxxxxxxxxxxxx Changed props:", changedProps);
//   }

//   prev.currentDragObject = SVGStore.currentDragObject;
//   prev.currentSelectedId = SVGStore.currentSelectedId;
//   prev.isMovingLineEnd = SVGStore.isMovingLineEnd;
//   prev.objectWasClicked = SVGStore.objectWasClicked;
//   prev.creatingNewObject = SVGStore.creatingNewObject;
//   prev.newClimbingObject = SVGStore.newClimbingObject;
//   prev.newObjectPositioned = SVGStore.newObjectPositioned;
//   prev.hasObjectMoved = SVGStore.hasObjectMoved;
//   prev.value = SVGStore.value;
//   prev.panToCoordinates = SVGStore.panToCoordinates;
//   prev.showSelected = SVGStore.showSelected;
//   prev.limitRoutesOnScreen = SVGStore.limitRoutesOnScreen;
//   prev.showFilter = SVGStore.showFilter;

//   console.log("Something changed in the SVGStore.");
//   console.log(
//     "The value of currentDragObject is =" +
//       SVGStore.currentDragObject
//   );
//   console.log(
//     "The value of currentSelectedId is =" + SVGStore.currentSelectedId
//   );
//   console.log(
//     "The value of isMovingLineEnd is =" + SVGStore.isMovingLineEnd
//   );
//   console.log("The value of objectWasClicked is =" + SVGStore.objectWasClicked);
//   console.log("The value of creatingNewObject is =" + SVGStore.creatingNewObject);
//   console.log("The value of newClimbingObject is =" + SVGStore.newClimbingObject);
//   console.log(
//     "The value of newObjectPositioned is =" + SVGStore.newObjectPositioned
//   );
//   console.log(
//     "The value of hasObjectMoved is =" + SVGStore.hasObjectMoved
//   );
//   console.log("The value of value is =" + SVGStore.value);
//   console.log("The value of panToCoordinates is =" + SVGStore.panToCoordinates);
//   console.log("The value of showSelected is =" + SVGStore.showSelected);
//   console.log(
//     "The value of limitRoutesOnScreen is =" + SVGStore.limitRoutesOnScreen
//   );
//   console.log("The value of showFilter is =" + SVGStore.showFilter);
// }, [
//   SVGStore.currentDragObject,
//   SVGStore.currentSelectedId,
//   SVGStore.isMovingLineEnd,
//   SVGStore.objectWasClicked,
//   SVGStore.creatingNewObject,
//   SVGStore.newClimbingObject,
//   SVGStore.newObjectPositioned,
//   SVGStore.hasObjectMoved,
//   SVGStore.value,
//   SVGStore.panToCoordinates,
//   SVGStore.showSelected,
//   SVGStore.limitRoutesOnScreen,
//   SVGStore.showFilter
// ]);

selectionStore.clearSelection = action(function() {
  selectionStore.currentDragObject = null;
  selectionStore.currentSelectedId = null;
  selectionStore.currentSelected = null;
  selectionStore.isMovingLineEnd = false;
  selectionStore.objectWasClicked = false;
  selectionStore.creatingNewObject = false;
  selectionStore.newClimbingObject = null;
  // selectionStore.newObjectPositioned = false;
  selectionStore.hasObjectMoved = false;
  selectionStore.showSelected = false;
  selectionStore.isFormEdited = false;
  selectionStore.changeApprovalNeeded = false;
  selectionStore.onDiscardApprovedFn = null;
  selectionStore.hideOtherObjects = false;
  selectionStore.pointerMoved = false;
  // Clear the observable array
  selectionStore.wasLongPressed.clear();
  selectionStore.changesWereSaved = false;
});

selectionStore.clearPersonalData = () => {
  selectionStore.clearSelection();
};

selectionStore.clearNewObject = function() {
  selectionStore.newClimbingObject = undefined;
  selectionStore.currentSelectedId = undefined;
  selectionStore.currentSelected = undefined;
};

selectionStore.createNewObjectInStore = action(function(
  coords,
  lineEndCoords,
  callBack
) {
  console.log("Creating new object in store.");

  let newClimbingObject = {};
  //climbingRouteStore.merge(climbingRouteStore, newClimbingObject);

  newClimbingObject.labelX = Math.floor(coords.x);
  newClimbingObject.labelY = Math.floor(coords.y);

  if (lineEndCoords) {
    newClimbingObject.lineEndX = Math.floor(lineEndCoords.x);
    newClimbingObject.lineEndY = Math.floor(lineEndCoords.y);
  }
  // climbingRouteStores.climbingRouteStore.merge(
  //   newClimbingObject,
  //   climbingRouteStores.climbingRouteStore
  // );
  if (selectionStore.isFormEdited) {
    // console.log("Change approval needed. New Object not created yet");
    selectionStore.onDiscardApprovedFn = () => {
      creationApproved(newClimbingObject, callBack);
    };
    selectionStore.changeApprovalNeeded = true;
    return;
  }
  creationApproved(newClimbingObject, callBack);
});

let creationApproved = action((newClimbingObject, callBack) => {
  // console.log(
  //   "Change approval GRANTED(or never needed). New Object will be created:",
  //   newClimbingObject
  // );
  selectionStore.isFormEdited = false;
  selectionStore.creatingNewObject = true;
  selectionStore.newClimbingObject = newClimbingObject;
  selectionStore.currentSelectedId = undefined;
  selectionStore.currentSelected = undefined;
  // selectionStore.newObjectPositioned = false;
  if (callBack) {
    callBack();
  }
});

selectionStore.addNearestWall = computedCache => {
  let nearestWA = SVGLocator.nearestWall(
    computedCache,
    selectionStore.newClimbingObject
  );
  if (nearestWA) {
    selectionStore.newClimbingObject.climbing_wall_id =
      nearestWA.climbingWall.id;
  }
};

selectionStore.getCurrentDragObject = () => {
  if (
    selectionStore.creatingNewObject &&
    selectionStore.newClimbingObject &&
    // Note: if both of these are undefined then we are dragging the new route
    selectionStore.currentDragObject &&
    selectionStore.currentDragObject.id === selectionStore.newClimbingObject.id
  ) {
    return selectionStore.newClimbingObject;
  } else if (selectionStore.currentDragObject) {
    return selectionStore.currentDragObject;
  }
  return undefined;
};

// SVGStore.setCreatingNewObject = newValue => {
//   console.log("Setting timeout");
//   SVGStore.clearDoubleClickTimeout();
//   if (SVGStore.creatingNewObjectTimeout === newValue) return;
//   console.log("Setting timeout because it has changed:" + newValue);

//   if (newValue) {
//     // This is wrapped in a timeout so that a double click can be detected and stop
//     // the "New Route" image being flashed on the screen
//     SVGStore.creatingNewObjectTimeout = setTimeout(() => {
//       console.log("The time out has gone off");
//       SVGStore.creatingNewObject = true;
//     }, 250);
//   } else {
//     SVGStore.newClimbingObject = false;
//   }
// };

/*
 * NOTE: These two functions were to avoid flashing the "New Route" on the map
 * and then having the double click clear it away.
 * It isn't working at the moment, so instead of calling theFn after a timeout
 * I'm calling theFn() immediately which defeats the point of these two SVGStore functions
 * I'm leaving them here so I can work on them in the future.
 * To recreate my current problem. Comment out "theFn()" and comment in the setTimeout(theFn)
 */
selectionStore.setDoubleClickTimeout = theFn => {
  if (selectionStore.creatingDoubleClickTimeout) {
    selectionStore.clearDoubleClickTimeout();
  }

  //theFn();
  selectionStore.creatingDoubleClickTimeout = setTimeout(() => {
    selectionStore.clearDoubleClickTimeout();
    theFn();
  }, constants.DOUBLE_TAP_DELAY);
};

selectionStore.clearDoubleClickTimeout = () => {
  if (selectionStore.creatingDoubleClickTimeout) {
    clearTimeout(selectionStore.creatingDoubleClickTimeout);
    selectionStore.creatingDoubleClickTimeout = undefined;
  }
};

export default selectionStore;
