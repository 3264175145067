import Recycler from "./Recycler.js";
import printMappings from "./printMappings";
import createMappingsContainer from "./createMappingsContainer";

let MAX_MS = 20;
export default function executeRouteAi(
  climbAssembliesInput,
  slotsInput,
  options
) {
  let recycler = new Recycler();

  let deepestMappings = createMappingsContainer();
  let currentMappings = createMappingsContainer();

  if (!climbAssembliesInput)
    console.log(
      "Object List (AKA ClimbingGrades) not declared in constructor of RouteAI"
    );
  if (!slotsInput) console.log("slots not declared in constructor of RouteAI");

  if (typeof climbAssembliesInput[0] === "string") {
    console.log(
      "*******************************************************************"
    );
    console.log(
      "The climbs must be an obj so that I can attach things to them"
    );
    debugger;
  }

  let debugMode = options && options.debugMode === true;
  let checkPreExistingRoutes = options.checkPreExistingRoutes !== false;

  let climbAssemblies = climbAssembliesInput ? climbAssembliesInput : [];
  let slots = slotsInput ? slotsInput : [];

  let wallToGradeListMap = new Map();
  let validSlotWrappersMap = new Map();
  let deepestStickingPoints = {};
  let deepestStickingPointsList = [];

  let countOfFailsAtDeepestSearchSoFar = 0;

  let iterationsCounter = 0;
  let startTime = Date.now();
  let outOfTime = false;
  let bestEffortReached = false;

  let retVal = {
    getMapping: () => {
      return deepestMappings.mappings;
    },
    deepestStickingPointsList
  };

  if (options.useSlotsMaxDepth && !options.allowFailures) {
    console.log(
      "Warning: at the moment you should use 'useSlotsMaxDepth' in conjuction with 'allowFailures'"
    );
    debugger;
  }

  // let maxDepth
  // if (options.useSlotsMaxDepth && climbAssemblies.length < slots.length) {
  //   maxDepth = slots.length;
  // } else {
  //   maxDepth = Math.min(climbAssemblies.length, slots.length);
  // }

  /*
   *
   * SETUP ALL THE FUNCTIONS
   *
   */
  function pushToStructure(slotIndex, climbIndex) {
    if (debugMode) {
      if (currentMappings.hasClimb(climbIndex)) {
        console.log(
          "ERRORxxx: This climb Index " + climbIndex + " is already in the list"
        );
        debugger;
      }
    }

    if (typeof slots[slotIndex].wallAssembly !== "undefined") {
      slots[slotIndex].wallAssembly.slotsInBuiltStructure++;
      wallToGradeListMap
        .get(slots[slotIndex].wallAssembly)
        .push(getId(climbIndex, climbAssemblies));
    }

    currentMappings.addMappingPropsMSC(true, slotIndex, climbIndex);
  }

  function popFromStructure() {
    let item = currentMappings.popWithoutRecycle();

    if (
      typeof item.slotIndex !== "undefined" &&
      typeof slots[item.slotIndex].wallAssembly !== "undefined"
    ) {
      slots[item.slotIndex].wallAssembly.slotsInBuiltStructure--;
      wallToGradeListMap.get(slots[item.slotIndex].wallAssembly).pop();
    }

    recycler.recycleMappingObject(item);
  }

  function isSlotUnavailableBecauseOfUnSlottedRoute(slotIndex) {
    /*      if (
        typeof slots[slotIndex].wallAssembly !== "undefined" &&
        typeof slots[slotIndex].wallAssembly.eligibleSlots !== "undefined"
        ) {*/

    // The eligibleSlots and unslottedClimbCount are only present for some of the use of executeRouteAi
    if (
      slots[slotIndex].wallAssembly.unslottedClimbCount +
        slots[slotIndex].wallAssembly.slotsInBuiltStructure >
      slots[slotIndex].wallAssembly.eligibleSlots.length
    ) {
      return true;
    }

    return false;
  }

  function pushSlotLessClimbToStructure(climbIndex) {
    if (debugMode) {
      if (currentMappings.hasClimb(climbIndex)) {
        console.log(
          "ERRORxxx: This SLOTLESS climb Index " +
            climbIndex +
            " is already in the list"
        );
        debugger;
      }
    }
    currentMappings.addMappingPropsMSC(false, undefined, climbIndex);
  }

  function popSlotLessClimbFromStructure() {
    currentMappings.popAndRecycle();
  }

  function copyBuiltToDeepest() {
    if (debugMode) {
      if (deepestMappings.mappings.length > currentMappings.mappings.length) {
        console.log("WARNINGG: Copying a smaller bbuilt structure??");
        debugger;
      }
    }

    // I can't push the currentMappings objects directly into the deepest
    // because later in time they might be recylcled when popped from the
    // currentMappings.
    for (let i = 0; i < currentMappings.mappings.length; i++) {
      deepestMappings.overwritePositionMSC(
        currentMappings.mappings[i].isMapped,
        currentMappings.mappings[i].slotIndex,
        currentMappings.mappings[i].climbIndex,
        i
      );
    }
  }

  function willBeDuplicateFn(gradeId, wallAssembly) {
    //this__.wallToGradeListMap = new Map();

    if (
      (checkPreExistingRoutes &&
        wallAssembly.gradesOfPreexistingRoutes.includes(gradeId)) ||
      wallToGradeListMap.get(wallAssembly).includes(gradeId)
    ) {
      return true;
    }
    return false;
  }

  function getId(x) {
    let climbId;
    if (typeof climbAssemblies[x].climbingGrade !== "undefined") {
      climbId = climbAssemblies[x].climbingGrade.id;
    } else {
      climbId = climbAssemblies[x].id;
    }
    return climbId;
  }
  /*
   *
   * END FUNCTION SETUP
   *
   */

  for (let slot of slots) {
    if (typeof slot.wallAssembly !== "undefined") {
      slot.wallAssembly.slotsInBuiltStructure = 0;
    }
  }

  const slotWrappers = [];
  for (let i = 0; i < slots.length; i++) {
    slotWrappers.push({
      originalSlotIndex: i,
      slot: slots[i],
      isUsed: false
    });
  }

  for (let x = 0; x < climbAssemblies.length; x++) {
    let climbId = getId(x, climbAssemblies);
    let validSlotWrappers = validSlotWrappersMap.get(climbId);

    if (!validSlotWrappers) {
      validSlotWrappers = [];
      validSlotWrappersMap.set(climbId, validSlotWrappers);
    }

    for (let i = 0; i < slots.length; i++) {
      let slot = slots[i];

      // we need to put an actualclimb into a slot
      // and mark it as taken
      //var tab = "";
      //for (var x = 0; x < climbMapCounter; x++){
      //	tab += " ";
      //}
      //console.log("ClimbMapCounter = " + climbMapCounter + "...Slot Pointer = " + this__.climbMap[climbMapCounter].slotPointers[i] + " for position i=" + i);

      for (let gradeIndex = 0; gradeIndex < slot.length; gradeIndex++) {
        if (slot[gradeIndex] === climbId) {
          //This slot can be used by this grade so add it to the valid slot wrappers
          // if it hasn't been added already
          if (!validSlotWrappers.includes(slotWrappers[i])) {
            validSlotWrappers.push(slotWrappers[i]);
          }
          break;
        }
      }
    }
  }

  // This sorting is to optimize the gymTest
  // If we don't put the hard to fill slots at the start then it takes ages.
  // If the length is 0 then the slot is open and needs to be at the end of the list
  let bySpecificity = ({ slot: slotA }, { slot: slotB }) => {
    if (slotA.length === 0) {
      if (slotB.length === 0) return 0;
      // else slotB has specified grades and therefore should be after slotA
      else return -1;
    }
    if (slotB.length === 0) {
      //Don't need to check for slotB == 0 because it was done above
      return 1;
    } else {
      //The slot with the longest list of grades is least specific and
      // therefore should go further back.
      return slotA.length - slotB.length;
    }
  };

  for (let slotWrappers of validSlotWrappersMap.values()) {
    slotWrappers.sort(bySpecificity);
  }

  /*
   * The wallToGradeListMap is used to check for duplicates
   */

  for (let slot of slots) {
    if (!wallToGradeListMap.has(slot.wallAssembly)) {
      wallToGradeListMap.set(slot.wallAssembly, []);
    }
  }

  function addStickingPoint(id, identifiableObject) {
    if (!deepestStickingPoints[id]) {
      let newStickingPoint = {
        count: 0,
        identifiableObject: identifiableObject
      };
      deepestStickingPoints[id] = newStickingPoint;
      deepestStickingPointsList.push(newStickingPoint);
    }
    deepestStickingPoints[id].count++;
  }

  function iterateToSolve(iterateClimbIndex, hasFailedAlready, failedCount) {
    iterationsCounter++;

    // If we have failed already the deepest search so far
    // and it has the fewest fails
    // then we need to copy the current best effort.
    let currentDepth = currentMappings.mappings.length;
    let deepestDepth = deepestMappings.mappings.length;

    //    if (currentDepth === 18) {
    //      debugger;
    //    }
    if (
      hasFailedAlready &&
      currentDepth - failedCount >
        deepestDepth - countOfFailsAtDeepestSearchSoFar
    ) {
      copyBuiltToDeepest();
      countOfFailsAtDeepestSearchSoFar = failedCount;
    }

    // Else if we haven't failed then we need to mark this as
    // the best search so far if it is deeper.
    else if (!hasFailedAlready && deepestDepth < currentDepth) {
      //BROOK: Should this be deepestDepth - this__.countOfFailsAtDeepestSearchSoFar to include the fails in the depth?
      copyBuiltToDeepest();
    }

    deepestDepth = deepestMappings.mappings.length;

    if (climbAssemblies.length === iterateClimbIndex) {
      // If the deepest search so far got all the way to the end as well
      // then we need to check to see if this attempt has less fails than the deepest search so far.
      // If it does have less fails then it is the best attempt so far
      if (failedCount < countOfFailsAtDeepestSearchSoFar) {
        copyBuiltToDeepest();
      }

      // If there aren't enough routes to fill all the slots
      // Then we have failed
      if (currentMappings.mappings.length < slots.length) {
        if (debugMode) {
          console.log(
            "BEST failed = " +
              failedCount +
              " current=" +
              currentDepth +
              " deepest=" +
              deepestDepth +
              " slots.length=" +
              slots.length
          );
        }
        bestEffortReached = true;
        return false;
      }

      //There are more options and there were some failures
      if (currentMappings.mappings.length > slots.length && failedCount > 0) {
        return false;
      }

      //console.log("Ended failed = " + failedCount + " current=" + currentDepth + " deepest=" + deepestDepth + " count=" + this__.iterationsCounter);
      //otherwise stop with success
      return true;
    }

    if (iterationsCounter % 1000 === 0) {
      let difference = Date.now() - startTime;
      if (debugMode) {
        console.log(
          "Iterating: failed = " +
            failedCount +
            " \t current=" +
            currentDepth +
            " \t deepest=" +
            deepestDepth +
            " \t count=" +
            iterationsCounter +
            "\t milliseconds=" +
            difference
        );
      }
      if (difference > MAX_MS) {
        console.log("Out of time");
        console.log(
          "Times Up: failedCount = " +
            failedCount +
            " \t current=" +
            currentDepth +
            " \t deepest=" +
            deepestDepth +
            " \t count=" +
            iterationsCounter +
            "\t milliseconds=" +
            difference
        );
        outOfTime = true;
        if (debugMode) {
          printMappings(deepestMappings.mappings, slots, climbAssemblies);
        }
      }
    }

    if (outOfTime) {
      return false;
    }

    let objectId = getId(iterateClimbIndex, climbAssemblies);

    if (climbAssemblies[iterateClimbIndex].climbingGrade === null) {
      debugger;
    }

    let validSlotWrappers = validSlotWrappersMap.get(objectId);

    for (let validSlotWrapper of validSlotWrappers) {
      // if this slot has NOT been used already then continue

      if (
        validSlotWrapper.isUsed ||
        (options.checkEligibleSlots &&
          isSlotUnavailableBecauseOfUnSlottedRoute(
            validSlotWrapper.originalSlotIndex
          ))
      ) {
        //do nothing because this slot has been used.
        // or the wall that it belongs to has been filled because there are unslotted routes taking up space.
      } else {
        // we need to put an actualclimb into a slot
        // and mark it as taken
        //var tab = "";
        //for (var x = 0; x < climbMapCounter; x++){
        //	tab += " ";
        //}
        //console.log("ClimbMapCounter = " + climbMapCounter + "...Slot Pointer = " + this__.climbMap[climbMapCounter].slotPointers[i] + " for position i=" + i);

        if (
          iterateClimbIndex === 63 &&
          validSlotWrapper.originalSlotIndex === 44
        ) {
          debugger;
        }
        if (
          options.skipDuplicateCheck ||
          !willBeDuplicateFn(objectId, validSlotWrapper.slot.wallAssembly)
        ) {
          pushToStructure(
            validSlotWrapper.originalSlotIndex,
            iterateClimbIndex
          );

          validSlotWrapper.isUsed = true;

          if (
            iterateToSolve(iterateClimbIndex + 1, hasFailedAlready, failedCount)
          ) {
            //It we have already failed then we deed to dismantle the currentMappings
            // as we return from the recursions
            if (hasFailedAlready) {
              popFromStructure();
            }
            // We succeeded
            return true;
          }

          //If we reached the end, and had not skipped some slots
          //Then this is the best we can do
          if (bestEffortReached && failedCount === 0) {
            return false;
          }

          if (outOfTime) {
            return false;
          }
          validSlotWrapper.isUsed = false;

          // We failed for this configuration.
          // we need to continue the loop
          // and try the next slot
          // but first we need to mark this slot as open
          popFromStructure();
        }
      }
    }

    //Add a tally to where we got stuck
    addStickingPoint(objectId, climbAssemblies[iterateClimbIndex]);

    //if we got to here then we have failed.
    // Either no satisfactory slot was found or a satisfactory slot was found but
    // the continuing iterateToSolves failed.
    if (options.allowFailures === true) {
      //We should only continue if we either haven't reached the very end (bestEffortReached)
      //Or we did reach it but we have less failures so far
      /* BROOK: I changed this on the 16th of June 2018
      if (
        this__.countOfFailsAtDeepestSearchSoFar > failedCount + 1 ||
        this__.bestEffortReached
      ) {*/
      if (
        (countOfFailsAtDeepestSearchSoFar > failedCount + 1 &&
          bestEffortReached) ||
        !bestEffortReached
      ) {
        // Now we need to try to find the best solution WITHOUT using up this slot.
        pushSlotLessClimbToStructure(iterateClimbIndex);

        // And we have to call the iterateToSolve again.
        iterateToSolve(iterateClimbIndex + 1, true, failedCount + 1);

        // We need to remove the slotLessClimb that we added above.
        popSlotLessClimbFromStructure();
      }
    }

    return false;
  }
  /*
   * Do the actual iteration.
   */
  retVal.succeeded = iterateToSolve(0, false, 0);

  //this__.printDeepestStructure();

  if (debugMode) {
    //We now have a structure of all the climbs to slots
    // but there might be unassigned slots so we need to add them to the structure
    // we need to use the deepestSearchSoFar structure because that is the final structure.
    console.log("The final structure looks like this:");
    console.log(JSON.stringify(currentMappings.mappings));
    console.log("");
    console.log("The PRE final deepest Structure looks like this");
    console.log(JSON.stringify(deepestMappings.mappings));
  }
  /*        for (var i = 0; i < deepestMappings.mappings.length; i++){
        	if (typeof deepestMappings.mappings[i].equivalentSlot === "undefined")
        	{
        		console.log("The equivalentSlot for index " + i);
        	}
        	else
        	{
	        	console.log("here is the slotString " + deepestMappings.mappings[i].equivalentSlot.slotStr);
    	    }
        }
*/

  /*
   * Now we need to add any empty slots to the end
   *
   */
  for (let i = 0; i < slots.length; i++) {
    deepestMappings.addMappingIfMissing(i);
  }

  /*
   * Now we need to add any slotless climbs to the end
   */
  for (let ci = 0; ci < climbAssemblies.length; ci++) {
    if (!deepestMappings.hasClimb(ci)) {
      deepestMappings.addMappingPropsMSC(false, undefined, ci);
    }
  }

  retVal.slotsFilled = deepestMappings.getSlotsFilledCount();

  return retVal;
} // end constructor of RouteAI
