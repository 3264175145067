import "moment-timezone";

import { observable } from "mobx";

export default function getClaimStore(
  context,
  asyncOpCreate,
  httpCodeHandlerFactory
) {
  const rest = context.rest;

  const this__ = observable({
    gym_id: undefined,
    userName: undefined,
    passcode: undefined
  });

  let errorHandler = httpCodeHandlerFactory.getDefaultCopy();

  this__.opClaim = asyncOpCreate(
    payload => rest.get("claimRegistration", payload),
    "opClaimRegistration",
    errorHandler
  );

  this__.claim = (callBack, status404Callback) => {
    let payload = {
      gym_id: this__.gym_id,
      passcode: this__.passcode,
      userName: this__.userName
    };

    errorHandler.handleStatusFns[404] = status404Callback;
    this__.opClaim.execute(payload, callBack);
  };

  return this__;
}
