import moment from "moment";

import { planTypes } from "../variables/constants";

/*
 * This function first gets the list of routes visible on the screen via getFilteredSerialLongevityObject().filteredSerialLongevityOrderedClimbAssemblies
 * Then it pretends to strip the minimum of all the suggested route, or, the numberToSet
 *
 */
export default {
  generatePlanForSerialLongevity: function(
    rootStore,
    numberToPretendStrip,
    numberToSet,
    gradeType
  ) {
    let filteredSerialLongevityOrderedClimbAssemblies = rootStore.computedFilteredReportStore.getFilteredSerialLongevityObject(
      gradeType
    ).filteredSerialLongevityOrderedClimbAssemblies;

    let climbAssembliesToStrip = filteredSerialLongevityOrderedClimbAssemblies
      .slice(0, numberToPretendStrip)
      .map(longevityAssembly => longevityAssembly.climbAssembly);

    let newSettingPlans = this.generateSettingPlans(
      rootStore,
      numberToSet,
      climbAssembliesToStrip,
      gradeType,
      true //allowSETPlans
    );

    let sortedSettingPlans = this.getSortedSettingPlans(
      newSettingPlans,
      climbAssembliesToStrip
    );

    rootStore.settingPlanStores.settingPlanStore.createSettingPlans(
      sortedSettingPlans
    );
  },

  /*
   *
   * This function:
   * 1) Creates a bunch of fake strip plans based on the climbsToStrip
   * 2) Get the suggestions based on any real plans plus the fake plans
   * 3) Matches up each SET suggestion with a climbToStrip if possible, if a match is found the
   * this match of SET suggestion + climbAssemblyToStrip is added to the retVal...
   * i.e. create a REPLACE instead of a SET
   * 4) If a not suggestion matched a climbAssemblyToStrip then a STRIP settingPlan is added to the retVal
   */
  generateSettingPlans: function(
    rootStore,
    numberToSet,
    climbAssembliesToStrip,
    gradeType,
    allowSETPlans
  ) {
    const { computedSettingReportStore } = rootStore;

    // I copy the array so I don't have side effects of splicing the array in this function
    climbAssembliesToStrip = [...climbAssembliesToStrip];

    const settingPlansOrig =
      rootStore.settingPlanStores.settingPlanCacheStore.items;

    const settingPlans = [...settingPlansOrig];

    //
    //  1) Creates a bunch of fake strip plans based on the climbAssemblies to strip
    //

    for (let climbAssembly of climbAssembliesToStrip) {
      let climbingRoute = climbAssembly.climbingRoute;

      let newFakeSettingPlan = {
        climbing_wall_id: climbingRoute.climbing_wall_id,
        climbing_grade_id: climbingRoute.climbing_grade_id,
        planType: planTypes.STRIP,
        route_to_strip_id: climbingRoute.id,
        routeToStrip: climbingRoute,
        planningDate: moment()
          .utc()
          .toISOString()
      };
      settingPlans.push(newFakeSettingPlan);
    }

    const planningAssemblies = computedSettingReportStore.getSettingPlansWithWallAssemblies(
      settingPlans
    );

    //
    // 2) Gets the suggestions based on any real plans plus the fake plans
    //

    let suggestionsAssembly = computedSettingReportStore.getSuggestionsAssembly(
      rootStore,
      gradeType,
      planningAssemblies,
      {
        ignoreStrippingPlans: false,
        sortTheResult: false,
        includeOnHoldRoutes: true
      }
    );

    let newSettingPlans = [];

    let planningDate = moment()
      .utc()
      .toISOString();

    let totalSetOrReplaced = 0;

    let gymId = rootStore.userStores.profileStore.gym_id;

    let suggestion;
    let newSettingPlan;

    //
    // 3) Matches up each suggestion with a climbAssemblyToStrip if possible, if a match is found the
    // this match of suggest + climbAssemblyToStrip is added to the retVal
    //

    for (
      let i = 0;
      i < suggestionsAssembly.suggestions.length &&
      totalSetOrReplaced < numberToSet;
      i++
    ) {
      if (totalSetOrReplaced >= numberToSet) {
        break;
      }
      suggestion = suggestionsAssembly.suggestions[i];

      newSettingPlan = {
        planningDate,
        climbing_wall_id: suggestion.wallAssembly.climbingWall.id,
        climbing_grade_id: suggestion.climbingGrade.id,
        gym_id: gymId,
        isComplete: false
      };

      // This says whether the suggestion to set a grade is on the same wall as...
      // any of the climbAssembliesToStrip
      let isFromSameWall = false;
      let climbAssemblyFromSameWall = null;

      // Find a climbToStrip that belongs to the same wall as the SET suggestion
      // i.e. the wall had a space because we stripped this climb, so we can say that
      // this SET plan for this wall replaces the climbToStrip.
      for (
        let catsIndex = 0;
        catsIndex < climbAssembliesToStrip.length;
        catsIndex++
      ) {
        // If this climbToStrip belongs to the same wall as the suggestion
        if (
          climbAssembliesToStrip[catsIndex].wallAssembly.climbingWall.id ===
          suggestion.wallAssembly.climbingWall.id
        ) {
          climbAssemblyFromSameWall = climbAssembliesToStrip[catsIndex];

          // remove this climb from the CATS so that it isn't match twice
          climbAssembliesToStrip.splice(catsIndex, 1);
          isFromSameWall = true;
          break;
        }
      }

      newSettingPlan.moreDebug = true;
      // If there was a climb in the slot which has been pretend stripped
      // Then we need to make the stripping plan a REPLACE plan
      // So we can strip the climbToStrip route and replace it with the suggested climb
      if (isFromSameWall) {
        newSettingPlan.planType = planTypes.REPLACE;
        newSettingPlan.route_to_strip_id =
          climbAssemblyFromSameWall.climbingRoute.id;
        newSettingPlan.routeToStrip = climbAssemblyFromSameWall.climbingRoute;
      }
      // The newSettingPlan is already a "set" plan so use it IF this is desired
      // It is not desired when we are trying to find a single setting plan for a route to be stripped
      else if (allowSETPlans) {
        newSettingPlan.planType = planTypes.SET;
      }

      //
      // This newSettingPlan is complete and should be included in the retVal
      //
      if (isFromSameWall || allowSETPlans) {
        totalSetOrReplaced++;
        newSettingPlans.push(newSettingPlan);
      }
    }

    //If we haven't set or replace numberToSet routes then
    // We need to strip as many as possible
    if (totalSetOrReplaced < numberToSet) {
      // We need to keep a record of how many routes that we have stripped from this wall
      let wallsToCountsMap = {};

      for (let y = 0; y < climbAssembliesToStrip.length; y++) {
        //let gradeId = climbAssembliesToStrip[y].climbingGrade.id;

        // Get the record of routes stripped for this wall
        let counts =
          wallsToCountsMap[
            climbAssembliesToStrip[y].wallAssembly.climbingWall.id
          ];

        // If there was no record make a new one starting at 0
        if (typeof counts === "undefined") {
          counts = { strippedJustNow: 0 };
          wallsToCountsMap[
            climbAssembliesToStrip[y].wallAssembly.climbingWall.id
          ] = counts;
        }

        // Only strip a route if there are too many on this wall
        if (
          climbAssembliesToStrip[y].wallAssembly.climbingRouteAssemblies
            .length -
            counts.strippedJustNow >
          climbAssembliesToStrip[y].wallAssembly.slotConfigsCopy.length
        ) {
          //Record the fact that we stripped a route from this wall
          counts.strippedJustNow++;

          let newSettingPlan = {
            planningDate,
            climbing_wall_id:
              climbAssembliesToStrip[y].wallAssembly.climbingWall.id,
            climbing_grade_id: climbAssembliesToStrip[y].climbingGrade.id,
            route_to_strip_id: climbAssembliesToStrip[y].climbingRoute.id,
            routeToStrip: climbAssembliesToStrip[y].climbingRoute,
            gym_id: gymId,
            planType: planTypes.STRIP,
            isComplete: false
          };
          newSettingPlans.push(newSettingPlan);
        }
      }
    }

    return newSettingPlans;
  },

  getSortedSettingPlans: function(newSettingPlans, climbAssembliesToStrip) {
    /*
     * Now we must sort the suggestions so that they are in the same order as the climbAssemblies to strip
     */

    // Put the SET plans first
    let sortedSettingPlans = [];
    for (let settingPlan of newSettingPlans) {
      if (settingPlan.planType === planTypes.SET) {
        sortedSettingPlans.push(settingPlan);
      }
    }

    // Then order the STRIP plans next, ordered by the original order of climbAssembliesToStrip
    for (let climbAssembly of climbAssembliesToStrip) {
      for (let settingPlan of newSettingPlans) {
        if (settingPlan.planType === planTypes.STRIP) {
          if (
            climbAssembly.climbingRoute.id === settingPlan.route_to_strip_id
          ) {
            sortedSettingPlans.push(settingPlan);
          }
        }
      }
    }
    // Then order the REPLACE plans next, ordered by the original order of climbAssembliesToStrip
    for (let climbAssembly of climbAssembliesToStrip) {
      for (let settingPlan of newSettingPlans) {
        if (settingPlan.planType === planTypes.REPLACE) {
          if (
            climbAssembly.climbingRoute.id === settingPlan.route_to_strip_id
          ) {
            sortedSettingPlans.push(settingPlan);
          }
        }
      }
    }

    return sortedSettingPlans;
  }
};

/*
function getStuffFromWallAssembly(wallAssembly) {
  let mapFromSlotToClimb = new Map();
  let surplus = [];

  let options = {
    allowFailures: true,
    skipDuplicateCheck: true,
    checkEligibleSlots: false
  };

  let routeAi = new executeRouteAi(
    wallAssembly.climbingRouteAssemblies,
    wallAssembly.slotConfigsCopy,
    options
  );

  let hasSurplus =
    wallAssembly.slotConfigsCopy.length <
    wallAssembly.climbingRouteAssemblies.length;

  routeAi.getMapping().forEach(mapping => {
    // If the mapping has a slot and a route
    // Take note of the relationship
    if (mapping.isMapped) {
      mapFromSlotToClimb.set(
        wallAssembly.slotConfigsCopy[mapping.slotIndex],
        wallAssembly.climbingRouteAssemblies[mapping.climbIndex]
      );
      return;
    }
    // else if the mapping has just a route
    // and this wall has a surplus of routes at the moment
    if (
      hasSurplus &&
      typeof mapping.climbIndex !== "undefined" &&
      mapping.climbIndex !== null
    ) {
      surplus.push(wallAssembly.climbingRouteAssemblies[mapping.climbIndex]);
    }
  });

  return {
    mapFromSlotToClimb,
    surplus
  };
}
*/
