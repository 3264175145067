import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Accordion_ui from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";

import styles from "../../assets/jss/material-dashboard-pro-react/components/accordionStyle.js";

const useStyles = makeStyles(styles);

export default function Accordion(props) {
  const [active, setActive] = React.useState(props.active);

  const handleChange = panel => (event, expanded) => {
    setActive(expanded ? panel : -1);
  };
  const classes = useStyles();
  const { collapses, onChange, unmountOnExit, style } = props;

  const actualActive =
    typeof props.overrideActive !== "undefined" ? props.overrideActive : active;

  return (
    <div className={classes.root}>
      {collapses.map((collapse, key) => {
        return (
          <Accordion_ui
            expanded={actualActive === key}
            onChange={onChange ? onChange(key) : handleChange(key)}
            key={key}
            classes={{
              root: classes.expansionPanel,
              expanded: classes.expansionPanelExpanded
            }}
            TransitionProps={{ unmountOnExit: unmountOnExit }}
            style={style}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.expansionPanelSummaryExpaned,
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expansionPanelSummaryExpandIcon
              }}
            >
              <h4 className={classes.title}>{collapse.title}</h4>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanelDetails}>
              {collapse.content}
            </AccordionDetails>
          </Accordion_ui>
        );
      })}
    </div>
  );
}

Accordion.defaultProps = {
  active: -1
};

Accordion.propTypes = {
  // index of the default active collapse
  active: PropTypes.number,
  overrideActive: PropTypes.number,
  onChange: PropTypes.func,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      content: PropTypes.node
    })
  ).isRequired,
  unmountOnExit: PropTypes.bool,
  style: PropTypes.object
};
