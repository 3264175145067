import React from "react";

import { logger } from "ticker-core";

import {
  GridContainer,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button
} from "ticker-components";

class MainErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, showError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logger.log(
      "A render error occured",
      error.message && error.message ? error.message : error,
      errorInfo
    );
    this.setState({ error: error, errorInfo: errorInfo });
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <GridContainer justify="center">
            <GridItem>
              <form>
                <Card style={{ maxWidth: "500px" }} login>
                  <CardHeader color="rose">
                    <h4>Whoops. Failed to render.</h4>
                  </CardHeader>
                  <CardBody>
                    {this.state.showError && (
                      <>
                        <div>{JSON.stringify(this.state.error)}</div>
                        <div>{JSON.stringify(this.state.error.message)}</div>
                        <div>{JSON.stringify(this.state.errorInfo)}</div>
                      </>
                    )}
                    {!this.state.showError && (
                      <>
                        <Button
                          color="white"
                          onClick={() => this.setState({ showError: true })}
                        ></Button>
                      </>
                    )}
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="primary"
                      round
                      onClick={() => {
                        var arr = window.location.href.split("/");

                        window.location.href =
                          arr[0] + "//" + arr[2] + "/" + arr[3];
                      }}
                    >
                      Click here to start again
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
    return this.props.children;
  }
}

export default MainErrorBoundary;
