import React, { memo } from "react";
import PropTypes from "prop-types";

import Fingerprint from "@material-ui/icons/Fingerprint";
import PersonAdd from "@material-ui/icons/PersonAdd";

import { withRootStore, coreStyle } from "ticker-core";
import { Button } from "ticker-components";

import makeStyles from "@material-ui/styles/makeStyles";

let useStyles = makeStyles(coreStyle.listItemIconStyle);

function LoginRequest({ rootStore, instruction, onClick }) {
  let classes = useStyles();
  let content;
  /*
  isClimberSite
  if not hasLoaded - show user regs
  if hasLoaded notRegistered - show regs

  */

  console.log("Rendering loginRequest");

  let userLoginBtn = (
    <Button
      simple
      color="info"
      size="text"
      onClick={() => {
        onClick && onClick();
        rootStore.history.push(rootStore.context.config.userLoginPath);
      }}
    >
      <Fingerprint className={classes.listItemIcon} />
      log in
    </Button>
  );

  let userRegBtn = (
    <Button
      simple
      color="info"
      size="text"
      onClick={() => {
        onClick && onClick();
        rootStore.history.push(rootStore.context.config.userRegisterPath);
      }}
    >
      <Fingerprint className={classes.listItemIcon} />
      register
    </Button>
  );
  let regBtn = (
    <Button
      simple
      color="info"
      size="text"
      onClick={() => {
        onClick && onClick();
        rootStore.history.push(
          rootStore.context.config.registrationRegisterPath
        );
      }}
    >
      <PersonAdd className={classes.listItemIcon} />
      register
    </Button>
  );
  let claimBtn = (
    <Button
      simple
      color="info"
      size="text"
      onClick={() => {
        onClick && onClick();
        rootStore.history.push("registrationClaim");
      }}
    >
      <Fingerprint className={classes.listItemIcon} />
      claim
    </Button>
  );

  let loginBtn = (
    <Button
      simple
      color="info"
      size="text"
      onClick={() => {
        onClick && onClick();
        rootStore.history.push(rootStore.context.config.registrationLoginPath);
      }}
    >
      <Fingerprint className={classes.listItemIcon} />
      log in
    </Button>
  );

  if (rootStore.isClimberSite) {
    if (!rootStore.userStores.profileStore.hasLoaded) {
      content = (
        <>
          {userLoginBtn}
          {" or "}
          {userRegBtn}
        </>
      );
    } else if (!rootStore.regStores.isRegistered()) {
      content = (
        <>
          {regBtn}
          {" or "}
          {claimBtn}
        </>
      );
    }
  } else if (rootStore.isPublicSite) {
    debugger;
    content = (
      <>
        {regBtn}
        {" or "}
        {loginBtn}
      </>
    );
  } else {
    return (
      <>You are using the gym interface. You are unable to {instruction}</>
    );
  }
  return (
    <>
      Please {content}
      <br />
      {instruction}
    </>
  );
}

LoginRequest.propTypes = {
  rootStore: PropTypes.object.isRequired,
  instruction: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default memo(withRootStore(LoginRequest));
